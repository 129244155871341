<template>
	<div v-if="grupo" class="container proximas-reservas-detalle">
		<div class="contenedor">
			<div class="row">
				<p class="col text-primary fw-bold fs-4 text-title" style="cursor: pointer" @click="doVolver">
					<i class="fa-solid fa-chevron-left me-2"></i>{{ $t('baseMigas.datosReserva') }}
					{{ grupo.reservas.length > 0 ? grupo.reservas[0].nombreUsuario : '' }}
				</p>
				<div v-if="allReservasPayed && hasAlojamiento && !grupo.viaje.buscador" class="col-12 text-end">
					<button class="btn btn-primary" @click="goToCheckIn">
						{{ $t('general.checkInOnline') }}
					</button>
				</div>
				<div class="pt-2">{{ $t('reserva.mensajeThankYouCompleto') }}</div>
				<div class="contenedor-occupancy justify-content-center mt-2 px-4">
					<!-- Miembros de la reserva -->
					<CardOccupancy
						:total-occupancies="grupo.habitacion && !grupo.habitacion.isComun ? grupo.habitacion.numPlazas : -1"
						:occupants-info="ocupantes"
						:plazas-libres-repercutidas="grupo.habitacion?.plazasLibresRepercutidas"
						:permitir-compartir="true" />
				</div>

				<CardReservaPlazasLibres
					v-if="mostrarPlazasLibres"
					class="mt-2"
					:plazas-libres="cantidadPlazasLibres"
					:plazas-ocupadas="ocupantes.length"
					:pago-pendiente="totalPendientePlazaLibre"
					:fecha-cancelacion="fechaLimiteToFillRoom"
					@copiar-link="copyUrlUnirse"
					@abonar-pago="handlerAbonarPagoPendiente" />

				<template v-if="grupo.habitacion?.plazasLibresRepercutidas">
					<i18n-t :keypath="'cardReservaPlazasLibres.msjPlazaLibresPagadas'" tag="div">
						<template #plazasLibres>
							<span class="fw-bold">{{ cantidadPlazasLibres }}</span>
						</template>
					</i18n-t>
				</template>
				<CardUrlUnirse
					v-if="!mostrarPlazasLibres && !grupo.viaje.buscador"
					:viaje="grupo.viaje"
					:codigo-grupo="grupo.codigo" />

				<div class="mt-3 mb-2">
					<CardLateralReserva
						:disable-collapse="true"
						:qr-visible="true"
						:doc-visible="true"
						:grupo-id="grupo.id"
						@recuperar-reserva="showModalRecuperar = true"
						@show-modal-qr="showModalQrReserva = true" />
					<ModalQrReserva :grupo="grupo" :modal-active="showModalQrReserva" @close-modal="showModalQrReserva = false" />
				</div>

				<div class="col-12 mb-3 d-flex row p-0">
					<div class="col-12 col-md-4">
						<button
							v-if="isEnabledModificar() && moreOfAnActiveReservation"
							class="btn btn-link"
							@click="showModalCancelar = true">
							{{ $t('botones.cancelar') }}
						</button>
					</div>
					<div v-if="!grupo.viaje.buscador" class="col-12 col-md-8 text-end p-0">
						<button
							v-if="isEnabledModificar() && moreOfAnActiveReservation"
							class="btn btn-primary"
							@click="
								$router.push({
									name: 'UsuarioProximaReservasModificar',
								})
							">
							{{ $t('botones.modificarReserva') }}
						</button>

						<button
							v-if="!grupo.viaje.infoPago && totalPendiente > 0"
							id="button-pagar"
							class="btn btn-primary"
							@click="abrirModalPagar">
							{{ $t('botones.pagar') }}
						</button>

						<button
							v-else-if="grupo.viaje.infoPago"
							class="btn btn-primary"
							@click="alertAutoCloseWarningWithoutCallback(4000, 'Información de pago: ' + grupo.viaje.infoPago)">
							{{ $t('botones.pagar') }}
						</button>
					</div>
				</div>
				<!-- Sección Informacion de transporte -->
				<template v-if="informacionBuses && Object.keys(informacionBuses).length !== 0">
					<div class="col-12 titulo mb-2">
						{{ $t('reserva.superGrupo.informacionTransporte') }}
					</div>
					<div id="info-buses" class="col-12 cuadro-info mb-2">
						<p class="text-primary fs-6 fw-bold">Número de autobús : {{ informacionBuses.nombre }}</p>
						Bus que parte desde {{ informacionBuses.lugarSalidaIda }} a las {{ informacionBuses.horaSalidaIda }} y
						retorna desde {{ informacionBuses.lugarSalidaVuelta }} a las
						{{ informacionBuses.horaSalidaVuelta }}
					</div>
				</template>

				<!-- Estado del pago de la reserva -->
				<div class="col-12 titulo mb-2">
					{{ $t('reserva.estadoPagoReserva') }}
				</div>
				<div v-if="!(grupo.viaje.hasPagoDiferido || totalPendiente <= 0)" class="col-12 text-red py-2">
					{{
						$t('reserva.msjNoPagos', { pendientePago: totalPendiente.toFixed(2), currency: agenciaStore.getCurrency })
					}}
				</div>

				<div class="col-12">
					<div class="tabla-pagos gx-0">
						<table>
							<thead>
								<tr>
									<th>{{ $t('reserva.concepto') }}</th>
									<th>{{ $t('general.fecha') }}</th>
									<th class="text-end">{{ $t('general.pagado') }}</th>
								</tr>
							</thead>
							<tbody>
								<tr v-for="(pago, concepto) in pagosAgrupados" :key="concepto">
									<td>
										<div class="py-2">
											{{ getPagosConcepto(concepto) }}
										</div>

										<template v-for="nombre in pago.nombres" :key="nombre">
											<div class="text-nombre">
												{{ nombre }}
											</div>
										</template>
									</td>
									<td>{{ pago.fecha }}</td>
									<td class="text-secondary text-number text-end">
										{{ pago.total.toFixed(2) }} {{ agenciaStore.getCurrency }}
									</td>
								</tr>

								<tr>
									<td><span class="text-pagado"> </span> {{ $t('subtitulo.total') }} {{ $t('general.pagado') }}</td>
									<td></td>
									<td class="text-secondary text-number text-end">
										{{ grupo.viaje.hasPagoDiferido ? totalPendiente.toFixed(2) : sumaTotalPagos(grupo.reservas) }}
										{{ agenciaStore.getCurrency }}
									</td>
								</tr>
							</tbody>
						</table>
					</div>
				</div>
				<!-- Sección Información de tu experiencia -->
				<template v-if="grupo.viaje.observaciones">
					<div class="col-12 titulo mb-2 pt-5">
						{{ $t('reserva.superGrupo.informacionViaje') }}
					</div>
					<div id="info-viaje" class="col-12 cuadro-info mb-2" @click="verMas()">
						<span
							v-sanitize-html="
								verMasInfoViaje ? grupo.viaje.observaciones : showOnlyBlockOfText(grupo.viaje.observaciones)
							" />

						<span class="mas-info">
							<i class="fa-solid fa-circle-info fa-xl"></i>
							{{ verMasInfoViaje ? $t('general.verMenos') : $t('general.masInformacion') }}
						</span>
					</div>
				</template>
			</div>
			<div class="container my-3">
				<div class="row justify-content-center text-center">
					<div class="col">
						<button class="btn btn-primary" @click="doVolver">
							{{ $t('botones.volverReservas') }}
						</button>
					</div>
				</div>
			</div>
			<div v-if="cargando" class="wait">
				<Spinner />
			</div>
		</div>

		<ModalPagarReserva :grupo="grupo" :modal-active="showModalPagar" @close-modal="showModalPagar = false" />

		<ModalRecuperacionReserva
			:grupo="grupo"
			:modal-active="showModalRecuperar"
			@close-modal="showModalRecuperar = false" />

		<ModalCancelarReserva :grupo="grupo" :modal-active="showModalCancelar" @close-modal="showModalCancelar = false" />

		<template v-if="grupo.habitacion">
			<ModalConfirmacionPlazasLibres
				:show="showModalPlazasLibres"
				:plazas-libres="cantidadPlazasLibres"
				:precio-total="totalPendientePlazaLibre"
				@on-close="showModalPlazasLibres = false"
				@on-cancel="handlerAnadirSuplemento"
				@on-submit="handlerGentePorReservar" />
		</template>
	</div>
	<div v-else>
		<Spinner />
	</div>
</template>

<script setup>
	import Spinner from '@/components/SpinnerLoading.vue';
	import { useRoute, useRouter } from 'vue-router';
	import { ref, computed, onBeforeMount } from 'vue';
	import { proximasExperiencias } from '@/store_pinia/proximasExperiencias';
	import { showOnlyBlockOfText } from '@/helpers/filters';
	import { alertAutoCloseWarningWithoutCallback } from '@/services/utils/AlertUtils';
	import { createToaster } from '@meforma/vue-toaster';
	import ReservasApiService from '@/services/api/skiandnight/ReservasApiService';
	import ModalPagarReserva from 'src/components/modales/Reserva/ModalPagarReserva.vue';
	import CardOccupancy from '@/modules/viajesGrupos/components/CardOccupancy.vue';
	import CardUrlUnirse from '@/components/card/unirse/CardUrlUnirse.vue';
	import ModalRecuperacionReserva from '@/components/modales/Reserva/ModalRecuperacionReserva';
	import ModalCancelarReserva from '@/components/modales/Reserva/ModalCancelarReserva.vue';
	import ModalConfirmacionPlazasLibres from '@/components/modales/viajesGrupo/ModalConfirmacionPlazasLibres.vue';
	import CardReservaPlazasLibres from '@/components/card/Reserva/CardReservaPlazasLibres.vue';
	import { occupantsNotManagedReservations, occupantsManagedReservations } from '@/services/utils/GrupoUtils';
	import { ESTADOS_RESERVA } from '@/constants/reservaConstants';
	import CardLateralReserva from '@/components/card/Reserva/CardLateralReserva.vue';
	import { informacionHabitacion } from '@/store_pinia/informacionHabitacion';
	import ModalQrReserva from '@/components/modales/Reserva/ModalQrReserva.vue';
	import { calculateTotalPendiente } from '@/services/utils/GrupoUtils';
	import { useI18n } from 'vue-i18n';
	import { CheckIn, UsuarioProximasReservas } from '@/router/RouterNames';
	import moment from 'moment';
	import { agenciaStoreModule } from '@/store_pinia/agencia';
	import { toRawDeep } from '@/helpers/reactivityUtils';

	const storeProxExp = proximasExperiencias();
	const storeHabitacion = informacionHabitacion();
	const agenciaStore = agenciaStoreModule();

	const route = useRoute();
	const router = useRouter();
	const showModalRecuperar = ref(false);
	const showModalPagar = ref(false);
	const showModalCancelar = ref(false);
	const grupoId = route.params.grupoId;
	const grupo = ref();
	const showModalQrReserva = ref(false);
	const cargando = ref(false);
	const verMasInfoViaje = ref(false);
	const informacionBuses = ref(null);
	const showModalPlazasLibres = ref(false);
	const { t } = useI18n();

	const fechaLimiteToFillRoom = computed(() => {
		if (!mostrarPlazasLibres.value) return null;
		let fechaLimite = moment(grupo.value.viaje.fechaLimitePago);
		if (grupo.value.viaje.daysToFillRoom > 0) {
			const fechaLimiteToFill = moment(grupo.value.habitacion.fechaCreacion).add(
				grupo.value.viaje.daysToFillRoom,
				'days'
			);
			fechaLimite = fechaLimite.isBefore(fechaLimiteToFill) ? fechaLimite : fechaLimiteToFill;
		}
		return fechaLimite.format('YYYY-MM-DD');
	});

	const toaster = createToaster({ position: 'top-right' });

	const moreOfAnActiveReservation = computed(
		() => grupo.value.reservas.filter(reserva => reserva.estado !== ESTADOS_RESERVA.CANCELADA).length >= 1
	);

	const allReservasPayed = computed(() =>
		grupo.value.reservas.every(reserva => reserva.estado === ESTADOS_RESERVA.PAGADA)
	);

	const hasAlojamiento = computed(() => grupo.value.habitacion);

	const mostrarPlazasLibres = computed(
		() =>
			grupo.value.habitacion &&
			!grupo.value.habitacion.isComun &&
			grupo.value.habitacion.plazasLibresEnabled &&
			!grupo.value.habitacion.plazasLibresRepercutidas &&
			moreOfAnActiveReservation.value &&
			grupo.value.habitacion.numPlazas - ocupantes.value.length > 0 &&
			ocupantes.value.length >= grupo.value.habitacion.numPlazas / 2
	);

	const pagosAgrupados = computed(() => {
		const agrupados = {};
		grupo.value.reservas.forEach(reserva => {
			reserva.pagos.forEach(pago => {
				if (!agrupados[pago.concepto]) {
					agrupados[pago.concepto] = {
						total: 0,
						nombres: [],
						fecha: pago.fecha,
					};
				}
				agrupados[pago.concepto].total += pago.cantidad;
				if (!agrupados[pago.concepto].nombres.includes(reserva.nombreUsuario)) {
					agrupados[pago.concepto].nombres.push(reserva.nombreUsuario);
				}
			});
		});
		return agrupados;
	});

	const totalPendiente = computed(() => calculateTotalPendiente(grupo.value.viaje, grupo.value.reservas));

	const ocupantes = computed(() => {
		const ocupantesNoGestionados = occupantsNotManagedReservations(grupo.value);
		const ocupantesGestionados = occupantsManagedReservations(grupo.value);
		return [...ocupantesNoGestionados, ...ocupantesGestionados];
	});

	const totalPendientePlazaLibre = computed(() => {
		if (grupo.value.habitacion) {
			return (
				(grupo.value.habitacion.numPlazas - ocupantes.value.length) * grupo.value.habitacion.alojamiento.precioCamaVacia
			);
		} else {
			return 0;
		}
	});

	const cantidadPlazasLibres = computed(() => {
		if (grupo.value.habitacion) {
			return grupo.value.habitacion.numPlazas - ocupantes.value.length;
		} else {
			return 0;
		}
	});

	function verMas() {
		verMasInfoViaje.value = !verMasInfoViaje.value;
	}

	function getPagosConcepto(concepto) {
		return concepto.includes('.') ? t(`reserva.pagos.${concepto}`) : concepto;
	}

	function sumaTotalPagos(reservas) {
		let sum = 0;
		reservas.map(({ pagos }) => {
			pagos.map(({ cantidad }) => {
				sum += cantidad;
			});
		});
		return sum.toFixed(2);
	}

	function doVolver() {
		router.push({
			name: UsuarioProximasReservas,
		});
	}

	function goToCheckIn() {
		router.push({
			name: CheckIn,
			params: {
				grupoId: grupoId,
				reservaId: grupo.value.reservas[0].id,
			},
		});
	}

	function isEnabledModificar() {
		if (grupo.value?.viaje?.fechaInicio) {
			let date = new Date(grupo.value.viaje.fechaInicio);
			let today = new Date();
			return date > today;
		}
		return true;
	}

	function copyUrlUnirse() {
		const dominioActual = window.location.origin;
		let url = `${dominioActual}/grupos/viajes/${grupo.value.viaje.id}/unirse/${grupo.value.codigo}`;
		let urlFinal = grupo.value.viaje.codigo ? url + `?codigo=${grupo.value.viaje.codigo.toUpperCase()}` : url;
		navigator.clipboard
			.writeText(urlFinal)
			.then(() => {
				toaster.success('URL copiada al portapapeles');
			})
			.catch(() => {
				toaster.error('Error al copiar la URL');
			});
	}

	function handlerAbonarPagoPendiente() {
		ReservasApiService.doForzarPlazasLibres(grupoId)
			.then(() => {
				getInformacionGrupo(grupoId).finally(async () => {
					await init();
					if (!grupo.value.viaje.infoPago && totalPendiente.value > 0) {
						document.getElementById('button-pagar').click();
					}
				});
			})
			.catch(err => {
				toaster.error(err);
			});
	}

	async function getInformacionGrupo(grupoId) {
		await storeProxExp.setGrupoSeleccionado(grupoId, false);
		grupo.value = storeProxExp.getGrupoSeleccionado;
	}

	function abrirModalPagar() {
		if (mostrarPlazasLibres.value) {
			showModalPlazasLibres.value = true;
		} else {
			showModalPagar.value = true;
		}
	}

	//Modal Confirmacion Plazas Libres
	function handlerAnadirSuplemento() {
		showModalPlazasLibres.value = false;
		handlerAbonarPagoPendiente();
	}

	function handlerGentePorReservar() {
		showModalPlazasLibres.value = false;
		showModalPagar.value = true;
	}

	async function init() {
		await getInformacionGrupo(grupoId);
		storeHabitacion.clearReservas();
		const grupoRaw = toRawDeep(grupo.value);
		storeHabitacion.setInformacionAlojamientoFromRGrupoDTO(grupoRaw);

		if (grupoRaw.viaje && grupoRaw.viaje.id) {
			await storeHabitacion.loadInformacionViaje(grupoRaw.viaje.id, grupoRaw.viaje.codigo ?? null);
		}
		grupoRaw.reservas.map(reserva => {
			reserva.usuario = {
				nombre: reserva.nombre,
				apellidos: reserva.apellidos,
				apellido: reserva.apellidos,
			};
			storeHabitacion.addReserva(reserva);
		});
	}

	onBeforeMount(async () => {
		await init();
	});
</script>

<style lang="scss" scoped>
	@media screen and (max-width: 1000px) {
		.contenedor {
			margin: 0 12px;
		}

		.titulo {
			font-size: 16px !important;
		}

		.tabla-pagos {
			thead {
				font-size: 12px !important;
			}

			font-size: 11px !important;
		}
		.text-number {
			font-size: 11px !important;
		}

		.btn-primary {
			font-size: 13px !important;
		}

		.btn-link {
			font-size: 12px !important;
		}
		.text-grupo {
			font-size: 14px !important;
		}

		.proximas-reservas-detalle {
			padding: 8rem 0 1rem 0 !important;
		}
	}

	.wait {
		position: fixed;
		top: 0;
		left: 0;
		width: 100vw;
		height: 100vh;
		z-index: 100;
		background-color: rgb(154, 154, 154, 0.5);
		padding-top: 40vh;
	}

	.proximas-reservas-detalle {
		background-color: white;
		min-height: 80vh;
		background-size: contain;
		background-position: top;
		color: var(--bs-primary);
		font-weight: 400;
		font-size: 0.8rem;
		padding: 12rem 0 1rem 0;
	}

	.titulo {
		font-size: 20px;
		font-weight: 500;
	}

	@media (min-width: 576px) {
		.modal-dialog {
			max-width: 800px;
			margin: 1.75rem auto;
		}
	}

	@media (min-width: 1000px) {
		.proximas-reservas-detalle {
			max-width: 71.8vw !important;
		}
	}

	.tabla-pagos {
		background-color: #f9f7f8;
		border-radius: var(--bs-border-radius-xl);
		font-size: 14px;
		padding: 0.5rem 1.25rem 1.25rem 1.25rem;

		font-size: 15px;
		font-weight: 400;

		table {
			width: 100%;
		}

		thead {
			font-size: 16px;
			font-weight: 500;
		}

		div {
			padding-bottom: 1rem;
			padding-right: 1rem;
		}

		th {
			border-bottom: 0.5px solid var(--bs-primary);
			font-size: 16px;
			font-weight: 500;
		}

		td {
			padding: 0.6rem 0.4rem;
			vertical-align: top;

			.text-nombre {
				font-size: 12px;
				font-weight: 400;
				padding: 0 0 0 10px;
			}

			.text-pagado {
				font-size: 15px;
				font-weight: 500;
			}
		}

		th {
			padding: 0.75rem;
			vertical-align: top;
		}
	}

	.cuadro-info {
		background-color: #f9f7f8;
		width: 98%;
		border-radius: var(--bs-border-radius-xl);
		padding: 1rem;
		margin: 0 auto;
	}

	.mas-info {
		float: right;
		cursor: pointer;
		font-size: 0.7rem;
		font-weight: 400;
	}

	.icono-mas-info {
		width: 1rem;
		margin-left: 0.7rem;
		margin-bottom: 0.5px;
	}

	.boton-supergrupo {
		cursor: pointer;
		display: flex;
		align-items: center;
		justify-content: center;
		padding-top: 0.5rem;
		width: 2rem;
		height: 2rem;
		border-radius: 50%;
		background-color: var(--bs-secondary);
		box-shadow: 0.1rem 0.1rem 0.2rem rgba(0, 0, 0, 0.5), -0.1rem -0.1rem 0.2rem rgba(255, 255, 255, 0.5);
		transition: all 0.2s ease;
		border: 0;
	}

	.icono-supergrupo {
		width: 1.5rem;
		margin-bottom: 0.5rem;
	}

	.boton-supergrupo:hover {
		box-shadow: 0.1rem 0.1rem 0.2rem rgba(255, 255, 255, 0.5), -0.1rem -0.1rem 0.2rem rgba(0, 0, 0, 0.5);
	}

	.boton-supergrupo:active {
		background-color: var(--bs-secondary);
	}

	.dropdown-items-supergrupo {
		margin-top: -1rem !important;
		margin-left: 1rem !important;
		padding: 0.5rem;
		font-size: 0.9rem;
		box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);

		.dropdown-item {
			padding: 0 0.5rem;
		}
	}

	.row {
		padding-bottom: 0;
	}

	.text-occupancies {
		font-size: 18px;
		font-weight: 400;
	}

	.btn-primary {
		text-align: center;
		font-size: 14px;
		font-weight: 500;
	}

	.btn-link {
		text-align: center;
		font-size: 16px;
		font-weight: 600;
	}

	.text-number {
		font-size: 15px;
		font-weight: 700;
	}

	.text-grupo {
		font-size: 15px;
		font-weight: 400;
	}

	.text-red {
		color: #f00;
		font-size: 14px;
		font-weight: 400;
	}
</style>
