import { defineStore } from 'pinia';

export const reservaHoteles = defineStore('reservaHoteles', {
	state: () => ({
		informacionHabitaciones: [],
		serviciosSelecionados: {},
		totalReserva: 0,
		reservaCreada: null,
	}),

	actions: {
		setServiciosHabitaciones(listaHabitaciones) {
			let totalHabitacionesServicios = [];
			listaHabitaciones.habitaciones.map((habitacion, indexHabitacion) => {
				this.serviciosSelecionados[`habitacion${indexHabitacion}`] = {};

				let informacionMiembro = habitacion.miembrosHabitacion;

				let informacionMiembrosServiciosPredefinidos = [];

				informacionMiembro.map((miembroHabitacion, indexPersona) => {
					let serviciosMiembroHabitacion = miembroHabitacion.serviciosCategorizados;
					this.serviciosSelecionados[`habitacion${indexHabitacion}`][`persona${indexPersona}`] = {
						edad: miembroHabitacion.edad,
					};

					let informacionMiembroAux = {
						filtros: miembroHabitacion.filtrosServicios,
						servicios: {},
						serviciosPredefinidos: {},
						edad: miembroHabitacion.edad,
						soyYo: false,
					};

					serviciosMiembroHabitacion.map(tipoServicio => {
						let informacionServicioPredefinido = {};
						if (tipoServicio.categoria === 'forfait') {
							informacionServicioPredefinido = tipoServicio.servicios.find(({ nulo }) => !nulo);

							//Si es menor de edad
							if (informacionServicioPredefinido === undefined)
								informacionServicioPredefinido = tipoServicio.servicios[0];

							this.serviciosSelecionados[`habitacion${indexHabitacion}`][`persona${indexPersona}`][
								tipoServicio.categoria
							] = informacionServicioPredefinido;
						} else {
							informacionServicioPredefinido = tipoServicio.servicios[0];
						}

						this.serviciosSelecionados[`habitacion${indexHabitacion}`][`persona${indexPersona}`]['edad'] =
							miembroHabitacion.edad;

						this.serviciosSelecionados[`habitacion${indexHabitacion}`][`persona${indexPersona}`]['soyYo'] =
							indexHabitacion == 0 && indexPersona == 0;
						informacionMiembroAux.soyYo = indexHabitacion == 0 && indexPersona == 0;
						informacionMiembroAux.serviciosPredefinidos[tipoServicio.categoria] = informacionServicioPredefinido;
						informacionMiembroAux.servicios[tipoServicio.categoria] = tipoServicio.servicios;
					});

					informacionMiembrosServiciosPredefinidos.push(informacionMiembroAux);
				});
				totalHabitacionesServicios.push(informacionMiembrosServiciosPredefinidos);
			});
			this.informacionHabitaciones = totalHabitacionesServicios;
		},
		setServiciosSeleccionados(serviciosSeleccionados) {
			this.serviciosSelecionados = serviciosSeleccionados;
		},
		setTotalReserva(totalReserva) {
			this.totalReserva = totalReserva;
		},
		setSoyYo(indexHabitacion, indexPersona) {
			Object.keys(this.serviciosSelecionados).map((keyHabitacion, indexHabitacionAux) => {
				Object.keys(this.serviciosSelecionados[keyHabitacion]).map((keyPersona, indexPersonaAux) => {
					this.serviciosSelecionados[keyHabitacion][keyPersona].soyYo =
						indexHabitacion == indexHabitacionAux && indexPersona == indexPersonaAux;
				});
			});
		},
		setReservaCreada(reserva) {
			this.reservaCreada = reserva;
		},
	},

	getters: {
		nombresIncompletos(state) {
			return Object.keys(state.serviciosSelecionados).some(keyHabitacion => {
				return Object.keys(state.serviciosSelecionados[keyHabitacion]).some(keyPersona => {
					return !state.serviciosSelecionados[keyHabitacion][keyPersona].nombre;
				});
			});
		},
	},
});
