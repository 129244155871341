import * as mutationsTypes from './PasarelaPagoMutationsTypes';
import PasarelaPagoApiService from '@/services/api/skiandnight/PasarelaPagoApiService';

const state = {
	datosRedireccion: '',
};
const actions = {
	async solicitaCobroInicial({ commit }, { reservaId, codigoDescuento, pagoUnico }) {
		commit(mutationsTypes.SOLICITA_COBRO_INICIAL_REQUEST);
		return new Promise((resolve, reject) => {
			PasarelaPagoApiService.solicitaCobroInicial(reservaId, codigoDescuento, pagoUnico)
				.then(ppDatosRedireccionDTO => {
					commit(mutationsTypes.SOLICITA_COBRO_INICIAL_SUCCESS, ppDatosRedireccionDTO);
					resolve(ppDatosRedireccionDTO);
				})
				.catch(error => {
					commit(mutationsTypes.SOLICITA_COBRO_INICIAL_FAILURE, {
						error,
					});
					reject(error);
				});
		});
	},
};
const mutations = {
	// Solicita cobro inicial
	[mutationsTypes.SOLICITA_COBRO_INICIAL_REQUEST](state) {
		state.fetchingData = true;
		state.error = null;
	},
	[mutationsTypes.SOLICITA_COBRO_INICIAL_SUCCESS](state, { ppDatosRedireccionDTO }) {
		state.fetchingData = false;
		state.datosRedireccion = ppDatosRedireccionDTO;
		state.error = null;
	},
	[mutationsTypes.SOLICITA_COBRO_INICIAL_FAILURE](state, { error }) {
		state.fetchingData = false;
		state.error = error;
	},
};

export const pasarelaPago = {
	namespaced: true,
	state,
	actions,
	mutations,
};
