import * as mutationsTypes from './LoginMutationsTypes';
import LoginApiService from '../../services/api/skiandnight/LoginApiService';
import CookiesUtils from '../../services/utils/CookiesUtils';
import router from '@/router';

const state = {
	solicitudLoginSuccess: false,
	isLogged: false,
	usuario: {
		nombre: null,
		apellidos: null,
		roles: {},
		email: null,
	},
	fetchingData: false,
	error: null,
};

const actions = {
	obtieneInformacionUsuario({ commit }) {
		const usuario = CookiesUtils.getUsuarioFromLocalStorage();
		commit('guardaInformacionUsuario', usuario);
	},
	login({ commit }, { email, password }) {
		commit(mutationsTypes.LOGIN_REQUEST);
		return new Promise((resolve, reject) => {
			LoginApiService.login(email, password)
				.then(res => {
					localStorage.setItem('jwt-mg', res.token);
					const usuario = CookiesUtils.getUsuarioFromLocalStorage();
					commit(mutationsTypes.LOGIN_SUCCESS, usuario);
					resolve(usuario);
				})
				.catch(error => {
					commit(mutationsTypes.LOGIN_FAILURE, { error });
					reject(error);
				});
		});
	},
	loginGoogle({ commit }, token) {
		return new Promise(resolve => {
			localStorage.setItem('jwt-mg', token.token);
			const usuario = CookiesUtils.getUsuarioFromLocalStorage();
			commit(mutationsTypes.LOGIN_SUCCESS, usuario);
			resolve(usuario);
		});
	},
	logout({ commit }, redirect) {
		try {
			localStorage.removeItem('jwt-mg');
			if (redirect) router.push({ name: 'home' });

			commit(mutationsTypes.LOGOUT_SUCCESS);
		} catch (error) {
			commit(mutationsTypes.LOGOUT_FAILURE, { error });
		}
	},
};

const mutations = {
	guardaInformacionUsuario(state, usuario) {
		if (usuario) {
			state.isLogged = true;
			state.usuario.nombre = usuario.nombre;
			state.usuario.apellidos = usuario.apellidos;
			state.usuario.roles = usuario.roles;
			state.usuario.email = usuario.username;
		}
	},
	// login
	[mutationsTypes.LOGIN_REQUEST](state) {
		state.fetchingData = true;
		state.solicitudLoginSuccess = false;
		state.error = null;
	},
	[mutationsTypes.LOGIN_SUCCESS](state, usuario) {
		state.fetchingData = false;
		state.usuario.nombre = usuario.nombre;
		state.usuario.apellidos = usuario.apellidos;
		state.usuario.roles = usuario.roles;
		state.usuario.email = usuario.username;
		state.solicitudLoginSuccess = true;
		state.isLogged = true;
		state.error = null;
	},
	[mutationsTypes.LOGIN_FAILURE](state, { error }) {
		state.fetchingData = false;
		state.isLogged = false;
		state.solicitudLoginSuccess = false;
		state.error = error;
	},

	// logout
	[mutationsTypes.LOGOUT_SUCCESS](state) {
		state.fetchingData = false;
		state.isLogged = false;
		state.usuario.nombre = null;
		state.usuario.apellidos = null;
		state.usuario.roles = null;
		state.usuario.email = null;
		state.error = null;
	},
	[mutationsTypes.LOGOUT_FAILURE](state, { error }) {
		state.fetchingData = false;
		state.error = error;
	},
};

const getters = {
	isUsuarioLogged: state => {
		return state.isLogged;
	},
	getErrorMessage: state => {
		if (state.error && state.error.response && state.error.response.data) {
			return state.error.response.data;
		}
	},
	hasRoleGestorRooming: state => {
		return state.usuario.roles.includes('ROLE_GESTOR_ROOMING');
	},
};
export const login = {
	namespaced: true,
	state,
	actions,
	mutations,
	getters,
};
