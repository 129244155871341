import * as mutationsTypes from './UsuarioMutationsTypes';
import UsuarioApiService from '../../services/api/skiandnight/UsuarioApiService';

const state = {
	solicitudRegistroSuccess: false,
	solicitudRestablecerSuccess: false,
	solicitudRecordarSuccess: false,
	solicitudActivarSuccess: false,
	personalData: {
		nombre: null,
		apellidos: null,
		telefono: null,
		email: null,
	},
};

const actions = {
	registro({ commit }, usuarioRegistro) {
		commit(mutationsTypes.REGISTRO_REQUEST);
		return new Promise((resolve, reject) => {
			UsuarioApiService.doRegistro(usuarioRegistro)
				.then(() => {
					commit(mutationsTypes.REGISTRO_SUCCESS);
					resolve();
				})
				.catch(error => {
					commit(mutationsTypes.REGISTRO_FAILURE, { error });
					reject(error);
				});
		});
	},
	activarUsuario({ commit }, { codigo, password }) {
		commit(mutationsTypes.ACTIVACION_REQUEST);
		UsuarioApiService.activarUsuario(codigo, password)
			.then(() => commit(mutationsTypes.ACTIVACION_SUCCESS))
			.catch(error => commit(mutationsTypes.ACTIVACION_FAILURE, { error }));
	},
	recordarPassword({ commit }, email) {
		commit(mutationsTypes.RECORDAR_PASSWORD_REQUEST);
		return new Promise((resolve, reject) => {
			UsuarioApiService.recordarPassword(email)
				.then(() => {
					commit(mutationsTypes.RECORDAR_PASSWORD_SUCCESS);
					resolve();
				})
				.catch(error => {
					commit(mutationsTypes.RECORDAR_PASSWORD_FAILURE, { error });
					reject(error);
				});
		});
	},
	restablecerPassword({ commit }, { codigo, password }) {
		commit(mutationsTypes.REESTABLECER_PASSWORD_REQUEST);
		UsuarioApiService.reestablecerPassword(codigo, password)
			.then(() => commit(mutationsTypes.REESTABLECER_PASSWORD_SUCCESS))
			.catch(error => commit(mutationsTypes.REESTABLECER_PASSWORD_FAILURE, { error }));
	},
	modificarDatosUsuario({ commit }, usuario) {
		commit(mutationsTypes.MODIFICAR_DATOS_USUARIO_REQUEST);
		UsuarioApiService.modificarDatosUsuario(usuario)
			.then(usuario =>
				commit(mutationsTypes.MODIFICAR_DATOS_USUARIO_SUCCESS, {
					usuario,
				})
			)
			.catch(error =>
				commit(mutationsTypes.MODIFICAR_DATOS_USUARIO_FAILURE, {
					error,
				})
			);
	},
	obtenerDatosUsuario({ commit }, usuario) {
		commit(mutationsTypes.OBTENER_DATOS_USUARIO_REQUEST);
		UsuarioApiService.obtenerDatosUsuario(usuario)
			.then(usuario =>
				commit(mutationsTypes.OBTENER_DATOS_USUARIO_SUCCESS, {
					usuario,
				})
			)
			.catch(error => commit(mutationsTypes.OBTENER_PAISES_USUARIO_FAILURE, { error }));
	},
	modificarPassword({ commit }, password) {
		commit(mutationsTypes.MODIFICAR_PASSWORD_REQUEST);
		UsuarioApiService.modificarPassword(password)
			.then(() => commit(mutationsTypes.MODIFICAR_PASSWORD_SUCCESS))
			.catch(error => commit(mutationsTypes.MODIFICAR_PASSWORD_FAILURE, { error }));
	},
	doBajaUsuario({ commit }) {
		commit(mutationsTypes.BAJA_USUARIO_REQUEST);
		UsuarioApiService.doBajaUsuario()
			.then(() => commit(mutationsTypes.BAJA_USUARIO_SUCCESS))
			.catch(error => commit(mutationsTypes.BAJA_USUARIO_FAILURE, { error }));
	},
	getPaisesOpts({ commit }) {
		commit(mutationsTypes.OBTENER_PAISES_USUARIO_REQUEST);
		UsuarioApiService.getPaisesOpts()
			.then(paises =>
				commit(mutationsTypes.OBTENER_PAISES_USUARIO_SUCCESS, {
					paises,
				})
			)
			.catch(error => commit(mutationsTypes.OBTENER_PAISES_USUARIO_FAILURE, { error }));
	},
};

const mutations = {
	// registro
	[mutationsTypes.REGISTRO_REQUEST](state) {
		state.fetchingData = true;
		state.solicitudRegistroSuccess = false;
		state.error = null;
	},
	[mutationsTypes.REGISTRO_SUCCESS](state) {
		state.fetchingData = false;
		state.solicitudRegistroSuccess = true;
		state.error = null;
	},
	[mutationsTypes.REGISTRO_FAILURE](state, { error }) {
		state.fetchingData = false;
		state.solicitudRegistroSuccess = false;
		state.error = error;
	},

	// activacion
	[mutationsTypes.ACTIVACION_REQUEST](state) {
		state.fetchingData = true;
		state.solicitudActivarSuccess = false;
		state.error = null;
	},
	[mutationsTypes.ACTIVACION_SUCCESS](state) {
		state.fetchingData = false;
		state.solicitudActivarSuccess = true;
		state.error = null;
	},
	[mutationsTypes.ACTIVACION_FAILURE](state, { error }) {
		state.fetchingData = false;
		state.solicitudActivarSuccess = false;
		state.error = error;
	},

	// recordar
	[mutationsTypes.RECORDAR_PASSWORD_REQUEST](state) {
		state.fetchingData = true;
		state.solicitudRecordarSuccess = false;
		state.error = null;
	},
	[mutationsTypes.RECORDAR_PASSWORD_SUCCESS](state) {
		state.fetchingData = false;
		state.solicitudRecordarSuccess = true;
		state.error = null;
	},
	[mutationsTypes.RECORDAR_PASSWORD_FAILURE](state, { error }) {
		state.fetchingData = false;
		state.solicitudRecordarSuccess = false;
		state.error = error;
	},

	// restablecer
	[mutationsTypes.REESTABLECER_PASSWORD_REQUEST](state) {
		state.fetchingData = true;
		state.solicitudRestablecerSuccess = false;
		state.error = null;
	},
	[mutationsTypes.REESTABLECER_PASSWORD_SUCCESS](state) {
		state.fetchingData = false;
		state.solicitudRestablecerSuccess = true;
		state.error = null;
	},
	[mutationsTypes.REESTABLECER_PASSWORD_FAILURE](state, { error }) {
		state.fetchingData = false;
		state.solicitudRestablecerSuccess = false;
		state.error = error;
	},
};

const getters = {
	getErrorMessage: state => {
		if (state.error && state.error.response && state.error.response.data) {
			return state.error.response.data;
		}
	},
	solicitudRegistroSuccess: state => {
		return state.solicitudRegistroSuccess;
	},
};

export const usuario = {
	namespaced: true,
	state,
	actions,
	mutations,
	getters,
};
