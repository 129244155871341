import { defineStore } from 'pinia';
import HomeApiService from '@/services/api/skiandnight/HomeApiService';

const viajesGrupo = defineStore('viajes/apresSki', {
	state: () => ({
		apresSki: null,
	}),
	getters: {
		getApresSki(state) {
			if (state.apresSki) return state.apresSki;
			else this.setApresSki();
		},
		getApresSkiById: state => {
			return apresSkiId => state.apresSki.find(({ id }) => apresSkiId === id);
		},
	},
	actions: {
		setApresSki() {
			HomeApiService.getApresSkis().then(response => (this.apresSki = response));
		},
	},
});

export default viajesGrupo;
