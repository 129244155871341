import { defineStore } from 'pinia';
import ViajesGrupoApiService from '../services/api/skiandnight/ViajesGrupoApiService';

export const buscadorGrupos = defineStore('buscadorGrupos', {
	state: () => ({
		groups: {
			name: null,
			description: null,
		},
		destiny: {
			area: null,
			station: null,
		},
		date: {
			month: null,
			monthName: null,
			year: null,
		},
		filtrosViajes: {
			destino: null,
			tiposGrupo: null,
		},
	}),

	actions: {
		async setFilterInformation() {
			if (
				(this.filtrosViajes.destino && this.filtrosViajes.destino.length > 0 ||
				this.filtrosViajes.tiposGrupo && this.filtrosViajes.tiposGrupo.length > 0)
			) {
				return;
			}
			await ViajesGrupoApiService.getFiltroViajes().then(res => {
				this.filtrosViajes = res;
			});
		},

		setGroupsBuscadorGrupos(groups) {
			this.groups.name = groups.nombre;
			this.groups.description = groups.descripcion;
		},

		setDestinyBuscadorGrupos(area, station) {
			this.destiny.area = area;
			this.destiny.station = station;
		},

		setDateBuscadorGrupos(month, year, monthName) {
			this.date.month = month;
			this.date.year = year;
			this.date.monthName = monthName;
		},

		clearGroupsBuscadorGrupos() {
			this.groups.name = null;
			this.groups.description = null;
		},

		clearDestinyBuscadorGrupos() {
			this.destiny.area = null;
			this.destiny.station = null;
		},

		clearDateBuscadorGrupos() {
			this.date.month = null;
			this.date.year = null;
			this.date.monthName = null;
		},

		clearBuscadorGrupos() {
			this.$reset();
		},
	},
});
