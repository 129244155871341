<template>
	<div class="p-2">
		<p class="fw-bold text-center text-md-start fs-5">¡ {{ $t('habitacionBuscador.title') }} !</p>

		<div class="d-flex flex-column flex-md-row mt-4 align-items-center">
			<div class="col-12 col-md-7 fw-bold text-center text-md-start">
				{{ $t('habitacionBuscador.subTitle') }}
			</div>
			<div class="col-12 col-md-4 text-center mt-3 mt-md-0 d-flex align-items-center justify-content-center">
				<button
					type="button"
					class="btn btn-outline-primary btn-circle btn-sm"
					:disabled="listaHabitaciones.length == 1"
					@click="delHabitacion()">
					<i class="fa-solid fa-minus" />
				</button>
				<span class="mx-4 num-personas">
					{{ listaHabitaciones.length }}
				</span>
				<button
					type="button"
					class="btn btn-outline-primary btn-circle btn-sm"
					:disabled="listaHabitaciones.length == 4"
					@click="addHabitacion()">
					<i class="fa-solid fa-plus" />
				</button>
			</div>
		</div>
		<div class="d-flex flex-row row mt-3 fila-habitaciones justify-content-between">
			<div v-for="(habitacion, indexHabitacion) in listaHabitaciones" :key="indexHabitacion" class="col-12 col-sm-5">
				<div class="row fw-bold mb-3 gx-0">{{ $t('habitacionBuscador.habitacion') }} {{ indexHabitacion + 1 }}</div>
				<div class="row mb-3 gx-0 align-items-center">
					<div class="col-5 ps-0">
						{{ $t('habitacionBuscador.adulto') }}
					</div>
					<div class="col-7">
						<div class="d-flex justify-content-end align-items-center">
							<button
								type="button"
								class="btn btn-outline-primary btn-circle btn-sm"
								:disabled="cantidadTipoPersona(habitacion.habitacion).adultos == 1"
								@click="delAdultoHabitacion(indexHabitacion)">
								<i class="fa-solid fa-minus" />
							</button>
							<span class="mx-2 numero-personas">
								{{ cantidadTipoPersona(habitacion.habitacion).adultos }}
							</span>
							<button
								type="button"
								class="btn btn-outline-primary btn-circle btn-sm"
								:disabled="cantidadTipoPersona(habitacion.habitacion).adultos == 14"
								@click="addAdultoHabitacion(indexHabitacion)">
								<i class="fa-solid fa-plus" />
							</button>
						</div>
					</div>
				</div>
				<div class="row mb-3 gx-0 align-items-center">
					<div class="col-5 ps-0">
						{{ $t('habitacionBuscador.ninos') }}
					</div>
					<div class="col-7">
						<div class="d-flex justify-content-end align-items-center">
							<button
								type="button"
								class="btn btn-outline-primary btn-circle btn-sm"
								:disabled="cantidadTipoPersona(habitacion.habitacion).ninos == 0"
								@click="delNinoHabitacion(indexHabitacion)">
								<i class="fa-solid fa-minus" />
							</button>
							<span class="mx-2 numero-personas">
								{{ cantidadTipoPersona(habitacion.habitacion).ninos }}
							</span>
							<button
								type="button"
								class="btn btn-outline-primary btn-circle btn-sm"
								:disabled="cantidadTipoPersona(habitacion.habitacion).ninos == 4"
								@click="addNinoHabitacion(indexHabitacion)">
								<i class="fa-solid fa-plus" />
							</button>
						</div>
					</div>
				</div>
				<div v-if="getListaNinos(habitacion.habitacion).length > 0" class="row fw-bold gx-0">
					{{ $t('habitacionBuscador.edadNino') }}
				</div>
				<div v-for="(nino, indexNino) in habitacion.habitacion" :key="indexNino">
					<template v-if="nino != 30">
						<div class="row justify-content-between my-3 align-items-center gx-0">
							<div class="col-3 ps-0">
								{{ $t('habitacionBuscador.nino') }}
							</div>
							<div class="col-7">
								<div class="d-flex justify-content-end align-items-center">
									<button
										type="button"
										class="btn btn-outline-primary btn-circle btn-sm"
										:disabled="nino == 0"
										@click="restarEdadNino(indexHabitacion, indexNino)">
										<i class="fa-solid fa-minus" />
									</button>
									<span class="mx-2 numero-personas">
										{{ nino }}
									</span>
									<button
										type="button"
										class="btn btn-outline-primary btn-circle btn-sm"
										:disabled="nino == 17"
										@click="sumarEdadNino(indexHabitacion, indexNino)">
										<i class="fa-solid fa-plus" />
									</button>
								</div>
							</div>
						</div>
					</template>
				</div>

				<hr v-if="listaHabitaciones.length > 2 && indexHabitacion >= 0 && indexHabitacion <= 1" />
			</div>
		</div>
	</div>
</template>

<script>
	import { computed, defineComponent, ref, watch } from 'vue';
	import { buscadorStore } from '@/store_pinia/buscador';

	export default defineComponent({
		emits: ['habitaciones'],
		setup(props, { emit }) {
			const listaHabitaciones = ref([
				{
					habitacion: [30, 30],
				},
			]);

			const store_buscador = buscadorStore();

			const listaHabitacionesBuscador = computed(() => store_buscador.filtrosSeleccionadosBuscador.habitaciones);

			const addHabitacion = () => {
				if (listaHabitaciones.value.length > 4) return;
				listaHabitaciones.value.push({ habitacion: [30, 30] });
			};

			const delHabitacion = () => {
				if (listaHabitaciones.value.length < 1) return;
				listaHabitaciones.value.pop();
			};

			const delAdultoHabitacion = indexHabitacion => {
				let indexAdultoBorrar = listaHabitaciones.value[indexHabitacion].habitacion.findIndex(
					edadAdulto => edadAdulto === 30
				);

				listaHabitaciones.value[indexHabitacion].habitacion.splice(indexAdultoBorrar, 1);
			};

			const addAdultoHabitacion = indexHabitacion => {
				listaHabitaciones.value[indexHabitacion].habitacion.push(30);
			};

			const addNinoHabitacion = indexHabitacion => {
				listaHabitaciones.value[indexHabitacion].habitacion.push(0);
			};

			const delNinoHabitacion = indexHabitacion => {
				let listaAux = listaHabitaciones.value;
				let indexAdultoBorrar = listaAux[indexHabitacion].habitacion.findIndex(edadAdulto => edadAdulto !== 30);
				listaHabitaciones.value[indexHabitacion].habitacion.splice(indexAdultoBorrar, 1);
			};

			const getListaNinos = listaPersonas => {
				let listaAux = listaPersonas.filter(edad => edad !== 30);
				return listaAux;
			};

			const sumarEdadNino = (indexHabitacion, indexNino) => {
				listaHabitaciones.value[indexHabitacion].habitacion[indexNino]++;
			};

			const restarEdadNino = (indexHabitacion, indexNino) => {
				listaHabitaciones.value[indexHabitacion].habitacion[indexNino]--;
			};

			const cantidadTipoPersona = listaPersonas => {
				let personas = { adultos: 0, ninos: 0 };
				listaPersonas.map(persona => {
					if (persona === 30) personas.adultos++;
					else {
						personas.ninos++;
					}
				});
				return personas;
			};

			watch(
				listaHabitaciones,
				() => {
					emit('habitaciones', listaHabitaciones.value);
				},
				{ deep: true }
			);

			if (listaHabitacionesBuscador.value !== listaHabitaciones.value) {
				if (listaHabitacionesBuscador.value !== null) {
					listaHabitaciones.value = listaHabitacionesBuscador.value;
				}
			}

			return {
				listaHabitaciones,
				addHabitacion,
				delHabitacion,
				cantidadTipoPersona,
				addAdultoHabitacion,
				delAdultoHabitacion,
				addNinoHabitacion,
				delNinoHabitacion,
				getListaNinos,
				sumarEdadNino,
				restarEdadNino,
			};
		},
	});
</script>

<style scoped>
	.numero-personas {
		min-width: 1rem;
		text-align: center;
	}

	.btn-circle.btn-sm {
		width: 30px;
		height: 30px;
		padding: 6px 0px;
		border-radius: var(--bs-border-radius-xl);
		font-size: 8px;
		text-align: center;
	}
</style>
