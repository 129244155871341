<template>
	<div class="carousel-container">
		<button v-if="sortedViajes.length > 1 && !isMobile && !isAtStart" class="button-overlay start-0" @click="prev">
			<span class="me-1 mb-2"
				><svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M4.25661 9.91048L11.1866 16.7493C11.817 17.3714 11.817 18.38 11.1866 19.0022C10.5561 19.6243 9.53403 19.6243 8.90362 19.0022L0.832189 11.0369C0.201771 10.4148 0.201771 9.40615 0.832189 8.78403L8.90362 0.818803C9.53404 0.196679 10.5561 0.196679 11.1866 0.818803C11.817 1.44092 11.817 2.44959 11.1866 3.07171L4.25661 9.91048Z"
						fill="white" />
					<mask
						id="mask0_2060_17740"
						style="mask-type: luminance"
						maskUnits="userSpaceOnUse"
						x="0"
						y="0"
						width="12"
						height="20">
						<path
							d="M4.25661 9.91048L11.1866 16.7493C11.817 17.3714 11.817 18.38 11.1866 19.0022C10.5561 19.6243 9.53403 19.6243 8.90362 19.0022L0.832189 11.0369C0.201771 10.4148 0.201771 9.40615 0.832189 8.78403L8.90362 0.818803C9.53404 0.196679 10.5561 0.196679 11.1866 0.818803C11.817 1.44092 11.817 2.44959 11.1866 3.07171L4.25661 9.91048Z"
							fill="white" />
					</mask>
					<g mask="url(#mask0_2060_17740)"></g>
				</svg>
			</span>
		</button>
		<Carousel ref="carouselRef" v-bind="config" @init="handleInit" @slide-end="handleSlideEnd">
			<Slide v-for="viaje in sortedViajes" :key="viaje.id" class="py-4">
				<TripCard :viaje-tarjeta="viaje" :tipos-viaje="tiposViaje" />
			</Slide>
		</Carousel>
		<button v-if="sortedViajes.length > 1 && !isMobile && !isAtEnd" class="button-overlay end-0" @click="next">
			<span class="ms-1 mb-2"
				><svg width="12" height="20" viewBox="0 0 12 20" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path
						d="M7.39964 9.55827L0.469688 2.7195C-0.160729 2.09738 -0.160729 1.08871 0.469688 0.466592C1.10011 -0.155531 2.12222 -0.155531 2.75263 0.466592L10.8241 8.43182C11.4545 9.05394 11.4545 10.0626 10.8241 10.6847L2.75263 18.6499C2.12222 19.2721 1.10011 19.2721 0.469688 18.6499C-0.160729 18.0278 -0.160729 17.0192 0.469688 16.397L7.39964 9.55827Z"
						fill="white" />
					<mask
						id="mask0_2654_19185"
						style="mask-type: luminance"
						maskUnits="userSpaceOnUse"
						x="0"
						y="0"
						width="12"
						height="20">
						<path
							d="M7.39964 9.55827L0.469688 2.7195C-0.160729 2.09738 -0.160729 1.08871 0.469688 0.466592C1.10011 -0.155531 2.12222 -0.155531 2.75263 0.466592L10.8241 8.43182C11.4545 9.05394 11.4545 10.0626 10.8241 10.6847L2.75263 18.6499C2.12222 19.2721 1.10011 19.2721 0.469688 18.6499C-0.160729 18.0278 -0.160729 17.0192 0.469688 16.397L7.39964 9.55827Z"
							fill="white" />
					</mask>
					<g mask="url(#mask0_2654_19185)"></g>
				</svg>
			</span>
		</button>
	</div>
</template>

<script setup>
	import { Carousel, Slide } from 'vue3-carousel';
	import TripCard from '@/components/card/TripCard/TripCard.vue';
	import { computed, ref, onMounted, onBeforeUnmount } from 'vue';
	import 'vue3-carousel/dist/carousel.css';

	const carouselRef = ref(null);
	const isMobile = ref(false);

	const isAtStart = ref(true);
	const isAtEnd = ref(false);

	const config = ref({
		itemsToShow: 4,
		itemsToScroll: 1,
		snapAlign: 'start',
		wrapAround: false,
		touchDrag: true,
		mouseDrag: true,
	});

	const props = defineProps({
		listaViajes: {
			type: Array,
			default: () => [],
		},
		tiposViaje: {
			type: Array,
			require: false,
			default: () => [],
		},
	});

	const sortedViajes = computed(() => {
		return [...props.listaViajes].sort((a, b) => new Date(a.fechaInicio) - new Date(b.fechaInicio));
	});

	function next() {
		carouselRef.value?.next();
	}

	function prev() {
		carouselRef.value?.prev();
	}

	function handleInit() {
		checkArrows();
	}

	function handleSlideEnd({ currentSlideIndex, slidesCount }) {
		isAtStart.value = currentSlideIndex === 0;
		isAtEnd.value =
			currentSlideIndex === slidesCount - config.value.itemsToShow ||
			currentSlideIndex >= slidesCount - config.value.itemsToShow;
	}

	function checkArrows() {
		const currentIndex = carouselRef.value.modelValue ?? 0;
		isAtStart.value = currentIndex === 0;
		isAtEnd.value = sortedViajes.value.length === config.value.itemsToShow;
	}

	function updateMobileStatus() {
		isMobile.value = window.innerWidth < 1024;
	}

	function updateConfigBasedOnScreenWidth() {
		const screenWidth = window.innerWidth;

		if (screenWidth < 400) {
			config.value.itemsToShow = 1.2;
			config.value.snapAlign = 'center';
		} else if (screenWidth < 600) {
			config.value.itemsToShow = 1.3;
			config.value.snapAlign = 'center';
		} else if (screenWidth < 1024) {
			config.value.itemsToShow = 2;
			config.value.snapAlign = 'center';
		} else if (screenWidth < 1300) {
			config.value.itemsToShow = 3;
			config.value.snapAlign = 'start';
		} else {
			config.value.itemsToShow = 4;
			config.value.snapAlign = 'start';
		}
	}

	onMounted(function () {
		updateMobileStatus();
		updateConfigBasedOnScreenWidth();
		window.addEventListener('resize', updateMobileStatus);
		window.addEventListener('resize', updateConfigBasedOnScreenWidth);
	});

	onBeforeUnmount(function () {
		window.removeEventListener('resize', updateMobileStatus);
		window.removeEventListener('resize', updateConfigBasedOnScreenWidth);
	});
</script>

<style scoped>
	.carousel-container {
		position: relative;
		width: 100%;
		overflow: hidden;
	}

	.button-overlay {
		position: absolute;
		top: 44%;
		transform: translateY(-50%);
		background-color: var(--bs-primary);
		border-radius: 50%;
		width: 1em;
		height: 1em;
		display: flex;
		align-items: center;
		justify-content: center;
		color: white;
		font-size: 40px;
		cursor: pointer;
		box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3);
		border: none;
		z-index: 10;
	}

	.button-overlay:hover {
		background-color: #4a4a6a;
	}
</style>
