<template>
	<div v-if="agenciaStore.buscadorEnabled" class="">
		<div
			class="row align-items-center justify-content-center selector mb-3 w-100 mx-auto px-3"
			:class="{ 'selector-fuente-nohome': !isHome }">
			<div
				v-if="storeGlobal.permisos.viajes.grupos.showed"
				class="col-5 col-lg-auto selector-font-base p-0 mx-md-3"
				:class="[groups ? 'text-secondary selector-activo border-4 border-bottom border-secondary' : 'shadow-basic']"
				@click="StatusSearchHandler()">
				<span>{{ $t('searcher.organized') }}</span>
			</div>
			<div
				v-if="storeGlobal.permisos.viajes.buscador.showed"
				class="col-5 col-lg-auto selector-font-base p-0 mx-md-3"
				:class="[groups ? 'shadow-basic' : 'text-secondary selector-activo border-4 border-bottom border-secondary']"
				@click="StatusSearchHandler()">
				<span>{{ $t('searcher.personalized') }}</span>
			</div>
		</div>
		<template v-if="groups && storeGlobal.permisos.viajes.grupos.showed">
			<BuscadorGrupos />
		</template>
		<template v-if="!groups && storeGlobal.permisos.viajes.buscador.showed">
			<BuscadorParaTi />
		</template>
	</div>
</template>

<script>
	import { defineComponent, ref } from 'vue';
	import BuscadorGrupos from '@/components/buscadores/BuscadorGrupos.vue';
	import BuscadorParaTi from '@/components/buscadores/BuscadorParaTi.vue';
	import { appGlobalStore } from '@/store_pinia/app';
	import { agenciaStoreModule } from '@/store_pinia/agencia';

	export default defineComponent({
		components: { BuscadorGrupos, BuscadorParaTi },
		props: {
			isHome: {
				type: Boolean,
				default: false,
				required: false,
			},
			buscadorGrupos: {
				type: Boolean,
				default: true,
			},
			selectorGrupos: {
				type: Boolean,
				default: () => true,
			},
		},
		setup(props) {
			const groups = ref(props.selectorGrupos);
			const storeGlobal = appGlobalStore();
			const agenciaStore = agenciaStoreModule();

			return {
				groups,
				StatusSearchHandler() {
					groups.value = !groups.value;
				},
				storeGlobal,
				agenciaStore,
			};
		},
	});
</script>

<style scoped lang="scss">
	@import '~@/assets/scss/main.scss';

	.selector-font-base {
		text-shadow: 2px 2px 4px var(--bs-primary);
		font-weight: 600;
		letter-spacing: 0.18px;
		text-align: center;
		cursor: pointer;
		font-size: 21px;
		color: white;
	}

	.selector-fuente-nohome {
		div {
			@include media-breakpoint-down(md) {
				color: var(--bs-primary);
				text-shadow: none;
			}
		}
	}
</style>
