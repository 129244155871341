<template>
	<div class="main">
		<div v-if="!loading" class="container-card">
			<h3 class="text-center text-primary fw-bold">
				{{ $t('botones.modificarReserva') }}
			</h3>
			<div class="py-4">
				<CardLateralReserva disable-collapse @limpiar-codigo-descuento="limpiarCodigoDescuentoHandler" />
			</div>

			<div class="row">
				<div class="col-12 col-md-6 pb-3">
					<template v-if="totalPendiente > 0">
						<FormCodigoDescuento
							:viaje-id="String(grupo.viaje.id)"
							:reservas-ids="grupo.reservas?.map(reserva => reserva.id)"
							@descuento-aplicado="codigoDescuentoHandler" />
					</template>
				</div>
				<div class="col-12 col-md-6 text-end pb-3">
					<div class="outer-card">
						<p class="fs-6 text-primary m-0 p-0 fw-bold importe">
							{{ $t('subtitulo.importePendiente') }}
						</p>
						<div class="inner-card">
							<div class="text-secondary fs-5 py-2 px-3 price">{{ roundNumbers(totalPendiente, 2) }} {{agenciaStore.getCurrency}}</div>
						</div>
					</div>
				</div>
			</div>
			<div class="d-flex justify-content-end mt-2">
				<button
					v-if="
						grupo.viaje.estado === ESTADOS_VIAJE.PUBLICADO &&
						grupo.viaje.continuarSinPagar &&
						!isNextHitoRestante()
					"
					class="btn btn-secondary"
					@click="modificarSinPagar()">
					{{ $t('botones.modificar').toLocaleUpperCase() }}
				</button>

				<button class="btn btn-primary" @click="registraPagoModificar()">
					{{ $t('botones.modificarPagar').toLocaleUpperCase() }}
				</button>
			</div>
		</div>

		<div v-else class="loading">
			<Spinner />
		</div>

		<div v-if="infoPagoRedsys">
			<form name="redsysPagoForm" :action="infoPagoRedsys.url" method="POST">
				<input type="hidden" name="Ds_SignatureVersion" :value="infoPagoRedsys.signatureVersion" />
				<input type="hidden" name="Ds_MerchantParameters" :value="infoPagoRedsys.params" />
				<input type="hidden" name="Ds_Signature" :value="infoPagoRedsys.signature" />
			</form>
		</div>

	</div>
</template>

<script setup>
	import { computed, ref, nextTick, onBeforeMount } from 'vue';
	import ReservasApiService from '@/services/api/skiandnight/ReservasApiService';
	import PasarelaPagoApiService from '@/services/api/skiandnight/PasarelaPagoApiService';
	import { useRoute, useRouter } from 'vue-router';
	import FormCodigoDescuento from '@/modules/viajesGrupos/components/FormCodigoDescuento.vue';
	import Spinner from '@/components/SpinnerLoading.vue';
	import { controlErrorRealizarPago } from '@/services/utils/ErrorControl';
	import CardLateralReserva from '@/components/card/Reserva/CardLateralReserva.vue';
	import { informacionHabitacion } from '@/store_pinia/informacionHabitacion';
	import { UsuarioProximasReservasDetalle } from '@/router/RouterNames';
	import { ESTADOS_VIAJE } from '@/constants/viajeConstants';
	import { HITOS_PAGO_TIPOCANTIDAD } from '@/constants/viajeConstants';
	import {ESTADOS_RESERVA, TIPO_CODIGO_DESCUENTO} from '@/constants/reservaConstants';
	import { agenciaStoreModule } from '@/store_pinia/agencia';
	import { toRawDeep } from '@/helpers/reactivityUtils';

	const route = useRoute();
	const router = useRouter();
	const storeHabitacion = informacionHabitacion();
	const agenciaStore = agenciaStoreModule();
	const grupo = ref();
	const reservasInDraft = ref();
	const loading = ref(true);
	const infoPagoRedsys = ref(null);
	const valorCodigoDescuento = ref('');

	const totalPendienteReal = computed(() => {
		let totalPendienteAux = 0;
		reservasInDraft.value.map(({ precioTotal, totalPagado }) => {
			let valorPendiente = precioTotal - totalPagado;
			totalPendienteAux += valorPendiente;
		});
		return totalPendienteAux;
	});

	const totalPendiente = computed(() => {
		let totalPendienteAux = totalPendienteReal.value;
		if (totalPendienteAux < 0) {
			totalPendienteAux = 0;
		}
		const informacionCodigoDescuento = valorCodigoDescuento.value;
		if (informacionCodigoDescuento) {
			if (informacionCodigoDescuento.pagoUnico && informacionCodigoDescuento.tipoCantidad === TIPO_CODIGO_DESCUENTO.PORCENTAJE) {
				totalPendienteAux = calcularDescuentoPorcentaje(
					Number(informacionCodigoDescuento.cantidad),
					Number(totalPendienteAux)
				);
			}
			if (totalPendienteAux < 0) {
				totalPendienteAux = 0;
			}
			if (totalPendienteAux > 0) {
				totalPendienteAux =
					totalPendienteAux - Number(informacionCodigoDescuento.cantidad) * reservasInDraft.value.length;
			}
		}
		return totalPendienteAux;
	});

	function roundNumbers(value, precision) {
		var multiplier = Math.pow(10, precision || 0);
		return Math.round(value * multiplier) / multiplier;
	}

	function moveToMisReservas() {
		router.push({
			name: UsuarioProximasReservasDetalle,
			params: { grupoId: grupo.value.id },
		});
	}

	async function registraPagoModificar() {
		try {
			const reservasId = reservasInDraft.value.map(reserva => reserva.id);
			const datosRedireccionDTO = await PasarelaPagoApiService.solicitaCobroSucesivo(reservasId, true, valorCodigoDescuento.value, true)
			if (!datosRedireccionDTO.url) {
				moveToMisReservas();
			} else {
				if (datosRedireccionDTO.redsysInfo) {
					infoPagoRedsys.value = datosRedireccionDTO.redsysInfo;
					infoPagoRedsys.value.url = datosRedireccionDTO.url;
					await nextTick()
					document.redsysPagoForm.submit();
				} else {
					window.location.replace(datosRedireccionDTO.url);
				}
			}
		} catch (err) {
			controlErrorRealizarPago(err);
		}
	}

	function modificarSinPagar() {
		let reservasId = reservasInDraft.value
			.map(reserva => reserva.id);

		PasarelaPagoApiService.solicitaContinuarSinPagar(reservasId)
			.then(() => {
				nextTick(() => {
					moveToMisReservas();
				});
			})
			.catch(err => {
				controlErrorRealizarPago(err);
			});
	}

	function calcularDescuentoPorcentaje(cantidadDescuento, valorTotal) {
		return Math.round(((100 - cantidadDescuento) * (valorTotal / 100) + Number.EPSILON) * 100) / 100;
	}

	function codigoDescuentoHandler(informacionCodigoDescuento) {
		valorCodigoDescuento.value = informacionCodigoDescuento;
	}

	function limpiarCodigoDescuentoHandler() {
		valorCodigoDescuento.value = null;
	}

	function isNextHitoRestante() {
		const fechaActual = Date.now();
		const hitosFuturos = grupo.value.viaje?.hitosPago.filter(hito => new Date(hito.fecha) > fechaActual);

		if (hitosFuturos.length === 0) {
			return false;
		}

		hitosFuturos.sort((a, b) => new Date(a.fecha) - new Date(b.fecha));
		return hitosFuturos[0].tipoCantidad === HITOS_PAGO_TIPOCANTIDAD.RESTANTE;
	}

	async function loadInfoStore() {
		storeHabitacion.clearReservas();
		const grupoRaw = toRawDeep(grupo.value);
		storeHabitacion.setInformacionAlojamientoFromRGrupoDTO(grupoRaw);
		if (grupoRaw.viaje && grupoRaw.viaje.id) {
			await storeHabitacion.loadInformacionViaje(grupoRaw.viaje.id, grupoRaw.viaje.codigo ?? null);
		}

		grupoRaw.reservas.forEach(reserva => {
			reserva.usuario = {
				nombre: reserva.nombre,
				apellidos: reserva.apellidos,
				apellido: reserva.apellidos,
			};
			storeHabitacion.addReserva(reserva);
		});
	}

	async function init() {
		try {
			// todo: usar el store igual que en UsuarioProximaReservasModificar
			grupo.value = await ReservasApiService.getGrupo(route.params.grupoId, true);
			reservasInDraft.value = grupo.value.reservas
				.filter(reserva => reserva.estado !== ESTADOS_RESERVA.CANCELADA && reserva.withDraft);

			loadInfoStore();
			loading.value = false;
		} catch (error) {
			console.error(error);
			loading.value = false;
		}
	}

	onBeforeMount(async () => {
		await init();
	});
</script>

<style scoped>
	.container-card {
		max-width: 1200px;
		margin: auto;
		padding-top: 14rem;
		padding-bottom: 4rem;
	}

	.card {
		border: 0;
		background-color: #f9f7f8;
		border-radius: var(--bs-border-radius-xxl);
	}

	.btn-secondary:hover {
		color: white;
	}

	.main {
		background-size: cover;
		background-repeat: no-repeat;
		margin: 0 35px;
	}

	.loading {
		height: 80vh;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.row {
		display: flex;
		flex-wrap: wrap;
	}

	.col-md-6 {
		display: flex;
		flex-direction: column;
		justify-content: center;
	}

	@media (max-width: 1000px) {
		.col-12 {
			width: 100%;
			display: block;
		}

		.text-end {
			text-align: start;
		}

		.container-card {
			padding-top: 10rem;
		}

		.importe,
		.price {
			font-size: 15px !important;
		}
	}

	.outer-card {
		width: 100%;
		background-color: white;
		position: relative;
		border: 1px solid #ccc;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		padding: 20px;
		height: 85px;
		border-radius: var(--bs-border-radius-xl);
		box-shadow: 0px 2px 4px 0px #00000080;
	}

	.inner-card {
		width: 30%;
		height: 100%;
		position: absolute;
		right: 0;

		.price {
			background-color: var(--bs-primary);
			border-radius: var(--bs-border-radius-xl);
			height: 100%;
			display: flex;
			justify-content: center;
			align-items: center;
			box-shadow: 0px 2px 4px 0px #00000080;
			border-left: 1px solid #ddd;
		}
	}
</style>
