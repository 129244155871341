<template>
	<div v-if="loading" class="loading w-100 position-absolute left d-flex justify-content-center">
		<SpinnerVue />
	</div>
	<form class="py-3" style="padding: 60px" @submit.prevent="actualizarInformacionUsuario()">
		<div class="form-group mb-3" :class="{ error: v$.nombre.$errors.length }">
			<label class="text-primary fw-bolder mb-1">{{ $t('home.form.personalInformation.name') }}</label>
			<input v-model="update.nombre" type="text" class="form-control bg-white text-start" @blur="v$.nombre.$touch" />
		</div>
		<div class="form-group mb-3" :class="{ error: v$.apellidos.$errors.length }">
			<label class="text-primary fw-bolder mb-1">{{ $t('home.form.personalInformation.lastName') }}</label>
			<input
				v-model="update.apellidos"
				type="text"
				class="form-control bg-white text-start"
				@blur="v$.apellidos.$touch" />
		</div>
		<div class="form-group mb-3" :class="{ error: v$.email.$errors.length }">
			<label class="text-primary fw-bolder mb-1">{{ $t('home.form.personalInformation.email') }}</label>
			<input
				v-model="update.email"
				type="email"
				class="form-control bg-white text-start"
				aria-describedby="emailHelp"
				@blur="v$.email.$touch" />
		</div>
		<div class="form-group mb-3" :class="{ error: v$.dni.$errors.length }">
			<label class="text-primary fw-bolder mb-1">{{ $t('home.form.personalInformation.dni') }}</label>
			<input v-model="update.dni" type="text" class="form-control bg-white text-start" @blur="v$.dni.$touch" />
		</div>
		<div class="form-group mb-3" :class="{ error: v$.direccion.$errors.length }">
			<label class="text-primary fw-bolder mb-1">{{ $t('home.form.personalInformation.direccion') }}</label>
			<input
				v-model="update.direccion"
				type="text"
				class="form-control bg-white text-start"
				@blur="v$.direccion.$touch" />
		</div>
		<div class="form-group mb-3" :class="{ error: v$.poblacion.$errors.length }">
			<label class="text-primary fw-bolder mb-1">{{ $t('home.form.personalInformation.poblacion') }}</label>
			<input
				v-model="update.poblacion"
				type="text"
				class="form-control bg-white text-start"
				@blur="v$.poblacion.$touch" />
		</div>
		<div class="form-group mb-3" :class="{ error: v$.provincia.$errors.length }">
			<label class="text-primary fw-bolder mb-1">{{ $t('home.form.personalInformation.provincia') }}</label>
			<input
				v-model="update.provincia"
				type="text"
				class="form-control bg-white text-start"
				@blur="v$.provincia.$touch" />
		</div>
		<div class="form-group mb-3" :class="{ error: v$.codigoPostal.$errors.length }">
			<label class="text-primary fw-bolder mb-1">{{ $t('home.form.personalInformation.codPostal') }}</label>
			<input
				v-model="update.codigoPostal"
				type="text"
				class="form-control bg-white text-start"
				@blur="v$.codigoPostal.$touch" />
		</div>
		<div class="form-group mb-3">
			<label class="text-primary fw-bolder mb-1">{{ $t('home.form.personalInformation.pais') }}</label>

			<select v-model="update.paisId" class="form-select" aria-label="Default select example">
				<option v-for="pais in paises" :key="pais.id" selected :value="pais.id">
					{{ pais.nombre }}
				</option>
			</select>
		</div>
		<div class="form-group mb-3" :class="{ error: v$.telefono.$errors.length }">
			<label class="text-primary fw-bolder mb-1">{{ $t('home.form.personalInformation.phone') }}</label>
			<input v-model="update.telefono" type="tel" class="form-control bg-white text-start" @blur="v$.telefono.$touch" />
		</div>
		<div class="form-group mb-3">
			<label class="text-primary fw-bolder mb-1">{{ $t('home.form.personalInformation.birthDate') }}</label>
			<Datepicker v-model="update.fechaNacimiento" auto-apply :format="format" />
		</div>
		<div class="form-check form-switch mb-3">
			<label class="form-check-label" for="mailingPublicidad">
				{{ $t('subtitulo.mailingPublicidad') }}
			</label>

			<input id="mailingPublicidad" v-model="update.mailingPublicidadActivo" class="form-check-input" type="checkbox" />
		</div>
		<div class="text-center">
			<button class="btn btn-primary" type="submit">
				{{ $t('botones.aceptar') }}
			</button>
			<div>
				<div class="btn btn-link">
					{{ $t('botones.darseBaja') }}
				</div>
			</div>
		</div>
	</form>
</template>

<script>
	import { reactive, ref } from 'vue';
	import Datepicker from '@vuepic/vue-datepicker';
	import UsuarioApiService from '../../../services/api/skiandnight/UsuarioApiService';
	import { useVuelidate } from '@vuelidate/core';
	import { required, email, numeric } from '@vuelidate/validators';
	import SpinnerVue from '../../../components/SpinnerLoading.vue';

	export default {
		name: 'UsuarioModificarDatos',
		components: { Datepicker, SpinnerVue },
		props: {
			userInformation: {
				type: Object,
				default: null,
			},
		},
		setup(props) {
			const update = reactive(props.userInformation);
			const paises = ref();
			const dateFormat = ref();

			function padTo2Digits(num) {
				return num.toString().padStart(2, '0');
			}

			const format = fechaNacimiento => {
				const day = fechaNacimiento.getDate();
				const month = fechaNacimiento.getMonth() + 1;
				const year = fechaNacimiento.getFullYear();

				dateFormat.value = `${year}-${padTo2Digits(month)}-${padTo2Digits(day)}`;

				return `${day}/${month}/${year}`;
			};

			const loading = ref(false);

			const rules = {
				nombre: { required },
				apellidos: { required },
				email: { required, email },
				dni: { required },
				direccion: { required },
				poblacion: { required },
				provincia: { required },
				codigoPostal: { required },
				telefono: { required, numeric },
			};

			const v$ = useVuelidate(rules, update);

			const actualizarInformacionUsuario = () => {
				v$.value.$validate();
				if (!v$.value.$invalid) {
					update.fechaNacimiento = dateFormat.value;
					update.paisId = Number(update.paisId);
					update.dni = update.dni.toUpperCase();
					UsuarioApiService.modificarDatosUsuario(update);
					loading.value = true
						.then(res => {
							console.log(res);
						})
						.catch(err => {
							console.error(err);
						})
						.finally(() => {
							loading.value = false;
						});
				}
			};

			UsuarioApiService.getPaisesOpts().then(res => {
				paises.value = res;
			});
			return {
				update,
				format,
				paises,
				actualizarInformacionUsuario,
				v$,
				loading,
			};
		},
	};
</script>

<style lang="scss" scoped>
	.loading {
		background-color: #49494983;
		height: calc(100% + 300px);
		z-index: 1000;
	}

	.error {
		.form-control {
			border-color: #dc3545;
		}
	}
</style>
