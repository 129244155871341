<template>
	<buscador-grupos-desktop-home
		:informacion-busqueda-grupos="filterInformationStore"
		:filtro-viajes="filtroViajes"
		@setear-destino-buscador="setDestiny"
		@setear-fecha-buscador="setFechasViajes"
		@setear-grupos-buscador="setGroups"
		@limpiar-destino-buscador="clearDestinos"
		@limpiar-fecha-buscador="clearFechas"
		@do-busqueda-viajes-grupos="realizarBusquedaViajesGrupos" />

	<buscador-grupos-mobile-home
		:informacion-busqueda-grupos="filterInformationStore"
		:filtro-viajes="filtroViajes"
		@setear-destino-buscador="setDestiny"
		@setear-fecha-buscador="setFechasViajes"
		@setear-grupos-buscador="setGroups"
		@limpiar-destino-buscador="clearDestinos"
		@limpiar-fecha-buscador="clearFechas"
		@do-busqueda-viajes-grupos="realizarBusquedaViajesGrupos" />

	<!-- <div class="bg-white no-home mx-auto d-block d-md-none py-3" style="max-width: 150px" v-if="route.name != 'home'">
        <div class="row justify-content-center">
            <div class="
                  col-4
                  text-center
                  d-flex
                  align-items-center
                  justify-content-center
                " data-bs-toggle="modal" data-bs-target="#buscadorMobil">
                <i class="fa-solid fa-filter fa-xl"></i>
                Filtro
            </div>
            <div class="col-4 text-center">
        <button class="btn btn-primary p-2">
          <i
            class="fa-solid fa-magnifying-glass fa-xl"
            @click="goTo(filterInformationStore)"
          ></i>
        </button>
      </div>
        </div>
    </div>

    <div class="modal fade" id="buscadorMobil" tabindex="-1" aria-labelledby="buscadorMobilLabel" aria-hidden="true">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="buscadorMobilLabel">Filtro de viajes</h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="input-group mb-3 d-flex flex-column">
                        <label for="" class="fw-bolder">{{
                            $t("searcher.destiny.title")
                        }}</label>
                        <div class="
                        bg-white
                        p-2
                        rounded-2
                        d-flex
                        justify-content-between
                        pointer
                        align-items-center
                      " style="border: 1px solid #ced4da" :data-bs-toggle="filterInformationStore.destiny.station ? '' : 'modal'
                              " data-bs-target="#modalDestinosMobil">
                            <span v-if="filterInformationStore.destiny.station">
                                {{ filterInformationStore.destiny.station }}
                            </span>
                            <span v-else>{{ $t("searcher.destiny.subtitle") }}</span>
                            <button class="btn btn-link" @click="filterInformationStore.clearDestinyBuscadorGrupos()"
                                v-if="filterInformationStore.destiny.station">
                                <i class="fa-solid fa-xmark"></i>
                            </button>
                            <i class="fa-solid fa-chevron-down" v-else></i>
                        </div>
                    </div>
                    <div class="input-form mb-2">
                        <label class="fw-bolder" id="datesLabel">
                            {{ $t("searcher.date.title") }}
                        </label>
                        <Datepicker v-model="date" monthPicker autoApply :format="format" :minDate="new Date(2022, 11, 1)">
                        </Datepicker>
                    </div>
                    <div class="input-form">
                        <label class="fw-bolder" id="datesLabel">
                            {{ $t("searcher.groups.title") }}
                        </label>
                        <select class="form-select" aria-label="Default select example" v-model="valorGrupos">
                            <option :value="null">Selecciona el tipo de grupo</option>
                            <template v-for="grupo in filtroViajes.tiposGrupo" :key="grupo.id">
                                <option :value="grupo">
                                    {{ grupo.descripcion }}
                                </option>
                            </template>
                        </select>
                    </div>

                    <div class="input-form">
                        <div class="btn btn-primary w-100 mt-3" @click="goTo(filterInformationStore)"
                            data-bs-dismiss="modal">
                            {{ $t('general.buscar') }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="modalDestinosMobil" tabindex="-1" aria-labelledby="modalDestinosMobilLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title" id="destinosModalLabel">
                        {{ $t("searcher.destiny.title") }}
                    </h5>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body">
                    <div class="column justify-content-around py-2">
                        <div v-for="(area, index) in filtroViajes.destino" :key="index" class="col-12">
                            <div class="fw-bold text-primary">
                                {{ area.nombre }}
                            </div>
                            <div v-for="lugarExperiencia in area.lugaresExperiencia" :key="lugarExperiencia.nombre" role="button">
                                <div class="buscador-subtitulo" :id="lugarExperiencia.id" :value="lugarExperiencia.nombre"
                                    data-bs-toggle="modal" data-bs-target="#buscadorMobil"
                                    @click="setDestiny(area, lugarExperiencia)">
                                    {{ titleCase(lugarExperiencia.nombre) }}
                                </div>
                            </div>
                            <br />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>-->
</template>

<script setup>
	import BuscadorGruposDesktopHome from './buscadorGrupos/BuscadorGruposDesktopHome.vue';
	import BuscadorGruposMobileHome from './buscadorGrupos/BuscadorGruposMobileHome.vue';
	import { ViajesGrupoCatalogo } from '@/router/RouterNames';
	import { computed } from 'vue';
	import { ref } from 'vue';
	import { namesMonths } from '@/helpers/dateUtiles';
	import { buscadorGrupos } from '@/store_pinia/buscadorGrupos';
	import router from '@/router';
	const filterInformationStore = buscadorGrupos(),
		route = ref(router.currentRoute);

	const filtroViajes = computed(() => filterInformationStore.filtrosViajes);

	const setDestiny = (area, station) => {
		filterInformationStore.setDestinyBuscadorGrupos(area.nombre, station.nombre);
	};

	const setFechasViajes = date => {
		filterInformationStore.setDateBuscadorGrupos(date.month, date.year, namesMonths('es-Es')[date.month]);
	};

	const setGroups = group => {
		filterInformationStore.setGroupsBuscadorGrupos(group);
	};

	const clearDestinos = () => {
		filterInformationStore.clearDestinyBuscadorGrupos();
	};

	const clearFechas = () => {
		filterInformationStore.clearDateBuscadorGrupos();
	};

	const actualizarQueryBuscadorGrupos = (fechaViajes, destinoViajes, grupoViajes) => {
		let fechaInicio = undefined;
		let fechaFin = undefined;

		if (fechaViajes.year && fechaViajes.month) {
			const auxInicio = new Date(fechaViajes.year, fechaViajes.month, 1);
			const auxFin = new Date(fechaViajes.year, fechaViajes.month + 1, 0);
			fechaInicio = `${auxInicio.getFullYear()}-${
				auxInicio.getMonth() + 1 > 9 ? auxInicio.getMonth() + 1 : '0' + (auxInicio.getMonth() + 1)
			}-${auxInicio.getDate() > 9 ? auxInicio.getDate() : '0' + auxInicio.getDate()}`;
			fechaFin = `${auxFin.getFullYear()}-${
				auxFin.getMonth() + 1 > 9 ? auxFin.getMonth() + 1 : '0' + (auxFin.getMonth() + 1)
			}-${auxFin.getDate() > 9 ? auxFin.getDate() : '0' + auxFin.getDate()}`;
		}

		router.replace({
			name: ViajesGrupoCatalogo,
			query: {
				grupo: grupoViajes.name,
				area: destinoViajes.area ? destinoViajes.area.toLowerCase() : undefined,
				lugarExperiencia: destinoViajes.station ? destinoViajes.station.toLowerCase() : undefined,
				fechaInicio,
				fechaFin,
			},
		});
	};

	const realizarBusquedaViajesGrupos = () => {
		const informacionBuscadorGrupos = { ...filterInformationStore.$state };
		actualizarQueryBuscadorGrupos(
			informacionBuscadorGrupos.date,
			informacionBuscadorGrupos.destiny,
			informacionBuscadorGrupos.groups
		);
	};

	if (route.value.name !== ViajesGrupoCatalogo) {
		filterInformationStore.setFilterInformation();
	}
</script>
