<template>
	<div v-if="misClientes" class="container proximas-reservas-detalle">
		<!-- Tabla -->
		<button class="btn btn-primary" @click="compartirHomeComercial">Compartir url</button>
		<div class="col-12 mb-4">
			<div class="row header-tabla gx-0 align-items-center">
				<div class="col-2">Fechas</div>
				<div class="col-2">
					{{ $t('general.experiencia') }}
				</div>
				<div class="col-2">Hotel</div>
				<div class="col-2">
					{{ $t('home.form.personalInformation.name') }}
				</div>
				<div class="col-2">Grupo</div>
				<div class="col-1">Plazas</div>
				<div class="col-1 text-center">Estado de la Reserva</div>
			</div>
			<div class="row body-tabla gx-0 align-items-center">
				<div v-for="cliente in misClientes" :key="cliente" class="row gx-0">
					<div class="col-2">
						{{ cliente.fecha }}
					</div>
					<div class="col-2">
						{{ cliente.viajeInfo }}
					</div>
					<div class="col-2">
						{{ cliente.hotel }}
					</div>
					<div class="col-2">
						{{ cliente.nombre }}
					</div>
					<div class="col-2">
						{{ cliente.grupo }}
					</div>
					<div class="col-1">
						{{ cliente.plazas }}
					</div>
					<div class="col-1 text-center">
						{{ cliente.estado }}
					</div>
					<!--    <small class="col-3 col-md-1"></small>-->
				</div>
			</div>
		</div>
	</div>
	<template v-else>
		<Spinner />
	</template>
</template>

<script setup>
	import { ref } from 'vue';
	import { misClientesStore } from '@/store_pinia/staff';
	import { createToaster } from '@meforma/vue-toaster';
	import CookiesUtils from '@/services/utils/CookiesUtils';
	import Spinner from '@/components/SpinnerLoading.vue';

	const toaster = createToaster({ position: 'top-right' });
	const misClientes = ref();
	const store_pinia_misClientes = misClientesStore();
	store_pinia_misClientes.setMisClientes().then(() => {
		misClientes.value = store_pinia_misClientes.misClientes;
	});

	function compartirHomeComercial() {
		navigator.clipboard
			.writeText(window.location.origin + '/grupos/viajes?comercialId=' + CookiesUtils.getUsuarioFromLocalStorage().id)
			.then(() => {
				toaster.success('Codigo copiado');
			});
	}
</script>

<style lang="scss" scoped>
	.proximas-reservas-detalle {
		background-color: white;
		min-height: 80vh;
		background-size: contain;
		background-position: top;
		color: var(--bs-primary);
		font-weight: 400;
		font-size: 0.8rem;
		padding-top: 12rem;
	}
	.header-tabla {
		background-color: var(--bs-primary);
		color: white;
		width: 100%;
		border-radius: var(--bs-border-radius-lg);
		box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5);
		margin-bottom: 1rem;
		padding: 1rem 0rem 0rem 1rem;

		div {
			padding-bottom: 1rem;
			padding-right: 1rem;
		}
	}

	.body-tabla {
		background-color: #f9f7f8;
		width: 100%;
		border-radius: var(--bs-border-radius-xl);
		padding-top: 1rem;
		padding-left: 1rem;

		div {
			padding-bottom: 1rem;
		}
	}
	.row {
		padding-bottom: 0;
	}
</style>
