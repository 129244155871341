import { isRef, isReactive, isProxy, toRaw, unref } from 'vue';


export function toRawDeep(input) {
	if (Array.isArray(input)) {
		return input.map((item) => toRawDeep(item));
	} if (isRef(input) || isReactive(input) || isProxy(input)) {
		return toRawDeep(toRaw(unref(input)));
	} if (input && typeof input === 'object') {
		return Object.keys(input).reduce((acc, key) => {
			acc[key] = toRawDeep(input[key]);
			return acc;
		}, {});
	}
	return input;
}
