<template>
	<div v-if="storeGlobal.valores != {}" class="banner-top-page__container">
		<div
			:class="backgroundBanner.class"
			:style="backgroundBanner.url ? { 'background-image': 'url(' + backgroundBanner.url + ')' } : {}">
			<div v-if="mostrarBuscador">
				<div class="container py-3 pt-5 pt-md-3 text-center">
					<div class="mt-5 banner-text">{{ $t('home.banner.title') }}</div>
				</div>

				<div v-if="agenciaStore.buscadorEnabled" class="container py-3 pt-5 pt-md-3 flex">
					<div class="mt-4 mb-5 d-flex justify-content-center gap-3 flex-column flex-md-row">
						<button class="btn btn-primary" @click="goToViajesGrupoCatalogo">
							{{ $t('home.banner.experienciasAnyo') }}
						</button>
						<button class="btn btn-primary" @click="goToBuscador">{{ $t('home.banner.experienciasAMedida') }}</button>
					</div>
				</div>
			</div>

			<div class="container pt-5 pt-md-3 mt-5">
				<slot />
			</div>
		</div>
	</div>
</template>

<script setup>
	import { useRouter } from 'vue-router';
	import { appGlobalStore } from '@/store_pinia/app';
	import { agenciaStoreModule } from '@/store_pinia/agencia';
	import { computed, ref, onMounted } from 'vue';
	import { useRoute } from 'vue-router';
	import { Buscador, ViajesGrupoCatalogo } from '@/router/RouterNames';

	const router = useRouter();

	const props = defineProps({
		viajesGrupoCatalogo: Boolean,
		mostrarBuscador: {
			type: Boolean,
			default: true,
		},
	});

	const isMobile = ref(window.innerWidth <= 992);
	const storeGlobal = appGlobalStore();
	const route = useRoute();
	const isHomePage = ref(false);
	const agenciaStore = agenciaStoreModule();

	function checkIfHomePage() {
		isHomePage.value = route.name === 'home';
	}

	const backgroundBanner = computed(() => {
		if (isMobile.value && !isHomePage.value) {
			return {};
		}

		if (isHomePage.value) {
			return {
				class: 'home_background',
				url: storeGlobal.valores.img_home,
			};
		}

		if (props.viajesGrupoCatalogo) {
			return {
				class: 'viajes-grupo-catalogo__background',
				url: storeGlobal.valores.img_catalogo_fondo,
			};
		}

		return {};
	});

	function goToBuscador() {
		router.push({
			name: Buscador,
		});
	}

	function goToViajesGrupoCatalogo() {
		router.push({
			name: ViajesGrupoCatalogo,
		});
	}

	onMounted(() => {
		checkIfHomePage();
		window.addEventListener('resize', () => {
			isMobile.value = window.innerWidth <= 992;
		});
	});
</script>

<style lang="scss" scoped>
	.banner-top-page__container {
		.home_background {
			padding-top: 12rem;
			padding-bottom: 3rem;
			background-position: center;
			background-size: cover;
			background-repeat: no-repeat;
			@media only screen and (max-width: 990px) {
				padding-top: 2rem;
			}
		}

		.viajes-grupo-catalogo__background {
			padding-top: 14rem;
			padding-bottom: 4rem;
			background-position: center;
			background-size: cover;
			background-repeat: no-repeat;
			@media only screen and (max-width: 990px) {
				padding-top: 2rem;
			}
		}
	}

	.banner-text {
		color: var(--Blanco, #fff);
		text-align: center;
		text-shadow: 0px 0px 1px rgba(33, 36, 68, 0.4), 2px 4px 5px rgba(33, 36, 68, 0.1),
			4px 6px 20px rgba(33, 36, 68, 0.1);
		font-family: 'Montserrat';
		font-size: 65px;
		font-style: italic;
		font-weight: 700;
		line-height: normal;
		letter-spacing: 0.18px;
		text-transform: uppercase;
	}

	.btn-primary {
		background-color: var(--bs-primary);
		border-color: var(--bs-primary);
		border-radius: 50px;
		font-size: 18px;
		padding: 10px 20px;
		color: #fff;
		text-shadow: 0px 0px 1px rgba(var(--bs-primary-rgb), 0.4), 2px 4px 5px rgba(var(--bs-primary-rgb), 0.1);
		box-shadow: 2px 2px 4px rgba(var(--bs-primary-rgb), 0.2);
		transition: background-color 0.3s ease;
		cursor: pointer;
	}

	@media (max-width: 768px) {
		.banner-text {
			color: white;
			text-align: center;
			text-shadow: 0px 0px 1px rgba(var(--bs-primary-rgb), 0.4), 2px 4px 5px rgba(var(--bs-primary-rgb), 0.1),
				4px 6px 20px rgba(var(--bs-primary-rgb), 0.1);
			font-family: 'Montserrat';
			font-size: 45px;
			font-style: italic;
			font-weight: 700;
			line-height: normal;
			letter-spacing: 0.18px;
			text-transform: uppercase;
		}
	}
</style>
