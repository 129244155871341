import { defineStore } from 'pinia';

export const usuariosFormulario = defineStore('usuariosFormulario', {
	state: () => ({
		formulario: null,
	}),

	actions: {
		setFormularioId(id) {
			this.formulario = id;
		},
	},
});
