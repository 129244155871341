<template>
	<div class="formulario-presupuesto">
		<form @submit.prevent>
			<h1 class="mb-4">
				{{ $t('home.form.personalInformation.title') }}
			</h1>

			<div class="mb-3" :class="{ error: v$.nombre.$errors.length }">
				<label for="inputName" class="form-label"> *{{ $t('home.form.personalInformation.name') }} </label>
				<input v-model="presupuesto.nombre" type="text" class="form-control" @blur="v$.nombre.$touch" />
			</div>
			<div class="mb-3" :class="{ error: v$.apellidos.$errors.length }">
				<label for="inputLastname" class="form-label"> *{{ $t('home.form.personalInformation.lastName') }} </label>
				<input v-model="presupuesto.apellidos" type="text" class="form-control" @blur="v$.apellidos.$touch" />
			</div>
			<div class="mb-3" :class="{ error: v$.email.$errors.length }">
				<label for="inputEmail" class="form-label"> *{{ $t('home.form.personalInformation.email') }} </label>
				<input v-model="presupuesto.email" type="email" class="form-control" @blur="v$.email.$touch" />
			</div>
			<div class="mb-3" :class="{ error: v$.telefono.$errors.length }">
				<label for="inputEmail" class="form-label"> *{{ $t('home.form.personalInformation.phone') }} </label>
				<input v-model="presupuesto.telefono" class="form-control" type="text" @blur="v$.telefono.$touch" />
			</div>
			<div class="mb-3">
				<label for="inputEmail" class="form-label">{{ $t('home.form.personalInformation.birthDate') }}</label>
				<Datepicker v-model="presupuesto.fechaNacimiento" :format="format" />
			</div>

			<div class="mb-3 form-check">
				<input
					id="exampleCheck1"
					v-model="terms.privacity"
					type="checkbox"
					class="form-check-input"
					@blur="v2$.privacity.$touch" />
				<label
					class="form-check-label"
					for="exampleCheck1"
					:class="v2$.privacity.$errors.length ? 'text-danger fw-bolder' : ''">
					{{ $t('home.form.policy.readAccept') }}
					<button
						type="button"
						class="btn pl-0"
						data-bs-toggle="modal"
						data-bs-target="#exampleModal2"
						style="width: fit-content; cursor: pointer; padding: 0"
						:class="v2$.privacity.$errors.length ? 'text-danger fw-bolder' : ''">
						<u> {{ $t('general.terminosCondiciones') }}</u>
					</button>
					<div
						id="exampleModal2"
						class="modal fade"
						tabindex="-1"
						aria-labelledby="exampleModalLabel"
						aria-hidden="true">
						<div class="modal-dialog modal-dialog-centered">
							<div class="modal-content">
								<div class="modal-header">
									<h5 id="exampleModalLabel" class="modal-title">
										{{ $t('general.proteccionDatos') }}
									</h5>
									<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" />
								</div>
								<div class="modal-body">
									<table class="table">
										<tbody class="text-start">
											<tr>
												<td class="fw-bolder">RESPONSABLE</td>
												<td>Travel&Night S.L.</td>
											</tr>
											<tr>
												<td class="fw-bolder">FINALIDAD</td>
												<td>
													Directorio de contactos y gestión financiera, administrativa y comercial de las actividades
													desarrolladas por Travel&Night S.L
												</td>
											</tr>
											<tr>
												<td class="fw-bolder">LEGITIMACIÓN</td>

												<td>Celebración y ejecución de contrato y consentimiento del interesado.</td>
											</tr>

											<tr>
												<td class="fw-bolder">DESTINATARIO</td>
												<td>
													Sus datos serán cedidos a las empresas encargadas del alojamiento reservado, a las centrales
													de reserva de hoteles y apartamentos, a los proveedores de servicios externos y entidades
													aseguradoras y en todos los casos en que se establezca por obligación legal.
												</td>
											</tr>
											<tr>
												<td class="fw-bolder">DERECHOS</td>
												<td>
													De acceso, de rectificación, de supresión, de limitación de tratamiento, de portabilidad y de
													oposición de los datos como se explica en la información adicional.
												</td>
											</tr>

											<tr>
												<td class="fw-bolder">INFORMACIÓN ADICIONAL</td>

												<td>
													Puede consultar la información adicional y detallada sobre nuestra Política de Protección de
													Datos remitiéndose a pd@skiandnight.com
												</td>
											</tr>
										</tbody>
									</table>
								</div>
							</div>
						</div>
					</div>
				</label>
			</div>

			<div class="row d-flex justify-content-around mt-3">
				<div class="col-3 d-flex justify-content-center">
					<button class="btn btn-primary" @click="validateForm1()">
						{{ $t('home.form.nextButton') }}
					</button>
				</div>
			</div>
		</form>
	</div>
</template>
<script setup>
	import { reactive } from 'vue';
	import Datepicker from '@vuepic/vue-datepicker';
	import HomeApiService from '../../../services/api/skiandnight/HomeApiService';
	import router from '../../../router';
	import { usuariosFormulario } from '../../../store_pinia/user';
	import useValidate from '@vuelidate/core';
	import { required, email, numeric } from '@vuelidate/validators';

	const store = usuariosFormulario();

	const terms = reactive({
		privacity: false,
	});

	const presupuesto = reactive({
		nombre: null,
		apellidos: null,
		email: null,
		telefono: null,
		fechaNacimiento: null,
	});

	const rules = {
		nombre: { required },
		apellidos: { required },
		email: { required, email },
		telefono: { required, numeric },
	};

	const rule2 = {
		privacity: { checked: value => value === true },
	};

	const v$ = useValidate(rules, presupuesto);
	const v2$ = useValidate(rule2, terms);

	const validateForm1 = () => {
		v$.value.$validate();
		v2$.value.$validate();

		if (!v$.value.$invalid && !v2$.value.$invalid) {
			if (presupuesto.fechaNacimiento) {
				let date = new Date(presupuesto.fechaNacimiento);
				presupuesto.fechaNacimiento = date.toLocaleDateString('en-US');
			}

			HomeApiService.sendInformacionPersonal(presupuesto).then(res => {
				store.setFormularioId(res);
				router.push('/presupuesto');
			});
		}
	};

	const format = fechaNacimiento => {
		const day = fechaNacimiento.getDate();
		const month = fechaNacimiento.getMonth() + 1;
		const year = fechaNacimiento.getFullYear();
		return `${day}/${month}/${year}`;
	};
</script>

<style lang="scss" scoped>
	.formulario-presupuesto {
		max-width: 800px;
		margin: auto;
		margin-bottom: 5rem;
		padding: 12rem 2rem 4rem 2rem;
	}

	.error {
		.form-control {
			border-color: #dc3545;
		}
	}
</style>
