<template>
	<div class="dropdown icon-perfil-container">
		<button
			id="userDropdown"
			class="icon-perfil"
			:class="isUsuarioLogged ? ' border border-secondary' : ''"
			type="button"
			aria-label="User dropdown button"
			data-bs-toggle="dropdown"
			aria-expanded="false">
			<span v-if="isUsuarioLogged" class="text-secondary fs-6 fw-bolder rounded-circle">
				{{ nombreUsuario[0].toUpperCase() }}
			</span>
			<i v-else class="fa-regular fa-user fa-lg" />
		</button>

		<ul id="userDrop" class="dropdown-menu p-3 shadow-lg" aria-labelledby="userDropdown">
			<div>
				<li>
					<p class="text-primary fw-bold border-bottom-2 px-3 py-1 fs-5 item-title">
						<u>
							{{ $t('header.user.title') }}
						</u>
					</p>
				</li>
				<template v-if="!isUsuarioLogged">
					<li>
						<span class="dropdown-item" @click="openModalLogin()">{{ $t('header.user.login') }}</span>
					</li>
					<li>
						<span class="dropdown-item" @click="openModalLogin()">{{ $t('header.user.signIn') }}</span>
					</li>
				</template>

				<template v-else>
					<li>
						<router-link class="dropdown-item" :to="{ name: UsuarioProximasReservas }">
							{{ $t('reserva.misReservas') }}
						</router-link>
					</li>
					<li v-if="hasRoleGestorRooming">
						<router-link class="dropdown-item" :to="{ name: UsuarioRooming }">
							{{ $t('rooming.miRooming') }}
						</router-link>
					</li>
					<li>
						<router-link class="dropdown-item" :to="{ name: 'perfil' }">
							{{ $t('general.miPerfil') }}
						</router-link>
					</li>
					<li v-if="rolesUsuario.includes(rolComercial)">
						<router-link class="dropdown-item" :to="{ name: 'MisClientes' }">
							{{ $t('subtitulo.misViajeros') }}
						</router-link>
					</li>
					<li class="dropdown-item" @click="botonLogout()">
						{{ $t('general.cerrarSesion') }}
					</li>
				</template>
			</div>
		</ul>
	</div>
</template>

<script setup>
	import { UsuarioProximasReservas, UsuarioRooming } from '@/router/RouterNames';
	import { rolComercial } from '@/constants/rolesName';
	import { computed } from 'vue';
	import { useStore } from 'vuex';
	import { useLoginStore } from '@/store_pinia/login';
	import { alertAutoCloseSuccessWithoutCallback } from '@/services/utils/AlertUtils';
	import router from '@/router';

	const store = useStore();
	const loginStore = useLoginStore();

	const isUsuarioLogged = computed(() => store.getters['login/isUsuarioLogged']);
	const nombreUsuario = computed(() => store.state.login.usuario.nombre);
	const hasRoleGestorRooming = computed(() => store.getters['login/hasRoleGestorRooming']);
	const rolesUsuario = computed(() => store.state.login.usuario.roles);

	function openModalLogin() {
		loginStore.openLoginModal();
	}

	function botonLogout() {
		store.dispatch('login/logout').then(() => {
			const dropdownMenus = document.querySelectorAll('#userDrop, #userDropMobile');
			dropdownMenus.forEach(dropdownMenu => {
				if (dropdownMenu && dropdownMenu.classList.contains('show')) {
					dropdownMenu.classList.remove('show');
				}
			});
			alertAutoCloseSuccessWithoutCallback(2000);
			if (router.currentRoute.value.matched.some(r => r.meta.requiresLogin)) {
				router.push({ name: 'home' });
			}
		});
	}
</script>

<style scoped lang="scss">
	@import '~@/assets/scss/main.scss';

	.dropdown-item {
		cursor: pointer;
		transition: 0.2s;
	}

	.dropdown-item:hover {
		background-color: transparent;
		color: var(--bs-secondary);
	}

	.icon-perfil-container {
		z-index: 999;
		@include media-breakpoint-down(md) {
			width: 35px;
			height: 35px;
		}
		width: 60px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-top: 2px;
	}

	.icon-perfil {
		@include media-breakpoint-down(md) {
			width: 35px;
			height: 35px;
			font-size: 15px;
		}
		z-index: 1002;
		width: 45px;
		height: 45px;
		background-color: var(--bs-primary);
		border: 1.5px solid var(--bs-secondary);
		border-radius: 50%;
		color: var(--bs-secondary);
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 20px;
		box-shadow: 0 1px 3px 0 rgb(var(--bs-primary-rgb), 0.5);
	}

	.item-title {
		text-underline-offset: 8px;
	}
</style>
