<template>
	<div class="">
		<p class="text-primary fw-bolder fs-5">{{ $t('codigoDescuento.tienesCodDescuento') }}</p>
		<form class="row" @submit.prevent="validarCodigo(codigoDescuento)">
			<div class="col-8">
				<input
					v-model="codigoDescuento"
					type="text"
					class="form-control"
					:placeholder="$t('input.placeholder.introduceCampo')"
					:disabled="loading"
					required />
			</div>
			<div class="col-4">
				<button class="btn btn-primary fw-bold" type="submit" :disabled="loading">
					{{ $t('general.aplicar') }}
				</button>
			</div>
		</form>
	</div>
</template>

<script>
	import { defineComponent, ref } from 'vue';
	import ViajesGrupoApiService from '@/services/api/skiandnight/ViajesGrupoApiService';
	import { alertAutoCloseSucessHtml, alertAutoCloseWarningWithoutCallback } from '@/services/utils/AlertUtils';

	export default defineComponent({
		name: 'FormCodigoDescuento',
		props: {
			viajeId: {
				type: Number,
				default: null,
			},
			reservasIds: {
				type: Array,
				default: () => [],
			},
		},
		emits: ['valorDescontar', 'codigoDescuento'],
		setup(props, { emit }) {
			const codigoDescuento = ref(null);
			const loading = ref(false);
			const validarCodigo = codigo => {
				loading.value = true;
				ViajesGrupoApiService.validarDescuento(props.viajeId, codigo, props.reservasIds)
					.then(infomacionCupo => {
						alertAutoCloseSucessHtml(
							3000,
							` <strong><p class="text-primary">${infomacionCupo.descripcion}</p><p class="text-secondary">${infomacionCupo.cantidad} ${infomacionCupo.tipoCantidad}</p></strong>`
						);
						emit('valorDescontar', infomacionCupo);
						emit('codigoDescuento', codigo);
					})
					.catch(err => {
						alertAutoCloseWarningWithoutCallback(2000, err.response.data || err.response);
						emit('valorDescontar', null);
					})
					.finally(() => {
						loading.value = false;
						codigoDescuento.value = null;
					});
			};
			return { validarCodigo, codigoDescuento, loading };
		},
	});
</script>

<style scoped></style>
