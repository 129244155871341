import SkiAndNightApiService from './BaseSkiAndNightApiService';

const USUARIO_API_VERSION = 'v1';
const USUARIO_API_BASEPATH_URL = '/usuario/' + USUARIO_API_VERSION;

class UsuarioApiService {
	doRegistro(usuarioRegistro) {
		const uUsuarioRegistroDTO = this._buildUUsuarioRegistroDTO(usuarioRegistro);
		return SkiAndNightApiService.post(USUARIO_API_BASEPATH_URL.concat('/registro'), uUsuarioRegistroDTO);
	}

	registrarYLoguear(usuarioRegistro) {
		const uUsuarioRegistroDTO = this._buildUUsuarioRegistroDTO(usuarioRegistro);
		return SkiAndNightApiService.post(USUARIO_API_BASEPATH_URL.concat('/registroAndLogin'), uUsuarioRegistroDTO);
	}

	activarUsuario(codigo, password) {
		const uPasswordDTO = this._buildUPasswordDTO(password);
		return SkiAndNightApiService.put(USUARIO_API_BASEPATH_URL.concat('/activar/' + codigo), uPasswordDTO);
	}

	recordarPassword(email) {
		return SkiAndNightApiService.put(USUARIO_API_BASEPATH_URL.concat('/password/recordar/' + email));
	}

	registrarGoogle(usuarioRegistro) {
		const uUsuarioRegistroDTO = this._buildUUsuarioRegistroGoogleDTO(usuarioRegistro);

		return SkiAndNightApiService.post(USUARIO_API_BASEPATH_URL.concat('/registroGoogle'), uUsuarioRegistroDTO);
	}

	registrarYLoguearGoogle(usuarioRegistro) {
		const uUsuarioRegistroDTO = this._buildUUsuarioRegistroGoogleDTO(usuarioRegistro);
		return SkiAndNightApiService.post(USUARIO_API_BASEPATH_URL.concat('/registroAndLoginGoogle'), uUsuarioRegistroDTO);
	}

	reestablecerPassword(codigo, password) {
		const uPasswordDTO = this._buildUPasswordDTO(password);
		return SkiAndNightApiService.put(USUARIO_API_BASEPATH_URL.concat('/password/reestablecer/' + codigo), uPasswordDTO);
	}

	validateEmail(emailUser) {
		return SkiAndNightApiService.get(USUARIO_API_BASEPATH_URL.concat(`/email/validar?email=${emailUser}`));
	}

	addAccesoLink(comercialId) {
		return SkiAndNightApiService.post(USUARIO_API_BASEPATH_URL.concat(`/accesosLink?comercial=${comercialId}`));
	}

	modificarDatosUsuario(usuario) {
		return SkiAndNightApiService.put(USUARIO_API_BASEPATH_URL.concat('/datos'), this._buildUUsuarioDTO(usuario));
	}

	obtenerDatosUsuario() {
		return SkiAndNightApiService.get(USUARIO_API_BASEPATH_URL.concat('/datos'));
	}

	modificarPassword(passwordEdicion) {
		return SkiAndNightApiService.put(
			USUARIO_API_BASEPATH_URL.concat('/datos/password'),
			this._buildUPasswordEdicionDTO(passwordEdicion)
		);
	}

	doBajaUsuario() {
		return SkiAndNightApiService.put(USUARIO_API_BASEPATH_URL.concat('/baja'));
	}

	getPaisesOpts() {
		return SkiAndNightApiService.get(USUARIO_API_BASEPATH_URL.concat('/paises'));
	}

	darBajaPublicidad(codigo) {
		return SkiAndNightApiService.put(USUARIO_API_BASEPATH_URL.concat('/datos/publicidad/baja/' + codigo));
	}

	uploadDniImages(formDNI) {
		const config = {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		};
		return SkiAndNightApiService.post(USUARIO_API_BASEPATH_URL.concat('/datos/dni'), formDNI, config);
	}

	//////////////////////
	// PRIVATE BUILDERS //
	//////////////////////

	_buildUUsuarioRegistroDTO(usuarioRegistro) {
		return {
			nombre: usuarioRegistro.nombre,
			apellidos: usuarioRegistro.apellidos,
			telefono: usuarioRegistro.telefono,
			email: usuarioRegistro.email,
			emailConfirm: usuarioRegistro.emailConfirm,
			fechaNacimiento: usuarioRegistro.fechaNacimiento,
			password: usuarioRegistro.password,
			mailingPublicidadActivo: usuarioRegistro.mailingPublicidadActivo,
		};
	}

	_buildUPasswordDTO(password) {
		return {
			password: password.password,
			passwordConfirm: password.passwordConfirm,
		};
	}

	_buildUUsuarioDTO(usuario) {
		return {
			nombre: usuario.nombre,
			apellidos: usuario.apellidos,
			telefono: usuario.telefono,
			email: usuario.email,
			emailConfirm: usuario.emailConfirm,
			fechaNacimiento: usuario.fechaNacimiento,
			dni: usuario.dni,
			direccion: usuario.direccion,
			paisId: usuario.paisId,
			provincia: usuario.provincia,
			poblacion: usuario.poblacion,
			codigoPostal: usuario.codigoPostal,
			mailingPublicidadActivo: usuario.mailingPublicidadActivo,
		};
	}

	_buildUPasswordEdicionDTO(passwordEdicion) {
		return {
			password: passwordEdicion.password,
			passwordConfirm: passwordEdicion.passwordConfirm,
			oldPassword: passwordEdicion.oldPassword,
		};
	}

	_buildUUsuarioRegistroGoogleDTO(usuarioRegistro) {
		return {
			authCode: usuarioRegistro.code,
		};
	}
}

export default new UsuarioApiService();
