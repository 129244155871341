<template>
	<div>
		<button class="btn btn-circle" :disabled="paginaActual === 1" @click="primeraPagina">
			<i class="fa-solid fa-chevron-left" /><i class="fa-solid fa-chevron-left" />
		</button>

		<button class="btn btn-circle" :disabled="paginaActual === 1" @click="anteriorPagina">
			<i class="fa-solid fa-chevron-left" />
		</button>

		<span>
			{{ paginaActual }}
			<span v-if="cantidadPaginas != 1">/ {{ cantidadPaginas }}</span>
		</span>
		<!-- <template v-if="cantidadPaginas > 5 && paginaActual > 3">
			<button
					class="btn btn-circle"
					@click="cambiarPagina(1)"
				>
					1
				</button>
			<i class="fa-solid fa-ellipsis"></i>
		</template>
		<template v-for="pagina in cantidadPaginas" :key="pagina">
			<template v-if="mostrarPagina(pagina)">
				<button
					class="btn btn-circle"
					:class="pagina == paginaActual ? 'border-primary border-2' : ''"
					@click="cambiarPagina(pagina)"
				>
					{{ pagina }}
				</button>
			</template>
		</template>
		<template v-if="cantidadPaginas > 5 && paginaActual + 2 < cantidadPaginas">
			<i class="fa-solid fa-ellipsis"></i>
			<button
					class="btn btn-circle"
					@click="cambiarPagina(cantidadPaginas)"
				>
					{{cantidadPaginas}}
				</button>
		</template> -->
		<button class="btn btn-circle" :disabled="paginaActual === cantidadPaginas" @click="siguientePagina">
			<i class="fa-solid fa-chevron-right" />
		</button>

		<button class="btn btn-circle" :disabled="paginaActual === cantidadPaginas" @click="ultimaPagina">
			<i class="fa-solid fa-chevron-right" /> <i class="fa-solid fa-chevron-right" />
		</button>
	</div>
</template>

<script setup>
	defineProps({
		cantidadPaginas: {
			type: Number,
			default: null,
		},
		paginaActual: {
			type: Number,
			default: null,
		},
	});

	const emits = defineEmits(['enviarPagina', 'siguiente', 'anterior', 'primera', 'ultima']);

	// function cambiarPagina(numeroPagina) {
	// 	emits('enviarPagina', numeroPagina);
	// }

	function siguientePagina() {
		emits('siguiente');
	}

	function anteriorPagina() {
		emits('anterior');
	}

	function ultimaPagina() {
		emits('ultima');
	}

	function primeraPagina() {
		emits('primera');
	}

	// function mostrarPagina(pagina) {
	// 	if (props.paginaActual <= 3) {
	// 		return pagina <= 5;
	// 	} else if (props.paginaActual >= props.cantidadPaginas - 2) {
	// 		return pagina >= props.cantidadPaginas - 4;
	// 	} else {
	// 		const inicio = Math.max(1, props.paginaActual - 2);
	// 		const fin = Math.min(props.cantidadPaginas, props.paginaActual + 2);
	// 		return pagina >= inicio && pagina <= fin;
	// 	}
	// }
</script>

<style lang="scss" scoped>
	button {
		border-radius: 100%;
		width: 38px;
		height: 38px;
		transition: 0.1s ease-in-out;
		margin: 0 2px;
		padding: 0;

		&:hover {
			background-color: var(--bs-secondary);
		}
	}
</style>
