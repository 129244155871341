import BaseApiService from '../BaseApiService';
// import {store} from '../../../store'; // Importa tu Vuex store
import router from '@/router';
import { tokenJwtVencido } from '@/services/utils/CookiesUtils';

class BaseSkiAndNightApiService extends BaseApiService {
	constructor() {
		const AxiosSkiAndNightConfig = {
			baseURL: process.env.VUE_APP_SKI_AND_NIGHT_BASE_URL + process.env.VUE_APP_SKI_AND_NIGHT_API_BASE_PATH,
			withCredentials: process.env.NODE_ENV === 'development',
		};
		super(AxiosSkiAndNightConfig);

		// const actions = {
		// 	...mapActions({
		// 		logout: 'login/logout'
		// 	})
		// };

		this.axioInstance.interceptors.response.use(
			function (response) {
				tokenJwtVencido();
				// Any status code that lie within the range of 2xx cause this function to trigger
				// Do something with response data
				return response.data;
			},
			async function (error) {
				if (error.response.data.code === 401 && !router.currentRoute.value.meta.requiresLogin) {
					if (tokenJwtVencido()) {
						// await store.dispatch('login/logout',{redirect:false})
					}
				}
				return Promise.reject(error);
			}
		);
	}
}

export default new BaseSkiAndNightApiService();
