import { defineStore } from 'pinia';
import ReservasApiService from '../services/api/skiandnight/ReservasApiService';
import { shallowRef } from 'vue';

export const proximasExperiencias = defineStore('proximasExperiencias', {
	state: () => ({
		grupos: shallowRef(null),
		grupoSeleccionado: shallowRef(null),
	}),
	getters: {
		getGrupoSeleccionado(state) {
			return state.grupoSeleccionado;
		},
		getGrupos(state) {
			return state.grupos;
		},
	},
	actions: {
		async loadProximasExperiencias() {
			await ReservasApiService.getProximasExperiencias().then(res => {
				this.grupos = res;
			});
		},
		async setGrupoSeleccionado(grupoId, cache = true) {
			let index = -1;

			if (this.grupos != null && cache) {
				index = this.grupos.findIndex(habitacion => {
					return habitacion.id === grupoId;
				});
				if (index !== -1) {
					this.grupoSeleccionado = this.grupos[index];
					return;
				}
			}

			await ReservasApiService.getGrupo(grupoId).then(res => {
				this.grupoSeleccionado = res;
			});
		},
	},
});
