export const agencia = 1;

export const URL_SNOWTIME = 'https://snowtime.mygocu.com';
export const URL_SKIANDNIGHT = 'https://skiandnight.com';
export const URL_SEAANDNIGHT = 'https://seaandnight.mygocu.com';
export const URL_CIRCUITONACIONALDEPOKER = 'https://circuitonacionaldepoker.mygocu.com';
export const URL_XPLORERS = 'https://xplorers.mygocu.com';
export const URL_WANDERTRIP_UNIEVENTO = 'https://wandertrip-unievento.mygocu.com';
export const URL_WSF = 'https://wsf.mygocu.com';
export const URL_ANDORTRAVEL = 'https://andortravel.mygocu.com';
export const URL_MEGASNOWTRIPS = 'https://megasnowtrips.mygocu.com';
export const URL_SNOWDAZE = 'https://snowdaze.mygocu.com';
export const URL_SUNANDSNOWWEEKEND = 'https://sunandsnowweekend.mygocu.com';
export const URL_HYROX = 'https://hyrox.mygocu.com';
export const URL_SKISERVEIS = 'https://skiserveis.mygocu.com';
export const URL_MEGANEVE = 'https://meganeve.mygocu.com';
export const URL_FESTIVALHIBERNATION = 'https://festivalhibernation.mygocu.com';
export const URL_PRTRAVEL = 'https://prtravel.mygocu.com';
export const URL_DEMO = 'https://demo.mygocu.com';
export const URL_BOOMBASTICFESTIVAL = 'https://boombasticfestival.mygocu.com';
export const URL_CONSOLACIONMADRID = 'https://consolacionmadrid.mygocu.com';
export const URL_SECRETCLUB = 'https://secretclub.mygocu.com';
export const URL_FACTORIAL = 'https://factorial.mygocu.com';
export const URL_GRUPOEMPRESAAIRBUS = 'https://grupoempresaairbus.mygocu.com';
export const URL_SNOWROWFESTIVAL = 'https://snowrowfestival.mygocu.com';
export const URL_ANDORRASNOWDOWN = 'https://andorra-snowdown.mygocu.com';
export const URL_DOGTOWNFORMIGAL = 'https://dogtownformigal.mygocu.com';
export const URL_MOMOAPRESKI = 'https://momoapreski.mygocu.com';
export const URL_JDSIMONGUIDE = 'https://jdsimonguide.mygocu.com';
export const URL_RUGBYAMATEUR = 'https://rugbyamateur.mygocu.com';
export const URL_SPRINGBREAK = 'https://springbreak.mygocu.com';
export const URL_SELP = 'https://selp.mygocu.com';
export const URL_BULLFYCONECTA = 'https://bullfyconecta.mygocu.com';
export const URL_LAOLASURF = 'https://laolasurf.mygocu.com';
export const URL_GORILLAFEST = 'https://gorillafest.mygocu.com';
export const URL_BODOSOUND = 'https://bodosound.mygocu.com';
export const URL_DREAMBEACH = 'https://dreambeach.mygocu.com';
export const URL_WEEKENDBEACH = 'https://weekendbeach.mygocu.com';
export const URL_SKICENTER = 'https://skicenter.mygocu.com';
export const URL_AMAZONLOGISTICS = 'https://amazonlogistics.mygocu.com';
export const URL_ISLASONICA = 'https://islasonica.mygocu.com';
export const URL_MAGNIFICFEST = 'https://magnificfest.mygocu.com';
export const URL_BIKESHOWNATURLAND = 'https://bikeshownaturland.mygocu.com';
export const URL_LOVETHE90SALICANTE = 'https://lovethe90salicante.mygocu.com';
export const URL_GRANADALATINA = 'https://granadalatina.mygocu.com';
export const URL_WIUC = 'https://wiuc.mygocu.com';
export const URL_BSSPAIN = 'https://bsspain.mygocu.com';
