<template>
	<div class="carousel-content px-2 py-3">
		<h2 class="text-primary">
			{{ $t('general.articulosRelacionados') }}
		</h2>
		<div v-if="store.blogs">
			<Carousel snap-alig="start" :wrap-around="true" :autoplay="7000" :breakpoints="breakpoints">
				<Slide v-for="slide in store.blogs" :key="slide">
					<div class="px-2">
						<blogLandingPostVue :data-card="slide" />
					</div>
				</Slide>

				<template #addons>
					<Navigation />
				</template>
			</Carousel>
		</div>
	</div>
</template>

<script>
	import { defineComponent } from 'vue';
	import { Carousel, Slide, Navigation } from 'vue3-carousel';
	import { ref } from 'vue';
	import blogLandingPostVue from 'src/components/card/blog/BlogPostCard.vue';
	import storeBlogs from '@/store_pinia/blogs';

	export default defineComponent({
		name: 'HomeCarousel',
		components: {
			Carousel,
			Slide,
			Navigation,
			blogLandingPostVue,
		},

		setup() {
			const breakpoints = ref({});

			const store = storeBlogs();

			store.setBlogs();

			breakpoints.value = {
				0: { itemsToShow: 1 },
				800: {
					itemsToShow: 2,
				},
				1000: {
					itemsToShow: 3,
				},
			};
			return { breakpoints, store };
		},
	});
</script>

<style scoped lang="scss">
	.carousel-content {
		max-width: 1440px;
		margin: auto;
		margin-top: 1rem;

		h2 {
			font-size: 36px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			letter-spacing: 0.309px;
		}
	}

	.home-card {
		&__image {
			border-radius: var(--bs-border-radius-lg);
			mix-blend-mode: normal;
			opacity: 0.8;
			margin: auto;
		}

		&__title {
			font-size: 21px;
			font-weight: 700;
			line-height: 25.6px;
		}

		&__description {
			font-size: 14px;
			line-height: 17px;
			color: #6f6e8e;
		}
	}
</style>
