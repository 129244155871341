export function parseJwt(token) {
	let tokenDecoded = atob(token.split('.')[1]);
	let tokenJson = { ...JSON.parse(tokenDecoded) };
	tokenJson.apellidos = decodeURIComponent(tokenJson.apellidos);
	tokenJson.nombre = decodeURIComponent(tokenJson.nombre);
	return tokenJson;
}

function getUsuarioFromLocalStorage() {
	let usuario;
	let token = localStorage.getItem('jwt-mg');
	if (token) {
		usuario = parseJwt(token);
	}
	return usuario;
}

export function tokenJwtVencido() {
	let token = getUsuarioFromLocalStorage();

	if (!token || !token.exp) {
		return true;
	}

	const currentDateInSeconds = Math.floor(Date.now() / 1000);

	return currentDateInSeconds > token.exp;
}

export default { getUsuarioFromLocalStorage };
