import SkiAndNightApiService from './BaseSkiAndNightApiService';

const STAFF_API_VERSION = 'v1';
const STAFF_API_BASEPATH_URL = 'staff/' + STAFF_API_VERSION;

class StaffApiService {
	async getMisClientes() {
		return await SkiAndNightApiService.get(STAFF_API_BASEPATH_URL.concat('/misClientes'));
	}
}

export default new StaffApiService();
