import { defineStore } from 'pinia';
import HomeApiService from '../services/api/skiandnight/HomeApiService';

export const apresSkisStore = defineStore('apresSkisStore', {
	state: () => ({
		apresSkis: null,
		apresSki: null,
	}),

	actions: {
		async setApresSkis() {
			if (!this.apresSkis) {
				await HomeApiService.getApresSkis().then(res => (this.apresSkis = res));
			}
		},

		async setApresSki(apresSkiId) {
			if (!this.apresSki || this.apresSki.id != apresSkiId) {
				await HomeApiService.getApresSkiById(apresSkiId).then(res => (this.apresSki = res));
			}
		},
	},
});
