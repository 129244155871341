import { defineStore } from 'pinia';

export const useLoginStore = defineStore('login', {
	state: () => ({
		showLoginModal: false,
	}),
	actions: {
		openLoginModal() {
			this.showLoginModal = true;
		},
		closeLoginModal() {
			this.showLoginModal = false;
		},
	},
});
