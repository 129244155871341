<template>
	<SpinnerVue v-if="loading" />
	<form v-else enctype="multipart/form-data" @submit.prevent="registrarCheckIn(reservaCheckin)">
		<div class="row">
			<div class="col-12 col-sm-6">
				<div class="mb-3">
					<label for="formControlDni" class="form-label fw-bold text-primary">
						{{ $t('home.form.personalInformation.birthDate') }}
					</label>
					<Datepicker
						v-model="reservaCheckin.fechaNacimiento"
						auto-apply
						:placeholder="$t('home.form.personalInformation.birthDate')"
						:flow="['year', 'month', 'calendar']"
						:format="format" />
				</div>
			</div>
			<div class="col-12 col-sm-6">
				<div class="mb-3">
					<label for="formControlDni" class="form-label fw-bold text-primary">
						{{ $t('home.form.personalInformation.nacionalidad') }}
					</label>
					<input
						id="formControlDni"
						v-model="reservaCheckin.nacionalidad"
						type="text"
						class="form-control"
						:placeholder="$t('home.form.personalInformation.nacionalidad')"
						required />
				</div>
			</div>

			<div class="col-12 col-sm-6">
				<div class="mb-3">
					<label for="formControlDni" class="form-label fw-bold text-primary">
						{{ $t('home.form.personalInformation.lugarNacimiento') }}
					</label>
					<input
						id="formControlDni"
						v-model="reservaCheckin.lugarNacimiento"
						type="text"
						class="form-control"
						:placeholder="$t('home.form.personalInformation.lugarNacimiento')"
						required />
				</div>
			</div>

			<div class="col-12 col-sm-6">
				<div class="mb-3">
					<label for="formControlDni" class="form-label fw-bold text-primary">
						{{ $t('home.form.personalInformation.dni') }}
					</label>
					<input
						id="formControlDni"
						v-model="reservaCheckin.dni"
						type="text"
						class="form-control"
						:placeholder="$t('home.form.personalInformation.dni')"
						required
						maxlength="9" />
				</div>
			</div>
			<div class="col-12 col-sm-6">
				<div class="mb-3">
					<label for="formControlDni" class="form-label fw-bold text-primary">
						{{ $t('home.form.personalInformation.expedicionDni') }}
					</label>
					<Datepicker
						v-model="reservaCheckin.fechaExpedicionDni"
						auto-apply
						:placeholder="$t('home.form.personalInformation.expedicionDni')"
						:flow="['year', 'month', 'calendar']"
						:format="format" />
				</div>
			</div>

			<div class="col-12 col-sm-6">
				<div class="mb-3">
					<label for="formControlDni" class="form-label fw-bold text-primary">
						{{ $t('home.form.personalInformation.sexo') }}
					</label>
					<select v-model="reservaCheckin.sexo" class="form-select" aria-label="Default select example">
						<option value="Hombre" selected>
							{{ $t('home.form.personalInformation.hombre') }}
						</option>
						<option value="Mujer">
							{{ $t('home.form.personalInformation.mujer') }}
						</option>
					</select>
				</div>
			</div>
			<div class="col-12 col-sm-6">
				<div class="mb-3">
					<label for="formControlDni" class="form-label fw-bold text-primary">
						{{ $t('home.form.personalInformation.diaHoraLlegada') }}
					</label>
					<div class="d-flex">
						<Datepicker
							v-model="reservaCheckin.checkInDia"
							auto-apply
							:placeholder="$t('home.form.personalInformation.diaLlegada')"
							time-picker-inline
							:flow="['year', 'month', 'calendar']"
							:format="format"
							class="me-2" />
						<Datepicker
							v-model="reservaCheckin.checkInHora"
							auto-apply
							:placeholder="$t('home.form.personalInformation.horaLlegada')"
							:calendar-only="true"
							time-picker />
					</div>
				</div>
			</div>
			<div class="col-12 col-sm-6">
				<div class="mb-3">
					<label for="formControlDni" class="form-label fw-bold text-primary">
						{{ $t('home.form.personalInformation.horaSalida') }}
					</label>
					{{ reservaCheckin.diaCheckOut }}
					<div class="d-flex">
						<Datepicker
							v-model="reservaCheckin.checkOutDia"
							auto-apply
							:placeholder="$t('home.form.personalInformation.diaLlegada')"
							time-picker-inline
							:flow="['year', 'month', 'calendar']"
							:format="format"
							class="me-2" />
						<Datepicker
							v-model="reservaCheckin.checkOutHora"
							auto-apply
							:placeholder="$t('home.form.personalInformation.horaSalida')"
							:calendar-only="true"
							time-picker />
					</div>
				</div>
			</div>
		</div>

		<div v-if="!reservaCheckin.dniSubido" class="row">
			<div class="row px-4 mb-2 pe-0">
				<p class="px-0 text-primary fw-bold mb-2">
					{{ $t('home.form.personalInformation.ADni') }}
				</p>
				<input
					id="anversoDni"
					class="form-control"
					type="file"
					accept="image/jpeg, image/png"
					capture
					@change="handleAnverso($event)" />
				<label for="anversoDni" class="form-label text-muted" style="font-size: 0.8rem" />
			</div>
			<div class="row px-4 pe-0">
				<p class="px-0 text-primary fw-bold mb-2">
					{{ $t('home.form.personalInformation.RDni') }}
				</p>
				<input
					id="anversoDni2"
					class="form-control"
					type="file"
					accept="image/jpeg, image/png"
					capture
					@change="handleReverso($event)" />
				<label for="anversoDni2" class="form-label text-muted" style="font-size: 0.8rem" />
			</div>
		</div>
		<div v-else>
			<div class="row" style="margin-bottom: 2.5rem">
				<div class="col-6">
					<p class="text-primary fw-bold">
						{{ $t('home.form.personalInformation.anversoReversoDnis') }}
						<i class="fa-solid fa-circle-check text-success" />
					</p>
					<button type="button" class="boton boton-naranja me-0" @click="reservaCheckin.dniSubido = false">
						{{ $t('subtitulo.subirDni') }}
					</button>
				</div>
			</div>
		</div>
		<div class="col-12 col-sm-12">
			<div class="mb-3">
				<label for="formControlDni" class="form-label fw-bold text-primary">
					{{ $t('home.form.personalInformation.alergias') }}
				</label>
				<input
					id="formControlDni"
					v-model="reservaCheckin.alergias"
					type="text"
					class="form-control"
					:placeholder="$t('home.form.personalInformation.alergias')" />
			</div>
		</div>
		<hr class="my-4 mt-4" />
		<div v-if="reserva.hasAlquiler" class="row mb-3">
			<p class="mb-1">
				<span class="text-primary fw-bold">
					{{ $t('home.form.personalInformation.alquilerMaterial') }}
				</span>
			</p>
			<p class="text-muted fw-bold mb-2" style="font-size: 0.8rem">
				{{ $t('home.form.personalInformation.descripcionAlquilerMaterial') }}
			</p>
			<div class="row justify-content-between">
				<div class="col-6 col-sm-4">
					<div class="mb-3">
						<label for="formControlDni" class="form-label fw-bold text-primary">
							{{ $t('home.form.personalInformation.altura') }}
						</label>
						<input
							id="formControlDni"
							v-model="reservaCheckin.altura"
							type="number"
							class="form-control"
							:placeholder="$t('home.form.personalInformation.ingresaAltura')"
							required />
					</div>
				</div>
				<div class="col-6 col-sm-4">
					<label for="formControlDni" class="form-label fw-bold text-primary">
						{{ $t('home.form.personalInformation.peso') }}
					</label>
					<input
						id="formControlDni"
						v-model="reservaCheckin.peso"
						type="number"
						class="form-control"
						:placeholder="$t('home.form.personalInformation.ingresaPeso')"
						required />
				</div>
				<div class="col-6 col-sm-4">
					<label for="formControlDni" class="form-label fw-bold text-primary">
						{{ $t('home.form.personalInformation.tallaPie') }}
					</label>
					<input
						id="formControlDni"
						v-model="reservaCheckin.tallaPie"
						type="number"
						class="form-control"
						:placeholder="$t('home.form.personalInformation.ingresaTallaPie')"
						required />
				</div>
			</div>
		</div>
		<div class="row justify-content-center">
			<button type="submit" class="boton boton-naranja">
				{{ $t('home.form.realizarCheckin') }}
			</button>
		</div>
	</form>
</template>
<script setup>
	import Datepicker from '@vuepic/vue-datepicker';
	import ReservasApiService from '../../services/api/skiandnight/ReservasApiService';
	import { ref } from 'vue';
	import { fomatDatesHyphenPost } from '../../helpers/dateUtiles';
	import { useRoute } from 'vue-router';
	import SpinnerVue from '../../components/SpinnerLoading.vue';
	import { useI18n } from 'vue-i18n';
	import { alertAutoCloseWarningWithoutCallback } from '../../services/utils/AlertUtils';
	import { createToaster } from '@meforma/vue-toaster';

	const props = defineProps({
		reserva: {
			type: Object,
			default: null,
		},
		fechaInicio: {
			require: true,
			type: String,
			default: null,
		},
		fechaFin: {
			require: true,
			type: String,
			default: null,
		},
	});

	const emit = defineEmits(['checkRealizado']);

	const reservaCheckin = ref({
		...props.reserva,
		checkInDia: props.fechaInicio,
		checkInHora: null,
		checkOutDia: props.fechaFin,
		sexo: 'Hombre',
	});

	const { t } = useI18n();
	const loading = ref(false);
	const route = useRoute();
	const toaster = createToaster({ position: 'top-right' });

	function comprimirImagen(imagenComoArchivo, porcentajeCalidad) {
		return new Promise(function (resolve, reject) {
			const $canvas = document.createElement('canvas');
			const imagen = new Image();
			imagen.onload = function () {
				$canvas.width = imagen.width;
				$canvas.height = imagen.height;
				$canvas.getContext('2d').drawImage(imagen, 0, 0);
				$canvas.toBlob(
					function (blob) {
						if (blob === null) {
							return reject(blob);
						} else {
							resolve(blob);
						}
					},
					'image/jpeg',
					porcentajeCalidad / 100
				);
			};
			imagen.src = URL.createObjectURL(imagenComoArchivo);
		});
	}

	async function handleAnverso($event) {
		const target = $event.target;
		const blob = await comprimirImagen(target.files[0], 40);
		if (blob.size > 2097152) {
			toaster.error(`${t('home.form.tamañoMaximo')} 2mb`);
			document.getElementById('anversoDni').value = '';
			return;
		}
		reservaCheckin.value.anversoDni = blob;
	}

	async function handleReverso($event) {
		const target = $event.target;
		const blob = await comprimirImagen(target.files[0], 40);
		if (blob.size > 2097152) {
			toaster.error(`${t('home.form.tamañoMaximo')} 2mb`);
			document.getElementById('reversoDni2').value = '';
			return;
		}
		reservaCheckin.value.reversoDni = blob;
	}

	function format(fechaNacimiento) {
		const day = fechaNacimiento.getDate();
		const month = fechaNacimiento.getMonth() + 1;
		const year = fechaNacimiento.getFullYear();

		return `${day}/${month}/${year}`;
	}

	function formatTime(time) {
		const hora = time.hours < 10 ? `0${time.hours}` : time.hours;
		const minutos = time.minutes < 10 ? `0${time.minutes}` : time.minutes;
		const segundos = time.seconds < 10 ? `0${time.seconds}` : time.seconds;

		return `${hora}:${minutos}:${segundos}`;
	}

	function registrarCheckIn(reserva) {
		if (!reserva.fechaNacimiento || !reserva.fechaExpedicionDni) return;

		loading.value = true;

		const formCheckin = new FormData();

		formCheckin.append('fechaNacimiento', fomatDatesHyphenPost(reserva.fechaNacimiento));
		formCheckin.append('nacionalidad', reserva.nacionalidad);
		formCheckin.append('dni', reserva.dni);
		formCheckin.append('fechaExpedicionDni', fomatDatesHyphenPost(reserva.fechaExpedicionDni));

		if (reservaCheckin.value.checkInDia != null && reservaCheckin.value.checkInHora != null) {
			const checkInDia =
				typeof reservaCheckin.value.checkInDia === 'string'
					? reservaCheckin.value.checkInDia
					: fomatDatesHyphenPost(reservaCheckin.value.checkInDia.toISOString());

			formCheckin.append('horaCheckIn', `${checkInDia}T${formatTime(reservaCheckin.value.checkInHora)}`);
		}

		if (reservaCheckin.value.checkOutDia != null && reservaCheckin.value.checkOutHora != null) {
			const checkOutDia =
				typeof reservaCheckin.value.checkOutDia === 'string'
					? reservaCheckin.value.checkOutDia
					: fomatDatesHyphenPost(reservaCheckin.value.checkOutDia.toISOString());

			formCheckin.append('horaCheckOut', `${checkOutDia}T${formatTime(reservaCheckin.value.checkOutHora)}`);
		}

		reserva.sexo && formCheckin.append('sexo', reservaCheckin.value.sexo);

		reserva.anversoDni && formCheckin.append('anversoDni', reserva.anversoDni);
		reserva.reversoDni && formCheckin.append('reversoDni', reserva.reversoDni);
		reserva.altura && formCheckin.append('altura', reserva.altura);
		reserva.peso && formCheckin.append('peso', reserva.peso);
		reserva.tallaPie && formCheckin.append('tallaPie', reserva.tallaPie);
		reserva.lugarNacimiento && formCheckin.append('lugarNacimiento', reserva.lugarNacimiento);
		reserva.alergias && formCheckin.append('alergias', reserva.alergias);

		ReservasApiService.realizarCheckIn(reservaCheckin.value.id, route.params.grupoId, formCheckin)
			.then(function () {
				emit('checkRealizado');
			})
			.catch(function (err) {
				alertAutoCloseWarningWithoutCallback(3000, err.response.data);
			})
			.finally(function () {
				loading.value = false;
			});
	}
</script>

<style scoped>
	.boton {
		cursor: pointer;
		font-size: 0.8rem;
		font-weight: 500;
		padding: 0.5rem 1.5rem;
		border-radius: var(--bs-border-radius-xxl);
		transition: all 0.2s ease;
		border: 0;
	}

	.boton-naranja {
		background-color: var(--bs-secondary);
		color: white;
		box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5);
		max-width: 200px;
	}
</style>
