<template>
	<div class="formulario-presupuesto">
		<form @submit.prevent>
			<h1 class="mb-4">
				{{ $t('home.form.tripInformation.title') }}
			</h1>

			<div class="mb-3" :class="{ error: v$.origen.$errors.length }">
				<label for="inputName" class="form-label"> *{{ $t('home.form.tripInformation.whereFrom') }} </label>
				<input v-model="presupuesto.origen" type="text" class="form-control" @blur="v$.origen.$touch" />
			</div>
			<div class="mb-3" :class="{ error: v$.numPersonas.$errors.length }">
				<label for="inputLastname" class="form-label"> *{{ $t('home.form.tripInformation.howMany') }} </label>
				<input v-model="presupuesto.numPersonas" type="text" class="form-control" @blur="v$.numPersonas.$touch" />
			</div>

			<div class="mb-3">
				<label for="inputEmail" class="form-label"> *{{ $t('home.form.tripInformation.whoTravel.title') }} </label>
				<select
					v-model="presupuesto.tipoAcompaniante"
					class="form-select"
					aria-label="Default select example"
					@blur="v$.tipoAcompaniante.$touch">
					<option value="amigos">
						{{ $t('home.form.tripInformation.whoTravel.friends') }}
					</option>
					<option value="familia">
						{{ $t('home.form.tripInformation.whoTravel.family') }}
					</option>
					<option value="parejas">
						{{ $t('home.form.tripInformation.whoTravel.couple') }}
					</option>
					<option value="trabajo">
						{{ $t('home.form.tripInformation.whoTravel.work') }}
					</option>
					<option value="otros">
						{{ $t('home.form.others') }}
					</option>
				</select>
				<div v-if="presupuesto.tipoAcompaniante === 'otros'" class="w-50">
					<label for="tipoAcompaniante" class="form-label">{{ $t('home.form.tripInformation.fields.others') }}</label>
					<input v-model="tipoAcompaniante" type="text" class="form-control" @blur="v$.tipoAcompaniante.$touch" />
				</div>
			</div>

			<div class="mb-3" :class="{ error: v$.fechaInicio.$errors.length || v$.fechaFin.$errors.length }">
				<label for="whenTravel" class="form-label"> *{{ $t('home.form.tripInformation.whenTravel') }} </label>
				<Datepicker
					v-model="date"
					range
					:format="format2"
					auto-apply
					:clearable="false"
					@update:model-value="handleDateChange" />
			</div>

			<div class="mb-3" :class="{ error: v$.fechaInicioFF.$errors.length || v$.fechaFinFF.$errors.length }">
				<label for="inputName" class="form-label"> *{{ $t('home.form.tripInformation.howManyDays') }} </label>
				<Datepicker
					v-model="dateSki"
					range
					auto-apply
					:format="format2"
					:min-date="date?.[0]"
					:max-date="date?.[1]"
					:disabled="!date || !date[0] || !date[1]"
					:clearable="false"
					@update:model-value="handleSkiDateChange" />
			</div>

			<div class="mb-3">
				<label for="inputEmail" class="form-label"> *{{ $t('home.form.tripInformation.destiny.title') }} </label>

				<select
					v-model="presupuesto.destino"
					class="form-select"
					aria-label="Default select example"
					@blur="v$.destino.$touch">
					<option value="Andorra">{{ $t('home.form.tripInformation.places.andorra') }}</option>
					<option value="Formigal">{{ $t('home.form.tripInformation.places.formigal') }}</option>
					<option value="Sierra Nevada">{{ $t('home.form.tripInformation.places.sierraNevada') }}</option>
					<option value="otros">{{ $t('home.form.tripInformation.fields.others') }}</option>
					<option value="Sin Preferencia">{{ $t('home.form.tripInformation.destiny.noPreference') }}</option>
				</select>

				<template v-if="presupuesto.destino === 'otros'">
					<div>
						<label for="inputName" class="form-label">{{ $t('home.form.tripInformation.fields.otherStation') }}</label>
						<input v-model="destinoEstacion" type="text" class="form-control" />
					</div>
				</template>
			</div>

			<div class="mb-3">
				<label for="inputEmail" class="form-label">
					{{ $t('home.form.tripInformation.interests.title') }}
				</label>
				<select
					v-model="presupuesto.interes"
					class="form-select"
					aria-label="Default select example"
					@blur="v$.interes.$touch">
					<option value="gastronomia">
						{{ $t('home.form.tripInformation.interests.cousine') }}
					</option>
					<option value="fiesta y ambiente">
						{{ $t('home.form.tripInformation.interests.party') }}
					</option>
					<option value="relax y spa">
						{{ $t('home.form.tripInformation.interests.relax') }}
					</option>
					<option value="actividades en la nieve">
						{{ $t('home.form.tripInformation.interests.snow') }}
					</option>
					<option value="otros">
						{{ $t('home.form.others') }}
					</option>
				</select>
				<template v-if="presupuesto.interes === 'otros'">
					<div>
						<label for="inputName" class="form-label">{{ $t('home.form.tripInformation.fields.others') }}</label>
						<input v-model="interes" type="text" class="form-control" />
					</div>
				</template>
			</div>

			<div class="mb-3">
				<label for="inputEmail" class="form-label"> *{{ $t('home.form.tripInformation.accommodation.title') }} </label>
				<select
					v-model="presupuesto.tipoAlojamiento"
					class="form-select"
					aria-label="Default select example"
					@blur="v$.tipoAlojamiento.$touch">
					<option value="hotel">
						{{ $t('home.form.tripInformation.accommodation.hotel') }}
					</option>
					<option value="apartamento">
						{{ $t('home.form.tripInformation.accommodation.apartment') }}
					</option>
					<option value="Sin Preferencia">
						{{ $t('home.form.tripInformation.accommodation.noPreference') }}
					</option>
				</select>
			</div>

			<div class="mb-3">
				<label for="inputEmail" class="form-label">
					{{ $t('home.form.tripInformation.tracks.title') }}
				</label>
				<select v-model="presupuesto.distanciaAPistas" class="form-select" aria-label="Default select example">
					<option value="cerca">
						{{ $t('home.form.tripInformation.tracks.near') }}
					</option>
					<option value="Sin Preferencia">
						{{ $t('home.form.tripInformation.tracks.noPreference') }}
					</option>
				</select>
			</div>

			<div class="mb-3">
				<label for="comentarios" class="form-label">
					{{ $t('home.form.tripInformation.fields.comments') }}
				</label>
				<div class="form-floating">
					<textarea
						id="comentarios"
						v-model="presupuesto.comentarios"
						class="form-control"
						:placeholder="$t('home.form.tripInformation.fields.writeHere')"
						style="height: 100px" />
					<label for="comentarios">
						{{ $t('home.form.tripInformation.fields.writeHere') }}
					</label>
				</div>
			</div>

			<div class="row d-flex justify-content-around flex-wrap">
				<div class="col-3 d-flex justify-content-center">
					<button
						class="btn btn-primary"
						@click="
							firstCheck = true;
							secondCheck = false;
						">
						{{ $t('home.form.backButton') }}
					</button>
				</div>

				<div class="col-3 d-flex justify-content-center">
					<button type="submit" class="btn btn-primary" @click="validateForm2()">
						{{ $t('home.form.nextButton') }}
					</button>
				</div>
			</div>
		</form>
	</div>
</template>

<script setup>
	import { ref, reactive, onMounted } from 'vue';
	import { useRouter } from 'vue-router';
	import useVuelidate from '@vuelidate/core';
	import { required, numeric } from '@vuelidate/validators';
	import Datepicker from '@vuepic/vue-datepicker';
	import HomeApiService from '@/services/api/skiandnight/HomeApiService';
	import { usuariosFormulario } from '@/store_pinia/user';

	const router = useRouter();
	const store = usuariosFormulario();

	const DEFAULT_VALUES = Object.freeze({
		ALOJAMIENTO: 'hotel',
		DESTINO: 'Andorra',
		ACOMPANANTE: 'amigos',
	});

	const date = ref(null);
	const dateSki = ref(null);
	const tipoAcompaniante = ref(null);
	const destinoEstacion = ref(null);
	const interes = ref(null);

	const presupuesto = reactive({
		id: null,
		fechaInicio: null,
		fechaFin: null,
		numPersonas: null,
		tipoAlojamiento: DEFAULT_VALUES.ALOJAMIENTO,
		origen: null,
		destino: DEFAULT_VALUES.DESTINO,
		tipoAcompaniante: DEFAULT_VALUES.ACOMPANANTE,
		fechaInicioFF: null,
		fechaFinFF: null,
		interes: null,
		distanciaAPistas: null,
		comentarios: null,
	});

	const rules = {
		fechaInicio: { required },
		fechaFin: { required },
		numPersonas: { required, numeric },
		tipoAlojamiento: { required },
		origen: { required },
		destino: { required },
		tipoAcompaniante: { required },
		fechaInicioFF: { required },
		fechaFinFF: { required },
		interes: { required },
	};

	const v$ = useVuelidate(rules, presupuesto);

	function format2(dates) {
		if (!dates || !Array.isArray(dates) || dates.length < 2) {
			return '';
		}

		const [date1, date2] = dates;
		if (!date1 || !date2) {
			return '';
		}

		return `${date1.getDate()}/${date1.getMonth() + 1}/${date1.getFullYear()} - ${date2.getDate()}/${
			date2.getMonth() + 1
		}/${date2.getFullYear()}`;
	}

	function handleDateChange(newDate) {
		date.value = newDate;
		if (!newDate) {
			dateSki.value = null;
			presupuesto.fechaInicio = null;
			presupuesto.fechaFin = null;
		} else {
			presupuesto.fechaInicio = newDate[0].toISOString().split('T')[0];
			presupuesto.fechaFin = newDate[1].toISOString().split('T')[0];
		}
	}

	function handleSkiDateChange(newDate) {
		dateSki.value = newDate;
	}

	async function validateForm2() {
		if (!date.value?.[0] || !date.value?.[1] || !dateSki.value?.[0] || !dateSki.value?.[1]) return;

		const newInicio = date.value[0].toISOString().split('T')[0];
		const newFin = date.value[1].toISOString().split('T')[0];
		const newInicioSki = dateSki.value[0].toISOString().split('T')[0];
		const newFinSki = dateSki.value[1].toISOString().split('T')[0];

		presupuesto.fechaInicio = newInicio;
		presupuesto.fechaFin = newFin;
		presupuesto.fechaInicioFF = newInicioSki;
		presupuesto.fechaFinFF = newFinSki;
		presupuesto.id = store.formulario?.id;

		await v$.value.$validate();

		if (!v$.value.$invalid) {
			if (presupuesto.tipoAcompaniante === 'otros') {
				presupuesto.tipoAcompaniante = tipoAcompaniante.value;
			}

			if (presupuesto.destino === 'otros') {
				presupuesto.destino = destinoEstacion.value;
			}

			if (presupuesto.interes === 'otros') {
				presupuesto.interes = interes.value;
			}

			await HomeApiService.sendInformacionViaje(presupuesto);
			router.push('/presupuestotyp');
		}
	}

	onMounted(() => {
		if (!store.formulario) {
			router.push({ name: 'DatosPersonales' });
		}
	});
</script>

<style lang="scss" scoped>
	.formulario-presupuesto {
		max-width: 800px;
		margin: auto;
		margin-bottom: 5rem;
		padding: 12rem 2rem 4rem 2rem;
	}

	.error {
		.form-control {
			border-color: #dc3545;
		}
	}
</style>
