import { defineStore } from 'pinia';
import ViajesGrupoApiService from '@/services/api/skiandnight/ViajesGrupoApiService';

const viajesGrupo = defineStore('viajes/viajesGrupo', {
	state: () => ({
		viajes: null,
		filtrosViajes: null,
	}),
	getters: {
		getViajesDestacados(state) {
			let viajesDestacados = [];
			state.viajes && (viajesDestacados = state.viajes.filter(viajes => viajes.destacado));
			return viajesDestacados;
		},

		getTiposViajesGrupo(state) {
			if (state.filtrosViajes) return state.filtrosViajes.tiposGrupo;
			this.setFiltroViajesGrupos();
			return [];
		},
	},
	actions: {
		setViajes() {
			ViajesGrupoApiService.getViajes().then(response => (this.viajes = response));
		},

		setFiltroViajesGrupos() {
			ViajesGrupoApiService.getFiltroViajes().then(response => (this.filtrosViajes = response));
		},
	},
});

export default viajesGrupo;
