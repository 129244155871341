import { formatDates } from '@/helpers/dateUtiles';
import i18n from '../i18n';

const { t } = i18n.global;

export const obtenerPoliticasCancelacion = (informacionHabitaciones, multiplesHabitaciones = true) => {
	let textoPoliticas = '<p>' + t('reserva.condicionesReserva') + '</p>';

	if (multiplesHabitaciones) {
		informacionHabitaciones.map((habitacion, index) => {
			textoPoliticas += `<h4 class='text-secondary'>	${t('general.habitacion')} ${index + 1}</h4>`;
			textoPoliticas += `<p>${habitacion.politicaCancelacion.nombre}</p><p>
			${t('reserva.costesAsociadosReserva')}
                </p><ul>`;

			habitacion.politicaCancelacion.tramos.map(tramo => {
				textoPoliticas += `<li>${t('general.el')} ${tramo.porcentaje}% ${t('reserva.cancelacionEntre')} ${formatDates(
					tramo.fechaInicio
				)} y ${t('general.antesDel')} ${formatDates(tramo.fechaFin)} </li>`;
			});
			textoPoliticas += '</ul>';
		});
	} else {
		textoPoliticas += `<p>${informacionHabitaciones.politicaCancelacion.nombre}</p>
            <p>
			${t('reserva.costesAsociadosReserva')}
                </p>
            <ul>`;

		let numTramos = informacionHabitaciones.politicaCancelacion.tramos.length;
		informacionHabitaciones.politicaCancelacion.tramos.map((tramo, indexTramo) => {
			textoPoliticas += `<li>${t('general.el')} ${tramo.porcentaje}% ${t('reserva.cancelacionEntre')} ${formatDates(
				tramo.fechaInicio
			)} y ${indexTramo == numTramos - 1 ? t('general.antesDel') : t('general.el').toLowerCase()} ${formatDates(
				tramo.fechaFin
			)} </li>`;
		});
		textoPoliticas += '</ul>';
	}

	return textoPoliticas;
};
