<template>
	<button class="mg-btn hvr-grow" :class="computedClass" :disabled="disabled" @click="handleClick">
		{{ label }}
	</button>
</template>

<script setup>
	import { computed } from 'vue';

	const props = defineProps({
		label: {
			type: String,
			required: true,
		},
		type: {
			type: String,
			default: 'primary',
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		size: {
			type: String,
			default: 'medium',
		},
		additionalClass: {
			type: String,
			default: '',
		},
	});

	const emit = defineEmits(['click']);

	const buttonTypes = {
		primary: 'bg-primary text-secondary',
		secondary: 'bg-secondary text-primary',
		danger: 'bg-red-500',
	};

	const buttonSizes = {
		small: ' text-sm', // 'py-1 px-1 text-sm',
		medium: '', // 'py-2 px-4',
		large: ' text-lg', // 'py-3 px-5 text-lg',
	};

	const computedClass = computed(() => {
		const baseClass = `${buttonTypes[props.type]} ${buttonSizes[props.size]} ${props.additionalClass}`;
		return props.disabled ? `${baseClass} opacity-50 cursor-not-allowed` : baseClass;
	});

	function handleClick(event) {
		event.stopPropagation();
		emit('click', event);
	}
</script>

<style scoped lang="scss">
	.mg-btn {
		border-radius: var(--bs-border-radius-xl);
		border: 0;
		box-shadow: 0px 1px 2px 0px #21244459;
		font-size: 14px;
		gap: 10px;
		transition: transform 0.35s ease-in-out;

		&.bg-secondary:hover {
			background-color: var(--bs-primary) !important;
			color: var(--bs-secondary) !important;
			transition: transform 0.35s ease-in-out;

			&:hover {
				transform: scale(1.1);
			}
		}

		&.bg-primary:hover {
			background-color: var(--bs-secondary) !important;
			color: var(--bs-primary) !important;
			transition: transform 0.35s ease-in-out;

			&:hover {
				transform: scale(1.1);
			}
		}
	}
</style>
