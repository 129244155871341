import { defineStore } from 'pinia';

export const appGlobalStore = defineStore('appGlobalStore', {
	state: () => ({
		loadingGlobal: false,
		valores: {},
		permisos: null,
		translations: {},
		isHeaderWhite: false,
	}),
	getters: {
		isSeaAndNightUrl() {
			return window.location.href.includes('seaandnight');
		},
	},
	actions: {
		setValoresResources(data) {
			this.valores = data;
		},
		setPermisos(data) {
			this.permisos = { ...data };
		},
		setTranslations(data, languageKey) {
			this.translations[languageKey] = data;
		},
	},
});
