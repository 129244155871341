<template>
	<span v-if="fromFlujoReserva" class="text-primary text-start title title-size mb-2">
		{{ $t('home.form.personalInformation.welcomeBack') }}
	</span>
	<template v-if="showRegisterValue">
		<form autocomplete="on" @submit.prevent="registrarLoguearUsuario(usuarioRegistro)">
			<div class="text-primary mb-3 introduce-msg">
				<span>{{ $t('home.form.personalInformation.dataRegister') }}</span>
			</div>
			<div class="mb-3">
				<input
					id="exampleFormControlInput1"
					v-model="usuarioRegistro.email"
					type="email"
					class="text-primary form-control text-start fs-6 text-12"
					:placeholder="'*' + $t('home.form.personalInformation.email')"
					required />
			</div>
			<div class="position-relative" :class="{ 'mb-3': !isPasswordStrong, 'mb-2': isPasswordStrong }">
				<input
					v-model="usuarioRegistro.password"
					:type="passwordVisible ? 'text' : 'password'"
					class="text-primary form-control text-start pr-5 text-12"
					autocomplete="off"
					:placeholder="'*' + $t('home.form.personalInformation.passwordRegister')"
					required
					@click.right.prevent
					@focus="isPasswordFocused = true"
					@blur="isPasswordFocused = false"
					@keydown="keydown"
					@copy.prevent
					@paste.prevent />
				<span
					class="pointer position-absolute toggle-password text-decoration-underline text-primary unselectable text-12"
					@click="togglePasswordVisibility">
					{{
						passwordVisible ? $t('home.form.personalInformation.ocultar') : $t('home.form.personalInformation.mostrar')
					}}
				</span>
			</div>
			<transition name="fade">
				<div
					v-show="!isPasswordStrong && (isPasswordFocused || usuarioRegistro.password?.length > 0)"
					class="password-validation mb-3">
					<p
						class="mb-0 fs-11 q-flex items-center"
						:class="{ 'green-text': pwRequirements.minLength, 'red-text': !pwRequirements.minLength }">
						<i class="fa-solid fa-xs me-2" :class="pwRequirements.minLength ? 'fa-check' : 'fa-x'" />
						{{ $t('home.form.personalInformation.password8CharReq') }}
					</p>
					<p
						class="mb-0"
						:class="{ 'green-text': pwRequirements.hasUpperCase, 'red-text': !pwRequirements.hasUpperCase }">
						<i class="fa-solid fa-xs me-2" :class="pwRequirements.hasUpperCase ? 'fa-check' : 'fa-x'" />
						{{ $t('home.form.personalInformation.password1MayusReq') }}
					</p>
					<p class="mb-0" :class="{ 'green-text': pwRequirements.hasNumber, 'red-text': !pwRequirements.hasNumber }">
						<i class="fa-solid fa-xs me-2" :class="pwRequirements.hasNumber ? 'fa-check' : 'fa-x'" />
						{{ $t('home.form.personalInformation.password1NumReq') }}
					</p>
				</div>
			</transition>
			<transition name="fade">
				<div v-show="isPasswordStrong" class="password-validation">
					<p class="fs-11 green-text q-flex items-center">
						<i class="fa-solid fa-xs me-2 fa-check" />
						{{ $t('home.form.personalInformation.passwordSeguridadBuena') }}
					</p>
				</div>
			</transition>

			<div class="mb-3">
				<input
					id="exampleFormControlInput1"
					v-model="usuarioRegistro.nombre"
					type="text"
					class="text-primary form-control text-start text-12"
					:placeholder="'*' + $t('home.form.personalInformation.name')"
					required />
			</div>
			<div class="mb-3">
				<input
					id="exampleFormControlInput1"
					v-model="usuarioRegistro.apellidos"
					type="text"
					class="text-primary form-control text-start text-12"
					:placeholder="'*' + $t('home.form.personalInformation.lastName')"
					required />
			</div>
			<div class="mb-3">
				<Datepicker
					v-model="usuarioRegistro.fechaNacimiento"
					auto-apply
					required
					:placeholder="'*' + $t('home.form.personalInformation.birthDate')"
					:format="format"
					:flow="flowCalendar" />
			</div>
			<div class="mb-3">
				<input
					id="exampleFormControlInput1"
					v-model="usuarioRegistro.telefono"
					type="tel"
					class="text-primary form-control text-start text-12"
					:placeholder="'*' + $t('home.form.personalInformation.phone')"
					required />
			</div>

			<div class="w-100 m-auto">
				<div class="col-12">
					<div class="form-check border-0">
						<input
							id="aceptarMailing"
							v-model="usuarioRegistro.mailingPublicidadActivo"
							class="form-check-input"
							type="checkbox" />
						<div class="form-check-label text-start" for="aceptarMailing">
							<span class="text-12">{{ $t('home.form.personalInformation.noRecibirMailing') }}</span>
						</div>
					</div>
					<div class="form-check border-0 d-flex align-items-center">
						<input id="flexCheckChecked" class="form-check-input" type="checkbox" value="" required />
						<label class="form-check-label text-start ms-2 mt-1" for="flexCheckChecked">
							<span class="text-12">{{ $t('home.form.policy.readAccept') }} </span>
							<a class="btn btn-link p-0" @click="openInTab('PoliticaPrivacidad')">
								<span class="text-12">{{ $t('home.form.policy.privacyPolicy') }}.</span>
							</a>
						</label>
					</div>
				</div>
			</div>

			<button type="submit" class="btn btn-primary my-3 mx-auto w-100 text-submit">
				<span class="text-mobile">{{ $t('home.form.personalInformation.registrar') }}</span>
			</button>

			<span class="cambio-vista text-12" @click="emits('mostrarLogin')">
				{{ $t('home.form.personalInformation.yaTienesCuenta') }}
				<span class="text-decoration-underline">{{ $t('header.user.login') }}</span>
			</span>
		</form>
	</template>
	<template v-else>
		<form autocomplete="off" @submit.prevent="loginUser(informacionLogin.email, informacionLogin.password)">
			<div :class="['text-primary', 'mb-2', 'introduce-msg', { 'mt-3': fromFlujoReserva }]">
				<span>{{ $t('home.form.personalInformation.contraseniaLogin') }}</span>
			</div>
			<div class="mb-3">
				<input
					v-model="informacionLogin.email"
					type="text"
					class="form-control text-start text-12"
					autocomplete="off"
					:placeholder="$t('home.form.personalInformation.email')"
					required />
			</div>
			<div class="mb-3 position-relative">
				<input
					v-model="informacionLogin.password"
					:type="passwordVisible ? 'text' : 'password'"
					class="text-primary form-control text-start pr-5 text-12"
					autocomplete="off"
					:placeholder="$t('home.form.personalInformation.password')"
					required
					@click.right.prevent
					@copy.prevent
					@paste.prevent />
				<span
					class="pointer position-absolute toggle-password text-decoration-underline text-primary unselectable text-12 mostrar"
					@click="togglePasswordVisibility">
					{{
						passwordVisible ? $t('home.form.personalInformation.ocultar') : $t('home.form.personalInformation.mostrar')
					}}
				</span>
			</div>

			<button type="submit" class="btn btn-primary m-auto w-100 text-submit mb-3">
				<span class="text-mobile"> {{ $t('login.entrar') }}</span>
			</button>
			<span class="cambio-vista text-center text-decoration-underline m-0 text-12" @click="mostrarRecuperarPassword">
				¿{{ $t('login.forgetPassword') }}?
			</span>
		</form>
	</template>
</template>

<script setup>
	import LoginApiService from '@/services/api/skiandnight/LoginApiService';
	import CookiesUtils from '@/services/utils/CookiesUtils';
	import { alertAutoCloseSuccessWithoutCallback, alertSuccessHtml } from '@/services/utils/AlertUtils';
	import { informacionHabitacion } from '@/store_pinia/informacionHabitacion';
	import { reactive, ref, watch, computed } from 'vue';
	import { useStore } from 'vuex';
	import { createToaster } from '@meforma/vue-toaster';
	import Datepicker from '@vuepic/vue-datepicker';
	import UsuarioApiService from '@/services/api/skiandnight/UsuarioApiService';
	import { controlErrorRegistrarLoginUsuario } from '@/services/utils/ErrorControl';
	import router from '@/router';
	import { useI18n } from 'vue-i18n';

	const emits = defineEmits(['mostrarLogin', 'mostrarRecuperarPassword', 'usuarioLogueado', 'loading']);
	const loading = ref(false);

	const props = defineProps({
		email: {
			type: String,
			required: true,
			default: '',
		},
		showRegister: {
			type: Boolean,
			required: false,
			default: true,
		},
		fromFlujoReserva: {
			type: Boolean,
			required: false,
			default: false,
		},
	});

	const { t } = useI18n();
	const showRegisterValue = ref(props.showRegister);
	const isPasswordFocused = ref(false);

	const passwordVisible = ref(false);

	function togglePasswordVisibility() {
		passwordVisible.value = !passwordVisible.value;
	}

	const informacionLogin = reactive({
		email: props.email,
		password: null,
	});

	const usuarioRegistro = reactive({
		nombre: null,
		apellidos: null,
		telefono: null,
		email: props.email,
		password: null,
		fechaNacimiento: null,
		mailingPublicidadActivo: null,
	});

	const pwRequirements = reactive({
		minLength: false,
		hasUpperCase: false,
		hasNumber: false,
	});

	const isPasswordStrong = computed(() => {
		return pwRequirements.minLength && pwRequirements.hasUpperCase && pwRequirements.hasNumber;
	});

	watch(
		() => usuarioRegistro.password,
		newPassword => {
			pwRequirements.minLength = newPassword.length >= 8;
			pwRequirements.hasUpperCase = /[A-Z]/.test(newPassword);
			pwRequirements.hasNumber = /[0-9]/.test(newPassword);
		}
	);

	const toaster = createToaster({ position: 'top-right' });
	const flowCalendar = ref(['year', 'month', 'calendar']);
	const format = date => {
		return date.toISOString().split('T')[0];
	};
	const storeHabitacion = informacionHabitacion();
	const store = useStore();

	const passwordErrorVisible = ref(false);

	const openInTab = routeName => {
		window.open(router.resolve({ name: routeName }).href, '_blank');
	};

	async function loginUser(username, password) {
		emits('loading', true);
		LoginApiService.login(username, password)
			.then(response => {
				localStorage.setItem('jwt-mg', response.token);
				const usuario = CookiesUtils.getUsuarioFromLocalStorage();
				actualizarInformacionReservaConUsuarioLogeado(storeHabitacion.getReservas, usuario);
				store.commit('login/LOGIN_SUCCESS', usuario);
				alertAutoCloseSuccessWithoutCallback(2000);
				emits('usuarioLogueado');
			})
			.catch(err => {
				const errorStatus = err?.response?.status;
				if (errorStatus === 401) {
					toaster.error('Los datos de usuario son invalidos');
				}
			})
			.finally(() => {
				emits('loading', false);
			});
	}

	// function mostrarRegistrarUsuario() {
	// 	emits('mostrarRegistrarUsuario');
	// }

	function mostrarRecuperarPassword() {
		emits('mostrarRecuperarPassword', informacionLogin.email);
	}

	/**
	 * Valida si existen reservas en el store y si la primera reserva es propia
	 * , que es del usuario que se acaba de logear,actualiza la informacion del
	 * usuario de la reserva con el usuario logeado
	 * @param {Array} listaReservas - La lista de reservas a actualizar.
	 * @param {Object} informacionUsuario - La información del usuario logeado.
	 */
	function actualizarInformacionReservaConUsuarioLogeado(listaReservas, informacionUsuario) {
		if (listaReservas.length > 0 && listaReservas[0].reservaPropia) {
			listaReservas[0].usuario = {
				nombre: informacionUsuario.nombre,
				apellido: informacionUsuario.apellidos.split(' ')[0],
			};
		}
	}

	const registrarLoguearUsuario = informacionUsuario => {
		if (!isPasswordStrong.value) {
			toaster.error(t('home.form.personalInformation.errorRegPassword'));
			loading.value = false;
			return;
		}

		loading.value = true;
		if (usuarioRegistro.fechaNacimiento === null) {
			toaster.error('Ingrese la fecha de nacimiento');
			loading.value = false;
			return;
		}

		let valorRegistrar = {
			...informacionUsuario,
			fechaNacimiento: format(informacionUsuario.fechaNacimiento),
		};

		valorRegistrar.mailingPublicidadActivo = !valorRegistrar.mailingPublicidadActivo;

		UsuarioApiService.registrarYLoguear(valorRegistrar)
			.then(res => {
				loading.value = false;
				localStorage.setItem('jwt-mg', res.token);
				const usuario = CookiesUtils.getUsuarioFromLocalStorage();
				store.commit('login/LOGIN_SUCCESS', usuario);

				emits('usuarioLogueado');
				alertSuccessHtml('<p>¡Registrado correctamente!</p>');
				t('home.form.personalInformation.registradoCorrect');
				alertSuccessHtml('<p>¡' + t('home.form.personalInformation.registradoCorrect') + '!</p>');
			})
			.catch(err => {
				loading.value = false;
				controlErrorRegistrarLoginUsuario(err);
			});
	};

	watch(isPasswordStrong, newValue => {
		passwordErrorVisible.value = !newValue;
	});
</script>

<style lang="scss" scoped>
	.cambio-vista {
		color: var(--bs-primary);
		font-size: 14px;
		font-weight: 400;
		cursor: pointer;
		transition: 0.3s ease-in-out;

		&:hover {
			color: var(--bs-secondary);
		}
	}

	.position-relative .toggle-password {
		top: 50%;
		right: 10px;
		transform: translateY(-50%);
		z-index: 2;
	}

	.password-validation p {
		font-size: 11px;
	}

	.fs-11 {
		font-size: 11px;
	}

	.red-text {
		color: #e50000;
	}

	.green-text {
		color: #19b000;
	}

	.unselectable {
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 0.5s;
	}

	.fade-enter-from,
	.fade-leave-to {
		opacity: 0;
	}

	:deep(.dp__input) {
		text-align: start !important;
		@media only screen and (max-width: 768px) {
			font-size: 12px !important;
		}
	}

	.introduce-msg {
		font-size: 14px;
	}

	.text-submit {
		font-weight: 600;
		box-shadow: 0px 1px 2px 0px rgba(var(--bs-primary-rgb), 0.35) !important;
	}

	.text-12 {
		@media only screen and (max-width: 768px) {
			font-size: 12px !important;
		}
	}

	.mostrar {
		right: 10px;
		top: 50%;
		transform: translateY(-50%);
	}

	.text-mobile {
		@media only screen and (max-width: 768px) {
			font-size: 14px !important;
			font-weight: 600 !important;
		}
	}
</style>
