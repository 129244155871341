<template>
	<div class="card p-3">
		<div class="card-title">
			<p class="mb-0 text-primary fw-bold">
				{{ buscador_store.hotelSeleccionado.nombre }}
			</p>
			<div class="d-flex">
				<div v-for="estrellas in buscador_store.hotelSeleccionado.estrellas" :key="estrellas">
					<i class="fa-regular fa-star" />
				</div>
			</div>

			<p class="mb-0">
				<i class="fa-solid fa-location-dot me-2 text-primary" />
				{{ buscador_store.filtrosSeleccionadosBuscador.destino.nombre }}
			</p>
			<p
				class="mb-0 btn btn-link px-0"
				data-bs-dismiss="modal"
				@click="
					$router.push({
						name: BuscadorAlojamiento,
						params: {
							idAlojamiento: buscador_store.hotelSeleccionado.id,
						},
					})
				">
				<i class="fa-solid fa-circle-info me-2 text-primary" />
				{{ $t('general.masInformacion') }}
			</p>
		</div>
		<div class="card shadow">
			<div class="card-body pb-0">
				<div class="text-primary">
					<p class="mb-0">
						<span class="fw-bold">
							{{
								datediff(
									buscador_store.filtrosSeleccionadosBuscador.fechasAlojamiento[0],
									buscador_store.filtrosSeleccionadosBuscador.fechasAlojamiento[1]
								)
							}}
							{{ $t('resumenServiciosBuscador.nochesAlojamiento') }} :
						</span>

						<span class="translate">
						{{ formatArrayDates(buscador_store.filtrosSeleccionadosBuscador.fechasAlojamiento) }}
						</span>
					</p>
					<p v-if="agenciaStore.buscadorForfaitEnabled">
						<span class="fw-bold">
							{{
								datediff(
									buscador_store.filtrosSeleccionadosBuscador.fechasForfait[0],
									buscador_store.filtrosSeleccionadosBuscador.fechasForfait[1]
								)
							}}
							{{ $t('resumenServiciosBuscador.diasForfait') }} :
						</span>
						<span class="translate">{{ formatArrayDates(buscador_store.filtrosSeleccionadosBuscador.fechasForfait) }}</span>
					</p>
				</div>
				<template v-if="totalHabitaciones">
					<template v-for="(habitacion, indexHabitacion) in totalHabitaciones" :key="habitacion.habitacion">
						<div>
							<h5 class="card-title text-primary fw-bold translate">
								{{ habitacion.habitacion }}
							</h5>
							<hr />
							<template v-for="(persona, indexPersona) in habitacion.personas" :key="persona.nombre">
								<div>
									<h5 class="card-subtitle my-3 row fs-6 fw-bold text-primary">
										<div class="col-7">
											<span v-if="!isEmptyString(persona.nombre)">
												{{ persona.nombre }}
											</span>
											<span v-else> {{ $t('general.persona') }} {{ indexPersona + 1 }} </span>
										</div>
										<div class="col-5 text-end">
											{{
												currency(getPrecioBase(informacionCombinacion[indexHabitacion])).add(getTotalServicios(persona.servicios))
											}}
											{{ agenciaStore.getCurrency }}
										</div>
									</h5>
									<template v-for="servicio in persona.servicios" :key="servicio.id">
										<div class="card-text mb-2">
											<div class="row">
												<div class="col-9 text-primary translate">
													{{ servicio.nombre }} x {{ servicio.numDias }}
													<span v-if="servicio.numDias == 1"> {{ $t('general.dia') }}</span>
													<span v-else> {{ $t('general.dias') }}</span>
												</div>
												<div class="col-3 text-end text-secondary fw-bold">
													<span v-if="servicio.precio"> {{ servicio.precio }}{{ agenciaStore.getCurrency }} </span>
												</div>
											</div>
										</div>
									</template>
								</div>
							</template>
							<hr />
							<div class="row text-primary fw-bold fs-6 mb-4">
								<div class="col-9">
									{{ $t('subtitulo.total') }}
								</div>
								<div class="col-3 text-end text-secondary">
									{{ currency(getTotalHabitacion(habitacion, indexHabitacion)) }}
									{{ agenciaStore.getCurrency }}
								</div>
							</div>
						</div>
					</template>
					<div class="row bg-primary text-secondary fs-6 py-3 fw-bold">
						<div class="col-9">
							{{ $t('subtitulo.importeTotal') }}
						</div>
						<div class="col-3 text-end">{{ currency(getTotalReserva(totalHabitaciones)) }}{{ agenciaStore.getCurrency }}</div>
					</div>
				</template>
			</div>
		</div>
	</div>
</template>

<script>
	import { computed, defineComponent } from 'vue';
	import { buscadorStore } from '@/store_pinia/buscador';
	import { reservaHoteles } from '@/store_pinia/reservaHoteles';
	import { BuscadorAlojamiento } from '@/router/RouterNames';
	import { agenciaStoreModule } from '@/store_pinia/agencia';
	import currency from 'currency.js';

	export default defineComponent({
		methods: {currency},
		props: {
			serviciosSeleccionados: {
				type: Object,
				default: null,
			},
		},
		setup(props) {
			const buscador_store = buscadorStore();
			const storeReservaHoteles = reservaHoteles();
			const agenciaStore = agenciaStoreModule();

			const informacionCombinacion = computed(() => {
				let combinacionAux = [];
				buscador_store.combinacionSelecionada.combinacion.map(combinacion => {
					combinacionAux[combinacion.rph - 1] = combinacion;
				});
				return combinacionAux;
			});

			const totalHabitaciones = computed(() => {
				let listaHabitacionServicios = [];
				Object.keys(props.serviciosSeleccionados).map((habitacion, indexHabitacion) => {
					let totalPersonasHabitacion = Object.keys(props.serviciosSeleccionados[habitacion]);
					let listaServiciosPersona = [];

					Object.keys(totalPersonasHabitacion).map((servicios, indexPersona) => {
						let serviciosPersonaHabitacion = props.serviciosSeleccionados[habitacion][`persona${indexPersona}`];

						let personaHabitacionServicios = {
							edad: serviciosPersonaHabitacion.edad,
							nombre: serviciosPersonaHabitacion.nombre,
							servicios: [],
						};

						if (serviciosPersonaHabitacion === undefined) return;

						Object.keys(serviciosPersonaHabitacion).map(servicio => {
							if (servicio != 'nombre' && servicio != 'edad' && servicio != 'soyYo') {
								if (servicio == 'forfait') {
									personaHabitacionServicios.servicios.push(serviciosPersonaHabitacion[servicio]);
								} else {
									if (serviciosPersonaHabitacion[servicio].precio != 0) {
										personaHabitacionServicios.servicios.push(serviciosPersonaHabitacion[servicio]);
									}
								}
							}
						});

						listaServiciosPersona.push(personaHabitacionServicios);
					});
					listaHabitacionServicios.push({
						habitacion: `Habitación ${indexHabitacion + 1}`,
						personas: listaServiciosPersona,
					});
				});
				return listaHabitacionServicios;
			});

			const isEmptyString = word => {
				return word == undefined || word === ' ';
			};

			function getDayName(dateStr, locale) {
				var date = new Date(dateStr);
				return date.toLocaleDateString(locale, { weekday: 'long' });
			}

			const formatArrayDates = listaDates => {
				let monthList = ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'set', 'oct', 'nov', 'dic'];

				let formatDates = '';
				listaDates.forEach((date, index) => {
					let dateAux = `${getDayName(date, 'es-Es')}, ${new Date(date).getDate()} ${
						monthList[new Date(date).getMonth()]
					}`;

					if (listaDates.length - 1 == index) {
						formatDates += ` -  ${dateAux}`;
					} else {
						formatDates += dateAux;
					}
				});

				return formatDates;
			};

			const datediff = (first, second) => {
				return Math.round((second - first) / (1000 * 60 * 60 * 24)) + 1;
			};

			const getPrecioBase = combinacion => {
				// if (edadPersona > 17) {
				//   return combinacion.precios.personas.precioAdulto;
				// } else {
				//   let precioNinioXEdad = combinacion.precios.personas.ninios.find(({edad})=> edad == edadPersona).precio
				//   return precioNinioXEdad;
				// }

				return buscador_store.combinacionSelecionada.precioBaseTeoricoViaje + combinacion.precioCupo;
			};

			const getTotalHabitacion = (informacionHabitacion, indexHabitacion) => {
				let sumAux = 0;
				informacionHabitacion.personas.map(persona => {
					sumAux +=
						buscador_store.combinacionSelecionada.precioBaseTeoricoViaje +
						informacionCombinacion.value[indexHabitacion].precioCupo;
					persona.servicios.map(({ precio }) => (sumAux += precio));
				});
				return sumAux;
			};

			const getTotalReserva = totalHabitaciones => {
				let sumAux = 0;
				totalHabitaciones.map((habitacion, indexHabitacion) => {
					sumAux += getTotalHabitacion(habitacion, indexHabitacion);
				});
				storeReservaHoteles.setTotalReserva(sumAux);
				return sumAux;
			};

			const getTotalServicios = servicios => {
				let sumServicios = 0;
				servicios.map(({ precio }) => {
					sumServicios += precio;
				});
				return sumServicios;
			};

			return {
				totalHabitaciones,
				isEmptyString,
				buscador_store,
				formatArrayDates,
				datediff,
				informacionCombinacion,
				getPrecioBase,
				getTotalServicios,
				getTotalHabitacion,
				getTotalReserva,
				BuscadorAlojamiento,
				agenciaStore,
			};
		},
	});
</script>

<style scoped></style>
