import ViajesGrupoApiService from '@/services/api/skiandnight/ViajesGrupoApiService';
import * as AlertUtils from './AlertUtils';
import router from '@/router';
import { controlErrorDescartarReserva } from './ErrorControl';
import {ESTADOS_RESERVA} from '@/constants/reservaConstants';

export const isReservaActiva = function (estadoReserva) {
	return (
		estadoReserva === ESTADOS_RESERVA.BLOQUEADA ||
		estadoReserva === ESTADOS_RESERVA.RESERVADA ||
		estadoReserva === ESTADOS_RESERVA.PAGADA
	);
};

export async function descartarReserva(viajeId) {
	try {
		await ViajesGrupoApiService.descartarGrupoReservas(viajeId);
		AlertUtils.alertAutoCloseSuccess(1000, () => {});
	} catch(err) {
		AlertUtils.alertWarning(controlErrorDescartarReserva(err))
			.then(() => router.go(-1));
	}
}
