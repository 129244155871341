export const facebook = 'https://www.facebook.com/skiandnight/';
export const instagram = 'https://www.instagram.com/skiandnight/';
export const twitter = 'https://twitter.com/skiandnight';
export const tiktok = 'https://www.tiktok.com/@skiandnight';
export const youtube = 'https://www.youtube.com/channel/UCDdXd-P5KNHuaGm4WhRzEdA';
export const linkedin = 'https://www.linkedin.com/company/skinight/';
export const phone = '+34 910 054 547';

export const aramon = 'https://www.aramon.com/';
export const grandvalira = 'https://www.grandvalira.com/';

export const whatsapp = 'https://api.whatsapp.com/send?phone=34910054547';
