<template>
	<div v-if="!loading" class="pb-2">
		<div class="card-servicios__container">
			<div class="card-servicios__usuario">
				<div class="card-servicios__plazas">
					<span>
						{{ $t('general.persona') }} {{ numeroReserva }}
						<span v-if="!existeCodigoUnirse && numeroPlazas !== null">/ {{ numeroPlazas }}</span>
					</span>
					<template v-if="hasModificarPersona">
						<div v-if="isPrimeraReserva" class="reserva-propia__container">
							<div class="reserva-propia__checkbox">
								<label :for="'checkboxSoyYo' + numeroReserva">
									{{ $t('general.esMiReserva') }}
								</label>
								<input :id="'checkboxSoyYo' + numeroReserva" v-model="reserva.reservaPropia" type="checkbox" />
							</div>
						</div>
					</template>
				</div>
				<div class="card-servicios__usuario-info">
					<div class="card-servicios__usuario-texto">
						<template v-if="reserva.reservaPropia || !hasModificarPersona">
							<template v-if="reservas.length > 0 && reservas[idxReserva].usuario.nombre.length > 0">
								<div class="nombre-usuario">
									<input type="text" :placeholder="`${reservas[idxReserva].usuario.nombre}`" :disabled="true" />
								</div>
								<div class="apellido-usuario">
									<input type="text" :placeholder="`${reservas[idxReserva].usuario.apellido}`" :disabled="true" />
								</div>
							</template>
							<template v-else>
								<span class="label-usuario">
									{{ $t('general.nombreCard') }}
								</span>
							</template>
						</template>
						<template v-else>
							<div class="nombre-usuario">
								<input
									v-model="usuario.nombre"
									type="text"
									:placeholder="$t('input.field.nombre')"
									:disabled="reserva.reservaPropia" />
							</div>
							<div class="apellido-usuario">
								<input
									v-model="usuario.apellido"
									type="text"
									:placeholder="$t('input.field.apellido')"
									:disabled="reserva.reservaPropia" />
							</div>
						</template>
						<div class="actions-usuario">
							<button v-if="filteredServiciosCategorizados.length > 0" @click="toggleOpenDetails">
								<i class="fa-solid fa-chevron-up" :class="openDetails ? 'icon-active' : 'icon-inactive'" />
							</button>
							<button v-if="isBorrable" @click="cerrarCardServicio()">
								<i class="fa-solid fa-xmark" />
							</button>
						</div>
						<template v-if="hasModificarPersona">
							<div v-if="storeHabitacion._necesitaMenorEdad && !reserva.reservaPropia" class="edad-usuario">
								<div class="checkbox-edad">
									<label :for="'checkMenorEdad' + numeroReserva">
										{{ $t('general.menorEdad') }}
									</label>
									<input
										:id="'checkMenorEdad' + numeroReserva"
										v-model="menor"
										type="checkbox"
										style="margin-top: 0px" />
								</div>
							</div>
						</template>
					</div>
				</div>
			</div>
			<div v-if="fechaMenorEdad" class="row">
				<div class="calendar-usuario__container">
					<label>{{ $t('home.form.personalInformation.birthDate') }}</label>
					<Datepicker v-model="usuario.fecha" :format="format" :flow="['year', 'month', 'calendar']" auto-apply />
				</div>
			</div>
		</div>
		<div
			v-if="filteredServiciosCategorizados.length > 0"
			class="card mx-0 p-2 p-md-2 selector-servicios"
			:class="openDetails && !loading ? 'd-block' : 'd-none'"
			@click="emits('habilitarModificar')">
			<template v-for="categoriaServicios in filteredServiciosCategorizados" :key="categoriaServicios.categoria">
				<div class="row px-md-2">
					<ReservaServiciosSelector :categoria-servicios="categoriaServicios" :idx-reserva="idxReserva" />
				</div>
			</template>
		</div>
	</div>
	<div v-else>
		<Spinner />
	</div>
</template>

<script setup>
	import { ref, watch, computed, onMounted } from 'vue';
	import { useRoute } from 'vue-router';
	import { informacionHabitacion } from '@/store_pinia/informacionHabitacion';
	import ReservaServiciosSelector from './ReservaServiciosSelector.vue';
	import Datepicker from '@vuepic/vue-datepicker';
	import Spinner from '@/components/SpinnerLoading.vue';
	import { storeToRefs } from 'pinia/dist/pinia';
	import { useStore } from 'vuex';

	const props = defineProps({
		idxReserva: {
			type: Number,
			default: null,
		},
		numeroPlazas: {
			type: Number,
			default: null,
		},
		serviciosCategorizados: {
			type: Array,
			default: () => [],
		},
		hasModificarPersona: {
			type: Boolean,
			default: () => true,
		},
		openDetails: {
			type: Boolean,
			default: true,
		},
	});

	const emits = defineEmits(['habilitarModificar', 'close', 'toggleOpenDetails']);

	// todo -> remove this, a component should be route agnostic. All needed should be pass as parameter
	const route = useRoute();
	const store = useStore();
	const storeHabitacion = informacionHabitacion();
	const { loading, reservas } = storeToRefs(storeHabitacion);

	const reserva = reservas.value[props.idxReserva];
	const usuario = reserva.usuario;
	const menor = ref(false);

	const numeroReserva = computed(() => props.idxReserva + 1);
	const fechaMenorEdad = computed(() => menor.value && !reserva.reservaPropia && storeHabitacion._necesitaMenorEdad);
	const isPrimeraReserva = computed(() => numeroReserva.value === 1);
	const existeCodigoUnirse = route.query.codigo;
	const isBorrable = computed(() => !isPrimeraReserva.value);

	const filteredServiciosCategorizados = computed(() => {
		return props.serviciosCategorizados
			.map(categoria => ({
				...categoria,
				servicios: categoria.servicios.filter(servicio => servicio.activo),
			}))
			.filter(categoria => categoria.servicios.length > 0);
	});

	function refreshUsuario(forceChange) {
		if (!reserva.reservaPropia && forceChange) {
			usuario.nombre = '';
			usuario.apellido = '';
		} else if (reserva.reservaPropia && store.state.login.isLogged) {
			usuario.nombre = store.state.login.usuario.nombre;
			usuario.apellido = store.state.login.usuario.apellidos.split(' ')[0];
		}
	}

	function init() {
		if (!loading.value) {
			refreshUsuario(false);
		}
	}

	function format(fecha) {
		const day = fecha.getDate();
		const month = fecha.getMonth() + 1;
		const year = fecha.getFullYear();

		return `${day}/${month}/${year}`;
	}

	function cerrarCardServicio() {
		emits('close');
	}

	function toggleOpenDetails() {
		emits('toggleOpenDetails');
	}

	watch(
		() => reserva.reservaPropia,
		() => {
			refreshUsuario(true);
		}
	);

	watch(loading, () => {
		init();
	});

	onMounted(() => {
		init();
	});
</script>

<style lang="scss" scoped>
	@import '~@/assets/scss/san.scss';

	.text-primary {
		@include media-breakpoint-down(md) {
			font-size: 13px;
		}
		font-size: 13px;
	}

	.v-enter-active,
	.v-leave-active {
		transition: opacity 0.3s ease;
	}

	.v-enter-from,
	.v-leave-to {
		opacity: 0;
	}

	.icon-inactive {
		transition: 0.3s;
	}

	.icon-active {
		transform: rotate(180deg);
		transition: 0.3s;
	}

	.form-check-input:checked {
		background-color: var(--bs-secondary);
		border-color: var(--bs-secondary);
	}

	.form-check-input[type='checkbox'] {
		border-radius: 0;
	}

	.card-servicios {
		&__container {
			@extend .bg-primary,
				.text-white,
				.w-100,
				.row,
				.py-4,
				.px-2,
				.d-flex,
				.align-items-center,
				.mx-auto,
				.mb-2,
				.justify-content-center;
			border-radius: var(--bs-border-radius-xl);
			border: 1px solid rgba(var(--bs-primary-rgb), 0.25);
			background: linear-gradient(41deg, var(--bs-primary) -21.45%, #696969 144.52%, #a3a3a3 144.52%);
			box-shadow: 0 1px 2px 1px rgba(var(--bs-primary-rgb), 0.35);

			.calendar-usuario__container {
				@extend .mt-sm-3, .col, .col-sm-4;
			}

			@include media-breakpoint-down(md) {
				font-size: 13px;
			}
		}

		&__usuario {
			@extend .row, .justify-content-between, .d-flex, .flex-column, .flex-lg-row;

			&-info {
				@extend .col-12;
			}

			&-texto {
				@extend .row, .justify-content-between, .d-flex, .flex-row;

				.nombre-usuario,
				.apellido-usuario {
					@extend .col-12, .col-md-5, .mt-2;

					input {
						@extend .form-control;
					}
				}

				.edad-usuario {
					place-self: center;
					@extend .col-12, .col-6, .mt-2;

					.checkbox-edad {
						@extend .form-check, .p-0, .border-0, .d-flex, .justify-content-end, .align-items-center;

						label {
							@extend .form-check-label;
						}

						input {
							@extend .form-check-input, .ms-2;
							border-radius: var(--bs-border-radius-sm) !important;
						}
					}
				}

				.label-usuario {
					@extend .col, .align-middle;
					flex: 4;
				}

				.actions-usuario {
					@extend .col, .col-sm-1, .d-flex, .justify-content-end, .mt-2;

					button {
						@extend .btn, .btn-primary, .bg-transparent, .border-0;
					}
				}
			}
		}

		&__plazas {
			@extend .col, .col-md-3, .col-lg-2, .d-flex, .w-100;

			.reserva-propia {
				&__container {
					@extend .col, .justify-content-end;
				}

				&__checkbox {
					@extend .form-check, .p-0, .border-0, .d-flex, .justify-content-end;

					label {
						@extend .form-check-label;
					}

					input {
						@extend .form-check-input, .ms-2;
						border-radius: var(--bs-border-radius-sm) !important;
					}
				}
			}
		}
	}

	.selector-servicios {
		border-radius: var(--bs-border-radius-xl);
	}
</style>
