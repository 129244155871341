export const ESTADOS_VIAJE = {
	EN_EDICION: 'EN_EDICION',
	PUBLICADO: 'PUBLICADO',
	PAGADO: 'PAGADO',
	CERRADO: 'CERRADO',
	HISTORICO: 'HISTORICO',
};

export const ESTADOS_VIAJE_ORDER = {
	EN_EDICION: 0,
	PUBLICADO: 1,
	EN_PAGO: 2,
	PAGADO: 3,
	CERRADO: 4,
	PEDIDO_REALIZADO: 5,
	HISTORICO: 6,
};

export const TIPOS_PAGO = {
	DIFERIDO: 'DIFERIDO',
	TPV: 'TPV',
};

export const HITOS_PAGO_TIPOCANTIDAD = {
	FIJO: 'FIJO',
	PORCENTAJE: 'PORCENTAJE',
	RESTANTE: 'RESTANTE',
};
