<template>
	<span class="tooltip-container">
		<svg :width="size" :height="size" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g id="info">
				<path
					id="Combined Shape"
					fill-rule="evenodd"
					clip-rule="evenodd"
					d="M0.832031 10C0.832031 15.0626 4.93609 19.1667 9.9987 19.1667C15.0613 19.1667 19.1654 15.0626 19.1654 10C19.1654 4.9374 15.0613 0.833344 9.9987 0.833344C4.93609 0.833344 0.832031 4.9374 0.832031 10ZM17.4987 10C17.4987 14.1421 14.1408 17.5 9.9987 17.5C5.85656 17.5 2.4987 14.1421 2.4987 10C2.4987 5.85787 5.85656 2.50001 9.9987 2.50001C14.1408 2.50001 17.4987 5.85787 17.4987 10ZM10.832 13.3333V10C10.832 9.53977 10.4589 9.16668 9.9987 9.16668C9.53846 9.16668 9.16537 9.53977 9.16537 10V13.3333C9.16537 13.7936 9.53846 14.1667 9.9987 14.1667C10.4589 14.1667 10.832 13.7936 10.832 13.3333ZM10.588 6.91075C10.9134 7.23619 10.9134 7.76383 10.588 8.08927C10.2625 8.4147 9.73488 8.4147 9.40944 8.08927C9.08401 7.76383 9.08401 7.23619 9.40944 6.91075C9.73488 6.58532 10.2625 6.58532 10.588 6.91075Z"
					:fill="color" />
			</g>
		</svg>
		<div v-if="messageInfo" :class="[isMobile ? 'tooltip-bottom' : 'tooltip-left']">{{ messageInfo }}</div>
	</span>
</template>

<script setup>
	import { computed, onMounted } from 'vue';

	defineProps({
		size: {
			type: String,
			default: '20px',
		},
		color: {
			type: String,
			default: 'var(--bs-primary)',
		},
		messageInfo: {
			type: String,
			default: '',
		},
	});

	const isMobile = computed(() => window.innerWidth < 768);

	onMounted(() => {
		window.addEventListener('resize', () => {
			isMobile.value = window.innerWidth < 768;
		});
	});
</script>
<style lang="scss" scoped>
	path.location-icon__path {
		fill: var(--bs-primary);
	}
	:deep(.tooltip-left) {
		width: 30vw;
		@media (max-width: 768px) {
			font-size: 11px;
		}
	}
</style>
