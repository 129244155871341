import Swal from 'sweetalert2';
import { descartarReserva } from './ReservasUtils';
import router from '@/router';
import i18n from '@/i18n';
import * as RouterNames from '@/router/RouterNames';

const { t } = i18n.global;

/**
 * Wrapper de sweetAlert2 para estandarizar y centralizar
 * Esta alerta mostrará un mensaje con un botón de volver,
 * el cual cerrará la alerta cuando sea pulsado y llamará a la funcion callback pasada
 * como argumento
 *
 * @param text texto que se mostrará en la alerta
 * @param callback funcion callback que se ejecutraá cuando se pulse volver
 */
export function alertSuccessWithCallback(text, callback) {
	Swal.fire({
		text: text,
		icon: 'success',
		confirmButtonText: t('botones.volver'),
	}).then(result => {
		if (result.isConfirmed) {
			callback();
		}
	});
}

/**
 * Wrapper de sweetAlert2 para estandarizar y centralizar
 * Esta alerta mostrará un mensaje con un botón de volver,
 * el cual cerrará la alerta cuando sea pulsado
 *
 * @param text
 * @returns {Promise<SweetAlertResult<Awaited<any>>>}
 */
export function alertSuccess(text) {
	return Swal.fire({
		text: text,
		icon: 'success',
		confirmButtonColor: '#212444',
		confirmButtonText: t('botones.volver'),
	});
}

/**
 * Wrapper de sweetAlert2 para estandarizar y centralizar
 * Esta alerta mostrará un mensaje en formato HTML con un botón de volver,
 * el cual cerrará la alerta cuando sea pulsado
 *
 * @param text
 * @returns {Promise<SweetAlertResult<Awaited<any>>>}
 */
export async function alertSuccessHtml(textHtml) {
	return await Swal.fire({
		html: textHtml,
		icon: 'success',
		confirmButtonColor: '#212444',
		confirmButtonText: t('botones.continuar'),
	});
}

/**
 * Wrapper de sweetAlert2 para estandarizar y centralizar
 * Esta alerta se cerrará pasado el tiempo indicado
 *
 * @param timer tiempo en milisegundos que tarda en desaparecer el mensaje
 * @param callback funcion que se ejecutará cuando se cierre el mensaje
 */
export function alertAutoCloseSuccess(timer, callback) {
	return Swal.fire({
		icon: 'success',
		timer: timer,
		showConfirmButton: false,
	}).then(result => {
		if (result.dismiss === Swal.DismissReason.timer) {
			callback();
		}
	});
}

export function alertConfirmDialogWithFunction(titulo, texto, confirmButtonText, callback) {
	Swal.fire({
		title: titulo,
		text: texto,
		icon: 'warning',
		showCancelButton: true,
		confirmButtonColor: '#212444',
		cancelButtonColor: '#FF0000FF',
		confirmButtonText: confirmButtonText,
		cancelButtonText: t('botones.cancelar'),
	}).then(result => {
		if (result.isConfirmed) {
			callback();
		}
	});
}

export async function alertWarning(text) {
	return await Swal.fire({
		text: text,
		icon: 'warning',
		confirmButtonColor: '#212444',
		confirmButtonText: t('botones.volver'),
	});
}

/**
 *  * Wrapper de sweetAlert2 para estandarizar y centralizar
 * Esta alerta se cerrará pasado el tiempo indicado
 *
 * @param timer tiempo en milisegundos que tarda en desaparecer el mensaje
 * @returns {Promise<SweetAlertResult<Awaited<any>>>}
 */
export async function alertAutoCloseSuccessWithoutCallback(timer) {
	return await Swal.fire({
		icon: 'success',
		timer: timer,
		showConfirmButton: false,
	});
}

export async function alertAutoCloseWarningWithoutCallback(timer, text) {
	return await Swal.fire({
		icon: 'warning',
		text: text,
		timer: timer,
		showConfirmButton: false,
	});
}

export function alertAutoCloseSucessHtml(timer, html) {
	return Swal.fire({
		icon: 'success',
		html: html,
		timer: timer,
		showConfirmButton: false,
	});
}

export async function alertRecuperarDescartarReserva(viajeId, codigoViaje) {
	const root = await document.documentElement;
	const computedStyles = await getComputedStyle(root);
	const primaryColor = computedStyles.getPropertyValue('--bs-secondary').trim();
	const result = await Swal.fire({
		allowOutsideClick: false,
		allowEscapeKey: false,
		confirmButtonText: t('botones.recuperar'),
		confirmButtonColor: primaryColor,
		text: t('errors.grupoReservas.pendientes'),
		showDenyButton: true,
		denyButtonText: t('botones.descartar'),
	});
	if (result.isConfirmed) {
		router.push({
			name: RouterNames.GruposReservaResumen,
			params: {
				viajeId: viajeId,
			},
			query: {
				codigo: codigoViaje,
			},
		});
	} else if (result.isDenied) {
		await descartarReserva(viajeId);
	}
}

/**
 * Wrapper de sweetAlert2 para estandarizar y centralizar
 * Esta alerta mostrará solo un ícono de error y se cerrará automáticamente después del tiempo indicado sin mostrar texto ni barra de progreso.
 *
 * @param timer tiempo en milisegundos que tarda en desaparecer el mensaje
 * @returns {Promise<SweetAlertResult<Awaited<any>>>}
 */
export async function alertAutoCloseError(timer) {
	return await Swal.fire({
		icon: 'error',
		timer: timer,
		showConfirmButton: false,
		timerProgressBar: false,
		didOpen: toast => {
			toast.addEventListener('mouseenter', Swal.stopTimer);
			toast.addEventListener('mouseleave', Swal.resumeTimer);
		},
	});
}
