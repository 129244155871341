<template>
	<div class="container superior">
		<div class="row">
			<div class="col">
				<h2 class="text-primary display-5 fw-bold text-start">{{ $t('general.politicaPrivacidad') }}</h2>
			</div>
		</div>
		<div
			v-for="(politica, index) in politicaPrivacidad"
			:key="politica.id"
			:class="['row question', { 'mt-5': index === 0 }]">
			<a
				class="btn w-100 text-start fw-bold fs-4"
				data-bs-toggle="collapse"
				:href="`#collapse` + politica.id"
				role="button"
				aria-expanded="false"
				aria-controls="collapseOne">
				{{ politica.titulo }}
			</a>
			<div :id="'collapse' + politica.id" class="collapse">
				<div class="card card-body">
					<p>
						{{ politica.descripcion }}
					</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { agenciaStoreModule } from '@/store_pinia/agencia';
	import { computed } from 'vue';

	const agenciaStore = agenciaStoreModule();

	const politicaPrivacidad = computed(() => {
		return agenciaStore.getPoliticaPrivacidad?.politicasPrivacidad || '';
	});
</script>

<style scoped lang="scss">
	.btn {
		border-radius: 0;
		color: var(--bs-primary);
		font-size: 24px;
		font-weight: 700;
		line-height: 30px;
		letter-spacing: 0.2px;
		padding: 1.2rem 0;
		padding-left: 2rem;
		position: relative;

		&::after {
			content: '\2303';
			font-size: 2rem;
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY(-50%);
			white-space: nowrap;
		}

		&.collapsed {
			&::after {
				content: '\2304';
			}
		}

		&:hover {
			color: var(--bs-secondary);
		}
	}

	.card-body {
		border: none;
	}

	.collapse {
		margin-bottom: 1rem;
	}

	.row.question {
		border-top: 1px solid #21244440;
	}

	.superior {
		padding-top: 7rem;
	}

	@media only screen and (min-width: 962px) {
		.superior {
			padding-top: 15rem;
		}

		.titulo {
			font-size: 2rem;
		}
	}
</style>
