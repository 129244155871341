<template>
	<div v-if="serviciosDisponibles" class="col my-2 translate">
		<div class="title-card">
			<div class="text">
				<i :class="informacionServicio.icono" />
				<span>{{ informacionServicio.titulo }}</span>
			</div>
			<div class="d-flex">
				<div v-if="servicioSeleccionado" class="d-flex">
					<span v-if="servicioSeleccionado.precio" class="text-secondary mx-2">
						<span v-if="servicioSeleccionado.precio != 0">
							<span v-if="servicioSeleccionado.precio > 0">+</span>
							{{ servicioSeleccionado.precio }}{{agenciaStore.getCurrency}}
						</span>
					</span>
					<span v-if="servicioSeleccionado.precio < 0" class="dot-disabled" />
					<span v-if="servicioSeleccionado.precio >= 0 && !servicioSeleccionado.nulo" class="dot" />
				</div>
			</div>
		</div>
		<div class="body-card px-3">
			<p class="mt-3">
				{{ informacionServicio.subtitulo }}
			</p>
			<p v-if="servicioSeleccionado && !servicioSeleccionado.nulo" class="text-muted fw-bold">
				{{ servicioSeleccionado.descripcion }}
			</p>
			<div class="row d-flex flex-column flex-md-row">
				<div class="col col-md-3">
					<select v-model="filtroModalidades" class="form-select" @change="reiniciarFiltroModalidad()">
						<option :value="null">
							{{ informacionServicioNulo.nombre }}
						</option>
						<option v-for="modalidad in informacionFiltros.modalidades" :key="modalidad.id" :value="modalidad">
							{{ modalidad.nombre }}
						</option>
					</select>
				</div>
				<div v-if="filtroModalidades" class="col col-md-3 mt-2 mt-md-0">
					<select v-model="filtroGamas" class="form-select" @change="reiniciarFiltroGamas()">
						<option :value="null" selected disabled>Seleccionar gama</option>
						<template v-if="selector == 'alquiler'">
							<option v-for="gama in filtroModalidades.gamas" :key="gama.id" :value="gama">
								{{ gama.nombre }}
							</option>
						</template>
						<template v-else>
							<option v-for="gama in filtroModalidades.niveles" :key="gama.id" :value="gama">
								{{ gama.nombre }}
							</option>
						</template>
					</select>
				</div>
				<div v-if="filtroGamas" class="col col-md-6 mt-2 mt-md-0">
					<select v-model="servicioSeleccionado" class="form-select">
						<option :value="null" selected disabled>Seleccionar servicio</option>
						<option v-for="servicio in serviciosFiltrados.filter(s => s.activo)" :key="servicio.id" :value="servicio">
							{{ servicio.nombre }} ({{ dateMonthNumbers(servicio.fechaInicio) }} {{ $t('general.al') }}
							{{ dateMonthNumbers(servicio.fechaFin) }})
							<span v-if="servicio.precio > 0">(+</span>
							<span v-if="servicio.precio">
								<span v-if="servicio.precio < 0">(</span>
								{{ servicio.precio }}{{agenciaStore.getCurrency}})
							</span>
						</option>
					</select>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { ref, watch, computed } from 'vue';
	import { dateMonthNumbers } from '@/helpers/dateUtiles';
	import { selectServiceText } from '@/constants/selectService';
	import { agenciaStoreModule } from '@/store_pinia/agencia';
	export default {
		props: {
			/**
			 *  El selector de tipo subfiltro son solo validos alquiler|clases
			 */
			selector: {
				type: String,
				required: true,
			},

			serviciosDisponibles: {
				type: Array,
				required: true,
			},

			servicioPredefinido: {
				type: Object,
				default: null,
			},

			/**
			 *  El serviciosFiltro tiene la estructura
			 *  {
			 *      modalidades: [
			 *          {   id: @type {String}
			 *              nombre: @type {String}
			 *              gamas/niveles:[
			 *                  {
			 *                      id:@type {String}
			 *                      nombre:@type {String}
			 *                  }
			 *              ]
			 *          }
			 *      ]
			 *  }
			 */
			serviciosFiltro: {
				type: Object,
				required: true,
			},
		},
		emits: ['servicioActualizado'],
		setup(props, { emit }) {
			const agenciaStore = agenciaStoreModule();
			const informacionFiltros = {
				...props.serviciosFiltro[props.selector],
			};

			const informacionServicios = { ...props.serviciosDisponibles };

			const informacionServicioNulo = { ...props.servicioPredefinido };

			const informacionServicio = selectServiceText[props.selector];

			const filtroModalidades = ref(null);
			const filtroGamas = ref(null);

			const serviciosFiltrados = ref();
			const servicioSeleccionado = ref(null);

			const filtrarListaServiciosGamaModalidad = () => {
				let serviciosFiltradosAux = [];
				Object.values(informacionServicios).map(servicio => {
					serviciosFiltradosAux.push(servicio);
				});
				serviciosFiltrados.value = serviciosFiltradosAux.filter(({ modalidad, gama, nulo }) => {
					return modalidad.id == filtroModalidades.value.id && gama.id == filtroGamas.value.id && !nulo;
				});
			};

			const existeInformacion = computed(() => informacionServicios);

			if (existeInformacion.value) {
				watch([filtroModalidades, filtroGamas], () => {
					if (!filtroModalidades.value) {
						servicioSeleccionado.value = informacionServicioNulo;
					}
					if (filtroModalidades.value && filtroGamas.value) {
						filtrarListaServiciosGamaModalidad();
					}
				});

				servicioSeleccionado.value = informacionServicioNulo;
			}

			//CONTROL DE FILTROS
			const reiniciarFiltroModalidad = () => {
				filtroGamas.value = null;
				servicioSeleccionado.value = null;
			};

			const reiniciarFiltroGamas = () => {
				servicioSeleccionado.value = null;
			};

			//AGREGAR INFORMACION AL STORE PARA MOSTRAR EN CARD RESUMEN

			watch(servicioSeleccionado, () => {
				let servicioActualizar = {
					[props.selector]: servicioSeleccionado.value,
				};
				emit('servicioActualizado', servicioActualizar);
			});

			return {
				informacionFiltros,
				filtroModalidades,
				serviciosFiltrados,
				filtroGamas,
				servicioSeleccionado,
				reiniciarFiltroGamas,
				reiniciarFiltroModalidad,
				informacionServicioNulo,
				dateMonthNumbers,
				existeInformacion,
				informacionServicio,
				agenciaStore,
			};
		},
	};
</script>

<style lang="scss" scoped>
	.title-card {
		background-color: #f9f7f8;
		padding: 1rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-radius: var(--bs-border-radius-lg);

		.text {
			display: flex;
			align-items: center;
			i {
				margin-right: 0.5rem;
			}
		}
	}

	.dot {
		height: 25px;
		width: 25px;
		background-color: var(--bs-secondary);
		border-radius: 50%;
		display: inline-block;
	}

	.dot-disabled {
		height: 25px;
		width: 25px;
		background-color: #727272;
		border-radius: 50%;
		display: inline-block;
	}
</style>
