<template>
	<div class="container">
		<h2 class="my-3 text-primary fs-2 fw-bold px-3 text-center">
			{{ $t('porqueReservaTitulo2') }}
		</h2>
		<div class="row d-flex flex-wrap mb-4 reservar-content">
			<div v-for="motivo in textoPorqueReservar" :key="motivo.title" class="col-sm-6 col-lg-3 text-center">
				<div class="row">
					<div class="col-4 col-md-12 p-0">
						<img :src="motivo.imagenSrc" :alt="motivo.altText" :width="motivo.width" :height="motivo.height" />
					</div>
					<div class="col-8 col-md-12">
						<h5 class="text-center text-primary fw-bold">
							{{ motivo.title }}
						</h5>
						<p>
							{{ motivo.description }}
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { computed } from 'vue';
	import { useI18n } from 'vue-i18n';

	const { t } = useI18n();
	const isMobile = computed(() => window.innerWidth <= 900);

	const medida = computed(() => (isMobile.value ? 90 : 150));

	const textoPorqueReservar = computed(() => [
		{
			title: t('porqueReservar2[0].icono'),
			description: t('porqueReservar2[0].descripcion'),
			imagenSrc: require('@/assets/categorias/Pago2.svg'),
			altText: t('porqueReservar[0].icono').toLowerCase(),
			width: medida.value,
			height: medida.value,
		},
		{
			title: t('porqueReservar2[1].icono'),
			description: t('porqueReservar2[1].descripcion'),
			imagenSrc: require('@/assets/categorias/Atencion2.svg'),
			altText: t('porqueReservar[1].icono').toLowerCase(),
			width: medida.value,
			height: medida.value,
		},
		{
			title: t('porqueReservar2[2].icono'),
			description: t('porqueReservar[2].descripcion'),
			imagenSrc: require('@/assets/categorias/Flexibilidad.svg'),
			altText: t('porqueReservar2[2].icono').toLowerCase(),
			width: medida.value,
			height: medida.value,
		},
		{
			title: t('porqueReservar2[3].icono'),
			description: t('porqueReservar2[3].descripcion'),
			imagenSrc: require('@/assets/categorias/Ocio.svg'),
			altText: t('porqueReservar2[3].icono').toLowerCase(),
			width: medida.value,
			height: medida.value,
		},
	]);
</script>

<style lang="scss" scoped>
	.container {
		max-width: 95vw;
	}

	.reservar-content {
		@media only screen and (max-width: 990px) {
			padding: 0 20px;
			h5 {
				font-size: 15px !important;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
				letter-spacing: 0.206px;
				text-align: left !important;
			}
			p {
				font-size: 14px !important;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				letter-spacing: 0.12px;
				text-align: left !important;
			}
		}
	}
</style>
