<template>
	<div class="card-resumen">
		<div class="card-body">
			<div class="title text-primary pb-3">{{ $t('reserva.howPay') }}</div>
			<div>
				<div class="d-flex align-items-center justify-content-between mb-2">
					<input
						id="precioTotal"
						v-model="isPagoUnicoSelected"
						type="radio"
						name="precioTotal"
						:value="true"
						class="d-none"
						@change="optionsChange(true)" />
					<label
						for="precioTotal"
						class="w-100 d-flex align-items-center justify-content-between pointer"
						:class="{ 'fw-bold': isPagoUnicoSelected }">
						<span v-if="!isPagosSucesivos" class="me-4 text-primary">
							{{ $t('reserva.pagarTotalReserva') }} {{ precioTotalGrupo }} {{agenciaStore.getCurrency}}
						</span>
						<span v-else class="me-4 text-primary">
							{{ $t('reserva.pagarTotalRestante') }} ({{ totalPagarRestanteGrupo }} {{agenciaStore.getCurrency}})
						</span>
						<span class="d-flex justify-content-center align-items-center me-4">
							<RadioButtonIcon :is-selected="isPagoUnicoSelected" />
						</span>
					</label>
				</div>
				<template v-if="hitosPago?.length > 1 && !isPagoUnicoForced">
					<input
						id="precioInicial"
						v-model="isPagoUnicoSelected"
						type="radio"
						name="precioInicial"
						:value="false"
						class="d-none"
						:disabled="fechaVencimientoHitoActualAlreadyPayed !== null"
						:class="{ 'opacity-50': fechaVencimientoHitoActualAlreadyPayed !== null }"
						@change="optionsChange(false)" />
					<label
						for="precioInicial"
						class="w-100 d-flex align-items-center justify-content-between"
						:class="{
							'fw-bold': !isPagoUnicoSelected,
							'opacity-50': fechaVencimientoHitoActualAlreadyPayed !== null,
							pointer: fechaVencimientoHitoActualAlreadyPayed === null,
						}">
						<span class="me-4 text-primary">
							{{ $t('reserva.pagarPlazos') }}
						</span>
						<span class="d-flex justify-content-center align-items-center me-4">
							<RadioButtonIcon :is-selected="!isPagoUnicoSelected" />
						</span>
					</label>
					<div class="card-plazos text-primary">
						<div
							v-for="(hito, index) in hitosPago"
							:key="index"
							class="pago"
							:class="{ 'opacity-50': fechaVencimientoHitoActualAlreadyPayed !== null }">
							<div v-if="index !== hitosPago.length - 1">
								<span class="pagos me-2">{{ $t('reserva.pago') }} {{ index + 1 }}</span>
								<span v-if="index === 0 && !isPagosSucesivos">
									{{ $t('reserva.paga') }} {{ currency(hito.cantidad) }} {{agenciaStore.getCurrency}} {{ $t('general.ahora') }}.
								</span>
								<span v-else>
									{{ $t('reserva.paga') }} {{ currency(hito.cantidad) }} {{agenciaStore.getCurrency}} {{ $t('general.antesDel') }}
									{{ formatDateSlashes(hito.fecha) }}.
								</span>
							</div>
							<div v-else>
								<span class="pagos me-2">{{ $t('reserva.pago') }} {{ index + 1 }}</span>
								<span>
									{{ $t('reserva.paga') }} {{ currency(hito.cantidad) }} {{agenciaStore.getCurrency}} {{ $t('general.antesDel') }}
									{{ formatDateSlashes(hito.fecha) }}.
								</span>
							</div>
						</div>
					</div>
					<span v-if="fechaVencimientoHitoActualAlreadyPayed !== null" class="fechavenc-span">
						{{
							$t('reserva.pagoPlazosDeshabilitado', {
								fecha: formatDateSlashes(fechaVencimientoHitoActualAlreadyPayed),
							})
						}}
					</span>
				</template>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { ref, computed, onMounted, nextTick, watch } from 'vue';
	import { formatDateSlashes } from '@/helpers/dateUtiles';
	import { informacionHabitacion } from '@/store_pinia/informacionHabitacion';
	import { calculaPrecioTotal, calculaPrecioTotalReservas, calculaPrecioTotalSinTasas } from '@/helpers/viajesGrupos';
	import { max } from '@popperjs/core/lib/utils/math';
	import { HITOS_PAGO_TIPOCANTIDAD } from '@/constants/viajeConstants';
	import { TIPOS_PAGO } from '@/constants/viajeConstants';
	import RadioButtonIcon from '@/components/Icons/RadioButtonIcon';
	import { agenciaStoreModule } from '@/store_pinia/agencia';
	import currency from 'currency.js';

	defineProps({
		isPagosSucesivos: {
			type: Boolean,
			required: false,
			default: false,
		},
	});

	const emits = defineEmits(['pago-unico', 'hito-base']);

	const storeHabitacion = informacionHabitacion();
	const agenciaStore = agenciaStoreModule();
	const isPagoUnicoSelected = ref(true);
	const fechaVencimientoHitoActualAlreadyPayed = ref(null);
	const isPagoUnicoForced = computed(
		() =>
			storeHabitacion.getCodigoDescuento?.pagoUnico ||
			storeHabitacion.getViaje?.tipoPago === TIPOS_PAGO.DIFERIDO ||
			storeHabitacion.getViaje?.debtLiquidationPeriod ||
			(storeHabitacion.getAlojamiento && storeHabitacion.getAlojamiento.habitacion.integrado)
	);

	const precioTotalGrupo = computed(() => calculaPrecioTotalReservas(storeHabitacion.getResumenReservas));
	const totalPagadoGrupo = computed(() =>
		storeHabitacion.getResumenReservas?.reduce((accum, reserva) => accum.add(calcularTotalPagado(reserva)), currency(0))
	);
	const totalPagarRestanteGrupo = computed(() => precioTotalGrupo.value.subtract(totalPagadoGrupo.value));

	const hitosPago = computed(() => calculaHitosPago(storeHabitacion.getViaje?.hitosPago));

	function calcularTotalPagado(reserva) {
		return reserva?.pagos?.reduce((accum, pago) => accum.add(pago.cantidad), currency(0)) || currency(0);
	}

	function calcularTasasPagadas(reserva) {
		return reserva?.pagos?.reduce((accum, pago) => accum.add(pago.fee), currency(0)) || currency(0);
	}

	function calculaHitosPago(hitosPago) {
		const fechaActual = Date.now();
		const repercutirFee = storeHabitacion.getViaje?.repercutirFee;
		const hitosPagoFinales = [];
		storeHabitacion.getResumenReservas.forEach(reserva => {
			const precioTotalSinTasas = calculaPrecioTotalSinTasas(reserva, false);
			const precioTotal = calculaPrecioTotal(reserva, false);
			let totalPagado = calcularTotalPagado(reserva);
			if (reserva.descuento) {
				totalPagado = totalPagado.add(reserva.descuento);
			}
			const totalTasas = precioTotal.subtract(precioTotalSinTasas);
			const totalTasasPagadas = calcularTasasPagadas(reserva);
			const totalPagadoSinTasas = totalPagado.subtract(totalTasasPagadas);
			let pagadoRestante = totalPagadoSinTasas;
			let pagadoTasasRestante = totalTasasPagadas;

			let hitosPagoAjustados = ajustarHitosDePago(hitosPago, reserva);

			hitosPagoAjustados.forEach((hitoAjustado, idx) => {
				let cantidad = currency(max(0, hitoAjustado.cantidad.subtract(pagadoRestante).value));
				pagadoRestante = currency(max(0, pagadoRestante.subtract(hitoAjustado.cantidad).value));
				if (repercutirFee) {
					let ratio = hitoAjustado.cantidad.value / precioTotalSinTasas.value;
					let cantidadTasas = max(0, totalTasas.multiply(ratio).subtract(pagadoTasasRestante).value);
					pagadoTasasRestante = currency(max(0, pagadoTasasRestante.subtract(totalTasas.multiply(ratio)).value));
					cantidad = cantidad.add(max(0, cantidadTasas));
				}
				hitoAjustado.cantidad = currency(max(0, cantidad.value));
				if (hitosPagoFinales[idx] == null) {
					hitosPagoFinales[idx] = hitoAjustado;
				} else {
					hitosPagoFinales[idx].cantidad = hitosPagoFinales[idx].cantidad.add(hitoAjustado.cantidad);
				}
			});
		});

		const hitosPagoPagados = hitosPagoFinales.filter(hito => hito.cantidad.value === 0);
		if (hitosPagoPagados.length > 0) {
			const lastHitoPagoPagado = hitosPagoPagados[hitosPagoPagados.length - 1];
			if (new Date(lastHitoPagoPagado.fecha) >= fechaActual) {
				fechaVencimientoHitoActualAlreadyPayed.value = lastHitoPagoPagado.fecha;
			}
		}
		return hitosPagoFinales.filter(hito => hito.cantidad.value > 0);
	}

	function ajustarHitosDePago(hitosPago, reserva) {
		const fechaActual = Date.now();
		let cantidadAcc = currency(0);
		let hitoAcumulado = false;
		let precioTotalSinTasas = calculaPrecioTotalSinTasas(reserva, false);
		let precioTotalSinTasasNiComplementos = precioTotalSinTasas.subtract(reserva.precioComplementos);

		return hitosPago
			.map((hito, idx) => {
				const fechaLimite = new Date(hito.fecha);
				let cantidad = calculaCantidadHito(hito, precioTotalSinTasasNiComplementos);
				if (idx === 0) {
					cantidad = cantidad.add(reserva.precioComplementos);
				}
				cantidadAcc = cantidadAcc.add(cantidad);
				if (fechaActual > fechaLimite) {
					hitoAcumulado = true;
				} else {
					if (hitoAcumulado) {
						hitoAcumulado = false;
						return {
							cantidad: cantidadAcc,
							tipoCantidad: hito.tipoCantidad,
							fecha: hito.fecha,
						};
					} else {
						if (HITOS_PAGO_TIPOCANTIDAD.RESTANTE === hito.tipoCantidad) {
							cantidad = precioTotalSinTasas.subtract(cantidadAcc);
						}
						return {
							cantidad: cantidad,
							tipoCantidad: hito.tipoCantidad,
							fecha: hito.fecha,
						};
					}
				}
			})
			.filter(hito => hito !== undefined);
	}

	function calculaCantidadHito(hitoPago, precioTotalSinTasas) {
		if (HITOS_PAGO_TIPOCANTIDAD.FIJO === hitoPago.tipoCantidad) {
			return currency(hitoPago.cantidad);
		} else if (HITOS_PAGO_TIPOCANTIDAD.PORCENTAJE === hitoPago.tipoCantidad) {
			return (currency(hitoPago.cantidad).divide(100)).multiply(precioTotalSinTasas);
		} else {
			return currency(0);
		}
	}

	function optionsChange(selectedValue) {
		isPagoUnicoSelected.value = selectedValue;
		emits('pago-unico', selectedValue);
	}

	watch(hitosPago, hitosPago => {
		emits('hito-base', hitosPago[0]);
	});

	onMounted(async () => {
		await nextTick();
		emits('pago-unico', isPagoUnicoSelected.value);
		emits('hito-base', hitosPago.value[0]);
	});
</script>

<style scoped lang="css">
	.card-resumen {
		border-radius: var(--bs-border-radius-xl);
		border: 1px solid rgba(var(--bs-primary-rgb), 0.25);
	}

	.card-body {
		padding: 2rem;
	}

	.title {
		font-size: 16px;
		font-weight: 500;
	}

	.text-primary {
		color: var(--bs-primary);
	}

	.pagos {
		font-size: 12px;
		font-weight: 700;
		color: var(--bs-secondary);
	}

	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 0.5s;
	}
	.fade-enter-from,
	.fade-leave-to {
		opacity: 0;
	}

	.fechavenc-span {
		font-size: 13px;
		color: var(--bs-primary);
	}
</style>
