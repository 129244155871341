import { defineStore } from 'pinia';
import StaffApiService from '../services/api/skiandnight/StaffApiService';

export const misClientesStore = defineStore('misClientesStore', {
	state: () => ({
		misClientes: null,
	}),

	actions: {
		async setMisClientes() {
			if (!this.misClientes) {
				await StaffApiService.getMisClientes().then(res => (this.misClientes = res));
			}
		},
	},
});
