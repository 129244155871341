<template>
	<div class="row-occupancy d-flex flex-wrap justify-content-start m-auto">
		<div
			v-for="(user, index) in usuariosPrincipal"
			:key="`user-${index}`"
			class="user-container d-flex align-items-center justify-content-start text-center">
			<div v-if="user.isFree" class="d-flex align-items-center justify-content-start">
				<IconFree :plazas-libres-repercutidas="plazasLibresRepercutidas" class="icon-size m-1" />
				<div class="nombre-occupant text-nowrap mx-2 text-primary">
					{{ $t('reserva.visualizacionOcupantes.plaza') }} {{ $t('reserva.visualizacionOcupantes.libre') }}
				</div>
			</div>
			<template v-else>
				<IconOccupied :user="user" class="icon-size m-1" />
				<div class="nombre-occupant text-nowrap mx-2 text-primary">
					{{ truncateText(user.nombre) }} {{ truncateText(user.apellido) }}
				</div>
				<div v-if="user.id" class="nombre-occupant text-nowrap mx-2">(#{{ user.id }})</div>
			</template>
		</div>
	</div>
</template>

<script setup>
	import { useI18n } from 'vue-i18n';
	import { computed } from 'vue';
	import IconFree from '@/components/Icons/IconFree.vue';
	import IconOccupied from '@/components/Icons/IconOccupied.vue';

	const { t } = useI18n();
	const props = defineProps({
		totalOccupancies: {
			type: Number,
			default: 0,
			required: true,
		},
		occupantsInfo: {
			type: Array,
			default: () => [],
		},
		hasHabs: {
			type: Boolean,
			default: false,
		},
	});

	function truncateText(text, maxLength = 7) {
		const formattedText = text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
		return formattedText.length > maxLength ? `${formattedText.substring(0, maxLength)}...` : formattedText;
	}

	const usuariosPrincipal = computed(() => {
		const users = props.occupantsInfo.map((user, index) => ({
			nombre: user?.nombre || (props.hasHabs ? t('reserva.visualizacionOcupantes.persona') : 'Nombre'),
			apellido: user?.apellido || (props.hasHabs ? `${index + 1}/${props.totalOccupancies}` : 'Apellido'),
			done: user?.done,
			isFree: user?.nombre === t('reserva.visualizacionOcupantes.persona'),
			id: user?.id || '',
		}));
		return [
			...users,
			...Array.from({ length: props.totalOccupancies - users.length }, () => ({
				nombre: '',
				apellido: '',
				done: false,
				isFree: true,
				freeOccupancy: true,
			})),
		];
	});
</script>

<style lang="scss" scoped>
	@import '~@/assets/scss/san.scss';

	.row-occupancy {
		padding: 5px 3px 0px 3px;
		overflow: hidden;
		align-items: center;
		@include media-breakpoint-down(lg) {
			padding-bottom: 10px;
		}
	}

	.user-container {
		@media (max-width: 768px) {
			width: 50%;
		}
	}

	.icon-occupant-active {
		border: 1px solid var(--bs-secondary) !important;
	}

	@include media-breakpoint-down(md) {
		.icono-ocupado,
		.icon-occupant-active,
		.icono-libre {
			width: 24px !important;
			height: 24px !important;
			font-size: 15px !important;
			place-self: center;
		}
		.occupancy {
			width: 50%;
			margin: 0px !important;
			padding: 5px;
		}
	}

	.icon-size {
		min-width: 24px !important;
		max-width: 24px !important;
		min-height: 24px !important;
		max-height: 24px !important;
		font-size: 15px;
	}

	.nombre-occupant {
		color: #172b4d;
		font-size: 14px;
		font-weight: 400;
		@include media-breakpoint-down(lg) {
			font-size: 12px;
		}
	}

	.text-center {
		text-align: -webkit-center !important;
	}
</style>
