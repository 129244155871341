import { defineStore } from 'pinia';
import BuscadorApiServices from '@/services/api/skiandnight/BuscadorApiServices';
import { fomatDatesHyphen } from '@/helpers/dateUtiles';

const today = new Date();
const tomorrow = new Date(today);
const tomorrowAux = new Date();
tomorrowAux.setDate(today.getDate() + 1);
const newDate = new Date(tomorrow.setDate(tomorrowAux.getDate() + 2));

export const buscadorStore = defineStore('buscadorStore', {
	state: () => ({
		//para guardar la lugarExperiencia de la opcion seleccionada en la primera pantalla de hoteles
		//y en la siguiente desplegar por defecto todas las opciones de esa lugarExperiencia
		estacionSeleccionada: null,

		//para guardar la lugarExperiencia de la opcion finalmente seleccionada
		//y pedir los servicios para esta lugarExperiencia
		//no nos sirve la del filtro ya que se puede buscar por area
		estacionOpcionSeleccionada: null,

		opcionesPension: {
			SD: 'Solo desayuno',
			SA: 'Solo Alojamiento',
			AD: 'Alojamiento y desayuno',
			MP: 'Media pensión',
			PC: 'Pensión completa',
		},

		hotelSeleccionado: null,
		hoteles: null,
		loadingHoteles: false,
		combinacionSelecionada: null,
		echoToken: null,
		filtrosBuscador: null,
		filtrosLateral: null,
		filtrosSeleccionadosBuscador: {
			destino: {
				nombre: null,
				areaId: null,
				estacionId: null,
			},
			fechasAlojamiento: [tomorrowAux, newDate],
			fechasForfait: [tomorrowAux, newDate],
			habitaciones: [{ habitacion: [30, 30] }],
		},
		nuestrosViajes: [],
		cacheId: null,
		paginatorState: null,
	}),

	actions: {
		async setNuestrosViajes() {
			if (
				this.filtrosSeleccionadosBuscador.destino.nombre == null ||
				this.filtrosSeleccionadosBuscador.fechasAlojamiento == null ||
				this.filtrosSeleccionadosBuscador.fechasForfait == null ||
				this.filtrosSeleccionadosBuscador.habitaciones == null
			) {
				return;
			}

			await BuscadorApiServices.getNuestrosViajes({
				area: Number(this.filtrosSeleccionadosBuscador.destino.areaId),
				lugarExperiencia: Number(this.filtrosSeleccionadosBuscador.destino.estacionId),
				fechaInicio: fomatDatesHyphen(this.filtrosSeleccionadosBuscador.fechasAlojamiento[0]),
				fechaFin: fomatDatesHyphen(this.filtrosSeleccionadosBuscador.fechasAlojamiento[1]),
			}).then(res => {
				this.nuestrosViajes = res;
			});
		},
		async setHotelesYForfait() {
			if (
				this.filtrosSeleccionadosBuscador.destino.nombre == null ||
				this.filtrosSeleccionadosBuscador.fechasAlojamiento == null ||
				this.filtrosSeleccionadosBuscador.fechasForfait == null ||
				this.filtrosSeleccionadosBuscador.habitaciones == null
			) {
				return;
			}

			this.loadingHoteles = true;
			await BuscadorApiServices.getHotelesYForfaits({
				forLanding: false,
				showXml: false,
				area: Number(this.filtrosSeleccionadosBuscador.destino.areaId),
				lugarExperiencia: Number(this.filtrosSeleccionadosBuscador.destino.estacionId),
				startDate: fomatDatesHyphen(this.filtrosSeleccionadosBuscador.fechasAlojamiento[0]),
				endDate: fomatDatesHyphen(this.filtrosSeleccionadosBuscador.fechasAlojamiento[1]),
				startDateFF: fomatDatesHyphen(this.filtrosSeleccionadosBuscador.fechasForfait[0]),
				endDateFF: fomatDatesHyphen(this.filtrosSeleccionadosBuscador.fechasForfait[1]),
				habitaciones: this.filtrosSeleccionadosBuscador.habitaciones,
				filter: {},
				// area: "1",
				// lugarExperiencia: "1",
				// startDate: "04-12-2022",
				// endDate: "06-12-2022",
				// startDateFF: "05-12-2022",
				// endDateFF: "06-12-2022",
				// habitaciones: [
				//     {
				//         habitacion : [30,30],

				//     },
				//     {
				//         habitacion : [30,30,4]
				//     }
				// ]
			})
				.then(res => {
					this.hoteles = res.opcionesParaUsuario;
					this.filtrosLateral = res.filtrosLateral;
					this.echoToken = res.echoToken;
					this.loadingHoteles = false;
					this.cacheId = res.cacheId;
					this.paginatorState = res.paginatorState;
				})
				.catch(() => {
					this.loadingHoteles = false;
				});
		},
		async cambiarPagina(cache, pageChange, paginatorState, filtro) {
			await BuscadorApiServices.getHotelesYForfaitsCambioPagina(cache, pageChange, paginatorState, filtro).then(res => {
				this.hoteles = res.opcionesParaUsuario;
				this.paginatorState = res.paginatorState;
			});
		},
		async cambiarSizePagina(cache, pageSize, paginatorState, filtro) {
			await BuscadorApiServices.getHotelesYForfaitsCambioSizePagina(cache, pageSize, paginatorState, filtro).then(
				res => {
					this.hoteles = res.opcionesParaUsuario;
					this.paginatorState = res.paginatorState;
				}
			);
		},
		async setFiltros() {
			await BuscadorApiServices.getFiltros().then(res => {
				this.filtrosBuscador = res;
			});
		},
		async setServiciosHotel() {
			await BuscadorApiServices.getServiciosHotel({
				showXml: false,
				area: '1',
				lugarExperiencia: '1',
				startDate: '04-12-2022',
				endDate: '06-12-2022',
				startDateFF: '05-12-2022',
				endDateFF: '06-12-2022',
				habitaciones: [
					{
						habitacion: [30, 30],
					},
					{
						habitacion: [30, 30, 4],
					},
				],
			}).then(res => {
				console.log(res);
			});
		},

		async setDestino(nombre, areaId, estacionId) {
			this.filtrosSeleccionadosBuscador.destino = await {
				nombre: nombre,
				areaId: areaId,
				estacionId: estacionId,
			};
		},
		setFechasForfait(fechaInicio, fechaFin) {
			this.filtrosSeleccionadosBuscador.fechasForfait = [fechaInicio, fechaFin];
		},
		setHabitaciones(habitaciones) {
			this.filtrosSeleccionadosBuscador.habitaciones = habitaciones;
		},

		async setFiltrosSeleccionadosBuscador(destino, fechasAlojamiento, fechasForfait, habitaciones) {
			this.clearFiltrosSeleccionadosBuscador();
			const defaultDates = [new Date(tomorrowAux), new Date(newDate)];

			this.filtrosSeleccionadosBuscador.destino = destino ||
				this.filtrosSeleccionadosBuscador.destino || { nombre: null, areaId: null, estacionId: null };

			if (fechasAlojamiento && Array.isArray(fechasAlojamiento) && fechasAlojamiento.length === 2) {
				this.filtrosSeleccionadosBuscador.fechasAlojamiento = fechasAlojamiento.map(fecha => new Date(fecha));
			} else {
				this.filtrosSeleccionadosBuscador.fechasAlojamiento =
					this.filtrosSeleccionadosBuscador.fechasAlojamiento || defaultDates;
			}

			if (fechasForfait && Array.isArray(fechasForfait) && fechasForfait.length === 2) {
				this.filtrosSeleccionadosBuscador.fechasForfait = fechasForfait.map(fecha => new Date(fecha));
			} else {
				this.filtrosSeleccionadosBuscador.fechasForfait =
					this.filtrosSeleccionadosBuscador.fechasForfait || defaultDates;
			}

			this.filtrosSeleccionadosBuscador.habitaciones = habitaciones ||
				this.filtrosSeleccionadosBuscador.habitaciones || [{ habitacion: [30, 30] }];
		},
		setCombinacion(combinacion) {
			this.combinacionSelecionada = combinacion;
		},
		setEstacionOpcionSeleccionada(lugarExperiencia) {
			this.estacionOpcionSeleccionada = lugarExperiencia;
		},
		clearFiltrosSeleccionadosBuscador() {
			this.filtrosSeleccionadosBuscador = {
				destino: {
					nombre: null,
					areaId: null,
					estacionId: null,
				},
				fechasAlojamiento: null,
				fechasForfait: null,
				habitaciones: null,
			};
		},
	},
});
