<template>
	<p v-show="!noTitle" class="text-primary text-title">{{ $t('codigoDescuento.tienesCodDescuento') }}</p>
	<div class="row">
		<div class="input-wrapper">
			<input
				v-model="codigoDescuento"
				type="text"
				class="form-control"
				:placeholder="$t('reserva.descuentoCodIntrod')"
				:disabled="loading"
				required />
			<button
				class="btn btn-outline-secondary bg-secondary text-primary"
				type="button"
				:disabled="loading"
				@click="validarCodigo()">
				<span class="text-apply">
					{{ $t('general.aplicar') }}
				</span>
			</button>
		</div>
	</div>
</template>

<script setup>
	import { ref } from 'vue';
	import ViajesGrupoApiService from '@/services/api/skiandnight/ViajesGrupoApiService';
	import { alertAutoCloseSucessHtml, alertAutoCloseWarningWithoutCallback } from '@/services/utils/AlertUtils';
	import { useI18n } from 'vue-i18n';

	const props = defineProps({
		viajeId: {
			type: String,
			default: null,
		},
		reservasIds: {
			type: Array,
			default: () => [],
		},
		noTitle: {
			type: Boolean,
			required: false,
			default: false,
		},
	});

	const emits = defineEmits(['descuentoAplicado']);
	const codigoDescuento = ref(null);
	const loading = ref(false);
	const { t } = useI18n();

	async function validarCodigo() {
		try {
			loading.value = true;
			let res = await ViajesGrupoApiService.validarDescuento(props.viajeId, codigoDescuento.value, props.reservasIds);
			alertAutoCloseSucessHtml(3000, ` <strong><p class="text-primary">${res.descripcion}</p></strong>`);

			emits('descuentoAplicado', { codigo: codigoDescuento.value, ...res });
		} catch (err) {
			alertAutoCloseWarningWithoutCallback(
				2000,
				t('reserva.descuentoCodError', {
					codigo: codigoDescuento.value,
				})
			);
		} finally {
			loading.value = false;
			codigoDescuento.value = null;
		}
	}
</script>

<style scoped>
	.text-title {
		font-size: 18px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		letter-spacing: 0.134px;
	}

	@media screen and (max-width: 768px) {
		.text-title {
			font-size: 16px;
		}

		.btn-primary {
			font-size: 12px;
		}

		.form-control {
			font-size: 12px;
		}
	}

	[type='text'],
	input[type='text']::placeholder {
		text-align: left;
		left: 2px;
		top: 0;
	}

	.input-wrapper {
		position: relative;
		display: inline-block;
		border-radius: var(--bs-border-radius-xxl);
		overflow: hidden;

		.form-control {
			border-radius: var(--bs-border-radius-xxl);
			background: rgba(33, 36, 68, 0.02);
			box-shadow: 0px 0px 3px 0px rgba(33, 36, 68, 0.35) inset;
			height: 3rem;
		}

		@media screen and (max-width: 768px) {
			width: 100%;
		}
	}

	.input-wrapper button {
		position: absolute;
		border-radius: var(--bs-border-radius-xl);
		top: 50%;
		right: 10px;
		transform: translateY(-50%);
		height: 70%;
		border-radius: var(--bs-border-radius-xl);
		font-size: 0.8em;
		margin-right: 1rem;
	}

	.text-apply {
		font-weight: 500;
		@media screen and (max-width: 768px) {
			font-size: 12px;
		}
	}
</style>
