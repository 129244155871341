/**
 * Copia al clipboard el texto cuyo element id en html sea textToBeCopied
 * FIXME mejora, que no esté hardcoded el element id
 */
export function copyToClipboard() {
	const copyText = document.getElementById('textToBecopied').textContent; // TODO sacar id a parametro
	const textArea = document.createElement('textarea');
	textArea.textContent = copyText;
	document.body.append(textArea);
	textArea.select();
	document.execCommand('copy');
}

/**
 * Forma y descarga un pdf dado un bytestring y un nombre de fichero
 * @param byteString Pdf en string de bytes
 * @param fileFullName nombre de fichero con extension
 */
export function downloadPDFFromByteString(byteString, fileFullName) {
	var blob = new Blob([byteString], { type: 'application/pdf' }); // change resultByte to bytes
	var link = document.createElement('a');
	link.href = window.URL.createObjectURL(blob);
	link.download = fileFullName;
	link.click();
}
