<template>
	<div class="container superior">
		<div class="row titulo">
			<div class="col">POLÍTICA DE COOKIES</div>
		</div>
		<div class="row">
			<div class="col">
				<p>
					<a
						class="btn btn-primary"
						data-bs-toggle="collapse"
						href="#collapseOne"
						role="button"
						aria-expanded="false"
						aria-controls="collapseOne">
						1. QUÉ SON LAS COOKIES
					</a>
				</p>
				<div id="collapseOne" class="collapse">
					<div class="card card-body">
						<p>
							Las cookies son ficheros de texto que se almacenan en el dispositivo del usuario que navega a través de
							Internet y que, en particular, contiene un número que permite identificar unívocamente dicho dispositivo,
							aunque éste cambie de localización o de dirección IP.
						</p>
						<p>
							Las cookies son instaladas durante la navegación por Internet, bien por los sitios web que visita el
							usuario o bien por terceros con los que se relaciona el sitio web, y permiten a éste conocer su actividad
							en el mismo sitio o en otros con los que se relaciona éste, por ejemplo: el lugar desde el que accede, el
							tiempo de conexión, el dispositivo desde el que accede (fijo o móvil), el sistema operativo y navegador
							utilizados, las páginas más visitadas, el número de clicks realizados y de datos respecto al
							comportamiento del usuario en Internet.
						</p>
						<p>
							Las cookies se asocian únicamente a su navegador y no proporcionan por sí mismas datos personales. Las
							cookies no pueden dañar su dispositivo y además son muy útiles, ya que nos ayudan a identificar y resolver
							errores.
						</p>
						<p>
							El sitio web es accesible sin necesidad de que las cookies estén activadas, si bien, su desactivación
							puede impedir el correcto funcionamiento del mismo.
						</p>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<p>
					<a
						class="btn btn-primary"
						data-bs-toggle="collapse"
						href="#collapseTwo"
						role="button"
						aria-expanded="false"
						aria-controls="collapseTwo">
						2. AUTORIZACIÓN PARA EL USO DE COOKIES
					</a>
				</p>
				<div id="collapseTwo" class="collapse">
					<div class="card card-body">
						<p>
							De conformidad con el aviso de cookies que aparece en el pie de página del sitio web, el usuario o
							visitante de los mismos acepta que, al navegar por el mismo, consiente expresamente el uso de cookies
							según la descripción que se detalla a continuación, excepto en la medida que haya modificado la
							configuración de su navegador para rechazar la utilización de las mismas.
						</p>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<p>
					<a
						class="btn btn-primary"
						data-bs-toggle="collapse"
						href="#collapsThree"
						role="button"
						aria-expanded="false"
						aria-controls="collapsThree">
						3. TIPO DE COOKIES EMPLEADAS
					</a>
				</p>
				<div id="collapsThree" class="collapse">
					<div class="card card-body">
						<p>
							El Titular de la web utiliza cookies estrictamente necesarias que sirven para facilitar la correcta
							navegación en el sitio Web, así como para asegurar que el contenido de los mismos se carga eficazmente.
							Estas cookies son, además, cookies de sesión es decir que tienen carácter temporal y expiran y se borran
							automáticamente cuando el usuario cierra su navegador.
						</p>
						<p>
							Cookies de Facebook Ireland Lt, que se utilizan para que los visitantes puedan interactuar con el
							contenido de Facebook y que se generan únicamente para los usuarios de dichas redes sociales. Las
							condiciones de utilización de estas cookies y la información recopilada se regula por la política de
							privacidad de la plataforma social correspondiente.
						</p>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<p>
					<a
						class="btn btn-primary"
						data-bs-toggle="collapse"
						href="#collapseFour"
						role="button"
						aria-expanded="false"
						aria-controls="collapseFour">
						4. CÓMO DESACTIVAR O ELIMINAR LAS COOKIES
					</a>
				</p>
				<div id="collapseFour" class="collapse">
					<div class="card card-body">
						<p>
							Las Cookies se pueden desactivar y eliminar siguiendo las siguientes guías en función del navegador
							empleado:
							<br />
							<a
								href="https://support.microsoft.com/es-es/windows/eliminar-y-administrar-cookies-168dab11-0753-043d-7c16-ede5947fc64d#ie=ie-11"
								target="_blank">
								Eliminar y administrar cookies en Internet Explorer
							</a>
							<br />
							<a href="https://support.google.com/chrome/answer/95647?hl=es" target="_blank">
								Borrar, habilitar y administrar cookies en Chrome
							</a>
							<br />
							<a href="https://support.mozilla.org/es/kb/Borrar%20cookies" target="_blank">
								Borrar cookies en Firefox
							</a>
							<br />
							<a href="https://support.apple.com/es-es/HT201265" target="_blank">
								Configuración web de Safari en el iPhone, el iPad y el iPod touch
							</a>
						</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

.
<script>
	export default {};
</script>

<style scoped>
	.superior {
		padding-top: 7rem;
	}

	.titulo {
		color: var(--bs-primary);
		font-size: 1.5rem;
		margin-bottom: 2rem;
		font-weight: 900;
	}

	.btn {
		width: 100%;
		text-align: start;
		padding-left: 2rem;
		background-image: linear-gradient(72deg, #313056 31%, #313056 31%, #30356b 91%);
	}

	.collapse {
		margin-bottom: 1rem;
	}

	@media only screen and (min-width: 962px) {
		.superior {
			padding-top: 15rem;
		}

		.titulo {
			font-size: 2rem;
		}
	}
</style>
