<template>
	<div
		class="icono-ocupado"
		:class="['icono-ocupado', { 'icon-occupant-active': !user.done || allColors, 'thin-border': thinBorder }]">
		{{ getFirstLetter(user.nombre) }}
	</div>
</template>

<script setup>
	defineProps({
		user: {
			type: Object,
			default: () => ({}),
		},
		allColors: {
			type: Boolean,
			default: false,
		},
		thinBorder: {
			type: Boolean,
			default: false,
		},
	});

	function getFirstLetter(name) {
		return name ? name.charAt(0).toUpperCase() : '';
	}
</script>

<style scoped>
	.icono-ocupado {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 68px;
		height: 68px;
		background-color: #ffffff;
		border: 3px solid var(--bs-primary);
		border-radius: 50%;
		color: var(--bs-primary);
		font-size: 30px;
		font-weight: bold;
		box-shadow: 0px 1px 2px 1px #21244459;
	}

	.icon-occupant-active {
		background-color: var(--bs-primary);
		border: 3px solid var(--bs-secondary);
		color: var(--bs-secondary);
	}

	.thin-border {
		border: 1px solid var(--bs-secondary);
	}
</style>
