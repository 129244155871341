import SkiAndNightApiService from './BaseSkiAndNightApiService';

const SEGURIDAD_API_BASEPATH_URL = '';

class LoginApiService {
	login(mail, password) {
		return SkiAndNightApiService.post(SEGURIDAD_API_BASEPATH_URL.concat('/login_check'), {
			username: mail,
			password,
		});
	}

	loginGoogle(token) {
		return SkiAndNightApiService.post(SEGURIDAD_API_BASEPATH_URL.concat('/login_google_check'), null, null, {
			Authorization: `Bearer ${token}`,
		});
	}
}

export default new LoginApiService();
