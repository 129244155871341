import { defineStore } from 'pinia';
import HomeApiService from '../services/api/skiandnight/HomeApiService';

export default defineStore('blogsStore', {
	state: () => ({
		blogs: null,
		blogFiltros: null,
	}),

	actions: {
		async setBlogs() {
			if (!this.blogs) {
				await HomeApiService.getBlog().then(res => (this.blogs = res.reverse()));
			}
		},
		async setFilters() {
			if (!this.blogFiltros) {
				await HomeApiService.getBlogFilters().then(res => (this.blogFiltros = res));
			}
		},
	},
});
