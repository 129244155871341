<template>
	<div>
		<BannerLanding viajes-grupo-catalogo />
		<div v-if="infoLanding" class="container-landing">
			<div class="header-landing row">
				<h1 class="title text-primary col-12">
					{{ infoLanding.titulo }}
				</h1>
				<span class="text-primary description col-12">
					{{ infoLanding.descripcion }}
				</span>
			</div>
			<div v-if="!loading">
				<template v-for="(i, indexC) in infoLanding.filtrosLanding" :key="i.id">
					<div v-if="infomacionCard[indexC]" class="header-tarjetas">
						<h2 class="title text-primary">
							{{ i.titulo }}
						</h2>
						<button class="btn btn-landing text-right text-primary px-4 py-0 desktop" @click="verTodasLasOfertas(i)">
							Ver todas las ofertas
							<a class="fa-solid fa-long-arrow-right fa-md text-primary my-2" />
						</button>
					</div>

					<div v-if="infomacionCard[indexC]" class="contenedor-tarjetas desktop">
						<div class="d-flex flex-row tarjetas w-100 flex-wrap justify-content-evenly justify-content-xl-start">
							<TarjetaLanding
								v-for="x in infomacionCard[indexC].opcionesParaUsuario.slice(0, 4)"
								:key="x.id"
								tipo="alojamiento"
								:informacion="x"
								:filtros="i"
								class="p-2" />
						</div>
						<div class="espacio" />
					</div>
					<div v-if="infomacionCard[indexC]" class="movil">
						<button class="btn btn-landing text-right text-primary px-4 py-0" @click="verTodasLasOfertas(i)">
							Ver todas las ofertas
							<a class="fa-solid fa-long-arrow-right fa-md text-primary my-2" />
						</button>
						<base-carousel
							:items-carousel="infomacionCard[indexC].opcionesParaUsuario.slice(0, 4)"
							:is-loading="!infomacionCard[indexC].opcionesParaUsuario.slice(0, 4)"
							:autoplay-time="2500"
							:max-elements="3">
							<template #itemCarousel="itemCarousel">
								<TarjetaLanding tipo="alojamiento" :informacion="itemCarousel.data" :filtros="i" class="p-2" />
							</template>
						</base-carousel>
					</div>
				</template>
			</div>
			<div v-else class="container-spinner">
				<SpinnerVue />
			</div>
		</div>
	</div>
</template>

<!-- <script>
	import BuscadorApiServices from '@/services/api/skiandnight/BuscadorApiServices';
	import { useRoute, useRouter } from 'vue-router';

	export default {
		beforeCreate: async function () {
			const route = useRoute();
			const metaData = await BuscadorApiServices.getLandingMasivas(
				route.params.uri
			);

			if (metaData['metaTitulo'] && metaData['metaDescripcion']) {
				document.title = metaData.metaTitulo;
				document.querySelector("[name='description']").remove();
				var meta = document.createElement('meta');
				meta.name = 'description';
				meta.content = metaData.metaDescripcion;
				document.getElementsByTagName('head')[0].appendChild(meta);
			}
		}
	};
</script> -->

<script setup>
	import BuscadorApiServices from '@/services/api/skiandnight/BuscadorApiServices';
	import { useRoute, useRouter } from 'vue-router';
	import BannerLanding from '@/components/BannerLanding.vue';
	import BaseCarousel from '@/components/Carousel/BaseCarousel.vue';
	import TarjetaLanding from '@/components/tarjetas/TarjetaLanding.vue';
	import { ref } from 'vue';

	import { fomatDatesHyphen } from '@/helpers/dateUtiles';
	import { Buscador } from '@/router/RouterNames';
	import SpinnerVue from '@/components/SpinnerLoading.vue';

	const router = useRouter();
	const route = useRoute();
	const infomacionCard = ref([]);
	// const uri = route.params.uri;
	const infoLanding = ref();
	const loading = ref(true);

	const uriBuilder = () => {
		return (
			route.params.uri1 +
			(route.params.uri2 != '' ? `/${route.params.uri2}` : '') +
			(route.params.uri3 != '' ? `/${route.params.uri3}` : '')
		);
	};

	BuscadorApiServices.getLandingMasivas(uriBuilder()).then(async res => {
		infoLanding.value = res;

		if (res['metaTitulo'] && res['metaDescripcion']) {
			document.title = res.metaTitulo;
			if (document.querySelector("[name='description']")) {
				document.querySelector("[name='description']").remove();
			}
			var meta = document.createElement('meta');
			meta.name = 'description';
			meta.content = res.metaDescripcion;
			document.getElementsByTagName('head')[0].appendChild(meta);
		}

		loading.value = true;
		const promises = infoLanding.value.filtrosLanding.map(async filtro => {
			const response = await BuscadorApiServices.getHotelesYForfaits({
				forLanding: true,
				showXml: false,
				area: Number(filtro?.area),
				lugarExperiencia: Number(filtro?.lugarExperiencia),
				startDate: fomatDatesHyphen(filtro?.fechaInicio),
				endDate: fomatDatesHyphen(filtro?.fechaFin),
				startDateFF: fomatDatesHyphen(filtro?.fechaInicioFF),
				endDateFF: fomatDatesHyphen(filtro?.fechaFinFF),
				habitaciones: filtro?.habitaciones,
				filter: {},
			});
			return response;
		});

		Promise.all(promises.map(p => p.catch(() => {})))
			.then(responses => {
				infomacionCard.value.push(...responses.filter(r => r !== undefined));
			})
			.catch(error => {
				console.log(error);
			})
			.finally(() => {
				loading.value = false;
			});
	});

	const verTodasLasOfertas = filtrosLanding => {
		let destino = {
			nombre: '',
			areaId: filtrosLanding.area,
			estacionId: filtrosLanding.lugarExperiencia,
		};
		let fechasAlojamiento = [filtrosLanding.fechaInicio, filtrosLanding.fechaFin];
		let fechasForfait = [filtrosLanding.fechaInicioFF, filtrosLanding.fechaFinFF];
		let habitaciones = filtrosLanding.habitaciones;

		router.push({
			name: Buscador,
			query: {
				destino: JSON.stringify(destino),
				fechasAlojamiento: JSON.stringify(fechasAlojamiento),
				fechasForfait: JSON.stringify(fechasForfait),
				habitaciones: JSON.stringify(habitaciones),
			},
		});
	};
</script>

<style lang="scss" scoped>
	.container-landing {
		padding-top: 3rem;
		margin: 0 15rem;

		@media screen and (max-width: 1000px) {
			margin: 0 3rem;
			.header-tarjetas {
				display: flow !important;

				.title {
					font-size: 16px;
					font-style: normal;
					font-weight: 600;
					line-height: normal;
					letter-spacing: 0.154px;
				}

				.btn-landing {
					margin-top: 1rem;
					float: inline-end;
					font-size: 10px;
					font-style: normal;
					font-weight: 600;
					line-height: normal;
					letter-spacing: 0.154px;
				}
			}
		}

		.title {
			font-size: 24px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			letter-spacing: 0.154px;
		}
		.btn-landing {
			border-radius: var(--bs-border-radius-xl);
			border: 1px solid var(--bs-primary);
		}
		.header-tarjetas {
			display: flex;
			justify-content: space-between;
		}
	}
	.contenedor-tarjetas {
		margin: 2rem 0rem;
	}

	.header-landing {
		margin-bottom: 4.5rem;
		.title {
			font-size: 32px;
			font-style: normal;
			font-weight: 600;
			line-height: normal;
			letter-spacing: 0.154px;
			margin-bottom: 1rem;
		}
		.description {
			font-size: 16px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			letter-spacing: 0.154px;
		}
		@media screen and (max-width: 1000px) {
			.title {
				font-size: 20px;
				font-style: normal;
				font-weight: 600;
				line-height: normal;
				letter-spacing: 0.154px;
			}
			.description {
				font-size: 14px;
				font-style: normal;
				font-weight: 400;
				line-height: normal;
				letter-spacing: 0.154px;
			}
			.btn-landing {
				border-radius: var(--bs-border-radius-xl);
				border: 1px solid var(--bs-primary);
			}
		}
	}

	.movil {
		margin-top: 1.5rem;
		margin-bottom: 1.5rem;
	}

	.container-spinner {
		min-height: 80vh;
		display: flex;
		align-items: center;
		justify-content: center;
	}
</style>
