export function getLanguageData(language) {
	switch (language) {
		case 'en':
			return {
				name: 'English',
				code: language,
				icon: 'https://flagicons.lipis.dev/flags/4x3/gb.svg',
			};
		case 'es':
			return {
				name: 'Spanish',
				code: language,
				icon: 'https://flagicons.lipis.dev/flags/4x3/es.svg',
			};
		case 'fr':
			return {
				name: 'Français',
				code: language,
				icon: 'https://flagicons.lipis.dev/flags/4x3/fr.svg',
			};
	}
}

export const availablesLanguages = [
	{ id: 'es', language: 'es-ES' },
	{ id: 'fr', language: 'fr-FR' },
	{ id: 'it', language: 'it-IT' },
	{ id: 'en', language: 'en-GB' },
];

export function firstLetterCapital(word) {
	const arr = word.split(' ');
	for (var i = 0; i < arr.length; i++) {
		arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
	}
	return arr.join(' ');
}
