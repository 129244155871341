/**
 * @type {import('@vue/cli-service').ProjectOptions}
 */

const path = require('path');

module.exports = {
	publicPath: '/',
	assetsDir: 'resources/front/',

	pluginOptions: {
		i18n: {
			locale: 'es',
			fallbackLocale: 'es',
			localeDir: 'locales',
			enableLegacy: false,
			runtimeOnly: false,
			compositionOnly: false,
			fullInstall: true,
		},
	},

	configureWebpack: {
		resolve: {
			fallback: {
				path: require.resolve('path-browserify'),
			},
			alias: {
				'@': path.resolve(__dirname, 'src'),
				'@components': path.resolve(__dirname, 'src/components'),
				src: path.resolve(__dirname, 'src'),
			},
		},
	},
};
