import { defineStore } from 'pinia';
import ViajesGrupoApiService from '@/services/api/skiandnight/ViajesGrupoApiService';
import { getServicioUniqueId } from '@/helpers/serviciosUtils';
import {
	calculaDescuento,
	calculaDuracionViaje,
	calculaPrecioBase,
	calculaPrecioComplementos,
	calculaPrecioServicios,
	calculaPrecioGastos, calculaPrecioForServicioWithCantidad,
} from '@/helpers/viajesGrupos';
import { max, min } from '@popperjs/core/lib/utils/math';
import {ESTADOS_RESERVA, TIPO_TASA} from '@/constants/reservaConstants';
import currency from 'currency.js';
import { toRawDeep } from '@/helpers/reactivityUtils';

export const informacionHabitacion = defineStore('informacionHabitacion', {
	state: () => ({
		reservas: [],
		//@deprecated
		precioBase: 0,
		//@deprecated
		descuento: 0,
		codigoDescuento: null,
		servicios: [],
		loading: true,
		filtros: null,
		infoAlaquiler: {},
		infoClases: {},
		dataAlquiler: [],
		dataClases: [],
		infoAlquilerNulo: {
			nombre: null,
		},
		infoClasesNulo: null,
		informacionGrupo: {},
		informacionAlojamiento: {
			nombre: '',
			estrellas: 0,
			poblacion: '',
			cupos: [
				{
					tipo: {
						nombre: '',
					},
					plazas: 0,
					regimen: {
						nombre: '',
					},
				},
			],
			habitacion: [
				{
					codigo: '',
					id: 0,
					numPlazas: 0,
					numPlazasOcupadas: 0,
					plazas: 0,
					tipo: { id: 0, nombre: '' },
				},
			],
		},
		reservaRecuperada: null,
		tiposGruposViajes: [],
		informacionViaje: {},
	}),
	getters: {
		//todo: Remove safely
		getTotalReserva(state) {
			let sum = currency(0);
			if (state.getResumenReservas) {
				state.getResumenReservas.map(res => {
					if (res.habilitarReserva || res.habilitarReserva == undefined) {
						sum = sum.add(res.suma);
						if (state.informacionAlojamiento) {
							sum = sum.add(state.informacionAlojamiento.incremento).add(state.informacionAlojamiento.habitacion.precioTotal);
						}
					}
				});
			}
			return sum;
		},
		getCodigoDescuento(state) {
			return state.codigoDescuento;
		},
		getViaje(state) {
			return state.informacionViaje;
		},
		getAlojamiento(state) {
			return state.informacionAlojamiento;
		},
		getGrupo(state) {
			return state.informacionGrupo;
		},
		getServicios(state) {
			return state.servicios;
		},
		getReservaById: state => {
			return reservaId => state.reservas.find(({ id }) => id === reservaId);
		},
		getReservaByIdx: state => {
			return reservaIdx => state.reservas[reservaIdx];
		},
		getReservaIdxById: state => {
			return reservaId => state.reservas.findIndex(({ id }) => id === reservaId);
		},
		getPrecioServiciosReserva: state => {
			const getReservaIdxById = this.getReservaIdxById(state);
			return reservaId => {
				let precio = currency(0);
				let reserva = getReservaIdxById(reservaId);
				Object.keys(reserva.serviciosWithCantidad).forEach(key => {
					const servicioWithCantidad = reserva.serviciosWithCantidad[key];
					precio = precio.add(currency(servicioWithCantidad.servicio.precio).multiply(servicioWithCantidad.cantidad));
				});
				return precio;
			};
		},
		calculaPrecioBaseGrupo(state) {
			let precioBase = currency(state.informacionViaje.precioBase);
			if (state.informacionAlojamiento) {
				precioBase = precioBase.add(state.informacionAlojamiento.habitacion.precioTotal)
					.add(state.informacionAlojamiento.incremento);
			}
			return precioBase;
		},

		getFeeTotalPlataforma(state) {
			return state.informacionViaje.repercutirFee ? state.informacionViaje.fee : 0;
		},
		getReservas(state) {
			return state.reservas;
		},
		getResumenReservas(state) {
			if (state.reservas.length === 0) return [];
			return state.reservas.map(reserva => {
				let reservaAux = {
					reservaPropia: false,
					tasasExternasCalculadas: [],
					tasasInternasCalculadas: [],
					usuario: null,
					nombreUsuario: '',
					precioBaseViaje: state.informacionViaje.precioBase,
					precioTotalCupo: state.informacionAlojamiento?.habitacion.precioTotal || 0,
					incrementoHotel: state.informacionAlojamiento?.incremento || 0,

					// todo: Remove safely
					suma: state.precioBase,
					...reserva,
				};
				reservaAux.precioServicios = calculaPrecioServicios(reservaAux);
				reservaAux.precioComplementos = calculaPrecioComplementos(reservaAux);
				reservaAux.precioGastos = calculaPrecioGastos(reservaAux);
				reservaAux.precioBase = calculaPrecioBase(reservaAux);
				reservaAux.precioTotalSinTasas = reservaAux.precioBase.add(reservaAux.precioGastos);
				reservaAux.precioTotal = reservaAux.precioTotalSinTasas;

				if (state.codigoDescuento !== null) {
					const totalPendiente = max(0, reservaAux.precioBase.subtract(reservaAux.totalPagado).value);
					reservaAux.descuento = currency(min(totalPendiente, calculaDescuento(state.codigoDescuento, reservaAux.precioBase).value));
				} else {
					reservaAux.descuento = currency(0);
				}

				// todo -> if the booking is for a child (< 18) this should not be added
				if (state.informacionAlojamiento?.tasaTuristica && state.informacionAlojamiento?.tasaTuristica !== 0) {
					let numDays = calculaDuracionViaje(state.informacionViaje);
					let precioTasaTuristica = currency(state.informacionAlojamiento.tasaTuristica).multiply(numDays);
					reservaAux.precioTotal = reservaAux.precioTotal.add(precioTasaTuristica);
					reservaAux.tasasExternasCalculadas.push({
						importe: precioTasaTuristica,
						codigo: TIPO_TASA.TASA_TURISTICA,
					});
				}

				const tasaTotal = this.getFeeTotalPlataforma;

				if (tasaTotal !== 0) {
					//Tasa gestion plataforma
					let precioTasaPlataforma = 0;
					//SI es de NUEVA CREACION o BLOQUEADA, la tasa se autocalcula
					//SI es MODIFICACION DE RESERVA, se mantiene
					if (!reservaAux.estado || reservaAux.estado === ESTADOS_RESERVA.BLOQUEADA) {
						precioTasaPlataforma = reservaAux.precioBase.multiply(tasaTotal);
					} else {
						const tasaPlataforma = reservaAux.tasasInternas.find(tasa => tasa.codigo === TIPO_TASA.TASA_GESTION_PLATAFORMA);
						precioTasaPlataforma = currency(tasaPlataforma ? tasaPlataforma.importe : 0);
					}
					reservaAux.precioTotal = reservaAux.precioTotal.add(precioTasaPlataforma);
					reservaAux.tasasInternasCalculadas.push({
						importe: precioTasaPlataforma,
						codigo: TIPO_TASA.TASA_GESTION_PLATAFORMA,
					});

					//Tasa gestion cambio
					if (reservaAux.tasasInternas) {
						let sumaTasasCambioBase = reservaAux.tasasInternas
							.filter(tasa => tasa.codigo === TIPO_TASA.TASA_GESTION_CAMBIO)
							.reduce((sum, tasa) => sum.add(tasa.importe), currency(0));
						reservaAux.precioTotal = reservaAux.precioTotal.add(sumaTasasCambioBase);
						if (sumaTasasCambioBase.value > 0) {
							reservaAux.tasasInternasCalculadas.push({
								importe: sumaTasasCambioBase,
								codigo: TIPO_TASA.TASA_GESTION_CAMBIO,
							});
						}
					}

					if (reservaAux.deltaCambioTmp && reservaAux.deltaCambioTmp.value > 0) {
						const tasaCambioExist = reservaAux.tasasInternasCalculadas.find(tasa => tasa.codigo === TIPO_TASA.TASA_GESTION_CAMBIO);
						reservaAux.precioTotal = reservaAux.precioTotal.add(reservaAux.deltaCambioTmp);
						if (tasaCambioExist) {
							tasaCambioExist.importe = tasaCambioExist.importe.add(reservaAux.deltaCambioTmp);
						} else {
							reservaAux.tasasInternasCalculadas.push({
								importe: currency(reservaAux.deltaCambioTmp),
								codigo: TIPO_TASA.TASA_GESTION_CAMBIO,
							});
						}
					}
				}

				// todo: Analyse if this could be removed
				reservaAux.serviciosWithCantidad = Object.keys(reserva.serviciosWithCantidad).map(key => {
					const servicioWithCantidad = reserva.serviciosWithCantidad[key];
					reservaAux.suma = currency(servicioWithCantidad.servicio.precio).multiply(servicioWithCantidad.cantidad).add(reservaAux.suma);
					return servicioWithCantidad;
				});
				return reservaAux;
			});
		},
		getUsuariosReserva(state) {
			return state.reservas.map(reserva => {
				return {
					nombre: reserva.usuario?.nombre || '',
					apellido: reserva.usuario?.apellido || '',
					done: false,
				};
			});
		},
		alquilerMaterialInfo(state) {
			if (state.servicios.filter(ser => ser.categoria === 'alquiler').length > 0) {
				let infoservicios = [];

				let serviciosAux = [];

				state.servicios.filter(ser => ser.categoria === 'alquiler').map(val => infoservicios.push(val.servicios));

				infoservicios.map(data => {
					data.map(val => {
						if (!val.nulo) {
							serviciosAux.push(val);
						} else {
							state.infoAlquilerNulo = val;
						}
					});
				});

				if (state.infoAlaquiler.gama && state.infoAlaquiler.modalidad.id) {
					state.dataAlquiler = serviciosAux.filter(e => {
						if (e.gama.id == state.infoAlaquiler.gama && e.modalidad.id == state.infoAlaquiler.modalidad.id) {
							return e;
						}
					});
				}

				return state.dataAlquiler;
			} else {
				state.dataAlquiler = false;
			}
		},
		clasesInfo(state) {
			if (state.servicios.filter(ser => ser.categoria === 'clases').length > 0) {
				let infoservicios = [];

				let serviciosAux = [];

				state.servicios.filter(ser => ser.categoria === 'clases').map(val => infoservicios.push(val.servicios));

				infoservicios.map(data => {
					data.map(val => {
						if (!val.nulo) {
							serviciosAux.push(val);
						} else {
							state.infoClasesNulo = val;
						}
					});
				});

				if (state.infoClases.nivel && state.infoClases.modalidad) {
					state.dataClases = serviciosAux.filter(e => {
						if (e.gama.id == state.infoClases.nivel && e.modalidad.id == state.infoClases.modalidad.id) {
							return e;
						}
					});
				}

				return state.dataClases;
			} else {
				state.dataClases = false;
			}
		},
		getNombresReserva(state) {
			return state.reservas.map((res, index) => {
				if (res['usuario'] === undefined) {
					return {
						id: res.id,
						nombre: res.usuarioNombre,
						index: index,
					};
				}
				return { id: res.id, nombre: res.usuario.nombre, index: index };
			});
		},
		_necesitaMenorEdad(state) {
			return state.tiposGruposViajes.some(({ nombre }) => {
				if (nombre === 'familias' || nombre === 'colegios') {
					return true;
				} else return false;
			});
		},
	},
	actions: {
		setCodigoDescuento(codigoDescuentoData) {
			this.codigoDescuento = codigoDescuentoData;
		},
		addNotPersistedReserva(reservaData) {
			let data = {
				persisted: false,
				serviciosWithCantidad: {},
				gastos: [],
				complementos: [],
				...reservaData,
			};
			this.reservas.push(data);
		},
		deleteReserva(id) {
			this.reservas.splice(
				this.reservas.findIndex(data => data.id === id),
				1
			);
		},
		async loadInformacionViaje(viajeId, codigoViaje = null) {
			if (
				this.informacionViaje === undefined ||
				Object.keys(this.informacionViaje).length === 0 ||
				this.informacionViaje.id !== viajeId
			) {
				this.loading = true;
				try {
					await Promise.all([
						ViajesGrupoApiService.getViaje(viajeId, codigoViaje).then(res => {
							this.informacionViaje = res;
						}),
						ViajesGrupoApiService.getServicios(viajeId).then(res => {
							this.servicios = res.serviciosCategorizados;
							this.tiposGruposViajes = res.tipos;
						}),
						ViajesGrupoApiService.getFiltroServicios(viajeId).then(res => {
							this.filtros = res;
						}),
					]);
				} finally {
					this.loading = false;
				}
			}
		},
		setInformacionAlojamientoFromVGAlojamientoReservaDTO(vgAlojamientoReservaDTO) {
			this.informacionAlojamiento = {};
			this.informacionAlojamiento.estrellas = vgAlojamientoReservaDTO.estrellas;
			this.informacionAlojamiento.incremento = vgAlojamientoReservaDTO.incremento || 0;
			this.informacionAlojamiento.nombre = vgAlojamientoReservaDTO.nombre;
			this.informacionAlojamiento.poblacion = vgAlojamientoReservaDTO.poblacion;
			this.informacionAlojamiento.precioCamaVacia = vgAlojamientoReservaDTO.precioCamaVacia;
			this.informacionAlojamiento.tasaTuristica = vgAlojamientoReservaDTO.tasaTuristica;
			this.informacionAlojamiento.habitacion = vgAlojamientoReservaDTO.habitacion;
			this.informacionAlojamiento.hasHabitaciones = vgAlojamientoReservaDTO.hasHabitaciones;
			this.informacionAlojamiento.codigoSinHabitacion = vgAlojamientoReservaDTO.codigoSinHabitacion;
		},
		setInformacionAlojamientoFromRGrupoDTO(rGrupoDTO) {
			if (rGrupoDTO.habitacion) {
				this.informacionAlojamiento = {};
				this.informacionAlojamiento.estrellas = rGrupoDTO.habitacion.alojamiento.estrellas;
				this.informacionAlojamiento.incremento = rGrupoDTO.habitacion.alojamiento.incremento || 0;
				this.informacionAlojamiento.nombre = rGrupoDTO.habitacion.alojamiento.nombre;
				this.informacionAlojamiento.poblacion = rGrupoDTO.habitacion.alojamiento.poblacion;
				this.informacionAlojamiento.precioCamaVacia = rGrupoDTO.habitacion.alojamiento.precioCamaVacia;
				this.informacionAlojamiento.tasaTuristica = rGrupoDTO.habitacion.alojamiento.tasaTuristica;
				this.informacionAlojamiento.hasHabitaciones = !rGrupoDTO.habitacion.isComun;
				this.informacionAlojamiento.codigoSinHabitacion = rGrupoDTO.codigo;
				this.informacionAlojamiento.habitacion = {
					tipo: {
						nombre: rGrupoDTO.habitacion.alojamiento.tipo,
					},
					...rGrupoDTO.habitacion,
				};
			} else {
				this.clearInformacionAlojamiento();
			}
		},
		// todo@jorge: evaluar si tiene sentido este metodo o se deberia utilizar otro a pesar de necesitar una llamada extra a la API
		setInformacionAlojamientoFromVGAlojamientoViajeDTO(vgAlojamientoViajeDTO) {
			const cupo = vgAlojamientoViajeDTO.cupos[0];
			this.informacionAlojamiento = vgAlojamientoViajeDTO;
			this.informacionAlojamiento.incremento = vgAlojamientoViajeDTO.incremento || 0;
			this.informacionAlojamiento.hasHabitaciones = cupo.hasHabitaciones;
			this.informacionAlojamiento.habitacion = {
				numPlazas: cupo.plazas,
				numPlazasOcupadas: 0,
				plazasLibresRepercutidas: false,
				ocupantes: [],
				...cupo,
			};
		},
		clearInformacionAlojamiento() {
			this.informacionAlojamiento = null;
		},
		setInformacionGrupoFromVGGrupoDTO(vgGrupoDTO) {
			this.informacionGrupo = vgGrupoDTO;
		},
		clearReservas() {
			this.reservas = [];
		},
		addReserva(reservaDto) {
			let reserva = {
				persisted: true,
				...reservaDto,
			};
			let serviciosByTipo = {};
			reservaDto.serviciosWithCantidad.forEach(swc => {
				serviciosByTipo[getServicioUniqueId(swc.servicio)] = swc;
			});
			reserva.usuario = {
				...reservaDto.usuario,
				// todo-> Use apellidos instead of apellido
				apellido: reservaDto.usuario.apellidos,
			};
			// The servicios needs to be index to avoid duplicates
			reserva.serviciosWithCantidad = serviciosByTipo;
			this.reservas.push(reserva);
		},
		initOldServicios(reservaId) {
			const index = this.reservas.findIndex(reserva => reserva.id === reservaId);
			if (index !== -1) {
				this.reservas[index].oldServiciosWithCantidad = toRawDeep(this.reservas[index].serviciosWithCantidad);
				this.reservas[index].deltaCambioTmp = currency(0);
			}
		},
		//Compara los servicios antiguos con los nuevos y calcula la Tasa de Gestion de Cambio
		updateTasaCambio(idxReserva) {
			const tasaTotal = this.getFeeTotalPlataforma;
			const reserva = this.reservas[idxReserva];
			if (reserva.oldServiciosWithCantidad && reserva.serviciosWithCantidad) {
				let sumatorioCambioNew = currency(0);
				Object.keys(reserva.serviciosWithCantidad).forEach(servicioKey => {
					const newServicioWithCantidad = reserva.serviciosWithCantidad[servicioKey];
					const newServicio = newServicioWithCantidad.servicio;
					const oldServicioWithCantidad = reserva.oldServiciosWithCantidad[servicioKey] || {
						servicio: {id: null, precio: currency(0)},
						cantidad: 0
					};
					const oldServicio = oldServicioWithCantidad.servicio;

					if (newServicio.id === oldServicio.id &&
						newServicioWithCantidad.cantidad === oldServicioWithCantidad.cantidad) {
						reserva.deltaCambioTmp = currency(0);
						return;
					}
					const newPrecio = calculaPrecioForServicioWithCantidad(newServicioWithCantidad);
					const oldPrecio = calculaPrecioForServicioWithCantidad(oldServicioWithCantidad);
					const deltaPrecio = Math.abs(newPrecio.subtract(oldPrecio).value);
					if (deltaPrecio > 0) {
						sumatorioCambioNew = sumatorioCambioNew.add(currency(deltaPrecio).multiply(tasaTotal));
						//todo @ramon -> descomentar cuando se libere la funcionalidad en el back
						//sumatorioCambioNew = sumatorioCambioNew.add(Math.max(0.2, currency(deltaPrecio).multiply(tasaTotal).value));
					}
				});

				if (sumatorioCambioNew.value > 0) {
					reserva.deltaCambioTmp = sumatorioCambioNew;
				}
			}
		},
		addServicioToReserva(idxReserva, servicio, cantidad = 1, isModificacion = false) {
			const reserva = this.reservas[idxReserva];
			reserva.serviciosWithCantidad[getServicioUniqueId(servicio)] = {servicio: servicio, cantidad: cantidad};
			if (isModificacion && reserva.estado !== ESTADOS_RESERVA.BLOQUEADA) {
				this.updateTasaCambio(idxReserva);
			}
		},
	},
});
