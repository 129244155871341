<template>
	<div v-if="imagenes">
		<div class="row">
			<div v-for="(imagen, index) in imagenes" :key="imagen" class="col-12 col-sn-3 col-md-4 p-2">
				<img :src="imagen" class="imagen-show" alt="" @click="showMultiple(index)" />
			</div>
		</div>

		<vue-easy-lightbox :visible="visibleRef" :imgs="imgsRef" :index="indexRef" :loop="true" @hide="onHide">
			<template #toolbar="{}" />
		</vue-easy-lightbox>
	</div>
</template>

<script>
	import VueEasyLightbox from 'vue-easy-lightbox';
	import { ref, defineComponent } from 'vue';

	export default defineComponent({
		components: {
			VueEasyLightbox,
		},
		props: {
			imagenes: {
				type: Array,
				default: () => [],
			},
		},
		setup(props) {
			const visibleRef = ref(false);
			const indexRef = ref(0); // default 0
			const imgsRef = ref(props.imagenes);
			// Img Url , string or Array of string
			// ImgObj { src: '', title: '', alt: '' }
			// 'src' is required
			// allow mixing

			const onShow = () => {
				visibleRef.value = true;
			};
			const showMultiple = index => {
				// imgsRef.value = [
				//   { title: 'test img', src: 'http://via.placeholder.com/350x150' },
				//   'http://via.placeholder.com/350x150'
				// ]
				indexRef.value = index;
				onShow();
			};
			const onHide = () => (visibleRef.value = false);

			return {
				visibleRef,
				indexRef,
				imgsRef,
				showMultiple,
				onHide,
			};
		},
	});
</script>

<style>
	.imagen-show {
		width: 100%;
		height: 100%;
		max-height: 15rem;
		object-fit: cover;
		cursor: pointer;
	}
</style>
