import { createLogger, createStore } from 'vuex';
import createCache from 'vuex-cache';
import { login } from './login/LoginModule';
import { usuario } from './usuario/UsuarioModule';
import { pasarelaPago } from '@/store/pasarelaPago/PasarelaPagoModule';

const debug = process.env.NODE_ENV !== 'production';

export const store = createStore({
	strict: debug,
	plugins: debug ? [createLogger(), createCache()] : [createCache()],
	modules: {
		login,
		usuario,
		pasarelaPago,
	},
});
