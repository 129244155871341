import { defineStore } from 'pinia';

export const agenciaStoreModule = defineStore('agenciaStore', {
	state: () => ({
		nombre: null,
		currency: null,
		telefono: null,
		buscadorForfaitEnabled: true,
		buscadorEnabled: null,
		recursos: {
			avisosLegales: null,
		},
		politicaPrivacidad: {
			id: null,
			politicasPrivacidad: [],
		},
	}),
	getters: {
		getCurrency(state) {
			return state.currency;
		},
		getTelefono(state) {
			return state.telefono;
		},
		getAvisosLegales(state) {
			return state.recursos.avisosLegales;
		},
		getPoliticaPrivacidad(state) {
			return state.politicaPrivacidad;
		},
		getPoliticasPrivacidad(state) {
			return state.politicaPrivacidad.politicasPrivacidad;
		},
	},
	actions: {
		setData(hAgenciaDto) {
			this.nombre = hAgenciaDto.nombre;
			this.currency = hAgenciaDto.currency;
			this.telefono = hAgenciaDto.telefono;
			this.buscadorForfaitEnabled = hAgenciaDto.buscadorForfaitEnabled;
			this.buscadorEnabled = hAgenciaDto.buscadorEnabled;
			this.recursos.avisosLegales = hAgenciaDto.recursos.avisos_legales;
			this.politicaPrivacidad = hAgenciaDto.politicaPrivacidad;
		},
	},
});
