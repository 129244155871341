<template>
	<button :class="'btn ' + buttonType" @click="verPolizaPdf()">
		{{ buttonText }}
	</button>
</template>

<script setup>
	import ViajesGrupoApiService from '@/services/api/skiandnight/ViajesGrupoApiService';
	const props = defineProps({
		buttonType: {
			type: String,
			default: () => 'btn-link',
		},
		buttonText: {
			type: String,
			default: () => 'BOTON ABRIR PDF',
		},
		idServicio: {
			type: String,
			required: true,
			default: () => null,
		},
		idViaje: {
			type: String,
			required: true,
			default: () => null,
		},
	});

	const verPolizaPdf = () => {
		var newWindow = window.open();
		ViajesGrupoApiService.getPolizaSeguroViaje(props.idViaje, props.idServicio).then(res => {
			var file = new Blob([res], { type: 'application/pdf' });
			var fileURL = URL.createObjectURL(file);
			newWindow.location = fileURL;
		});
	};
</script>

<style scoped>
	.btn {
		font-size: 0.9rem;
	}
</style>
