<template>
	<div class="card-servicios__container">
		<div class="card-servicios__usuario">
			<div class="card-servicios__plazas mb-1">
				<span>{{ $t('general.persona') }} {{ indexPersona + 1 }}</span>
				<template v-if="isFirstOne">
					<div class="reserva-propia__container">
						<div class="reserva-propia__checkbox">
							<label :for="'checkboxSoyYo' + indexPersona">
								{{ $t('general.esMiReserva') }}
							</label>
							<input :id="'checkboxSoyYo' + indexPersona" v-model="soyYo" type="checkbox" />
						</div>
					</div>
				</template>
			</div>

			<div class="card-servicios__usuario-info">
				<div class="card-servicios__usuario-texto">
					<template v-if="!soyYo">
						<div class="nombre-usuario">
							<input
								v-model="nombreUsuario.nombre"
								type="text"
								:placeholder="$t('home.form.personalInformation.name')" />
						</div>
						<div class="apellido-usuario">
							<input
								v-model="nombreUsuario.apellido"
								type="text"
								:placeholder="$t('home.form.personalInformation.lastName')" />
						</div>
					</template>
					<template v-else>
						<span class="label-usuario">
							{{ $t('general.nombreCard') }}
						</span>
					</template>

					<div v-if="isUnderAge" class="edad-usuario">
						<div class="checkbox-edad">
							<label> {{ $t('general.esMenorEdad') }} ({{ informacionMiembro.edad }} {{ $t('general.anios') }}) </label>
							<input type="checkbox" disabled checked />
						</div>
					</div>

					<div class="actions-usuario">
						<button v-if="hasServices" class="btn btn-transparent" @click="showServices = !showServices">
							<i class="fa-solid fa-chevron-up" :class="showServices ? 'icon-active' : 'icon-inactive'" />
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>

	<Transition name="slide-up">
		<template v-if="showServices">
			<div class="selector-servicios card mx-0 p-2 p-md-2">
				<select-service-group-viajes-medida
					:index-persona="indexPersona"
					:informacion-miembro="informacionMiembro"
					:id-viaje="idViaje"
					@servicios-persona-actualizado="actualizarServicio($event)" />
			</div>
		</template>
	</Transition>
</template>

<script>
	import { computed, defineComponent, reactive, ref, watch } from 'vue';
	import { useStore } from 'vuex';
	import SelectServiceGroupViajesMedida from '../../components/select/selectService/SelectServiceGroupViajesMedida.vue';
	export default defineComponent({
		components: { SelectServiceGroupViajesMedida },
		props: {
			indexPersona: {
				type: Number,
				default: null,
			},
			informacionMiembro: {
				type: Object,
				default: null,
			},
			indexHabitacion: {
				type: Number,
				default: null,
			},
			idViaje: {
				type: Number,
				default: null,
			},
		},
		emits: ['serviciosPersonaActualizado'],
		setup(props, { emit }) {
			const showServices = ref(props.indexPersona == 0 && props.indexHabitacion == 0);

			const nombreUsuario = reactive({
				nombre: '',
				apellido: '',
			});

			const store = useStore();

			const isUnderAge = props.informacionMiembro.edad < 18;

			const soyYo = ref(props.informacionMiembro.soyYo);

			const isLogged = computed(() => store.state.login.isLogged);

			let serviciosPersona = {};

			serviciosPersona['persona' + props.indexPersona] = {};

			const actualizarServicio = $event => {
				emit('serviciosPersonaActualizado', $event);
			};

			const isFirstOne = props.indexPersona == 0 && props.indexHabitacion == 0;

			watch(nombreUsuario, () => {
				serviciosPersona['persona' + props.indexPersona][
					'nombre'
				] = `${nombreUsuario.nombre} ${nombreUsuario.apellido}`;
				emit('serviciosPersonaActualizado', serviciosPersona);
			});

			const setearInformacionUsuario = () => {
				if (isFirstOne) {
					if (store.state.login.isLogged) {
						if (props.indexHabitacion == 0 && props.indexPersona == 0) {
							serviciosPersona['persona' + props.indexPersona]['nombre'] = `${store.state.login.usuario.nombre} ${
								store.state.login.usuario.apellidos.split(' ')[0]
							}`;
							serviciosPersona['persona' + props.indexPersona]['soyYo'] = soyYo.value;
							emit('serviciosPersonaActualizado', serviciosPersona);
						}
					}
				}
			};

			watch(isLogged, () => {
				if (isFirstOne) {
					if (isLogged.value) {
						serviciosPersona['persona' + props.indexPersona]['nombre'] = `${store.state.login.usuario.nombre} ${
							store.state.login.usuario.apellidos.split(' ')[0]
						}`;
						serviciosPersona['persona' + props.indexPersona]['soyYo'] = soyYo.value;
						emit('serviciosPersonaActualizado', serviciosPersona);
					} else {
						serviciosPersona['persona' + props.indexPersona]['nombre'] = ' ';
					}
					emit('serviciosPersonaActualizado', serviciosPersona);
				}
			});

			setearInformacionUsuario();

			watch(soyYo, () => {
				if (soyYo.value) {
					setearInformacionUsuario();
				} else {
					serviciosPersona['persona' + props.indexPersona]['nombre'] = ' ';
				}

				serviciosPersona['persona' + props.indexPersona]['soyYo'] = soyYo.value;
				emit('serviciosPersonaActualizado', serviciosPersona);
			});

			const hasServices = computed(() => {
				return true;
			});

			return {
				showServices,
				actualizarServicio,
				nombreUsuario,
				soyYo,
				isFirstOne,
				isUnderAge,
				isLogged,
				hasServices,
			};
		},
	});
</script>

<style lang="scss" scoped>
	@import '~@/assets/scss/san.scss';

	.card-servicios {
		&__container {
			@extend .bg-primary,
				.text-white,
				.w-100,
				.row,
				.py-4,
				.px-2,
				.d-flex,
				.align-items-center,
				.mx-auto,
				.mb-2,
				.justify-content-center;
			border-radius: var(--bs-border-radius-xl);
			border: 1px solid rgba(var(--bs-primary-rgb), 0.25);
			background: linear-gradient(41deg, var(--bs-primary) -21.45%, #696969 144.52%, #a3a3a3 144.52%);
			box-shadow: 0 1px 2px 1px rgba(var(--bs-primary-rgb), 0.35);

			@include media-breakpoint-down(md) {
				font-size: 13px;
			}
		}

		&__usuario {
			@extend .row, .justify-content-between, .d-flex, .flex-column, .flex-lg-row;

			&-info {
				@extend .col-12;
			}

			&-texto {
				@extend .row, .justify-content-between, .d-flex, .flex-row;

				.nombre-usuario,
				.apellido-usuario {
					@extend .col-12, .col-md-5, .mt-2;

					input {
						@extend .form-control;
					}
				}

				.edad-usuario {
					place-self: center;
					@extend .col-12, .col-6, .mt-2;

					.checkbox-edad {
						@extend .form-check, .p-0, .border-0, .d-flex, .justify-content-end, .align-items-center;

						label {
							@extend .form-check-label;
						}

						input {
							@extend .form-check-input, .ms-2;
							border-radius: var(--bs-border-radius-sm) !important;
						}
					}
				}

				.label-usuario {
					@extend .col, .align-middle;
					flex: 4;
				}

				.actions-usuario {
					@extend .col, .col-sm-1, .d-flex, .justify-content-end, .mt-2;

					button {
						@extend .btn, .btn-primary, .bg-transparent, .border-0;
					}
				}
			}
		}

		&__plazas {
			@extend .col, .col-md-3, .col-lg-2, .d-flex, .w-100;

			.reserva-propia {
				&__container {
					@extend .col, .justify-content-end;
				}

				&__checkbox {
					@extend .form-check, .p-0, .border-0, .d-flex, .justify-content-end;

					label {
						@extend .form-check-label;
					}

					input {
						@extend .form-check-input, .ms-2;
						border-radius: var(--bs-border-radius-sm) !important;
					}
				}
			}
		}
	}

	.selector-servicios {
		border-radius: var(--bs-border-radius-xl);
	}

	.icon-inactive {
		transition: 0.3s;
	}

	.icon-active {
		transform: rotate(180deg);
		transition: 0.3s;
	}

	.slide-up-enter-active,
	.slide-up-leave-active {
		transition: all 0.3s ease-out;
	}

	.slide-up-enter-from {
		opacity: 0;
		transform: translateY(30px);
	}

	.slide-up-leave-to {
		opacity: 0;
		transform: translateY(-30px);
	}
	.btn-transparent {
		box-shadow: none !important;
	}

	.form-check-input:checked {
		background-color: var(--bs-secondary);
		border-color: var(--bs-secondary);
	}
</style>
