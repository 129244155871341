<template>
	<!-- Sección cabecera del apres ski -->
	<template v-if="apresSki">
		<div
			class="col-12 d-flex flex-row align-items-center justify-content-around apres-ski-img-fondo"
			:style="`background-image: linear-gradient(rgba(255,255,255,0.5), rgba(255,255,255,0.5)), url(${apresSki.imagenPrincipal})`">
			<img :src="apresSki.logo" alt="" class="d-flex logo-apres-ski align-items-center justify-content-center" />
		</div>

		<div class="apres-skis-detalle">
			<!-- Sección imágenes secundarias del apres ski -->
			<carousel
				:items-to-show="pantalla_grade ? 5.75 : 4.5"
				wrap-around
				:autoplay="4000"
				class="d-none d-sm-block imagenes-secundarias-apres-ski">
				<slide v-for="imagenesSecundaria in apresSki.imagenesSecundarias" :key="imagenesSecundaria">
					<div class="img-carousel" :style="`background-image: url(${imagenesSecundaria})`" />
				</slide>
			</carousel>

			<div class="container datos-apres-ski my-4 my-sm-5">
				<div class="d-sm-flex align-items-center justify-content-between">
					<div class="d-flex align-items-center">
						<p class="titulo mb-0">
							{{ capitalize(apresSki.nombre) }}
						</p>

						<button
							class="btn btn-link d-none d-sm-flex align-items-center"
							data-bs-toggle="modal"
							data-bs-target="#mapaModalApresSki">
							<i class="fa-solid fa-location-pin" />
							<span class="mx-1">{{ $t('alojamiento.verMapa') }}</span>
						</button>
					</div>

					<p v-if="movil" class="ubicacion-apres-ski">
						{{ apresSki.ubicacion }}
					</p>

					<div class="d-flex justify-content-between align-items-center">
						<button
							class="btn btn-link d-flex align-items-center d-sm-none"
							data-bs-toggle="modal"
							data-bs-target="#mapaModalApresSki">
							<i class="fa-solid fa-location-pin" />
							<span class="mx-1">{{ $t('alojamiento.verMapa') }}</span>
						</button>

						<div
							v-if="!loading"
							id="mapaModalApresSki"
							class="modal fade"
							tabindex="-1"
							aria-labelledby="mapaModalApresSkiLabel"
							aria-hidden="true">
							<div class="modal-dialog">
								<div class="modal-content">
									<div class="modal-header">
										<h5 id="mapaModalApresSkiLabel" class="modal-title">Ubicación</h5>
										<button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" />
									</div>
									<div class="modal-body">
										<GMapMap :center="posicion" :zoom="8" map-type-id="terrain" style="min-height: 60vh">
											<GMapMarker :position="posicion" :clickable="true" :draggable="false" />
										</GMapMap>
									</div>
								</div>
							</div>
						</div>

						<div>
							<button type="button" class="btn btn-link" data-bs-toggle="modal" data-bs-target="#modalFotos">
								{{ $t('alojamiento.verFoto') }}
							</button>
							<div
								id="modalFotos"
								class="modal fade"
								tabindex="-1"
								aria-labelledby="modalFotosLabel"
								aria-hidden="true">
								<div class="modal-dialog">
									<div class="modal-content">
										<div class="modal-body">
											<div class="row">
												<LightBoxPhotos :imagenes="apresSki.imagenesSecundarias" />
											</div>
										</div>
										<div class="modal-footer">
											<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">
												{{ $t('general.cerrar') }}
											</button>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>

				<p v-if="!movil" class="ubicacion-apres-ski">
					{{ apresSki.ubicacion }}
				</p>

				<!-- Sección datos del apres ski -->
				<div class="row gx-0">
					<div class="datos-basicos-apres-ski col-12 col-sm-6">
						<!-- <p class="titulo-descripcion">{{$t('alojamiento.horariosCondiciones')}}</p> -->
						<div class="row d-flex my-3 card-check ms-0 me-0 me-sm-5 align-items-center">
							<div class="col-6">
								<div class="row gx-0 ms-0 align-items-center">
									<div class="col-3">
										<img class="iconos-condiciones" src="@/assets/icons/reloj.webp" alt="" />
									</div>
									<div class="col-9">
										<p class="m-0 fw-bold">Horario</p>
										<span>{{ apresSki.horario }}</span>
									</div>
								</div>
							</div>

							<div class="col-6">
								<div class="row gx-0 align-items-center">
									<div class="col-3">
										<img class="iconos-condiciones" src="@/assets/icons/entradas.webp" alt="" />
									</div>
									<div class="col-9">
										<p class="m-0 fw-bold">Entrada</p>
										<span>{{ apresSki.precio }}</span>
									</div>
								</div>
							</div>
						</div>
						<div class="me-0 me-sm-5 mt-5 mb-5">
							<iframe width="100%" :height="movil ? 250 : 350" :src="apresSki.video" style="border-radius: var(--bs-border-radius-xxl)" />
						</div>
					</div>

					<div class="d-flex flex-column col-12 col-sm-6">
						<p class="titulo-descripcion">{{ $t('reserva.descripcion') }} del après ski</p>
						<p id="descripcion" class="text-muted descripcion-apres-ski" :class="mostrarMas ? '' : 'informacion'">
							{{ apresSki.descripcion }}
						</p>
						<button v-if="!mostrarMas" type="button" class="btn btn-link text-start" @click="mostrarMas = !mostrarMas">
							{{ $t('general.mostrarMas') }}
						</button>
						<button v-else type="button" class="btn btn-link text-start" @click="mostrarMas = !mostrarMas">
							{{ $t('general.mostrarMenos') }}
						</button>
					</div>
				</div>
			</div>

			<base-carousel
				v-if="apresSkisEstacion"
				:items-carousel="apresSkisEstacion"
				:is-loading="loadingApresSkis"
				:autoplay-time="2600"
				:max-elements="3"
				class="mx-auto px-2"
				style="max-width: 1440px">
				<template #title>
					<div class="d-flex justify-content-between align-items-center">
						<p class="fs-3 text-primary fw-bold mb-4">Las mejores ofertas en {{ apresSki.lugarExperiencia }}</p>
					</div>
				</template>
				<template #itemCarousel="itemCarouselProps">
					<TarjetaHomeMejoresApresSkis :apres-ski="itemCarouselProps.data" />
				</template>
			</base-carousel>
		</div>
	</template>
	<template v-else>
		<Spinner />
	</template>
</template>

<script>
	import HomeApiService from 'src/services/api/skiandnight/HomeApiService';
	import { useRoute } from 'vue-router';

	export default {
		beforeCreate: async function () {
			const currentRoute = await useRoute();
			const metaData = await HomeApiService.getApresSkiByIdSEO(currentRoute.params.apresSkiId);
			if (metaData['titulo'] && metaData['descripcion']) {
				document.title = metaData.titulo;
				document.querySelector("[name='description']").remove();
				var meta = document.createElement('meta');
				meta.name = 'description';
				meta.content = metaData.descripcion;
				document.getElementsByTagName('head')[0].appendChild(meta);
			}
		},
	};
</script>

<script setup>
	import Spinner from '@/components/SpinnerLoading.vue';
	import { Carousel, Slide } from 'vue3-carousel';
	import TarjetaHomeMejoresApresSkis from '@/components/tarjetas/TarjetaHomeMejoresApresSkis.vue';
	import { ref, computed } from 'vue';
	import { apresSkisStore } from '@/store_pinia/apresSkis';
	import BaseCarousel from '@/components/Carousel/BaseCarousel.vue';
	import LightBoxPhotos from '@/components/utils/lightBoxPhotos.vue';
	import { capitalize } from '@/helpers/filters';
	// import { useRoute } from "vue-router";
	const apresSkis = ref();
	const apresSki = ref();
	const loadingApresSkis = ref(true);
	const mostrarMas = ref(false);
	const store_pinia_apresSkis = apresSkisStore();
	const route = useRoute();

	const apresSkiId = route.params.apresSkiId;

	store_pinia_apresSkis.setApresSkis().then(() => {
		apresSkis.value = store_pinia_apresSkis.apresSkis;
		loadingApresSkis.value = false;
	});
	store_pinia_apresSkis.setApresSki(apresSkiId).then(() => {
		apresSki.value = store_pinia_apresSkis.apresSki;
	});
	document.addEventListener('DOMContentLoaded', function () {
		console.log(document.getElementById('descripcion').style.lineHeight);
	});

	const pantalla_grade = computed(() => {
		return window.screen.availWidth > 1536;
	});

	const movil = computed(() => {
		return window.screen.availWidth <= 575;
	});

	const posicion = computed(() => {
		return {
			lat: apresSki.value.latitud,
			lng: apresSki.value.longitud,
		};
	});

	const apresSkisEstacion = computed(() => {
		return apresSkis.value.filter(obj => {
			return obj.id != apresSki.value.id && obj.lugarExperiencia == apresSki.value.lugarExperiencia;
		});
	});
</script>

<style lang="scss" scoped>
	.apres-skis-detalle {
		background-color: white;
		min-height: 80vh;
		background-size: contain;
		background-position: top;
		color: var(--bs-primary);
		font-weight: 400;
		font-size: 0.8rem;
	}
	.apres-ski-img-fondo {
		min-height: 65vh;
		background-size: cover;
		background-position: center;
	}

	@media (max-width: 575px) {
		.apres-ski-img-fondo {
			min-height: 35vh;
		}
		.logo-apres-ski {
			max-width: 75%;
			margin-top: 5rem;
		}
	}

	.imagenes-secundarias-apres-ski {
		margin-top: -340px;
	}

	.img-carousel {
		border-radius: var(--bs-border-radius-xxl);
		width: 300px;
		height: 200px;
		background-size: cover;
		background-position: center;
	}

	.titulo-descripcion {
		font-weight: 700;
		font-size: 1.2rem;
		opacity: 0.7;
	}

	.informacion {
		overflow: hidden;
		text-overflow: ellipsis;
		display: -webkit-box;
		-webkit-line-clamp: 6; /* number of lines to show */
		line-clamp: 6;
		-webkit-box-orient: vertical;
	}

	.descripcion-apres-ski {
		font-size: 0.95rem;
	}

	.ubicacion-apres-ski {
		font-size: 0.95rem;
	}
	.titulo {
		font-size: 1.6rem;
		font-weight: 700;
	}

	.subtitulo {
		font-size: 1rem;
		font-weight: 700;
		margin-bottom: 0.5rem;
	}

	.otros-datos {
		font-weight: 500;
		font-size: 0.95rem;
	}

	.card-check {
		background-color: #f9f7f8;
		padding: 1.3rem 0.5rem;
		border-radius: var(--bs-border-radius-xl);
		box-shadow: 0 4px 2px -2px gray;
	}

	.iconos-condiciones {
		width: 75%;
	}

	@media screen and (max-width: 992px) {
		.text-container {
			display: flex;
			flex-direction: column;

			.left,
			.right {
				width: 100%;
			}
		}
	}

	@media (min-width: 576px) {
		//a partir de sm
		.apres-skis-detalle {
			padding-top: 12rem;
		}
		.logo-apres-ski {
			height: 100px;
		}
		.iconos-condiciones {
			width: 50%;
		}
	}

	@media (min-width: 1537px) {
		.logo-apres-ski {
			height: 150px;
		}
	}
</style>
../../../services/api/skiandnight/HomeApiService
