<template>
	<div>
		<div class="container-buscador">
			<p class="mb-0 p-0">
				<span class="fw-bold">{{ $t('searcher.destiny.title') }}:</span>
				{{ valoresFiltroBuscadorParaTi.destino.nombre }}
			</p>
			<p class="mb-0 p-0">
				<span class="fw-bold">{{ $t('alojamiento.fechaAlojamiento') }} :</span>
				{{ formatArrayDates(valoresFiltroBuscadorParaTi.fechasAlojamiento) }}
			</p>
			<p v-if="!landing && agenciaStore.buscadorForfaitEnabled" class="mb-0 p-0">
				<span class="fw-bold"> {{ $t('searcher.datesForfait.subtitle') }} : </span>
				{{ formatArrayDates(valoresFiltroBuscadorParaTi.fechasForfait) }}
			</p>
			<p class="mb-0 p-0">
				<span class="fw-bold"
					>{{
						$t(valoresFiltroBuscadorParaTi.habitaciones.length > 1 ? 'general.habitaciones' : 'general.habitacion')
					}}:</span
				>
				<span v-sanitize-html="getCantidadPersonasHTML(valoresFiltroBuscadorParaTi.habitaciones)" />
				<span>
					{{ valoresFiltroBuscadorParaTi.habitaciones.length }}
					{{
						valoresFiltroBuscadorParaTi.habitaciones.length > 1
							? $t('alojamiento.habitaciones.plural')
							: $t('alojamiento.habitaciones.singular')
					}}
				</span>
			</p>
		</div>
	</div>
</template>

<script>
	import { defineComponent, computed } from 'vue';
	import { useI18n } from 'vue-i18n';
	import { buscadorStore } from '@/store_pinia/buscador';
	import { agenciaStoreModule } from '@/store_pinia/agencia';
	import { useRoute } from 'vue-router';

	export default defineComponent({
		setup() {
			const { t } = useI18n();
			const store_buscador = buscadorStore();
			const agenciaStore = agenciaStoreModule();
			const route = useRoute();

			const landing = route.path === '/';
			const valoresFiltroBuscadorParaTi = computed(() => store_buscador.filtrosSeleccionadosBuscador);

			function getDayName(dateStr, locale) {
				var date = new Date(dateStr);
				return date.toLocaleDateString(locale, { weekday: 'long' });
			}

			const formatArrayDates = listaDates => {
				//   let nameList = ["lu", "ma", "mi", "ju", "vi", "sa", "do"];
				let monthList = ['ene', 'feb', 'mar', 'abr', 'may', 'jun', 'jul', 'ago', 'set', 'oct', 'nov', 'dic'];
				let formatDates = '';
				listaDates.forEach((date, index) => {
					let dateAux = `${getDayName(date, 'es-Es')}, ${new Date(date).getDate()} ${
						monthList[new Date(date).getMonth()]
					}`;

					if (listaDates.length - 1 == index) {
						formatDates += ` -  ${dateAux}`;
					} else {
						formatDates += dateAux;
					}
				});

				return formatDates;
			};

			const getCantidadPersonasHTML = listaHabitaciones => {
				let cantidadAdultos = 0;
				let cantidadNinos = 0;
				listaHabitaciones.forEach(({ habitacion }) => {
					habitacion.forEach(edad => {
						if (edad > 17) {
							cantidadAdultos++;
						} else {
							cantidadNinos++;
						}
					});
				});

				if (cantidadNinos > 0) {
					return `<span> ${cantidadAdultos} ${t(
						`general.personas.${cantidadAdultos === 1 ? 'singular.adulto' : 'plural.adulto'}`
					)} y ${cantidadNinos} ${t(
						`general.personas.${cantidadNinos === 1 ? 'singular.nino' : 'plural.nino'}`
					)}, </span>`;
				}

				return `<span> ${cantidadAdultos} ${t(
					`general.personas.${cantidadAdultos === 1 ? 'singular.adulto' : 'plural.adulto'}`
				)}, </span>`;
			};

			return {
				valoresFiltroBuscadorParaTi,
				formatArrayDates,
				getCantidadPersonasHTML,
				landing,
				agenciaStore,
			};
		},
	});
</script>

<style scoped>
	.container-buscador {
		font-size: 11px;
		border-radius: var(--bs-border-radius-lg);
	}
</style>
