import SkiAndNightApiService from './BaseSkiAndNightApiService';

const USUARIO_API_VERSION = 'v1/';
const USUARIO_API_BASEPATH_URL = '/rooming/' + USUARIO_API_VERSION;

class RoomingApiService {
	async getRoomingList() {
		return await SkiAndNightApiService.get(USUARIO_API_BASEPATH_URL);
	}

	async getRoomingByID(id) {
		return await SkiAndNightApiService.get(USUARIO_API_BASEPATH_URL.concat(`${id}`));
	}

	async saveRooming(id, data) {
		return await SkiAndNightApiService.post(USUARIO_API_BASEPATH_URL.concat(`${id}`), data);
	}

	async publishRooming(id) {
		return await SkiAndNightApiService.patch(USUARIO_API_BASEPATH_URL.concat(`${id}`));
	}
}

export default new RoomingApiService();
