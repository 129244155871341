import { appGlobalStore } from '@/store_pinia/app';
import { Home } from '@/router/RouterNames';

export const redireccionRutas = (to, next) => {
	const appStore = appGlobalStore();
	if (appStore.permisos) {
		const { blog, nosotros, home } = appStore.permisos;
		if (
			shouldBeRedirectedByRoutePrefix(to, blog.routes_prefix, blog.showed) ||
			shouldBeRedirectedByRoutePrefix(to, nosotros.routes_prefix, nosotros.showed)
		) {
			redirectToHome(to, next);
		} else if (shouldBeRedirectedByPathName(to, 'home', home.showed)) {
			redirectToPath(home.url_redirect, to, next);
		} else {
			next();
		}
	}
};

const redirectToHome = (to, next) => {
	next({ name: Home, query: { redirect: to.path } });
};

// const redirectToCatalogoViajes = (to, next) => {
// 	next({ name: ViajesGrupoCatalogo, query: { redirect: to.path } });
// };

const redirectToPath = (path, to, next) => {
	if (!path.startsWith('/')) {
		path = '/' + path;
	}
	next({ path: path, query: { redirect: to.path } });
};

const shouldBeRedirectedByRoutePrefix = (currentPath, routePrefixToCheck, canBeShowed) => {
	return currentPath.fullPath.startsWith(routePrefixToCheck) && !canBeShowed;
};

const shouldBeRedirectedByPathName = (currentPath, pathNameToCheck, canBeShowed) => {
	return currentPath.name === pathNameToCheck && !canBeShowed;
};
