<template>
	<div v-if="showUnirse" class="d-flex align-items-center container-codigo-viaje">
		<div class="url-unirse">{{ urlUnirse }}</div>
		<div class="boton-copiar pointer" @click="copyClipboard(urlUnirse)">
			<div class="text-copiar">
				{{ $t('botones.copiar') }}
				<svg class="url-icon" width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
					<g clip-path="url(#clip0_467_27523)">
						<path
							d="M10.6654 0.666504H2.66536C1.93203 0.666504 1.33203 1.2665 1.33203 1.99984V11.3332H2.66536V1.99984H10.6654V0.666504ZM12.6654 3.33317H5.33203C4.5987 3.33317 3.9987 3.93317 3.9987 4.6665V13.9998C3.9987 14.7332 4.5987 15.3332 5.33203 15.3332H12.6654C13.3987 15.3332 13.9987 14.7332 13.9987 13.9998V4.6665C13.9987 3.93317 13.3987 3.33317 12.6654 3.33317ZM12.6654 13.9998H5.33203V4.6665H12.6654V13.9998Z"
							fill="var(--bs-primary)" />
					</g>
					<defs>
						<clipPath id="clip0_467_27523">
							<rect width="16" height="16" fill="white" />
						</clipPath>
					</defs>
				</svg>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { computed } from 'vue';
	import { createToaster } from '@meforma/vue-toaster';
	import { isViajeDisponible } from '@/services/utils/ViajeUtils';

	const props = defineProps({
		viaje: {
			type: Object,
			required: true,
		},
		codigoGrupo: {
			type: String,
			required: true,
		},
	});

	const toaster = createToaster({ position: 'top-right' });

	const showUnirse = computed(() => isViajeDisponible(props.viaje));

	const urlUnirse = computed(() => {
		const dominioActual = window.location.origin;
		let url = `${dominioActual}/grupos/viajes/${props.viaje.id}/unirse/${props.codigoGrupo}`;
		return props.viaje.codigo ? url + `?codigo=${props.viaje.codigo.toUpperCase()}` : url;
	});

	const copyClipboard = () => {
		navigator.clipboard
			.writeText(urlUnirse.value)
			.then(() => {
				toaster.success('URL copiada al portapapeles');
			})
			.catch(() => {
				toaster.error('Error al copiar la URL');
			});
	};
</script>

<style lang="scss" scoped>
	.container-codigo-viaje {
		@media screen and (max-width: 900px) {
			font-size: 8px;
			height: 51px;
			width: 335px;
		}
		background: #ffffff;
		width: 500px;
		margin: 1rem auto;
		box-shadow: inset 0px 0px 3px rgba(33, 36, 68, 0.35);
		border-radius: var(--bs-border-radius-xxl);
		padding: 10px 15px 10px 15px;
		white-space: nowrap;
	}

	.url-unirse {
		@media screen and (max-width: 900px) {
			font-size: 14px;
			margin-right: 5px;
		}
		color: var(--bs-primary);
		font-size: 16px;
		margin-right: 7px;
		overflow: hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		opacity: 0.7;
	}

	.url-icon {
		@media screen and (max-width: 900px) {
			margin-top: -3px;
		}
		fill: var(--bs-primary);
		margin-left: 5px;
		margin-top: -3px;
	}

	.boton-copiar {
		@media screen and (max-width: 900px) {
			padding: 7px 18px;
		}
		padding: 10px 24px;
		background: var(--bs-secondary);
		box-shadow: 0px 1px 2px rgba(33, 36, 68, 0.35);
		border-radius: var(--bs-border-radius-xxl);
		&:hover {
			transition: transform 0.3s;
			transform: scale(1.05);
		}
	}

	.text-copiar {
		@media screen and (max-width: 900px) {
			font-size: 13px;
		}
		font-size: 16px;
		font-weight: 500;
		white-space: nowrap;
	}
</style>
