<template>
	<template v-if="storeGlobal.isSeaAndNightUrl">
		<div>
			<BannerTopVideoPage is-home class="position-relative" />
			<div>
				<CardsViajesSelector :grupos-viajes="tiposViajesGrupo" class="mt-5 mb-3" />
				<MejoresExperienciasCarousel :experiencias-list="experiencias.festivales" />

				<PorqueReservar2 />
			</div>
		</div>
	</template>
	<template v-else>
		<div>
			<BannerTopPage>
				<div class="home-view banner-home row container text-white"></div>
			</BannerTopPage>

			<div>
				<FestivalCollection />
				<BuscadorSectionHome />
				<PorqueReservar />

				<!-- SE OCULTAN EN NUEVA HOME /> -->
				<!-- <CardsViajesGrupo :grupos-viajes="tiposViajesGrupo" />
				<MejoresOfertasCarousel :viajes-destacados-list="viajesDestacados" />
				<EmbedYoutube />
				<ApresSkiCarousel :apres-skis-list="apresSkis" />
				<Sponsors :sponsors="sponsorsList" /> -->
			</div>
		</div>
	</template>
</template>

<script setup>
	import { computed, onMounted } from 'vue';
	import { useRoute } from 'vue-router';
	import FestivalCollection from '@/components/home/AgrupacionesExperienciasCollection.vue';
	import BuscadorSectionHome from '@/components/home/BuscadorSectionHome.vue';
	import Cookies from 'js-cookie';
	import viajesGrupoStoreModule from '@/store_pinia/viajes/viajesGrupo';
	import { festivalesStore } from '@/store_pinia/festivales';
	import { appGlobalStore } from '@/store_pinia/app';
	import BannerTopPage from '@components/BannerTopPage.vue';
	import BannerTopVideoPage from '@components/BannerTopVideoPage.vue';
	import MejoresExperienciasCarousel from '@components/Carousel/MejoresExperienciasCarousel.vue';
	import CardsViajesSelector from '@components/home/CardsViajesSelector.vue';
	import PorqueReservar from '@components/misc/PorqueReservar.vue';
	import PorqueReservar2 from '@components/misc/PorqueReservar2.vue';
	import UsuarioApiService from '@/services/api/skiandnight/UsuarioApiService';

	const route = useRoute();
	const storeGlobal = appGlobalStore();
	const viajesGrupoStore = viajesGrupoStoreModule();
	const experiencias = festivalesStore();
	const tiposViajesGrupo = computed(() => viajesGrupoStore.getTiposViajesGrupo);

	onMounted(() => {
		if (route.query.comercialId) {
			UsuarioApiService.addAccesoLink(route.query.comercialId);
			Cookies.set('comercialId', route.query.comercialId, { expires: 2 });
		}
	});
</script>

<style>
	.home-view .banner-home {
		max-width: 800px;
		margin: auto;
		padding-top: 56px;
	}

	.home-view .banner-home__title {
		margin-bottom: 1rem;
		font-size: 40px !important;
	}
</style>
