<template>
	<div>
		<div class="blog_banner">
			<div class="blog_banner-container">
				<div class="blog_banner-text">
					<h1 class="blog_banner__title">Blog</h1>
					<p class="blog_banner__description">
						En nuestro blog encontrarás los mejores planes en la nieve, consejos para esquiar, los destinos más
						apropiados según tu tipo de viaje y todo lo necesario para convertirte en un experto en nieve.
					</p>
				</div>
				<div v-if="blogFilters" class="blog_banner-buttons">
					<div class="row">
						<!-- d-flex flex-column flex-md-row -->
						<div class="col-12 col-md-8">
							<div class="d-md-flex justify-content-center justify-content-md-start">
								<div v-if="blogFilters.lugaresExperiencia.length > 0" class="dropdown">
									<button
										class="btn btn-secondary bg-white text-primary dropdown-toggle border-0 shadow-lg"
										role="button"
										data-bs-toggle="dropdown"
										aria-expanded="false">
										Estaciones
									</button>
									<ul class="dropdown-menu">
										<li
											v-for="lugarExperiencia in blogFilters.lugaresExperiencia"
											:key="lugarExperiencia.id"
											@click="dropdownFilterEstaciones = lugarExperiencia">
											<div class="dropdown-item">
												{{ lugarExperiencia.nombre }}
											</div>
										</li>
									</ul>
								</div>
								<div v-if="blogFilters.categorias.length > 0" class="dropdown ms-md-3">
									<button
										class="btn btn-secondary bg-white text-primary dropdown-toggle border-0 shadow-lg"
										role="button"
										data-bs-toggle="dropdown"
										aria-expanded="false">
										Categorías
									</button>
									<ul class="dropdown-menu">
										<li v-for="categoria in blogFilters.categorias" :key="categoria.id">
											<div class="dropdown-item" @click="dropdownFilterCategorias = categoria">
												{{ categoria.nombre }}
											</div>
										</li>
									</ul>
								</div>
								<div v-if="blogFilters.etiquetas.length > 0" class="dropdown ms-md-3">
									<button
										class="btn btn-secondary bg-white text-primary dropdown-toggle border-0 shadow-lg"
										role="button"
										data-bs-toggle="dropdown"
										aria-expanded="false">
										Etiquetas
									</button>
									<ul class="dropdown-menu">
										<li v-for="categoria in blogFilters.etiquetas" :key="categoria.id">
											<div class="dropdown-item" @click="dropdownFilterEtiquetas = categoria">
												{{ categoria.nombre }}
											</div>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div class="col-12 col-md-4">
							<div class="input-search-label">
								<span class="icon search-icon">@</span>
								<span class="icon attention-icon px-4 desktop">
									<img class="px-2" src="~@/assets/icons/search.svg" alt="" />
								</span>
								<input
									v-model="buscadorBlog"
									type="text"
									class="form-control input"
									placeholder="Busca por palabra"
									aria-describedby="basic-addon1"
									style="border-radius: var(--bs-border-radius-xl)" />
							</div>
						</div>
					</div>
					<div class="d-flex justify-content-start">
						<button
							v-if="dropdownFilterCategorias"
							class="btn btn-primary me-3"
							@click="dropdownFilterCategorias = null">
							{{ dropdownFilterCategorias.nombre }}
						</button>
						<button v-if="dropdownFilterEstaciones" class="btn btn-primary" @click="dropdownFilterEstaciones = null">
							{{ dropdownFilterEstaciones.nombre }}
						</button>
						<button v-if="dropdownFilterEtiquetas" class="btn btn-primary" @click="dropdownFilterEtiquetas = null">
							{{ dropdownFilterEtiquetas.nombre }}
						</button>
					</div>
				</div>
			</div>
		</div>
		<div v-if="!listaBlogs" class="loading-container">
			<SpinnerVue />
		</div>
		<div v-else class="landing-container__post row px-4 pt-4 justify-content-between">
			<div v-if="buscadorBlog" class="text-muted">{{ filteredBlogs.length }} resultados para la búsqueda</div>
			<landingPost v-for="blog in filteredBlogs" :key="blog.id" class="mt-5 col-md-5 col-lg-4" :data-card="blog" />
		</div>
		<div class="text-center my-4" @click="$router.push({ name: 'BlogLanding' })">
			<div class="btn btn-primary">Volver arriba</div>
		</div>
	</div>
</template>
<script>
	import landingPost from 'src/components/card/blog/BlogPostCard.vue';
	import SpinnerVue from 'src/components/SpinnerLoading.vue';
	import BlogStore from '@/store_pinia/blogs';
	import { ref, computed } from 'vue';
	export default {
		components: { landingPost, SpinnerVue },
		setup() {
			const blogStore = BlogStore();

			const listaBlogs = computed(() => blogStore.blogs);
			const loading = ref(true);
			const buscadorBlog = ref(null);
			const blogFilters = computed(() => blogStore.blogFiltros);
			const dropdownFilterEstaciones = ref(null);
			const dropdownFilterCategorias = ref(null);
			const dropdownFilterEtiquetas = ref(null);

			const filteredBlogs = computed(() => {
				if ((buscadorBlog.value == null || buscadorBlog.value == '') && dropdownFilterEstaciones.value) {
					let aux = [];
					listaBlogs.value.map(({ lugaresExperiencia }, index1) => {
						lugaresExperiencia.map(lugarExperiencia => {
							if (lugarExperiencia == dropdownFilterEstaciones.value.nombre) {
								aux.push(listaBlogs.value[index1]);
							}
						});
					});

					return aux;
				}

				if ((buscadorBlog.value == null || buscadorBlog.value == '') && dropdownFilterEtiquetas.value) {
					let aux = [];
					listaBlogs.value.map(({ etiquetas }, index1) => {
						etiquetas.map(lugarExperiencia => {
							if (lugarExperiencia == dropdownFilterEtiquetas.value.nombre) {
								aux.push(listaBlogs.value[index1]);
							}
						});
					});
					return aux;
				}

				if ((buscadorBlog.value == null || buscadorBlog.value == '') && dropdownFilterCategorias.value) {
					let aux = [];
					listaBlogs.value.map(({ categorias }, index1) => {
						categorias.map(lugarExperiencia => {
							if (lugarExperiencia == dropdownFilterCategorias.value.nombre) {
								aux.push(listaBlogs.value[index1]);
							}
						});
					});
					return aux;
				}

				if (buscadorBlog.value == null || buscadorBlog.value == '') {
					return listaBlogs.value;
				}

				return listaBlogs.value.filter(
					({ titulo, resumen, categorias, lugaresExperiencia, etiquetas }) =>
						titulo.toLowerCase().includes(buscadorBlog.value.toLowerCase()) ||
						resumen.toLowerCase().includes(buscadorBlog.value.toLowerCase()) ||
						categorias.some(text => text.toLowerCase().includes(buscadorBlog.value.toLowerCase())) ||
						lugaresExperiencia.some(text => text.toLowerCase().includes(buscadorBlog.value.toLowerCase())) ||
						etiquetas.some(text => text.toLowerCase().includes(buscadorBlog.value.toLowerCase()))
				);
			});

			blogStore.setBlogs();

			blogStore.setFilters();

			return {
				loading,
				buscadorBlog,
				filteredBlogs,
				blogFilters,
				dropdownFilterEstaciones,
				dropdownFilterEtiquetas,
				dropdownFilterCategorias,
				listaBlogs,
				blogStore,
			};
		},
	};
</script>

<style lang="scss" scoped>
	.landing-container__post {
		max-width: 1440px;
		margin: auto;
	}

	.loading-container {
		min-height: 80vh;
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.blog_banner {
		background-image: url('~@/assets/fotos/telesilla-banner.webp');
		background-size: cover;
		background-repeat: no-repeat;
		background-position: 0% 50%;
		margin: auto;
		min-height: 550px;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;

		&-container {
			max-width: 1440px;
			padding: 0 3rem;
			margin: 0 auto;
		}

		&__title {
			color: var(--bs-primary);
			font-size: 56px;
			font-style: normal;
			font-weight: 700;
			line-height: normal;
			letter-spacing: 0.309px;
			margin-bottom: 40px;
		}

		&__description {
			color: var(--bs-primary);
			font-size: 21px;
			font-style: normal;
			font-weight: 400;
			line-height: normal;
			letter-spacing: 0.18px;
		}

		&-buttons {
			margin-bottom: 2rem;
		}
	}

	@media screen and (max-width: 900px) {
		.blog_banner {
			&-container {
				padding: 0 1rem;
			}
			&__title {
				font-size: 36px;
				margin-bottom: 30px;
			}

			&__description {
				font-size: 14px;
			}
		}
	}

	@media screen and (max-width: 600px) {
		.blog_banner {
			&-container {
				.btn-secondary,
				.form-control {
					font-size: 12px;
				}
			}
		}
		.btn-primary {
			font-size: 14px;
		}
	}

	.error-icon,
	.search-icon {
		visibility: hidden;
	}

	.input-search-label {
		position: relative;
	}
	.input-search-label .icon {
		position: absolute;
		top: 10px;
		cursor: pointer;
	}

	.input-search-label .label {
		background-color: #ffffff;
		position: absolute;
		top: -9px;
		left: 14px;
		margin-bottom: 0;
		padding: 0 4px;
		margin-left: -3px;
		font-size: 12px;
		font-weight: 600;
	}
	.input-search-label .input.error ~ .error-icon {
		visibility: visible;
	}

	.search-icon + .error-icon {
		left: 34px;
	}

	.form-control {
		padding: 0.625rem 0.875rem;
	}
</style>
./BlogLanding.vue
