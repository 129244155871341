<template>
	<div class="row-occupancy" :class="{ 'justify-content-center text-center align-items-center': embeddedDisplay }">
		<div
			v-for="(user, index) in showedOccupants"
			:key="`user-${index}`"
			class="d-flex flex-column align-items-center text-center occupancy-item"
			:class="{
				'small-icon-occupancy': embeddedDisplay,
				'occupancy-card col-md-4 col-4': embeddedDisplay,
				'occupancy col': !embeddedDisplay,
			}">
			<div
				v-if="user.isFree"
				class="d-flex flex-column text-center pb-2"
				:class="{
					'small-icon-occupancy': embeddedDisplay,
					'occupancy-card col-md-4 col-4': embeddedDisplay,
					occupancy: !embeddedDisplay,
					pointer: enablePointer,
				}"
				@click="handleAddReserva">
				<IconFree :plazas-libres-repercutidas="plazasLibresRepercutidas" />
				<div class="nombre-occupant text-nowrap mx-2" :class="{ 'small-text': embeddedDisplay }">
					{{ $t('reserva.visualizacionOcupantes.plaza') }} <br />
					{{ $t('reserva.visualizacionOcupantes.libre') }}
				</div>
			</div>
			<template v-else>
				<IconOccupied :user="user" :all-colors="allColors" />
				<div class="nombre-occupant text-nowrap mx-2" :class="{ 'small-text': embeddedDisplay }">
					{{ truncateText(user.nombre) }} <br />
					{{ truncateText(user.apellido) }}
				</div>
				<div v-if="user.id" class="nombre-occupant text-nowrap mx-2" :class="{ 'small-text': embeddedDisplay }">
					(#{{ user.id }})
				</div>
			</template>
		</div>
		<div
			v-if="!embeddedDisplay && props.occupantsInfo.length > 5"
			class="d-flex flex-column align-items-center text-center occupancy"
			:class="{ col: true }"
			@click="toggleCollapse()">
			<button class="btn bg-transparent border-0">
				<i class="fa-solid fa-chevron-up" :class="isCollapsed ? 'icon-active' : 'icon-inactive'" />
			</button>
		</div>

		<transition
			name="collapse"
			class="row-occupancy"
			:class="{ 'justify-content-center text-center align-items-center': embeddedDisplay }">
			<div v-show="!isCollapsed">
				<div
					v-for="(user, index) in collapsedOccupants"
					:key="`user-${index}`"
					class="d-flex flex-column align-items-center text-center occupancy-item m-0"
					:class="{
						'small-icon-occupancy': embeddedDisplay,
						'occupancy-card col-md-4 col-4': embeddedDisplay,
						'occupancy col-md-1 col': !embeddedDisplay,
					}">
					<div
						v-if="user.isFree"
						class="d-flex flex-column text-center pb-2"
						:class="{ pointer: enablePointer }"
						@click="handleAddReserva">
						<IconFree :plazas-libres-repercutidas="plazasLibresRepercutidas" />
						<div class="nombre-occupant text-nowrap mx-2" :class="{ 'small-text': embeddedDisplay }">
							{{ $t('reserva.visualizacionOcupantes.plaza') }} <br />
							{{ $t('reserva.visualizacionOcupantes.libre') }}
						</div>
					</div>
					<template v-else>
						<IconOccupied :user="user" :all-colors="allColors" />
						<div class="nombre-occupant text-nowrap mx-2" :class="{ 'small-text': embeddedDisplay }">
							{{ truncateText(user.nombre) }} <br />
							{{ truncateText(user.apellido) }}
						</div>
						<div v-if="user.id" class="nombre-occupant text-nowrap mx-2" :class="{ 'small-text': embeddedDisplay }">
							(#{{ user.id }})
						</div>
					</template>
				</div>
			</div>
		</transition>
	</div>
</template>

<script setup>
	import { useI18n } from 'vue-i18n';
	import { computed, ref } from 'vue';

	import IconFree from 'src/components/Icons/IconFree.vue';
	import IconOccupied from 'src/components/Icons/IconOccupied.vue';

	const emit = defineEmits(['addReserva']);

	const { t } = useI18n();
	const props = defineProps({
		totalOccupancies: {
			type: Number,
			default: -1,
			required: false,
		},
		occupantsInfo: {
			type: Array,
			default: () => [],
		},
		allColors: {
			type: Boolean,
			default: false,
		},
		plazasLibresRepercutidas: {
			type: Boolean,
			default: false,
		},
		embeddedDisplay: {
			type: Boolean,
			default: false,
		},
		enablePointer: {
			type: Boolean,
			default: false,
		},
	});

	const isCollapsed = ref(true);

	const hasHabs = computed(() => props.totalOccupancies >= 0);

	const showedOccupants = computed(() => {
		if (props.occupantsInfo.length <= 5) {
			return allOccupants.value;
		} else {
			return allOccupants.value.slice(0, 4);
		}
	});

	const collapsedOccupants = computed(() => allOccupants.value.slice(4, allOccupants.value.length));

	const managedOccupants = computed(() => {
		return props.occupantsInfo.map((user, index) => ({
			nombre: user?.nombre || (hasHabs.value ? t('reserva.visualizacionOcupantes.persona') : t('input.field.nombre')),
			apellido:
				user?.apellido || (hasHabs.value ? `${index + 1}/${props.totalOccupancies}` : t('input.field.apellido')),
			done: user?.done,
			isFree: user?.nombre === t('reserva.visualizacionOcupantes.persona'),
			id: user?.id || '',
		}));
	});

	const nonManagedOccupants = computed(() => {
		if (hasHabs.value) {
			const remaining = props.totalOccupancies - managedOccupants.value.length;
			return Array.from({ length: remaining }, () => ({
				nombre: '',
				apellido: '',
				done: false,
				isFree: true,
				freeOccupancy: true,
			}));
		} else {
			return [];
		}
	});

	const allOccupants = computed(() => {
		return [...managedOccupants.value, ...nonManagedOccupants.value];
	});

	function truncateText(text, maxLength = 8) {
		const formattedText = text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
		return formattedText.length > maxLength ? `${formattedText.substring(0, maxLength)}...` : formattedText;
	}

	function handleAddReserva() {
		emit('addReserva');
	}

	function toggleCollapse() {
		isCollapsed.value = !isCollapsed.value;
	}
</script>
<style lang="scss" scoped>
	@import '~@/assets/scss/san.scss';

	.row-occupancy {
		padding: 5px 3px 0 3px;
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		max-width: 900px;
		overflow: hidden;
		@include media-breakpoint-down(lg) {
			padding-bottom: 10px;
		}
	}

	@include media-breakpoint-down(md) {
		.icono-ocupado,
		.icon-occupant-active,
		.icono-libre {
			width: 50px !important;
			height: 50px !important;
			font-size: 12px;
			place-self: center;
		}

		.occupancy {
			width: 20%;
			margin: 0 !important;
			padding: 5px;
		}
	}
	.occupancy {
		@extend .d-flex, .justify-content-center, .align-items-center, .text-center;
		width: 12.5%;
		@include media-breakpoint-down(md) {
			width: 100%;
			font-size: 12px;
			margin-right: 10px;
		}
		font-size: 18px;
		margin: 0.5rem;
	}

	.occupancy:nth-child(n + 9) {
		display: none;
	}

	.nombre-occupant {
		padding-top: 5px;
		color: var(--bs-primary);
		font-size: 14px;
		font-weight: 400;

		@include media-breakpoint-down(lg) {
			font-size: 10px;
		}
	}
	.icon-inactive {
		transition: 0.3s;
	}

	.icon-active {
		transform: rotate(180deg);
		transition: 0.3s;
	}
	.small-icon-occupancy {
		.icono-ocupado,
		.icon-occupant-active,
		.icono-libre {
			width: 40px !important;
			height: 40px !important;
			font-size: 12px;
			place-self: center;
		}
	}

	.small-text {
		font-size: 12px;
		place-self: center;
	}

	.text-center {
		text-align: -webkit-center !important;
	}
</style>
