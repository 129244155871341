export const ESTADOS_ROOMING = {
	NUEVO: 'NUEVO',
	EN_EDICION: 'EN_EDICION',
	PUBLICADO: 'PUBLICADO',
};

const green = {
	textColor: '#008E0E',
	bgColor: '#92FF9C',
};

const yellow = {
	textColor: '#8E6601',
	bgColor: '#FFE03C',
};

export const ESTADOS_ROOMING_CLASS = {
	EN_EDICION: yellow,
	PUBLICADO: green,
};

export function getClassEstadoRooming(estado) {
	const estadoData = ESTADOS_ROOMING_CLASS[estado];
	if (estadoData) {
		return {
			textColor: `${estadoData.textColor} !important`,
			bgColor: `${estadoData.bgColor} !important`,
		};
	}
	return {};
}
