// Registro
export const REGISTRO_REQUEST = 'REGISTRO_REQUEST';
export const REGISTRO_SUCCESS = 'REGISTRO_SUCCESS';
export const REGISTRO_FAILURE = 'REGISTRO_FAILURE';
// activarUsuario
export const ACTIVACION_REQUEST = 'ACTIVACION_REQUEST';
export const ACTIVACION_SUCCESS = 'ACTIVACION_SUCCESS';
export const ACTIVACION_FAILURE = 'ACTIVACION_FAILURE';
// recordarPassword
export const RECORDAR_PASSWORD_REQUEST = 'RECORDAR_PASSWORD_REQUEST';
export const RECORDAR_PASSWORD_SUCCESS = 'RECORDAR_PASSWORD_SUCCESS';
export const RECORDAR_PASSWORD_FAILURE = 'RECORDAR_PASSWORD_FAILURE';
// reestablecerPassword
export const REESTABLECER_PASSWORD_REQUEST = 'REESTABLECER_PASSWORD_REQUEST';
export const REESTABLECER_PASSWORD_SUCCESS = 'REESTABLECER_PASSWORD_SUCCESS';
export const REESTABLECER_PASSWORD_FAILURE = 'REESTABLECER_PASSWORD_FAILURE';
// modificarDatosUsuario
export const MODIFICAR_DATOS_USUARIO_REQUEST = 'MODIFICAR_DATOS_USUARIO_REQUEST';
export const MODIFICAR_DATOS_USUARIO_SUCCESS = 'MODIFICAR_DATOS_USUARIO_SUCCESS';
export const MODIFICAR_DATOS_USUARIO_FAILURE = 'MODIFICAR_DATOS_USUARIO_FAILURE';
// obtenerDatosUsuario
export const OBTENER_DATOS_USUARIO_REQUEST = 'OBTENER_DATOS_USUARIO_REQUEST';
export const OBTENER_DATOS_USUARIO_SUCCESS = 'OBTENER_DATOS_USUARIO_SUCCESS';
export const OBTENER_DATOS_USUARIO_FAILURE = 'OBTENER_DATOS_USUARIO_FAILURE';
// modificarPassword
export const MODIFICAR_PASSWORD_REQUEST = 'MODIFICAR_PASSWORD_REQUEST';
export const MODIFICAR_PASSWORD_SUCCESS = 'MODIFICAR_PASSWORD_SUCCESS';
export const MODIFICAR_PASSWORD_FAILURE = 'MODIFICAR_PASSWORD_FAILURE';
// doBajaUsuario
export const BAJA_USUARIO_REQUEST = 'BAJA_USUARIO_REQUEST';
export const BAJA_USUARIO_SUCCESS = 'BAJA_USUARIO_SUCCESS';
export const BAJA_USUARIO_FAILURE = 'BAJA_USUARIO_FAILURE';
// getPaisesOpts
export const OBTENER_PAISES_USUARIO_REQUEST = 'OBTENER_PAISES_USUARIO_REQUEST';
export const OBTENER_PAISES_USUARIO_SUCCESS = 'OBTENER_PAISES_USUARIO_SUCCESS';
export const OBTENER_PAISES_USUARIO_FAILURE = 'OBTENER_PAISES_USUARIO_FAILURE';
