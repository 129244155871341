<template>
	<div :class="fromFlujoReserva ? ['border-container', 'card'] : []">
		<div v-if="fromFlujoReserva" class="d-flex mb-2">
			<span
				class="text-primary fw-bold d-flex align-items-center"
				style="cursor: pointer"
				@click="$emit('volverALogin')">
				<i class="fa-solid fa-chevron-left me-2 d-inline"></i>
			</span>
			<span v-show="fromFlujoReserva" class="text-primary text-start title title-size">
				{{ $t('header.login.restablecerPassword') }}
			</span>
		</div>
		<input
			v-model="email"
			class="form-control w-100 text-start fs-6 text-12 mt-2"
			type="email"
			:placeholder="$t('home.form.personalInformation.email')" />

		<div v-if="formError" class="col-12 error">
			{{ formError }}
		</div>
		<div v-if="usuarioApiErrorMessage" class="col-12 error">
			{{ usuarioApiErrorMessage }}
		</div>

		<div class="mt-3 text-center">
			<button type="button" class="btn btn-primary w-100 btn-reset fs-6 m-auto" @click="botonRecordar">
				<span class="fw-semibold">{{ $t('botones.recordar') }}</span>
			</button>
		</div>
	</div>
</template>

<script setup>
	import { ref, computed, watch } from 'vue';
	import { useStore } from 'vuex';
	import * as AlertUtility from '@/services/utils/AlertUtils';
	import { useRouter } from 'vue-router';

	// Props definición con defineProps
	const props = defineProps({
		mailReset: {
			type: String,
			required: false,
			default: '',
		},
		fromLogin: {
			type: Boolean,
			required: false,
			default: true,
		},
		fromFlujoReserva: {
			type: Boolean,
			required: false,
			default: false,
		},
	});

	const emits = defineEmits(['volverALogin', 'passwordReset']);

	const store = useStore();
	const router = useRouter();

	const email = ref(props.mailReset);

	const formError = ref(null);

	const solicitudRecordarSuccess = computed(() => store.state.usuario.solicitudRecordarSuccess);
	const usuarioApiErrorMessage = computed(() => store.getters['usuario/getErrorMessage']);

	function validarCorreo(email) {
		const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
		return emailRegex.test(email);
	}

	function checkForm() {
		if (!email.value) {
			formError.value = 'Rellene todos los campos';
		} else if (!validarCorreo(email.value)) {
			formError.value = 'Introduzca una dirección de correo electrónico válida';
		} else {
			formError.value = null;
			return true;
		}
		return false;
	}

	async function botonRecordar() {
		if (checkForm()) {
			await store.dispatch('usuario/recordarPassword', email.value);
			emits('volverALogin');
		}
	}

	watch(solicitudRecordarSuccess, newValue => {
		if (newValue) {
			const text = 'Para restablecer la cuenta tienes que acceder al link que hemos enviado a tu correo electrónico';
			if (props.fromLogin) {
				emits('passwordReset');
				AlertUtility.alertSuccessWithCallback(text, () => router.push({ name: 'home' }));
			} else {
				AlertUtility.alertSuccessWithCallback(text, () => emits('volverALogin'));
			}
		}
	});
</script>

<style lang="scss" scoped>
	.container-login {
		border-radius: var(--bs-border-radius-xl);
		border: 1px solid rgba(33, 36, 68, 0.25);

		.title {
			font-size: 20px;
			font-weight: 500;
			letter-spacing: 0.309px;
		}

		.title-field {
			font-size: 14px;
			font-weight: 400;
		}
	}

	.titulo {
		font-size: 1.2em;
		font-weight: bold;
	}

	.fondo {
		background: linear-gradient(rgba(255, 255, 255, 0.7), rgba(255, 255, 255, 1)),
			url('~@/assets/fotos/bola-cristal-background.webp');
		padding-top: 10rem;
		width: 100%;
		background-size: cover;
		background-repeat: no-repeat;
	}

	.cuadro {
		padding-top: 2rem;
		padding-bottom: 2rem;
		border-radius: var(--bs-border-radius-xl);
		max-width: 30rem;
		margin: auto;
		background-image: linear-gradient(78deg, var(--bs-primary), #454a79);
		color: white;
	}

	input {
		width: 80%;
		padding: 0.4rem 1rem 0.4rem 1rem;
		font-size: 0.8rem;
		border-radius: var(--bs-border-radius-lg);
		margin-bottom: 1rem;
		color: var(--bs-primary);
	}

	// .btn {
	// 	background-color: white;
	// 	border-radius: var(--bs-border-radius-xl);
	// 	padding: 0.4rem 2rem 0.4rem 2rem;
	// 	font-size: 0.8rem;
	// 	box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
	// }

	.close {
		background-color: transparent;
		box-shadow: 0 0 0 0;
		border: 0;
		right: 10px;
		padding: 0.5rem;
		transition: 0.3s ease-in-out;
	}

	.close:hover {
		background-color: transparent;
		transform: scale(1.5);
	}

	.wrapper {
		padding-bottom: 10rem;
	}

	.error {
		font-size: 0.6rem;
		color: red;
		margin-bottom: 0.5rem;
	}

	.margin-bottom-small {
		margin-bottom: 1rem;
	}

	.btn-reset {
		box-shadow: 0 1px 2px 0 rgba(var(--bs-primary-rgb), 0.35) !important;
		@media only screen and (max-width: 768px) {
			font-size: 14px;
		}
	}

	.text-12 {
		@media only screen and (max-width: 768px) {
			font-size: 12px;
		}
	}

	.border-container {
		border-radius: var(--bs-border-radius-xl);
		padding: 1rem;
	}
</style>
