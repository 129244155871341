<template>
	<div class="container-background">
		<div class="container-thank text-primary bg-red">
			<h2 class="fw-bold">¡{{ $t('reserva.graciasReserva') }}!</h2>
			<p class="text-center fs-5 fw-bold">Bienvenido a {{ paramsRoute.nombreFestival }}</p>
			<p class="fs-5">
				{{ $t('reserva.mensajeExperiencia') }}
				<br />
				{{ $t('reserva.msjAnadirExtras') }}
			</p>
			<div class="d-flex justify-content-around mt-3">
				<button class="btn btn-secondary" @click="redirigir()">
					{{ $t('reserva.misReservas') }}
				</button>

				<!-- <button class="btn btn-secondary">Anadir extras</button> -->
			</div>
		</div>
	</div>
</template>

<script>
	import { useRoute, useRouter } from 'vue-router';
	export default {
		setup() {
			const route = useRoute().query;
			const router = useRouter();
			const paramsRoute = useRoute().params;

			return {
				route,
				redirigir() {
					router.push({ name: 'UsuarioProximasReservas' });
				},
				paramsRoute,
			};
		},
	};
</script>

<style lang="scss" scoped>
	.container-background {
		background-image: url('src/assets/fotos/fondo-thank.webp');
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}
	.container-thank {
		max-width: 1200px;
		margin: auto;
		text-align: center;
		padding: 240px 0 100px 0;
		min-height: 600px;
	}

	.codigo-viaje {
		background-color: #51515157;
		width: fit-content;
		margin: 1rem auto;
		border-radius: var(--bs-border-radius-lg);
	}
	.envio-mail {
		max-width: 450px;
		margin: auto;
		border-radius: var(--bs-border-radius-lg);

		& > .form-control {
			max-width: 85%;
		}
	}
</style>
