<template>
	<div class="tarjeta-reserva-alojamiento col-12 col-sm-10 col-md-5 col-lg-4 col-xl-3 col-xxl-2 ms-3">
		<div class="container text-center tarjeta-not-found py-4">
			<div class="row">
				<img class="img-not-found" src="../../assets/img/not_found_ilustracion.webp" alt="" />
			</div>
			<div class="d-flex flex-column justify-content-around info-not-found p-md-3 mt-1">
				<div class="title-no">
					{{ title }}
				</div>

				<div class="subtitle-no">
					<i18n-t keypath="reserva.mensajeCompraExp" tag="div" class="text-primary pt-2">
						<template #aqui>
							<a type="button" class="text-secondary" @click="goCatalogo">{{ $t('general.aqui') }}</a>
						</template>
					</i18n-t>
				</div>
			</div>
		</div>
	</div>
</template>
<script setup>
	import { useRouter } from 'vue-router';
	import { ViajesGrupoCatalogo } from 'src/router/RouterNames';

	defineProps({
		title: {
			type: String,
			default: null,
		},
	});
	const router = useRouter();

	function goCatalogo() {
		router.push({
			name: ViajesGrupoCatalogo,
		});
	}
</script>

<style lang="css" scoped>
	.tarjeta-reserva-alojamiento {
		min-width: 17.5rem;
		border-radius: var(--bs-border-radius-xl);
		background-color: #f9f7f8;
		box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.5);
	}

	.tarjeta-not-found {
		height: 100%;
	}

	.info-not-found {
		height: 40%;
	}

	.title-no {
		font-size: 1.1rem;
		font-weight: 700;
	}

	.subtitle-no {
		font-size: 1rem;
		font-weight: 400;
		padding-bottom: 1rem;
	}
</style>
