import SkiAndNightApiService from './BaseSkiAndNightApiService';
const BUSCADOR_API_VERSION = 'v1';
const BUSCADOR_API_BASEPATH_URL = '/buscador_temporal/' + BUSCADOR_API_VERSION;

class BuscadorApiServices {
	async getNuestrosViajes(informacionNuestrosViajes) {
		return await SkiAndNightApiService.post(BUSCADOR_API_BASEPATH_URL.concat('/obtenerNuestrosViajes'), {
			...informacionNuestrosViajes,
		});
	}

	async getHotelesYForfaits(filtrosBuscador) {
		return await SkiAndNightApiService.post(
			BUSCADOR_API_BASEPATH_URL.concat('/obtenerHotelesYForfaits'),
			filtrosBuscador
		);
	}

	async getHotelesYForfaitsCambioPagina(cache, pageChange, paginatorState) {
		return await SkiAndNightApiService.post(
			BUSCADOR_API_BASEPATH_URL.concat(`/obtenerHotelesYForfaits/${cache}/cambia_pagina/${pageChange}`),
			{
				paginatorState: paginatorState,
			}
		);
	}

	async getHotelesYForfaitsCambioSizePagina(cache, pageSize, paginatorState, filtro) {
		return await SkiAndNightApiService.post(
			BUSCADOR_API_BASEPATH_URL.concat(`/obtenerHotelesYForfaits/${cache}/cambia_size_pagina/${pageSize}`),
			{
				paginatorState: paginatorState,
				nombres: filtro.nombres.length == 0 ? null : filtro.nombres,
				tipos: filtro.tipos
					.map(({ nombre, selected }) => {
						if (selected) {
							return nombre.toUpperCase();
						}
					})
					.filter(data => data != null),
				poblaciones: filtro.poblaciones
					.map(({ id, selected }) => {
						if (selected) {
							return id;
						}
					})
					.filter(data => data != null),
				minPrecio: filtro.precios[0],
				maxPrecio: filtro.precios[1],
				minDistancia: filtro.distanciasPistas[0] / 1000,
				maxDistancia: filtro.distanciasPistas[1] / 1000,
			}
		);
	}

	async getFiltros() {
		return await SkiAndNightApiService.get(BUSCADOR_API_BASEPATH_URL.concat('/filtros'));
	}

	async getServiciosHotel(filtrosBuscador) {
		return await SkiAndNightApiService.post(BUSCADOR_API_BASEPATH_URL.concat('/obtenerServicios'), filtrosBuscador);
	}

	async createReserva(informacionReserva) {
		return await SkiAndNightApiService.post(BUSCADOR_API_BASEPATH_URL.concat('/reservar'), informacionReserva);
	}

	_buildReserva(reservaHotelHabitacion, informacionCombinacion, viajeId, echoToken, tipoCancelacion) {
		let bodyReservar = {
			tipoCancelacion,
			echoToken,
			viajeId: viajeId,
			habitaciones: [],
		};

		let informacionCombinacionTotalAux = informacionCombinacion;

		Object.keys(reservaHotelHabitacion).map((habitacionNombre, indexHabitacion) => {
			let informacionCombinacionAux = informacionCombinacionTotalAux[indexHabitacion];
			let bodyHabitacion = {
				...informacionCombinacionAux,
				precios: { ...informacionCombinacionAux.precios },
				rph: Number(informacionCombinacionAux.rph),
				hotelRef: Number(informacionCombinacionAux.hotelRef),
				miembrosHabitacion: [],
			};
			delete bodyHabitacion.precioCupo;
			delete bodyHabitacion.invblockcode;
			Object.keys(reservaHotelHabitacion[habitacionNombre]).map(nombrePersona => {
				let informacionPersona = reservaHotelHabitacion[habitacionNombre][nombrePersona];
				let bodyPersona = {
					reservaPropia: informacionPersona.soyYo,
					edad: informacionPersona.edad,
					nombre: String(informacionPersona.nombre).split(' ')[0],
					apellidos: String(informacionPersona.nombre).split(' ')[1],
					servicios: [],
				};
				Object.keys(reservaHotelHabitacion[habitacionNombre][nombrePersona]).map(key => {
					if (key !== 'edad' && key != 'soyYo' && key !== 'nombre') {
						bodyPersona.servicios.push(reservaHotelHabitacion[habitacionNombre][nombrePersona][key].id);
					}
				});
				bodyHabitacion.miembrosHabitacion.push(bodyPersona);
			});
			bodyReservar.habitaciones.push(bodyHabitacion);
		});
		return bodyReservar;
	}

	//AQUI SERVICES LANDING

	async getLandingMasivas(uri) {
		return await SkiAndNightApiService.get(BUSCADOR_API_BASEPATH_URL.concat(`/landingMasivas?uri=${uri}`));
		// BUSCADOR_API_BASEPATH_URL.concat(`/landingMasivas?uri=${uri}`));k
	}
}

export default new BuscadorApiServices();
