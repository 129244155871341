export const sanitizeHtml = {
	beforeMount(el, binding) {
		const html = binding.value;
		if (!html) return;

		const parser = new DOMParser();
		const doc = parser.parseFromString(html, 'text/html');

		const scripts = doc.querySelectorAll('script');
		scripts.forEach(script => script.remove());

		el.innerHTML = doc.body.innerHTML;
	},
};
