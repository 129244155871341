<template>
	<div class="perfil-container">
		<div v-if="!navMobile" class="py-2">
			<a class="text-primary" @click="menuMobile('volver')">
				<span> &lt; {{ $t('subtitulo.volverPerfil').toLocaleUpperCase() }} </span>
			</a>
		</div>
		<div class="bg-primary mx-auto px-2 py-4 nav-perfil">
			<div class="movil text-white fw-bold">
				<div v-if="navSelection == 'movil'">
					{{ update.nombre }}
				</div>
				<div v-else>
					{{ navTitle }}
				</div>
			</div>
			<ul class="nav justify-content-around desktop">
				<li
					class="nav-item"
					:class="navSelection == 'datos' ? 'text-secondary' : 'text-white'"
					@click="navSelection = 'datos'">
					<span class="">{{ $t('subtitulo.datos') }}</span>
				</li>
				<li
					class="nav-item"
					:class="navSelection == 'pass' ? 'text-secondary' : 'text-white'"
					@click="navSelection = 'pass'">
					<span>{{ $t('subtitulo.modificarcontrasenia') }}</span>
				</li>
				<li
					class="nav-item"
					:class="navSelection == 'dni' ? 'text-secondary' : 'text-white'"
					@click="navSelection = 'dni'">
					<span>{{ $t('subtitulo.subirDni') }}</span>
				</li>
				<!-- <li
				class="nav-item"
				:class="navSelection == 'recibos' ? 'text-secondary' : 'text-white'"
				@click="navSelection = 'recibos'"
			  >
				<span>Recibos</span>
			  </li> -->
			</ul>
		</div>

		<div v-if="navMobile" class="movil perfil-text">
			<div class="menu-vertical">
				<a class="" :class="navSelection == 'datos' ? 'text-secondary' : 'text-primary'" @click="menuMobile('datos')">
					<span class="row">
						<div class="col-10" align="">
							{{ $t('subtitulo.datos') }}
						</div>
						<div class="col-2" align="right">></div>
					</span>
				</a>
				<a :class="navSelection == 'pass' ? 'text-secondary' : 'text-primary'" @click="menuMobile('pass')">
					<span class="row">
						<div class="col-10" align="">
							{{ $t('subtitulo.modificarcontrasenia') }}
						</div>
						<div class="col-2" align="right">></div>
					</span>
				</a>
				<a class="" :class="navSelection == 'dni' ? 'text-secondary' : 'text-primary'" @click="menuMobile('dni')">
					<span class="row">
						<div class="col-10" align="">
							{{ $t('subtitulo.subirDni') }}
						</div>
						<div class="col-2" align="right">></div>
					</span>
				</a>
			</div>
		</div>

		<div v-if="navSelection == 'datos'" class="row perfil-text py-4">
			<div v-if="update" class="col-12 col-md-6">
				<div v-if="update.nombre" class="row mb-3">
					<div class="text-primary fw-bolder">
						{{ $t('home.form.personalInformation.name') }}
					</div>
					<div class="text-muted">
						{{ update.nombre }}
					</div>
				</div>
				<div v-if="update.apellidos" class="row mb-3">
					<div class="text-primary fw-bolder">
						{{ $t('home.form.personalInformation.lastName') }}
					</div>
					<div class="text-muted">
						{{ update.apellidos }}
					</div>
				</div>
				<div v-if="update.dni" class="row mb-3">
					<div class="text-primary fw-bolder">
						{{ $t('home.form.personalInformation.dni') }}
					</div>
					<div class="text-muted">
						{{ update.dni }}
					</div>
				</div>
				<div v-if="update.fechaNacimiento" class="row mb-3">
					<div class="text-primary fw-bolder">
						{{ $t('home.form.personalInformation.birthDate') }}
					</div>
					<div class="text-muted">
						{{ update.fechaNacimiento }}
					</div>
				</div>
				<div v-if="update.telefono" class="row mb-3">
					<div class="text-primary fw-bolder">
						{{ $t('home.form.personalInformation.phone') }}
					</div>
					<div class="text-muted">
						{{ update.telefono }}
					</div>
				</div>
				<div v-if="update.mailingPublicidadActivo" class="row mb-3">
					<div class="text-primary fw-bolder">
						{{ $t('home.form.personalInformation.emailComercialesActivos') }}
					</div>
					<div class="text-muted">
						{{ update.mailingPublicidadActivo ? 'Sí' : 'No' }}
					</div>
				</div>
			</div>
			<div v-if="update" class="col-12 col-md-6">
				<div v-if="update.email" class="row mb-3">
					<div class="text-primary fw-bolder">
						{{ $t('home.form.personalInformation.email') }}
					</div>
					<div class="text-muted" style="overflow-wrap: break-word">
						{{ update.email }}
					</div>
				</div>
				<div v-if="update.direccion" class="row mb-3">
					<div class="text-primary fw-bolder">
						{{ $t('home.form.personalInformation.direccion') }}
					</div>
					<div class="text-muted">
						{{ update.direccion }}
					</div>
				</div>
				<div v-if="update.poblacion" class="row mb-3">
					<div class="text-primary fw-bolder">
						{{ $t('home.form.personalInformation.poblacion') }}
					</div>
					<div class="text-muted">
						{{ update.poblacion }}
					</div>
				</div>
				<div v-if="update.provincia" class="row mb-3">
					<div class="text-primary fw-bolder">
						{{ $t('home.form.personalInformation.provincia') }}
					</div>
					<div class="text-muted">
						{{ update.provincia }}
					</div>
				</div>
				<div v-if="update.codigoPostal" class="row mb-3">
					<div class="text-primary fw-bolder">
						{{ $t('home.form.personalInformation.codPostal') }}
					</div>
					<div class="text-muted">
						{{ update.codigoPostal }}
					</div>
				</div>
			</div>
			<Spinner v-if="loading" />
		</div>
		<div v-if="navSelection === 'dni'">
			<form enctype="multipart/form-data" @submit.prevent="updateDniData()">
				<div class="position-relative perfil-text">
					<div
						v-if="loading"
						class="loading position-absolute h-100 w-100 top-0 d-flex justify-content-center align-items-center">
						<Spinner />
					</div>
					<div class="p-4">
						<div class="row mb-3">
							<label class="text-primary fw-bolder mb-1">{{ $t('home.form.personalInformation.ADni') }}</label>
							<div class="form-group">
								<div class="input-group">
									<input
										v-model="anversoDniName"
										type="text"
										class="form-control text-muted"
										style="text-align: left"
										readonly />
									<div class="input-group-btn">
										<span class="fileUpload btn btn-upload">
											<span id="upload" class="upl desktop">{{
												$t('home.form.personalInformation.selectedFile')
											}}</span>
											<img class="px-2" src="~@/assets/icons/upload_icon.svg" alt="" />
											<input
												id="anversoDni"
												type="file"
												class="upload up"
												accept="image/gif, image/jpeg, image/png"
												capture
												@change="handleAnverso($event)" />
										</span>
									</div>
								</div>
							</div>
							<label for="anversoDni" class="text-explicativo text-muted pt-2">
								{{ $t('home.form.personalInformation.msjADni') }}
							</label>
						</div>
						<div class="row">
							<label class="text-primary fw-bolder mb-1">{{ $t('home.form.personalInformation.RDni') }}</label>
							<div class="input-group">
								<input
									v-model="reversoDniName"
									type="text"
									class="form-control text-muted"
									style="text-align: left"
									readonly />
								<div class="input-group-btn">
									<span class="fileUpload btn btn-upload">
										<span id="upload" class="upl desktop">{{ $t('home.form.personalInformation.selectedFile') }}</span>
										<img class="px-2" src="~@/assets/icons/upload_icon.svg" alt="" />
										<input
											id="anversoDni2"
											type="file"
											class="upload up"
											accept="image/gif, image/jpeg, image/png"
											capture
											@change="handleReverso($event)" />
									</span>
								</div>
							</div>
							<label for="anversoDni2" class="text-explicativo text-muted py-2">
								{{ $t('home.form.personalInformation.msjADni') }}
							</label>
						</div>
					</div>
				</div>
				<div class="text-center py-4">
					<button class="btn btn-primary" type="submit">
						{{ $t('botones.guardar') }}
					</button>
				</div>
			</form>
		</div>
		<div v-if="navSelection == 'modificar'">
			<UsuarioModificarDatos :user-information="update" />
		</div>
		<div v-if="navSelection == 'recibos'" class="perfil-text py-4">
			<div class="row text-center justify-content-center fw-bold">
				<div class="col-4">
					{{ $t('general.experiencia') }}
				</div>
				<div class="col-4">
					{{ $t('general.fecha') }}
				</div>
				<div class="col-1" />
			</div>
			<div class="row text-center justify-content-center">
				<div class="col-4 d-flex justify-content-center align-items-center">Andorra - GrandValira</div>
				<div class="col-4 d-flex justify-content-center align-items-center">01-01-2018</div>
				<div class="col-1">
					<div class="btn btn-primary">
						<i class="fa-regular fa-upload" />
					</div>
				</div>
			</div>
			<div class="d-flex justify-content-center mt-4">
				<div class="btn btn-primary">
					{{ $t('botones.aceptar') }}
				</div>
			</div>
		</div>

		<div v-if="navSelection == 'datos'" class="d-flex justify-content-center py-4">
			<button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#modalModificar">
				{{ $t('botones.modificarDatos') }}
			</button>
			<div
				id="modalModificar"
				class="modal fade"
				tabindex="-1"
				aria-labelledby="modalModificarLabel"
				aria-hidden="true">
				<div class="modal-dialog modal-dialog-scrollable">
					<div class="modal-content">
						<!-- <div class="modal-header"> -->
						<!-- <h5 class="modal-title" id="exampleModalLabel">
										{{ $t('titulos.editarPerfil')}}
									</h5> -->
						<button
							type="button"
							class="btn-close p-5 pb-1"
							style="align-self: end"
							data-bs-dismiss="modal"
							aria-label="Close" />
						<!-- </div> -->
						<div class="modal-body p-0">
							<UsuarioModificarDatos :user-information="update" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-if="navSelection == 'pass'">
			<form @submit.prevent="actualizarPasswordFunc(actualizarPassword)">
				<div class="perfil-text py-4 position-relative">
					<div
						v-if="loading"
						class="loading position-absolute h-100 w-100 top-0 d-flex justify-content-center align-items-center">
						<Spinner />
					</div>

					<div class="form-group col-8 mx-auto mb-3">
						<label class="text-primary fw-bolder mb-1">{{ $t('home.form.personalInformation.password') }}</label>
						<input
							v-model="actualizarPassword.oldPassword"
							type="password"
							class="form-control bg-white text-start"
							aria-describedby="emailHelp" />
						<div class="text-explicativo text-center">
							<span class="text-muted">
								{{ $t('home.form.personalInformation.msjcontrasenia') }}
							</span>
						</div>
					</div>
					<div class="form-group col-8 mx-auto mb-3">
						<label class="text-primary fw-bolder mb-1">{{ $t('home.form.personalInformation.newpassword') }}</label>
						<input
							v-model="actualizarPassword.password"
							type="password"
							class="form-control bg-white text-start"
							aria-describedby="emailHelp" />
					</div>
					<div class="form-group col-8 mx-auto mb-3">
						<label class="text-primary fw-bolder mb-1">
							{{ $t('home.form.personalInformation.confirmnewpassword') }}
						</label>
						<input
							v-model="actualizarPassword.passwordConfirm"
							type="password"
							class="form-control bg-white text-start"
							aria-describedby="emailHelp" />
					</div>

					<div v-if="navSelection == 'pass'" class="d-flex justify-content-center" />
				</div>
				<div class="text-center py-4">
					<button class="btn btn-primary" type="submit">
						{{ $t('botones.guardar') }}
					</button>
				</div>
			</form>
		</div>
	</div>
</template>

<script>
	import { defineComponent, ref, reactive, onMounted, onBeforeUnmount } from 'vue';
	import { mapActions, mapGetters, mapState } from 'vuex';
	import UsuarioApiService from '../../services/api/skiandnight/UsuarioApiService';
	import Spinner from '../../components/SpinnerLoading.vue';
	import UsuarioModificarDatos from './components/UsuarioModificarDatos.vue';
	import { alertSuccessHtml } from '../../services/utils/AlertUtils';
	import { useI18n } from 'vue-i18n';
	import { controlErrorActualizarPassword, controlErrorActualizarDni } from '../../services/utils/ErrorControl';

	export default defineComponent({
		name: 'UsuarioPerfil',
		components: { Spinner, UsuarioModificarDatos },
		setup() {
			const { t } = useI18n();
			const update = reactive({
				apellidos: null,
				codigoPostal: null,
				direccion: null,
				dni: null,
				email: null,
				emailConfirm: null,
				fechaNacimiento: null,
				mailingPublicidadActivo: null,
				nombre: null,
				paisId: null,
				poblacion: null,
				provincia: null,
				telefono: null,
			});
			const loading = ref(true);
			let informacionUsuario = ref();
			const format = fechaNacimiento => {
				const day = fechaNacimiento.getDate();
				const month = fechaNacimiento.getMonth() + 1;
				const year = fechaNacimiento.getFullYear();

				return `${day}/${month}/${year}`;
			};
			const paises = ref();
			const isMobile = ref(false);
			const navSelection = ref('');

			const checkDevice = () => {
				isMobile.value = window.innerWidth <= 900; // Puedes ajustar este valor según tus necesidades
				navSelection.value = isMobile.value ? 'movil' : 'datos';
			};
			onMounted(() => {
				checkDevice();
				window.addEventListener('resize', checkDevice);
			});

			onBeforeUnmount(() => {
				window.removeEventListener('resize', checkDevice);
			});

			const navTitle = ref(update.nombre);
			const navMobile = ref(true);

			UsuarioApiService.obtenerDatosUsuario().then(res => {
				loading.value = false;
				// eslint-disable-next-line no-const-assign
				update.apellidos = res.apellidos;
				update.codigoPostal = res.codigoPostal;
				update.direccion = res.direccion;
				update.dni = res.dni;
				update.email = res.email;
				update.fechaNacimiento = res.fechaNacimiento;
				update.mailingPublicidadActivo = res.mailingPublicidadActivo;
				update.nombre = res.nombre;
				update.poblacion = res.poblacion;
				update.provincia = res.provincia;
				update.telefono = res.telefono;
			});

			const agregarDni = reactive({
				anversoDni: null,
				reversoDni: null,
			});

			const anversoDni = ref();

			const reversoDni = ref();
			const anversoDniName = ref(
				t('home.form.personalInformation.selecciona') +
					' ' +
					t('home.form.personalInformation.ADni').toLocaleLowerCase()
			);

			const reversoDniName = ref(
				t('home.form.personalInformation.selecciona') +
					' ' +
					t('home.form.personalInformation.RDni').toLocaleLowerCase()
			);

			const actualizarPassword = reactive({
				oldPassword: null,
				password: null,
				passwordConfirm: null,
			});

			const menuMobile = value => {
				navSelection.value = value;
				navMobile.value = false;
				switch (value) {
					case 'datos':
						navTitle.value = t('subtitulo.datos');

						break;
					case 'pass':
						navTitle.value = t('subtitulo.modificarcontrasenia');

						break;
					case 'dni':
						navTitle.value = t('subtitulo.subirDni');

						break;

					default:
						navMobile.value = true;
						navSelection.value = 'movil';
						break;
				}
			};

			const updateDniData = () => {
				loading.value = true;

				const formDNI = new FormData();

				formDNI.append('anversoDni', anversoDni.value);
				formDNI.append('reversoDni', reversoDni.value);

				UsuarioApiService.uploadDniImages(formDNI)
					.then(() => {
						alertSuccessHtml('<p>DNI actualizado</p>');
					})
					.catch(err => {
						controlErrorActualizarDni(err);
					})
					.finally(() => {
						loading.value = false;
					});
			};

			const actualizarInformacionUsuario = () => {
				UsuarioApiService.modificarDatosUsuario(update)
					.then(res => {
						console.log(res);
					})
					.catch(err => {
						console.error(err);
					});
			};

			const actualizarPasswordFunc = valueActualizar => {
				loading.value = true;
				UsuarioApiService.modificarPassword(valueActualizar)
					.then(() => {
						alertSuccessHtml('<p>Contraseña actualizada</p>');
					})
					.catch(err => {
						controlErrorActualizarPassword(err);
					})
					.finally(() => {
						actualizarPassword.oldPassword = null;
						actualizarPassword.password = null;
						actualizarPassword.passwordConfirm = null;
						loading.value = false;
					});
			};

			const handleAnverso = async $event => {
				const target = $event.target;
				anversoDni.value = target.files[0];
				anversoDniName.value = target.files[0].name;
			};

			const handleReverso = async $event => {
				const target = $event.target;
				reversoDni.value = target.files[0];
				reversoDniName.value = target.files[0].name;
			};

			return {
				informacionUsuario,
				navSelection,
				update,
				format,
				loading,
				paises,
				actualizarInformacionUsuario,
				actualizarPassword,
				actualizarPasswordFunc,
				isMobile,
				agregarDni,
				updateDniData,
				handleAnverso,
				handleReverso,
				anversoDni,
				reversoDni,
				anversoDniName,
				reversoDniName,
				navMobile,
				navTitle,
				menuMobile,
			};
		},
		data() {
			return {
				error: false,
			};
		},
		computed: {
			...mapState({
				nombreUsuario: state => state.login.usuario.nombre,
				emailUsuario: state => state.login.usuario.email,
				rolesUsuario: state => state.login.usuario.roles,
			}),
			...mapGetters({
				isUsuarioLogged: 'login/isUsuarioLogged',
			}),
		},
		methods: {
			...mapActions({
				logout: 'login/logout',
			}),
			botonLogout() {
				this.$router.push('/');
				this.logout();
			},
			volver: function () {
				this.$router.go(-1);
			},
		},
	});
</script>

<style lang="scss" scoped>
	.text-explicativo {
		font-style: normal;
		font-weight: 400;
		letter-spacing: 0.103px;
	}
	@media screen and (max-width: 900px) {
		.desktop {
			display: none;
		}
		.movil {
			display: block;
		}

		.text-explicativo {
			font-size: 10px !important;
		}

		.text-primary {
			font-size: 13px;

			span {
				font-size: 15.6px;
				font-style: normal;
				font-weight: 700;
				line-height: normal;
				letter-spacing: 0.12px;
			}
		}
		.text-muted {
			font-size: 13px;
		}
	}

	@media screen and (min-width: 900px) {
		.desktop {
			display: block;
		}
		.movil {
			display: none;
		}
		.upl {
			display: contents !important;
		}
		.text-explicativo {
			font-size: 14px;
		}
	}

	.perfil-container {
		padding: 14rem 0;
		max-width: 840px;
		margin: auto;

		@media screen and (max-width: 900px) {
			padding: 7rem 2rem;
			.nav {
				display: none;
			}
		}

		@media screen and (min-width: 900px) {
			padding: 7rem 2rem;
			.nav {
				display: flex;
				flex-wrap: wrap;
			}
			margin-top: 5rem;
		}

		.nav-perfil {
			border-radius: var(--bs-border-radius-xl);
			.text-secondary {
				border-bottom: 2px solid var(--bs-secondary);
			}
		}
	}

	.perfil-text {
		background-color: #f0f0f0;
		padding: 2rem;
		margin: 1rem 0;
		border-radius: var(--bs-border-radius-xl);

		@media screen and (max-width: 900px) {
			padding: 0.5rem;
		}
	}

	.loading {
		background-color: #49494938;
		height: calc(100% + 300px);
		z-index: 1000;
		border-radius: var(--bs-border-radius-xl);
	}

	.nav-item {
		cursor: pointer;

		span {
			transition: 0.3s ease-in-out;
		}

		&:hover {
			span {
				color: var(--bs-secondary);
			}
		}
	}
	.btn-upload {
		background-color: var(--bs-secondary);
		color: white;
		text-align: left;
		width: 100%;
		border-radius: var(--bs-border-radius-xxl);
		box-shadow: 0px 2px 4px 0px rgba(0, 0, 0, 0.5);
		padding: 5px;
	}
	.fileUpload {
		position: relative;
		overflow: hidden;
	}
	.fileUpload input.upload {
		position: absolute;
		top: 0;
		right: 0;
		margin: 0;
		padding: 0;
		font-size: 20px;
		cursor: pointer;
		opacity: 0;
		filter: alpha(opacity=0);
	}

	.menu-vertical a {
		background-color: #eee;
		color: black;
		display: block;
		padding: 12px;
		text-decoration: none;
	}
</style>
