<template>
	<div class="col my-2">
		<div class="title-card d-flex p-3 justify-content-between align-items-center rounded-2">
			<div class="d-flex align-items-center">
				<i :class="categoriaServiciosMetaData.icono" class="me-2 text-primary" />
				<span class="text-primary">{{ categoriaServiciosMetaData.titulo }}</span>
			</div>
			<div class="d-flex">
				<span v-if="precioTotal.value !== 0" class="text-secondary mx-2">
					<span v-if="precioTotal > 0">+</span>{{ precioTotal }}{{ agenciaStore.getCurrency }}
				</span>
				<template v-if="showCheck">
					<svg width="25" height="25" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
						<circle cx="16" cy="16" r="15" fill="var(--bs-secondary)" stroke="var(--bs-primary)" stroke-width="2" />
						<g clip-path="url(#clip0_561_15056)">
							<path
								d="M13.2492 19.8225L9.42667 16L8.125 17.2925L13.2492 22.4167L24.2492 11.4167L22.9567 10.1242L13.2492 19.8225Z"
								fill="white" />
						</g>
						<defs>
							<clipPath id="clip0_561_15056">
								<rect width="22" height="22" fill="white" transform="translate(5 5)" />
							</clipPath>
						</defs>
					</svg>
				</template>
				<template v-else>
					<svg width="25" height="25" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
						<circle cx="16" cy="16" r="15" fill="transparent" stroke="transparent" />
					</svg>
				</template>
			</div>
		</div>
		<div class="body-card">
			<div class="row d-flex justify-content-center mt-3">
				<template v-if="categoriaServicios.arquetipo === 'individual'">
					<ReservaServiciosIndividualSelector
						:idx-reserva="idxReserva"
						:categoria-servicios="categoriaServicios"
						@servicio-seleccionado="handlerServicioSeleccionado" />
				</template>
				<template v-else-if="categoriaServicios.arquetipo === 'subfiltro'">
					<ReservaServiciosSubfiltrosSelector
						:idx-reserva="idxReserva"
						:categoria-servicios="categoriaServicios"
						@servicio-seleccionado="handlerServicioSeleccionado" />
				</template>
				<template v-else-if="categoriaServicios.arquetipo === 'subtipo'">
					<ReservaServiciosSubtipoSelector
						:categoria-servicios="categoriaServicios"
						:idx-reserva="idxReserva"
						@servicio-seleccionado="handlerServicioSeleccionado" />
				</template>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { ref, computed } from 'vue';
	import ReservaServiciosSubfiltrosSelector from '@/modules/viajesGrupos/components/ReservaServiciosSubfiltrosSelector.vue';
	import ReservaServiciosIndividualSelector from '@/modules/viajesGrupos/components/ReservaServiciosIndividualSelector.vue';
	import ReservaServiciosSubtipoSelector from '@/modules/viajesGrupos/components/ReservaServiciosSubtipoSelector.vue';
	import { getCategoriasServiciosMetaData } from '@/helpers/serviciosUtils';
	import { agenciaStoreModule } from '@/store_pinia/agencia';
	import currency from 'currency.js';
	import { calculaPrecioForServicioWithCantidad } from '@/helpers/viajesGrupos';

	const props = defineProps({
		categoriaServicios: {
			type: Object,
			default: null,
		},
		idxReserva: {
			type: Number,
			default: null,
		},
	});

	const agenciaStore = agenciaStoreModule();
	const servicioWithCantidadSeleccionado = ref(null);
	const categoriaServiciosMetaData = getCategoriasServiciosMetaData().find(
		({ selector }) => selector === props.categoriaServicios.categoria
	);

	const showCheck = computed(() => {
		let serviciosWithCantidad = servicioWithCantidadSeleccionado.value || [];
		if (!Array.isArray(serviciosWithCantidad)) {
			serviciosWithCantidad = [serviciosWithCantidad];
		}
		return serviciosWithCantidad.some(swc => !swc.servicio.nulo);
	});

	const precioTotal = computed(() => {
		let serviciosWithCantidad = servicioWithCantidadSeleccionado.value || [];
		if (!Array.isArray(serviciosWithCantidad)) {
			serviciosWithCantidad = [serviciosWithCantidad];
		}
		return serviciosWithCantidad.reduce(
			(accum, swc) => accum.add(calculaPrecioForServicioWithCantidad(swc)),
			currency(0)
		);
	});

	function handlerServicioSeleccionado(data) {
		servicioWithCantidadSeleccionado.value = data;
	}
</script>

<style lang="css">
	.title-card {
		background-color: rgba(249, 247, 248, 1);

		border-radius: var(--bs-border-radius-lg);
	}
</style>
