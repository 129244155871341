<template>
	<div class="modal-body">
		<div
			v-for="reserva in grupo.reservas"
			:key="reserva.id"
			class="w-100 text-white fw-normal justify-content-between my-4 mx-0">
			<TarjetaProximaReservaAcciones
				:reserva="reserva"
				:grupo="grupo"
				:unique="reservasToRecuperar.length === 1"
				:action="'Recover'"
				@reserva-value="agregarCancelacionReserva" />
		</div>
	</div>

	<div class="text-center">
		<button type="button" class="btn btn-primary" @click="recuperarReserva()">
			{{ $t('reserva.recuperarReserva') }}
		</button>
		<button type="button" class="btn btn-link" data-bs-dismiss="modal">
			{{ $t('botones.descartar') }}
		</button>
	</div>
</template>

<script setup>
	import { ref, computed } from 'vue';
	import TarjetaProximaReservaAcciones from '@/components/tarjetas/TarjetaProximaReservaAcciones.vue';
	import { alertSuccessHtml } from '@/services/utils/AlertUtils';
	import ReservasApiService from '@/services/api/skiandnight/ReservasApiService';
	import Swal from 'sweetalert2';
	import { createToaster } from '@meforma/vue-toaster';
	import { ESTADOS_RESERVA } from '@/constants/reservaConstants';

	const props = defineProps({
		grupo: {
			type: Object,
			required: true,
		},
	});

	const emit = defineEmits(['close']);

	const reservasToRecuperar = computed(() =>
		props.grupo.reservas.filter(reserva => reserva.estado === ESTADOS_RESERVA.CANCELADA)
	);

	const listaReservasCancelacion = ref(reservasToRecuperar.value.map(reserva => reserva.id));

	const toaster = createToaster({ position: 'top-right' });

	function agregarCancelacionReserva(informacionReserva) {
		const indexReserva = listaReservasCancelacion.value.findIndex(({ id }) => id === informacionReserva.id);
		if (indexReserva === -1 && informacionReserva.addToPay) {
			listaReservasCancelacion.value.push(informacionReserva.id);
		} else {
			listaReservasCancelacion.value.splice(indexReserva, 1);
		}
	}

	function recuperarReserva() {
		if (listaReservasCancelacion.value.length <= 0) {
			toaster.error('Selecciona alguna reserva para continuar');
		} else {
			emit('close');
			Swal.fire({
				title: 'Recuperación de Reserva',
				text: '¿Estás seguro de que deseas recuperar las reservas?',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: 'var(--bs-primary)',
				cancelButtonColor: '#FF0000FF',
				confirmButtonText: 'Si, Recuperar',
				reverseButtons: true,
				cancelButtonText: 'No',
			}).then(result => {
				if (result.isConfirmed) {
					ReservasApiService.doRecuperarReservasGrupo(
						props.grupo.id,
						listaReservasCancelacion.value
					).then(() => {
						alertSuccessHtml(
							'Hemos comenzado con la recuperación de tu reserva, analizaremos si es posible recuperarla y en un plazo máximo de 72 horas nos pondremos en contacto contigo'
						);
					});
				}
			});
		}
	}

</script>

<style lang="css">
	.btn-primary {
		font-size: 14px;
		font-weight: 500;
	}
</style>
