<template>
	<div class="container superior">
		<div class="row titulo">
			<div class="col">
				DEBIDO A LA SITUACIÓN SANITARIA, SON MUCHOS LOS VIAJEROS QUE SE REPLATEAN SUS VACACIONES. POR ESE MOTIVO, DESDE
				SKI&NIGHT, HEMOS DESARROLLADO UNAS MEDIDAS DE SEGURIDAD PARA QUE NO TENGAS QUE PREOCUPARTE DE TU VIAJE.
			</div>
		</div>
		<div class="row">
			<div class="col">
				<p>
					<a
						class="btn btn-primary"
						data-bs-toggle="collapse"
						href="#collapseOne"
						role="button"
						aria-expanded="false"
						aria-controls="collapseOne">
						1. CANCELACIÓN GRATUITA POR COVID-19
					</a>
				</p>
				<div id="collapseOne" class="collapse">
					<div class="card card-body">
						LA CANCELACIÓN SERÁ GRATUITA EN LOS SIGUIENTES CASOS:
						<ul>
							<li>
								LIMITACIÓN/PROHIBICIÓN DE DESPLAZAMIENTO DE PAÍS, REGIÓN, CIUDAD O UNIDAD SANITARIA BÁSICA DE ORIGEN O
								DESTINO POR AUTORIDADES GUBERNAMENTALES.
							</li>
							<li>CONFINAMIENTO DE PAÍS, REGIÓN, CIUDAD O UNIDAD SANITARIA BÁSICA.</li>
							<li>
								SE EL VIAJERO ES POSITIVO EN COVID-19 POR PCR (NO EN UN TEST RÁPIDO) Y ESTÁ HOSPITALIZADO O EN
								CUARENTENA DOMICILIARIA (SERÁ NECESARIO PRESENTAR LA PRUEBA O EL INFORME MÉDICO A LA AGENCIA DE VIAJES).
							</li>
						</ul>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<p>
					<a
						class="btn btn-primary"
						data-bs-toggle="collapse"
						href="#collapseTwo"
						role="button"
						aria-expanded="false"
						aria-controls="collapseTwo">
						2. TRANSPORTE
					</a>
				</p>
				<div id="collapseTwo" class="collapse">
					<div class="card card-body">
						<p>SE TOMARÁ LA TEMPERATURA ANTES DE SUBIR A LOS TRANSPORTES.</p>

						<p>SERÁ OBLIGATORIA LA MASCARILLA DURANTE TODO EL TRAYECTO.</p>

						<p>USO DE GELES HIDROALCÓLICOS.</p>

						<p>CONTROL DE DISTANCIA DE SEGURIDAD.</p>

						<p>REFUERZO DE LIMPIEZA Y DESINFECCIÓN DE AUTOBUSES.</p>
					</div>
				</div>
			</div>
		</div>
		<div class="row">
			<div class="col">
				<p>
					<a
						class="btn btn-primary"
						data-bs-toggle="collapse"
						href="#collapsThree"
						role="button"
						aria-expanded="false"
						aria-controls="collapsThree">
						3. ACTIVIDADES
					</a>
				</p>
				<div id="collapsThree" class="collapse">
					<div class="card card-body">
						<p>USO OBLIGATORIO DE MASCARILLA EN LAS ACTIVIDADES.</p>

						<p>PROTOCOLOS DESINFECCIÓN MATERIAL.</p>

						<p>HORARIOS DIFERENCIADOS PARA EVITAR AGLOMERACIONES.</p>

						<p>REDUCCIÓN DE AFOROS Y GRUPOS.</p>

						<p>ASISTENCIA Y REORGANIZACIÓN DE LAS COLAS DE ACCESO.</p>

						<p>TOMA DE TEMPERATURA EN EL ACCESO.</p>

						<p>VERIFICACIÓN OBLIGATORIA DEL USO DE LA MASCARILLA.</p>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {};
</script>

<style scoped>
	.superior {
		padding-top: 7rem;
	}

	.titulo {
		color: var(--bs-primary);
		font-size: 1.5rem;
		margin-bottom: 2rem;
		font-weight: 900;
	}

	.btn {
		width: 100%;
		text-align: start;
		padding-left: 2rem;
		background-image: linear-gradient(72deg, #313056 31%, #313056 31%, #30356b 91%);
	}

	.collapse {
		margin-bottom: 1rem;
	}

	@media only screen and (min-width: 962px) {
		.superior {
			padding-top: 15rem;
		}

		.titulo {
			font-size: 2rem;
		}
	}
</style>
