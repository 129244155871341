import { defineStore } from 'pinia';
import ViajesGrupoApiService from '../services/api/skiandnight/ViajesGrupoApiService';
export const viajesGrupos = defineStore('viajesGrupos', {
	state: () => ({
		viajesGruposTotal: null,
		viajesGruposFiltrados: null,
		viajesGruposOfertas: [],
		preciosViajesFiltrados: {
			min: 0,
			max: 0,
		},
		viajeSeleccionado: null,
		viajesGruposCatalogo: null,
		subfilter: {
			priceMin: 0,
			priceMax: 1000,
		},
		lugaresExperiencia: [],
		loading: true,
		oferta: null,
	}),
	getters: {
		getViajesGruposDestacados(state) {
			if (state.viajesGruposTotal == null)
				this.setViajesGruposTotal().then(() => {
					return state.viajesGruposTotal.filter(viajes => viajes.destacado);
				});
			else {
				return state.viajesGruposTotal.filter(viajes => viajes.destacado);
			}
		},
		getMinViajesFiltrados(state) {
			let precios = [];
			if (state.viajesGruposFiltrados) {
				state.viajesGruposFiltrados.map(viaje => precios.push(viaje.precio));
			}
			return precios.length == 0 ? 0 : Math.min(...precios);
		},
		getMaxViajesFiltrados(state) {
			let precios = [];
			if (state.viajesGruposFiltrados) {
				state.viajesGruposFiltrados.map(viaje => precios.push(viaje.precio));
			}
			return precios.length == 0 ? 0 : Math.max(...precios);
		},
		getViajesGruposCatalogo(state) {
			let viajes = {};
			if (state.viajesGruposFiltrados) {
				state.viajesGruposFiltrados.map(viaje => {
					viajes[viaje.lugarExperiencia.nombreArea] = [];
				});
				state.viajesGruposFiltrados.map(viaje => {
					//TODO SET FILTERS PRICES AND STATIONS
					if (viaje.precio >= state.subfilter.priceMin && viaje.precio <= state.subfilter.priceMax) {
						viajes[viaje.lugarExperiencia.nombreArea].push(viaje);
						// viaje.poblaciones.map((poblacion) => {
						//     if (this.lugaresExperiencia.includes(poblacion.nombre)) {
						//         viajes[viaje.lugarExperiencia.nombreArea].push(viaje)
						//     }
						// }
						// )
					}
				});
				return viajes;
			}
		},
		getEstaciones(state) {
			if (state.viajesGruposFiltrados) {
				let auxPoblaciones = [];
				state.viajesGruposFiltrados.map(viaje =>
					viaje.poblaciones.map(poblacion => {
						if (auxPoblaciones.findIndex(element => element.nombre == poblacion.nombre) == -1) {
							auxPoblaciones.push({
								...poblacion,
								selected: true,
							});
						}
					})
				);
				return auxPoblaciones;
			}
		},
	},
	actions: {
		async setViajesGruposTotal(listaViajes) {
			this.loading = false;
			this.viajesGruposTotal = listaViajes;
		},

		async setViajesGruposOfertas() {
			if (this.viajesGruposTotal == null) {
				this.setViajesGruposTotal().then(() => {
					this.viajesGruposOfertas = this.viajesGruposTotal.filter(viaje => viaje.destacado);
					return;
				});
				return;
			}

			if (this.viajesGruposTotal) this.viajesGruposOfertas = this.viajesGruposTotal.filter(viaje => viaje.destacado);
		},
		// async setViajesFiltrados(station, group, month, year,festival=null) {
		async filtrarViajesPorDropdown(festival = null) {
			if (this.viajesGruposTotal == null) await this.setViajesGruposTotal();

			let totalAux = await this.viajesGruposTotal;

			festival &&
				(totalAux = totalAux.filter(viajeGrupo => {
					if (viajeGrupo.tipos[0] != undefined) return viajeGrupo.festival == festival;
				}));
			this.viajesGruposFiltrados = totalAux;
			return await totalAux;
		},
		async filtrarViajesPorFestival(festival) {
			if (this.viajesGruposTotal == null) await this.setViajesGruposTotal();

			let totalAux = await this.viajesGruposTotal;

			festival &&
				(totalAux = totalAux.filter(viajeGrupo => {
					if (viajeGrupo.tipos[0] != undefined) return viajeGrupo.festival == festival;
				}));
			this.viajesGruposFiltrados = totalAux;
			return await totalAux;
		},
		async setViajeSeleccionado(viajeId, codigoViaje = null) {
			await ViajesGrupoApiService.getViaje(viajeId, codigoViaje).then(res => {
				this.viajeSeleccionado = res;
			});
		},
		setSubfilterPrice(min, max) {
			this.subfilter.priceMin = min;
			this.subfilter.priceMax = max;
		},

		async setOferta(codigo) {
			//no hacer comprobación de si ya habiamos cargado la oferta ya que si el usuario no estaba logueado y se loguea necesitamos obtener de nuevo la oferta para saber el id del viaje votado
			await ViajesGrupoApiService.getOferta(codigo).then(res => (this.oferta = res));
		},
	},
});
