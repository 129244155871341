<template>
	<p class="text-primary text-title">{{ $t('codigoDescuento.tienesCodDescuento') }}</p>
	<form class="row w-100 p-0" @submit.prevent="validarCodigo(codigoDescuento)">
		<div class="input-wrapper">
			<input
				v-model="codigoDescuento"
				type="text"
				class="form-control"
				:placeholder="$t('reserva.descuentoCod')"
				:disabled="loading"
				required />
			<button class="btn btn-outline-secondary bg-secondary text-primary" type="submit" :disabled="loading">
				{{ $t('general.aplicar') }}
			</button>
		</div>
	</form>
</template>

<script setup>
	import { ref } from 'vue';
	import { alertAutoCloseSucessHtml, alertAutoCloseWarningWithoutCallback } from '@/services/utils/AlertUtils';
	import { useI18n } from 'vue-i18n';
	import EventosApiService from '@/services/api/skiandnight/EventosApiService';

	const props = defineProps({
		uri: {
			type: String,
			required: true,
		},
		eventoId: {
			type: Number,
			required: true,
		},
		ticketeraId: {
			type: Number,
			required: true,
		},
	});

	const codigoDescuento = ref('');
	const loading = ref(false);
	const emit = defineEmits(['descuento-validado']);
	const { t } = useI18n();

	function validarCodigo(codigo) {
		EventosApiService.getValidaCodigoDescuento(props.uri, props.eventoId, props.ticketeraId, codigo)
			.then(infomacionCupo => {
				alertAutoCloseSucessHtml(
					3000,
					` <strong><p class="text-primary">${infomacionCupo.descripcion}</p><p class="text-secondary">${infomacionCupo.cantidad} ${infomacionCupo.tipoCantidad}</p></strong>`
				);
				emit('descuento-validado', { ...infomacionCupo, codigo });
			})
			.catch(() => {
				alertAutoCloseWarningWithoutCallback(
					2000,
					t('reserva.descuentoCodError', {
						codigo: codigo,
					})
				);
			})
			.finally(() => {
				loading.value = false;
				codigoDescuento.value = '';
			});
	}
</script>

<style lang="scss" scoped>
	.text-title {
		font-size: 18px;
		font-style: normal;
		font-weight: 700;
		line-height: normal;
		letter-spacing: 0.134px;
	}

	@media screen and (max-width: 768px) {
		.text-title {
			font-size: 16px;
		}
		.btn-primary {
			font-size: 12px;
		}
		.form-control {
			font-size: 12px;
		}
	}
	[type='text'],
	input[type='text']::placeholder {
		text-align: left;
		left: 2px;
		top: 0;
	}
	.input-wrapper {
		position: relative;
		display: inline-block;
		border-radius: var(--bs-border-radius-xxl);
		overflow: hidden;

		.form-control {
			border-radius: var(--bs-border-radius-xxl);
			background: rgba(33, 36, 68, 0.02);
			box-shadow: 0px 0px 3px 0px rgba(33, 36, 68, 0.35) inset;
			height: 3.5rem;
		}

		@media screen and (max-width: 678px) {
			width: 100%;
		}
	}

	.input-wrapper button {
		position: absolute;
		border-radius: var(--bs-border-radius-xl);
		top: 50%;
		right: 10px; /* Ajusta la posición horizontal del botón */
		transform: translateY(-50%);
		height: 70%; /* Ajusta la altura del botón */
		border-radius: var(--bs-border-radius-xl); /* Ajusta el radio de los bordes del botón */
		font-size: 0.8em; /* Ajusta el tamaño del texto del botón */
		margin-right: 1rem;
	}
</style>
