export const ESTADOS_RESERVA = {
	BLOQUEADA: 'BLOQUEADA',
	RESERVADA: 'RESERVADA',
	PAGADA: 'PAGADA',
	CANCELADA: 'CANCELADA',
};

export const TIPO_CODIGO_DESCUENTO = {
	PORCENTAJE: 'PORCENTAJE',
};

export const TIPO_TASA = {
	TASA_GESTION_CAMBIO: 'TASA_GESTION_CAMBIO',
	TASA_GESTION_PLATAFORMA: 'TASA_GESTION_PLATAFORMA',
	TASA_TURISTICA: 'TASA_TURISTICA'
}
