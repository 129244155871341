<template>
	<div class="row d-flex flex-column flex-md-row translate">
		<div class="col-12 col-md-3">
			<select
				v-model="currentFirstFilter"
				class="form-select selector-servicio text-primary translate"
				:disabled="!isServicioDescontratable(reserva, servicioWithCantidadSeleccionado.servicio)"
				@change="firstFilterChangeHandler">
				<option :value="null">
					{{ servicioNulo.nombre }}
				</option>
				<option v-for="firstFilter in filtros.firstFilter" :key="firstFilter.id" :value="firstFilter">
					{{ firstFilter.nombre }}
				</option>
			</select>
		</div>
		<div v-if="currentFirstFilter" class="col-12 col-md-3 mt-2 mt-md-0">
			<select v-model="currentSecondFilter"
							class="form-select text-primary translate"
							:disabled="!isServicioDescontratable(reserva, servicioWithCantidadSeleccionado.servicio)"
							@change="secondFilterChangeHandler">
				<option :value="null" selected disabled>
					{{ $t('filtroModalidades.titulo.seleccionaGama') }}
				</option>
				<option v-for="secondFilter in currentFirstFilter?.secondFilter" :key="secondFilter.id" :value="secondFilter">
					{{ secondFilter.nombre }}
				</option>
			</select>
		</div>
		<div v-if="currentSecondFilter" class="col-12 col-md-6 mt-2 mt-md-0">
			<div class="input-group gap-3">
				<div class="select-container translate">
					<select
						v-model="servicioWithCantidadSeleccionado.servicio"
						class="form-select text-primary w-100 translate"
						:disabled="!isServicioDescontratable(reserva, servicioWithCantidadSeleccionado.servicio)"
						@change="handleServicioSeleccionadoChange">
						<option :key="servicioNulo.id" :value="servicioNulo" selected disabled>
							{{ $t('filtroModalidades.titulo.seleccionaServicio') }}
						</option>
						<option
							v-for="servicio in serviciosFiltrados.filter(s => s.activo)"
							class="translate"
							:key="servicio.id"
							:value="servicio"
							:disabled="!isServicioSelectable(reserva, servicio)">
							{{ servicio.nombre }} ({{ $t('general.del') }}
							{{ dateMonthNumbers(servicio.fechaInicio) }}
							{{ $t('general.al') }} {{ dateMonthNumbers(servicio.fechaFin) }})
							<template v-if="servicio.precio">
								(<template v-if="servicio.precio > 0">+</template>{{ servicio.precio }}{{ agenciaStore.getCurrency }})
							</template>
						</option>
					</select>
				</div>
				<div v-if="!servicioWithCantidadSeleccionado.servicio.nominal" class="cantidad-container">
						<InputSelectorCantidad
							:disabled="!isServicioSelectable(reserva, servicioWithCantidadSeleccionado.servicio)"
							:count="servicioWithCantidadSeleccionado.cantidad"
							:min-count="isServicioDescontratable(reserva, servicioWithCantidadSeleccionado.servicio) ? 1 : servicioWithCantidadSeleccionado.oldCantidad"
							@update-count="handleCantidadServicioSeleccionadoChange" />
				</div>
			</div>
		</div>
		<div class="row text-primary mb-2 description-text">
			<template v-if="!servicioWithCantidadSeleccionado.servicio.nulo">
				<div class="col-12 translate">
					{{ servicioWithCantidadSeleccionado.servicio.descripcion }}
				</div>
			</template>
		</div>
	</div>
</template>

<script setup>
	import { informacionHabitacion } from '@/store_pinia/informacionHabitacion';
	import { ref, onBeforeMount, computed, reactive } from 'vue';
	import { dateMonthNumbers } from '@/helpers/dateUtiles';
	import { isServicioSelectable, isServicioDescontratable } from '@/helpers/serviciosUtils';
	import { agenciaStoreModule } from '@/store_pinia/agencia';
	import InputSelectorCantidad from '@/components/inputs/InputSelectorCantidad';

	const props = defineProps({
		idxReserva: {
			type: Number,
			default: null,
		},
		categoriaServicios: {
			type: Object,
			default: null,
		},
	});

	const emits = defineEmits(['servicioSeleccionado']);

	const storeHabitacion = informacionHabitacion();
	const agenciaStore = agenciaStoreModule();
	const reserva = computed(() => storeHabitacion.getReservaByIdx(props.idxReserva));
	const filtros = computed(() => storeHabitacion.filtros[props.categoriaServicios.categoria]);
	const servicioNulo = computed(() => props.categoriaServicios.servicios.find(({ nulo }) => nulo));
	const currentFirstFilter = ref(null);
	const currentSecondFilter = ref(null);
	const serviciosFiltrados = ref([]);
	const servicioWithCantidadSeleccionado = reactive({ servicio: servicioNulo.value, cantidad: 1, oldCantidad: 1 });

	function initForPaqueteBasico() {
		let servicioBasico = props.categoriaServicios.servicios.find(({ paqueteBasico }) => paqueteBasico);
		initFiltersForServicio(servicioBasico);
		filterServicios();
		servicioWithCantidadSeleccionado.servicio = servicioBasico;
		servicioWithCantidadSeleccionado.cantidad = 1;
		servicioWithCantidadSeleccionado.oldCantidad = 1;
	}

	function initForReservaCurrentServicio(reservaCurrentServicioWithCantidad) {
		const currentServicio = reservaCurrentServicioWithCantidad.servicio;
		if (!currentServicio.nulo) {
			initFiltersForServicio(currentServicio);
			filterServicios();
		}
		servicioWithCantidadSeleccionado.servicio = reservaCurrentServicioWithCantidad.servicio;
		servicioWithCantidadSeleccionado.cantidad = reservaCurrentServicioWithCantidad.cantidad;
		servicioWithCantidadSeleccionado.oldCantidad = reservaCurrentServicioWithCantidad.cantidad;
	}

	function initFiltersForServicio(servicio) {
		currentFirstFilter.value = filtros.value.firstFilter.find(({ id }) => id === servicio.firstFilter.id);
		currentSecondFilter.value = currentFirstFilter.value.secondFilter.find(({ id }) => id === servicio.secondFilter.id);
	}

	function filterServicios() {
		serviciosFiltrados.value = props.categoriaServicios.servicios.filter(
			servicio =>
				servicio.firstFilter.id === currentFirstFilter.value.id &&
				servicio.secondFilter.id === currentSecondFilter.value.id &&
				!servicio.nulo
		);
	}

	function handleCantidadServicioSeleccionadoChange(newCantidad) {
		servicioWithCantidadSeleccionado.cantidad = newCantidad;
		addServicioToReserva(true);
	}

	function handleServicioSeleccionadoChange() {
		servicioWithCantidadSeleccionado.servicio.justSelected = true;
		// Forzar cantidad 1 si es nominal
		if (servicioWithCantidadSeleccionado.servicio.nominal) {
			servicioWithCantidadSeleccionado.cantidad = 1;
			servicioWithCantidadSeleccionado.oldCantidad = 1;
		}
		addServicioToReserva(true);
	}

	function addServicioToReserva(isModificacion = false) {
		storeHabitacion.addServicioToReserva(
			props.idxReserva,
			servicioWithCantidadSeleccionado.servicio,
			servicioWithCantidadSeleccionado.cantidad,
			isModificacion
		);
		emits('servicioSeleccionado', servicioWithCantidadSeleccionado);
	}

	function resetServicioSeleccionado(isModificacion = false) {
		servicioWithCantidadSeleccionado.servicio = servicioNulo.value;
		servicioWithCantidadSeleccionado.cantidad = 1;
		servicioWithCantidadSeleccionado.oldCantidad = 1;
		addServicioToReserva(isModificacion);
	}

	function firstFilterChangeHandler() {
		resetServicioSeleccionado(true);
		currentSecondFilter.value = null;
	}

	function secondFilterChangeHandler() {
		resetServicioSeleccionado(true);
		if (currentFirstFilter.value && currentSecondFilter.value) {
			filterServicios();
		}
	}

	function init() {
		const reservaCurrentServicioWithCantidad = reserva.value?.serviciosWithCantidad[props.categoriaServicios.categoria];
		if (reservaCurrentServicioWithCantidad === undefined) {
			if (props.categoriaServicios.paqueteBasico) {
				initForPaqueteBasico();
			} else {
				resetServicioSeleccionado();
			}
		} else {
			initForReservaCurrentServicio(reservaCurrentServicioWithCantidad);
		}
		addServicioToReserva();
	}

	onBeforeMount(() => init());
</script>

<style lang="scss" scoped>
	.title-card {
		background-color: #f9f7f8;
		padding: 1rem;
		display: flex;
		justify-content: space-between;
		align-items: center;
		border-radius: var(--bs-border-radius-lg);

		.text {
			display: flex;
			align-items: center;

			i {
				margin-right: 0.5rem;
			}
		}
	}

	.dot {
		height: 25px;
		width: 25px;
		background-color: var(--bs-secondary);
		border-radius: 50%;
		display: inline-block;
	}

	.dot-disabled {
		height: 25px;
		width: 25px;
		background-color: #727272;
		border-radius: 50%;
		display: inline-block;
	}

	.selector-servicio {
		background-color: rgba(var(--bs-primary-rgb), 0.02);
		box-shadow: 0 0 3px 0 rgba(var(--bs-primary-rgb), 0.35) inset;
	}

	.description-text {
		font-size: 12px;
		font-weight: 400;
		margin-top: 0.4rem;

		@media only screen and (max-width: 768px) {
			font-size: 10px;
		}
	}

	.input-group {
		display: flex;
		flex-wrap: nowrap;
		width: 100%;
		gap: 0.5rem;

		.select-container {
			flex: 1;
			min-width: 0;
			padding: 0;
		}

		.cantidad-container {
			width: auto;
			flex-shrink: 0;
		}

		> .col-9,
		> .col-3 {
			padding: 0;
		}

		@media (max-width: 768px) {
			> .col-9 {
				width: 75%;
			}
			> .col-3 {
				width: 25%;
			}
		}
	}

	.form-select {
		width: 100%;
		max-width: 100%;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	@media (max-width: 768px) {
		.col-12 {
			padding-left: 0.5rem;
			padding-right: 0.5rem;
		}
	}
</style>
