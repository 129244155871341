import {ESTADOS_VIAJE} from '@/constants/viajeConstants';

export function isViajeDisponible(viaje) {
	return viaje.estado === ESTADOS_VIAJE.PUBLICADO
		|| viaje.estado === ESTADOS_VIAJE.PAGADO
}

export function isViajeActivo(viaje) {
	return viaje.estado === ESTADOS_VIAJE.PUBLICADO
		|| viaje.estado === ESTADOS_VIAJE.PAGADO
		|| viaje.estado === ESTADOS_VIAJE.CERRADO
}