import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { store } from './store';
import VCalendar from 'v-calendar';
import i18n from './i18n';
import { createPinia } from 'pinia';
import Toaster from '@meforma/vue-toaster';
import VueGoogleMaps from '@fawmi/vue-google-maps';
import vue3GoogleLogin from 'vue3-google-login';
import MyGocuButton from './components/common/buttons/MyGocuButton.vue';
import { sanitizeHtml } from './directives/sanitizeHtml';

createApp(App)
	.use(i18n)
	.use(createPinia())
	.use(store)
	.use(router)
	.use(VCalendar, {})
	.use(Toaster)
	.component('mg-btn', MyGocuButton)
	.use(VueGoogleMaps, {
		load: {
			key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
			language: 'es',
		},
	})
	.use(vue3GoogleLogin, {
		clientId: process.env.VUE_APP_GOOGLE_OATH_CLIENT_ID,
	})
	.directive('sanitize-html', sanitizeHtml)
	.mount('#app');
//.use(VueAnalytics, {
//       id: 'UA-115159955-1'
// });
