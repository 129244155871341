// export const formatMayusMin= texto => {
//     return texto.charAt(0).toUpperCase() + texto.slice(1).toLowerCase();
//   }

export const formatMayusMin = texto => {
	let palabras = texto.toLowerCase().split(' ');
	for (let i = 0; i < palabras.length; i++) {
		palabras[i] = palabras[i].charAt(0).toUpperCase() + palabras[i].slice(1);
	}
	return palabras.join(' ');
};

export const generateRandomString = () => {
	const caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
	let resultado = '';
	for (let i = 0; i < 10; i++) {
		const indiceAleatorio = Math.floor(Math.random() * caracteres.length);
		resultado += caracteres.charAt(indiceAleatorio);
	}
	return resultado;
};
