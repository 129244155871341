<template>
	<div class="quienes-somos">
		<div class="seccion-principal">
			<div class="titulo-banner bg-secondary py-3 px-5">
				<h1>{{ $t('us.whoWeAre.banner.title') }}</h1>
			</div>

			<div class="text-banner bg-white">
				<p>
					{{ $t('us.whoWeAre.banner.text.p1') }}
				</p>
				<p>
					{{ $t('us.whoWeAre.banner.text.p2') }}
				</p>
			</div>
		</div>

		<div class="container-cifras my-5">
			<h2 class="text-primary fw-bolder titulo-cifras">
				{{ $t('us.whoWeAre.values.figures.title') }}
			</h2>

			<div class="w-100 bg-secondary">
				<div class="d-flex py-5 mt-5 px-0 text-primary textos">
					<div class="col-3 text-center">
						<span class="fs-1">10</span>
						{{ $t('us.whoWeAre.values.figures.experience') }}
					</div>
					<div class="col-3 text-center">
						<span class="fs-1">+25.000</span>
						{{ $t('us.whoWeAre.values.figures.travelers') }}
					</div>
					<div class="col-3 text-center">
						<span class="fs-1">+130</span>
						{{ $t('us.whoWeAre.values.figures.groups') }}
					</div>
					<div class="col-3 text-center">
						<span class="fs-1">+70</span>
						{{ $t('us.whoWeAre.values.figures.collaborations') }}
					</div>
				</div>
			</div>
		</div>

		<div class="container-valores my-5">
			<h2 class="text-primary my-5 pt-5 fw-bolder">
				{{ $t('us.whoWeAre.values.title') }}
			</h2>

			<div class="d-flex my-5 py-5 container-contenido">
				<div class="col-6">
					<div class="container">
						<img src="@/assets/equipo/ambiente.webp" alt="" class="w-100" />
					</div>
				</div>
				<div class="col-6 px-4">
					<h3 class="text-primary fw-bold mb-3">
						{{ $t('us.whoWeAre.values.enviroment.title') }}
					</h3>
					<p>
						{{ $t('us.whoWeAre.values.enviroment.p1') }}
					</p>
					<p>
						{{ $t('us.whoWeAre.values.enviroment.p2') }}
					</p>
				</div>
			</div>

			<div class="d-flex my-5 py-5 container-contenido middle">
				<div class="col-6 px-4 d-flex flex-column justify-content-center">
					<h3 class="text-primary fw-bold mb-3">
						{{ $t('us.whoWeAre.values.honesty.title') }}
					</h3>
					<p>
						{{ $t('us.whoWeAre.values.honesty.p1') }}
					</p>
					<p>
						{{ $t('us.whoWeAre.values.honesty.p2') }}
					</p>
					<p />
				</div>
				<div class="col-6">
					<div class="container">
						<img src="@/assets/equipo/honestidad.webp" alt="" class="w-100" />
					</div>
				</div>
			</div>

			<div class="d-flex my-5 py-5 container-contenido">
				<div class="col-6">
					<div class="container">
						<img src="@/assets/equipo/compromiso.webp" alt="" class="w-100" />
					</div>
				</div>
				<div class="col-6 px-4">
					<h3 class="text-primary fw-bold mb-3">
						{{ $t('us.whoWeAre.values.compromise.title') }}
					</h3>
					<p>
						{{ $t('us.whoWeAre.values.compromise.p1') }}
					</p>
					<p>
						{{ $t('us.whoWeAre.values.compromise.p2') }}
					</p>
				</div>
			</div>
		</div>

		<div class="season">
			<h2 class="fw-bolder text-primary">RESUMEN TEMPORADA 2022</h2>
			<div class="d-flex justify-content-center my-5">
				<img src="@/assets/equipo/season.png" alt="" class="img-season" />
			</div>
		</div>

		<div class="container-equipos my-4">
			<h2 class="fw-bolder text-primary">
				{{ $t('us.whoWeAre.team.title') }}
			</h2>

			<Carousel :wrap-around="true" :autoplay="4000" class="mobile-carousel" :breakpoints="breakpoints">
				<Slide key="1">
					<div class="d-flex flex-column align-items-center" style="max-width: 350px">
						<img
							src="@/assets/equipo/jaime.webp"
							alt=""
							style="object-fit: contain; width: 250px; height: 250px"
							class="my-4" />
						<div class="text-center text-container">
							<h4 class="fw-bolder text-primary">Jaime Garrido</h4>
							<p>CMO</p>
						</div>
					</div>
				</Slide>
				<Slide key="2">
					<div class="d-flex flex-column align-items-center">
						<img
							src="@/assets/equipo/fernandoGarnacho.webp"
							alt=""
							style="object-fit: contain; width: 250px; height: 250px"
							class="my-4" />
						<div class="text-center text-container">
							<h4 class="fw-bolder text-primary">Fernando Garnacho</h4>
							<p>COO</p>
						</div>
					</div>
				</Slide>
				<Slide key="3">
					<div class="d-flex flex-column align-items-center">
						<img
							src="@/assets/equipo/ignacio.webp"
							alt=""
							style="object-fit: contain; width: 250px; height: 250px"
							class="my-4" />
						<div class="text-center text-container">
							<h4 class="fw-bolder text-primary">Ignacio Losas</h4>
							<p>CEO</p>
						</div>
					</div>
				</Slide>
				<Slide key="4">
					<div class="d-flex flex-column align-items-center">
						<img
							src="@/assets/equipo/alfonso.webp"
							alt=""
							style="object-fit: contain; width: 250px; height: 250px"
							class="my-4" />
						<div class="text-center text-container">
							<h4 class="fw-bolder text-primary">Alfonso Bernal</h4>
							<p>Business Development Manager</p>
						</div>
					</div>
				</Slide>
				<Slide key="5">
					<div class="d-flex flex-column align-items-center">
						<img
							src="@/assets/equipo/diego.webp"
							alt=""
							style="object-fit: contain; width: 250px; height: 250px"
							class="my-4" />
						<div class="text-center text-container">
							<h4 class="fw-bolder text-primary">Diego Carhuavilca</h4>
							<p>Development</p>
						</div>
					</div>
				</Slide>
				<Slide key="6">
					<div class="d-flex flex-column align-items-center">
						<img
							src="@/assets/equipo/nabil.webp"
							alt=""
							style="object-fit: contain; width: 250px; height: 250px"
							class="my-4" />
						<div class="text-center text-container">
							<h4 class="fw-bolder text-primary">Nabil Ali</h4>
							<p>Development</p>
						</div>
					</div>
				</Slide>
				<Slide key="7">
					<div class="d-flex flex-column align-items-center">
						<img
							src="@/assets/equipo/jorge.webp"
							alt=""
							style="object-fit: contain; width: 250px; height: 250px"
							class="my-4" />
						<div class="text-center text-container">
							<h4 class="fw-bolder text-primary">Jorge González</h4>
							<p>Head of development</p>
						</div>
					</div>
				</Slide>
				<Slide key="8">
					<div class="d-flex flex-column align-items-center">
						<img
							src="@/assets/equipo/pilar.webp"
							alt=""
							style="object-fit: contain; width: 250px; height: 250px"
							class="my-4" />
						<div class="text-center text-container">
							<h4 class="fw-bolder text-primary">Pilar Lerma</h4>
							<p>Customer Experience Specialist</p>
						</div>
					</div>
				</Slide>
				<Slide key="9">
					<div class="d-flex flex-column align-items-center">
						<img
							src="@/assets/equipo/juan.webp"
							alt=""
							style="object-fit: contain; width: 250px; height: 250px"
							class="my-4" />
						<div class="text-center text-container">
							<h4 class="fw-bolder text-primary">Juan Losas</h4>
							<p>Marketing Manager</p>
						</div>
					</div>
				</Slide>
				<Slide key="10">
					<div class="d-flex flex-column align-items-center">
						<img
							src="@/assets/equipo/fernandoNovella.webp"
							alt=""
							style="object-fit: contain; width: 250px; height: 250px"
							class="my-4" />
						<div class="text-center text-container">
							<h4 class="fw-bolder text-primary">Fernando Novella</h4>
							<p>Product Manager</p>
						</div>
					</div>
				</Slide>
				<Slide key="11">
					<div class="d-flex flex-column align-items-center">
						<img
							src="@/assets/equipo/rocio.webp"
							alt=""
							style="object-fit: contain; width: 250px; height: 250px"
							class="my-4" />
						<div class="text-center text-container">
							<h4 class="fw-bolder text-primary">Rocío Tornero</h4>
							<p>Head of Communication</p>
						</div>
					</div>
				</Slide>
				<template #addons>
					<Navigation />
				</template>
			</Carousel>
		</div>
	</div>
</template>

<script>
	import { Carousel, Slide, Navigation } from 'vue3-carousel';
	import { useI18n } from 'vue-i18n';
	import { ref } from 'vue';
	export default {
		components: {
			Carousel,
			Slide,
			Navigation,
		},
		setup() {
			const { t } = useI18n();
			const breakpoints = ref({});
			breakpoints.value = {
				0: {
					itemsToShow: 1,
				},
				900: {
					itemsToShow: 2.5,
				},
				1200: {
					itemsToShow: 3,
				},
			};
			return { t, breakpoints };
		},
	};
</script>

<style lang="scss" scoped>
	.container-equipos,
	.container-valores,
	.titulo-cifras,
	.season {
		max-width: 1200px;
		margin: auto;
	}

	.col-3 {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.img-season {
		max-width: 550px;
	}

	.text-container {
		background-color: rgb(236, 236, 236);
		width: 350px;
		height: 160px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		padding: 1rem;
		border-radius: var(--bs-border-radius-xl);
	}

	.seccion-principal {
		background-image: url('~@/assets/fotos/est1.webp');
		background-size: cover;
		padding-top: 10rem;
		padding-bottom: 10rem;
		background-position: 50%;
	}

	.titulo-banner {
		margin-top: 5rem;
		width: fit-content;
	}

	.text-banner {
		margin-top: 3rem;
		margin-left: 2rem;
		padding: 1.5rem;
		width: 50%;
	}

	@media screen and (max-width: 992px) {
		.text-banner {
			width: 100% !important;
			margin: 2rem 0 !important;
		}
		.desktop-carousel {
			display: none;
			padding: 0 1rem;
		}
		.mobile-carousel {
			display: block;
		}

		.titulo-cifras {
			padding: 0 0 0 1rem;
		}

		.container-equipos {
			.text-primary {
				padding-left: 1rem !important;
			}
		}

		.container-cifras {
			.textos {
				flex-direction: column;
				align-items: center;

				.col-3 {
					margin: 1rem 0;
					width: 100% !important;
				}
			}
		}
		.container-valores {
			padding: 0 1rem !important;
			.middle {
				flex-direction: column-reverse !important;
			}
			.container-contenido {
				flex-direction: column;
				align-items: center;
				padding: 0 !important;

				.col-6 {
					width: 100% !important;
				}

				.text-primary {
					margin-top: 1.5rem;
				}
			}
		}

		.img-season {
			max-width: 350px;
		}
	}
</style>
