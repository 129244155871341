<template>
	<div class="d-flex align-items-center input-selector-cantidad">
		<button type="button" class="btn btn-circle" :disabled="disabled || count <= minCount" @click="decrementCount">
			<i class="fa-solid fa-minus" />
		</button>
		<div class="selector-servicio text-primary mx-2">
			<span class="numero-servicios">{{ count }}</span>
		</div>
		<button type="button" class="btn btn-circle" :disabled="disabled || count >= maxCount" @click="incrementCount">
			<i class="fa-solid fa-plus" />
		</button>
	</div>
</template>

<script setup>
	const props = defineProps({
		count: {
			type: Number,
			default: 1,
		},
		disabled: {
			type: Boolean,
			default: false,
		},
		minCount: {
			type: Number,
			default: 1,
		},
		maxCount: {
			type: Number,
			default: 10,
		},
	});

	const emit = defineEmits(['updateCount']);

	function incrementCount() {
		if (props.count < props.maxCount) {
			emit('updateCount', props.count + 1);
		}
	}

	function decrementCount() {
		if (props.count > props.minCount) {
			emit('updateCount', props.count - 1);
		}
	}
</script>

<style lang="scss" scoped>
	.input-selector-cantidad {
		.btn-circle {
			width: 26px;
			height: 26px;
			min-width: 26px;
			flex-shrink: 0;
			padding: 0;
			border-radius: 50%;
			text-align: center;
			font-size: 12px;
			line-height: 24px;
			border: 1px solid var(--bs-secondary);
			color: var(--bs-secondary);
			background: white;

			&:hover:not(:disabled) {
				background-color: var(--bs-secondary);
				color: white;
			}

			&:disabled {
				opacity: 0.5;
				cursor: not-allowed;
			}
		}

		.selector-servicio {
			width: 50px;
			background-color: rgba(var(--bs-primary-rgb), 0.02);
			box-shadow: 0 0 3px 0 rgba(var(--bs-primary-rgb), 0.35) inset;
			border-radius: var(--bs-border-radius);
			padding: 0.375rem 0.75rem;
			text-align: center;
			border: var(--bs-border-width) solid var(--bs-border-color);
		}

		.numero-servicios {
			font-weight: 400;
			font-size: 16px;
			line-height: 1.5;
			text-align: center;
			display: block;
			color: rgba(var(--bs-primary-rgb), var(--bs-text-opacity));
		}
	}
</style>
