<template>
	<div class="video-background">
		<video class="w-100" preload="none" playsinline autoplay muted loop type="video/webm">
			<source :src="videoSourceMP4" type="video/mp4" autoplay />
			<source :src="videoSourceWebM" type="video/webm" autoplay />
		</video>
		<div>
			<div class="div-title-video">
				<div v-if="isMobile" class="div-logo">
					<Transition name="slide-fade">
						<router-link v-if="!scroll" :to="{ name: 'home' }">
							<img
								alt="Logo Ski&Night"
								title="Logo Ski&Night"
								width="128"
								:src="storeGlobal.valores.img_logo_agencia"
								class="logo-img" />
						</router-link>
					</Transition>
				</div>
				<div>
					<div v-if="!isMobile" class="unselectable">
						<h1>VIVE EL VERANO DE TU VIDA</h1>
					</div>
					<div v-else class="unselectable">
						<h2>VIVE EL VERANO</h2>
						<h2>DE TU VIDA</h2>
					</div>
				</div>
				<div class="w-100">
					<Transition name="slide-fade">
						<div v-if="isMobile && !scroll" class="scroll-indicator unselectable" @click="scrollDown">
							<span>scroll</span>
							<div class="mt-3 bounce">
								<svg width="24" height="62" viewBox="0 0 12 31" fill="none" xmlns="http://www.w3.org/2000/svg">
									<path
										d="M5.46967 30.5303C5.76256 30.8232 6.23744 30.8232 6.53033 30.5303L11.3033 25.7574C11.5962 25.4645 11.5962 24.9896 11.3033 24.6967C11.0104 24.4038 10.5355 24.4038 10.2426 24.6967L6 28.9393L1.75736 24.6967C1.46447 24.4038 0.989591 24.4038 0.696698 24.6967C0.403805 24.9896 0.403805 25.4645 0.696698 25.7574L5.46967 30.5303ZM5.25 -3.27835e-08L5.25 30L6.75 30L6.75 3.27835e-08L5.25 -3.27835e-08Z"
										fill="white" />
								</svg>
							</div>
						</div>
					</Transition>
				</div>
			</div>
		</div>
	</div>
</template>

<script setup>
	import { ref, onMounted, onUnmounted } from 'vue';
	import videoPathWebM from '@/assets/videos/SaN_w.webm';
	import videoPathMP4 from '@/assets/videos/SaN_mp.mp4';
	import { appGlobalStore } from '@/store_pinia/app';

	const videoSourceWebM = ref('');
	const videoSourceMP4 = ref('');
	const storeGlobal = appGlobalStore();

	const scroll = ref(false);
	const isMobile = ref(window.innerWidth <= 992);

	const handleResize = () => {
		isMobile.value = window.innerWidth <= 992;
	};

	onMounted(() => {
		videoSourceWebM.value = videoPathWebM;
		videoSourceMP4.value = videoPathMP4;
		window.addEventListener('scroll', onScroll);
		window.addEventListener('resize', handleResize);
	});

	const onScroll = () => {
		if (window.scrollY > 80) {
			scroll.value = true;
		} else {
			scroll.value = false;
		}
	};

	const scrollDown = () => {
		window.scrollBy({
			top: 650,
			left: 0,
			behavior: 'smooth',
		});
	};

	onUnmounted(() => {
		window.removeEventListener('scroll', onScroll);
		window.removeEventListener('resize', handleResize);
	});
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/san.scss';

	.video-background {
		@extend .w-100, .position-relative, .overflow-hidden;
		height: 768px;

		&:before {
			@extend .d-block;
			content: '';
			padding-top: 56.25%;
		}

		&:after {
			@extend .position-absolute, .top-0, .start-0, .end-0, .bottom-0;
			content: '';

			background: linear-gradient(
				180deg,
				rgba(0, 0, 0, 0.986836) -23.38%,
				rgba(49, 48, 86, 0.99) 29.55%,
				rgba(49, 48, 86, 0.99) 48.07%,
				rgba(33, 36, 68, 0.99) 74.9%
			);
			opacity: 0.5;
			z-index: 2;
		}

		@include media-breakpoint-down(lg) {
			height: 100vh;
			width: 100vw;
			&:after {
				background: linear-gradient(
					180deg,
					rgba(0, 0, 0, 0.986836) -23.38%,
					rgba(49, 48, 86, 0.99) 29.55%,
					rgba(49, 48, 86, 0.99) 48.07%,
					rgba(33, 36, 68, 0.99) 74.9%
				);
				opacity: 0.5;
				z-index: 2;
			}
		}

		video {
			@extend .position-absolute, .top-0, .start-0, .w-100, .h-100, .object-fit-cover;
			z-index: 1;
		}
	}

	.div-title-video {
		@extend .text-white,
			.position-absolute,
			.top-50,
			.start-50,
			.translate-middle,
			.w-100,
			.text-center,
			.align-items-center;
		min-height: 200px;
		z-index: 5;

		.div-logo {
			@extend .overflow-hidden, .mb-3;
			height: 35vw;
			opacity: 1;
		}

		h1 {
			@extend .text-white;
			font-size: 4.5vw !important;
			margin-top: 10rem;
			font-weight: 700;
		}

		h2 {
			@extend .text-white;
			font-size: 7vw !important;
			font-weight: 700;
		}
	}

	.slide-fade-enter-active {
		transition: all 0.2s ease-out;
	}

	.slide-fade-leave-active {
		transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
	}

	.slide-fade-enter-from,
	.slide-fade-leave-to {
		opacity: 0;
	}

	.scroll-indicator {
		@extend .position-absolute, .text-center, .fs-3, .text-white, .w-100;
		margin-top: 13rem;

		@include media-breakpoint-down(lg) {
			margin-top: 7rem;
		}

		@include media-breakpoint-down(sm) {
			margin-top: 5rem;
		}

		@media (max-height: 750px) {
			margin-top: 5rem;
		}

		@media (min-height: 751px) {
			margin-top: 8rem;
		}
	}

	.unselectable {
		-webkit-user-select: none;
		-moz-user-select: none;
		-ms-user-select: none;
		user-select: none;
	}

	@keyframes animation-arrow {
		0%,
		100% {
			clip-path: polygon(0% 50%, 100% 50%, 100% 100%, 0% 100%);
		}
		50% {
			clip-path: polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%);
		}
	}

	.bounce {
		animation: animation-arrow 2s infinite;
	}

	.logo-img {
		@extend .h-100, .w-auto;
	}
</style>
