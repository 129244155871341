<template>
	<div :class="['card-habitacion', { completa: esCompleta, editando: esEditando }]">
		<div class="header" @click="toggleCollapse">
			<div>
				<span class="titulo">{{ t('general.habitacion') + ' ' + nombre }}</span>
				<div class="indicador-personas titulo mt-2">
					<div>{{ integrantes.length }}/{{ capacidad }}</div>
					<div class="iconos">
						<template v-for="(integrante, index) in capacidadArray" :key="index">
							<template v-if="index < integrantes.length">
								<IconOccupied :user="integrantes[index]" class="icono" thin-border />
							</template>
							<template v-else>
								<IconFree class="icono" rooming />
							</template>
						</template>
					</div>
					<template v-if="esCompleta">
						<i class="fa-solid fa-check check-completa"></i>
					</template>
				</div>
			</div>
			<div class="d-flex justify-content-center align-items-center">
				<button class="btn bg-transparent border-0 p-0">
					<i class="fa-solid fa-chevron-up titulo me-2 mt-2" :class="isCollapsed ? 'icon-active' : 'icon-inactive'" />
				</button>
			</div>
		</div>
		<transition
			name="collapse"
			@before-enter="onBeforeEnter"
			@after-enter="onAfterEnter"
			@before-leave="onBeforeLeave"
			@after-leave="onAfterLeave">
			<div v-show="!isCollapsed">
				<div v-show="isContentVisible" class="integrantes">
					<table class="table-integrantes">
						<tbody>
							<tr v-for="(integrante, index) in integrantes" :key="index" class="integrante-row">
								<td class="integrante-icon">
									<IconOccupied :user="integrante" class="icono" thin-border />
								</td>
								<td class="integrante-nombre-apellido titulo">
									<span>{{ getFullName(integrante) }}</span>
								</td>
								<td class="integrante-cross">
									<CrossIcon class="icono-eliminar" @click="eliminarIntegrante(integrante)" />
								</td>
							</tr>
						</tbody>
					</table>
					<template v-if="!editMode && !esEditando && !esCompleta">
						<div class="estado-agregar titulo" @click="activarEdicion">
							{{ t('rooming.cardHabitacion.anadirIntegrantes') }}
						</div>
					</template>
					<template v-if="editMode && !esCompleta && !esCompleta">
						<div class="estado-agregar-container">
							<div class="estado-agregar-dashed titulo" @click="desactivarEdicion">
								{{ t('rooming.cardHabitacion.selectFromTable') }}
							</div>
						</div>
					</template>
				</div>
			</div>
		</transition>
	</div>
</template>

<script setup>
	import { ref, computed, watch, onMounted, onBeforeUnmount } from 'vue';
	import { useI18n } from 'vue-i18n';
	import IconFree from '@/components/Icons/IconFree.vue';
	import IconOccupied from '@/components/Icons/IconOccupied.vue';
	import CrossIcon from '@/components/Icons/CrossIcon.vue';

	const { t } = useI18n();

	const props = defineProps({
		capacidad: {
			type: Number,
			required: true,
		},
		integrantes: {
			type: Array,
			default: () => [],
		},
		nombre: {
			type: String,
			required: true,
		},
		alojamiento: {
			type: String,
			required: true,
		},
		idHabitacion: {
			type: Number,
			required: true,
		},
		expandedHabitacionId: {
			type: String,
			default: '',
		},
	});

	const emit = defineEmits(['agregar-integrante', 'is-editing', 'eliminar-integrante', 'toggle-expand']);

	const esCompleta = computed(() => props.integrantes.length === props.capacidad);
	const isCollapsed = computed(
		() => props.expandedHabitacionId !== `${props.alojamiento.nombre}-${props.idHabitacion}`
	);
	const isContentVisible = ref(false);
	const editMode = ref(false);

	const isMobile = ref(window.innerWidth < 768);

	const capacidadArray = computed(() => {
		return Array.from({ length: props.capacidad });
	});

	function handleResize() {
		isMobile.value = window.innerWidth < 768;
	}

	function toggleCollapse() {
		if (isCollapsed.value) {
			emit('toggle-expand', props.idHabitacion);
		} else {
			emit('toggle-expand', null);
		}
	}

	function onBeforeEnter() {
		isContentVisible.value = true;
	}

	function onAfterEnter() {
		isContentVisible.value = true;
	}

	function onBeforeLeave() {
		isContentVisible.value = false;
	}

	function onAfterLeave() {
		isContentVisible.value = false;
	}

	function activarEdicion() {
		editMode.value = true;
		emit('is-editing', editMode.value, props.alojamiento.id, props.idHabitacion);
	}

	function desactivarEdicion() {
		editMode.value = false;
		emit('is-editing', editMode.value, props.alojamiento.id, props.idHabitacion);
	}

	function eliminarIntegrante(integrante) {
		emit('eliminar-integrante', props.idHabitacion, integrante);
	}

	function getFullName(integrante) {
		return integrante.nombre + ' ' + integrante.apellidos;
	}

	watch(
		() => props.integrantes.length,
		(newVal, oldVal) => {
			if (newVal === props.capacidad) {
				emit('toggle-expand', null);
			} else if (oldVal === props.capacidad && newVal < props.capacidad) {
				emit('toggle-expand', props.idHabitacion);
			}
		}
	);

	onMounted(() => {
		window.addEventListener('resize', handleResize);
	});

	onBeforeUnmount(() => {
		window.removeEventListener('resize', handleResize);
	});
</script>

<style scoped lang="scss">
	.titulo {
		color: var(--bs-primary);
	}

	.card-habitacion {
		border-radius: var(--bs-border-radius-xl);
		border: 1px solid rgba(var(--bs-primary-rgb, 0.5));
		background: white;

		box-shadow: 4px 6px 20px 0px rgba(var(--bs-primary-rgb), 0.1), 2px 4px 5px 0px rgba(var(--bs-primary-rgb), 0.1),
			0px 0px 1px 0px rgba(var(--bs-primary-rgb), 0.4);

		color: var(--bs-primary);
		font-size: 14px;
		font-style: normal;
		font-weight: 600;
		line-height: normal;
		letter-spacing: 0.154px;
		margin-bottom: 10px;
		padding: 10px;

		@media (max-width: 768px) {
			padding: 5px;
		}
	}

	.card-habitacion.completa {
		border: 1px solid #3ab726;
	}

	.header {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 10px;
		cursor: pointer;
		@media (max-width: 768px) {
			margin-bottom: 0 !important;
		}
	}

	.indicador-personas {
		display: flex;
		font-size: 18px;
	}

	.iconos {
		display: flex;
		align-items: center;
		gap: 5px;
		margin-left: 10px;
		flex-wrap: wrap;
	}

	.icono {
		width: 22px;
		height: 22px;
		margin-left: -10px;
	}

	.icono:first-child {
		margin-left: 0;
	}

	.icono-eliminar {
		width: 14px;
		height: 14px;
		cursor: pointer;
		margin-right: 13px;
		text-align: right;
		flex-shrink: 0;
		visibility: hidden;
		@media (max-width: 768px) {
			visibility: visible;
			margin-right: 0;
		}
	}

	.table-integrantes {
		width: 100%;
		border-collapse: collapse;
	}

	.table-integrantes tr:hover {
		background-color: #f9f7f8;
	}

	.integrante-row {
		background-color: white;
		border-bottom: 1px solid #ddd;
		position: relative;

		&:hover {
			background-color: #f9f7f8;

			.icono-eliminar {
				visibility: visible;
			}
		}
	}

	.integrante-icon,
	.integrante-nombre,
	.integrante-cross {
		padding: 8px;
		text-align: left;
		vertical-align: middle;
	}

	.integrante-nombre {
		padding-left: 5px;
	}

	.integrante-cross {
		text-align: right;
		flex-shrink: 0;
	}

	.estado-agregar-container {
		display: flex;
		justify-content: center;
	}

	.estado-agregar,
	.estado-agregar-dashed {
		border-radius: var(--bs-border-radius);
		border: 1px solid var(--bs-primary);
		background: var(--Gris, #f9f7f8);
		display: flex;
		justify-content: center;
		align-items: center;
		gap: 8px;
		width: 100%;
		height: 111px;
		cursor: pointer;
		margin-top: 10px;
		text-align: center;
		padding: 10px;
		box-sizing: border-box;
		z-index: 1059;
	}

	.estado-agregar-dashed {
		border-style: dashed;
	}

	.icon-inactive {
		transition: 0.3s;
	}

	.icon-active {
		transform: rotate(180deg);
		transition: 0.3s;
	}

	.collapse-enter-active,
	.collapse-leave-active {
		transition: height 0.3s ease;
	}

	.collapse-enter,
	.collapse-leave-to {
		height: 0;
		overflow: hidden;
	}

	.check-completa {
		color: #3ab726;
		margin-left: 10px;
		font-size: 18px;
	}

	@media (max-width: 768px) {
		.integrante-row {
			display: flex;
			align-items: center;
		}
		.integrante-icon,
		.integrante-nombre,
		.integrante-cross {
			padding-right: 4px;
			padding-left: 4px;
			text-align: left;
			vertical-align: middle;
		}

		.integrante-nombre-apellido {
			width: 21vw;
			white-space: nowrap;
			text-overflow: ellipsis;
			overflow: hidden;
			font-size: 13px;
			font-weight: 400;
		}

		.header {
			display: block;
			justify-content: space-between;
			align-items: center;
			margin-bottom: 10px;
			cursor: pointer;
		}
	}
</style>
